<template>
  <span class="scaling-svg-container">
    <svg xmlns="http://www.w3.org/2000/svg" width="40.211" height="44.012" viewBox="0 0 40.211 44.012">
      <path
        d="M20.106,44.012a4.46,4.46,0,0,1-2.186-.567l-.677-.384A33.858,33.858,0,0,1,0,13.584V13.26A4.484,4.484,0,0,1,2.274,9.367L17.917.571a4.484,4.484,0,0,1,4.375,0l15.64,8.8a4.479,4.479,0,0,1,2.279,3.894v.323A33.858,33.858,0,0,1,22.969,43.062l-.674.381A4.48,4.48,0,0,1,20.106,44.012Zm0-39.549L4.468,13.26v.323A29.392,29.392,0,0,0,19.431,39.17l.674.381.674-.381A29.392,29.392,0,0,0,35.743,13.584V13.26l-15.638-8.8Z"
        fill="#d4ab5b"
      />
    </svg>
  </span>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

@Component
export default class ShieldIcon extends Vue {}
</script>

<style></style>
