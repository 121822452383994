<template>
  <v-card>
    <v-card-text>
      <form-input ref="formInput" :flex="false">
        <v-row justify="center" no-gutters>
          <v-col cols="12" sm="12">
            <input-text
              v-model="passwordModel.oldPassword"
              :label="$t('form.userPasswordCurrent') | capitalize"
              color="accent"
              type="password"
              :rules="[required]"
            />
          </v-col>
        </v-row>
        <v-row justify="center" no-gutters>
          <v-col cols="12" sm="12">
            <input-text
              v-model="passwordModel.newPassword"
              :label="$t('form.userNewPassword') | capitalize"
              color="accent"
              type="password"
              :rules="[required, password]"
            />
          </v-col>
        </v-row>
        <v-row justify="center" no-gutters>
          <v-col cols="12" sm="12">
            <input-text
              v-model="confirmPassword"
              :label="$t('form.userPasswordConfirm') | capitalize"
              color="accent"
              type="password"
              :rules="[required, compare(passwordModel.newPassword, confirmPassword)]"
            />
          </v-col>
        </v-row>
      </form-input>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import { Component, Vue, PropSync } from "vue-property-decorator";

//Inputs
import FormInput from "@/components/shared/forms/FormInput.vue";
import InputText from "@/components/shared/inputs/InputText.vue";

//Models
import PasswordEdit from "@/models/api/administration/account/PasswordEdit";

//Validation
import requiredValidator from "@/rules/requiredValidator";
import passwordValidator from "@/rules/passwordValidator";
import passwordCompareValidator from "@/rules/passwordCompareValidator";

@Component({
  components: {
    FormInput,
    InputText,
  },
})
export default class UserPasswordForm extends Vue {
  @PropSync("value", { required: true })
  passwordModel!: PasswordEdit;

  required = requiredValidator;
  password = passwordValidator;
  compare = passwordCompareValidator;

  confirmPassword = "";

  //Form validation
  validate() {
    return (this.$refs.formInput as HTMLFormElement).validate();
  }
}
</script>

<style lang="scss" scoped>
.v-card {
  box-shadow: unset !important;
  background-color: transparent;
}
</style>
