<template>
  <div class="router-link-new-tab">
    <a :class="getClass()" href @click.prevent.stop="action()">
      <slot></slot>
    </a>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";
import { CustomVueRouter } from "../../../plugins/router";

@Component
export default class RouterLinkNewTab extends Vue {
  @Prop({ required: true })
  to!: string;
  @Prop({ required: true })
  type!: string;

  async action() {
    const url = this.$router.resolve(this.to).href;
    (this.$router as CustomVueRouter).redirectToPage(url);
  }

  getClass(): string {
    if (typeof this.type != "undefined") {
      if (this.type === "a") {
        return "router-link-new-tab__label__a text-caption black--text ml-2";
      }
      if (this.type === "button") {
        return "router-link-new-tab__label__button black--text";
      }
    }
    return "";
  }
}
</script>

<style lang="scss" scoped>
.router-link-new-tab {
  cursor: pointer;
}

.router-link-new-tab__label__a {
  cursor: unset;
  text-transform: initial;
}

.router-link-new-tab__label__button {
  cursor: unset;
  text-transform: uppercase;
}
</style>
