<template>
  <span class="scaling-svg-container" style="margin-left: 4px; margin-bottom: 2px">
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" class="scaling-svg">
      <g fill="none" stroke="#707070" stroke-width="1">
        <circle cx="7" cy="7" r="7" stroke="none" />
        <circle cx="7" cy="7" r="6.5" fill="none" />
      </g>
    </svg>
  </span>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

@Component
export default class CheckBoxOffIconBlack extends Vue {}
</script>

<style></style>
