<template>
  <span class="scaling-svg-container">
    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
      <g transform="translate(-269 -607)">
        <g>
          <circle cx="14" cy="14" r="14" transform="translate(269 607)" />
          <rect width="5.657" height="5.657" transform="translate(279 621) rotate(-45)" fill="#d4ab5b" />
        </g>
      </g>
    </svg>
  </span>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

@Component
export default class ProductCardMarkIcon extends Vue {}
</script>

<style></style>
