<template>
  <div class="carousel">
    <div v-if="!$vuetify.breakpoint.mobile" class="desktop">
      <v-carousel
        ref="carousel"
        v-model="currentPage"
        v-resize="setItemsCount"
        hide-delimiter-background
        :continuous="false"
        show-arrows
        height="400"
      >
        <template #prev="{ on }">
          <v-btn class="btn-block-icon" width="58px" icon v-on="on">
            <v-icon color="black">$buttonArrowLeft</v-icon>
          </v-btn>
        </template>
        <template #next="{ on }">
          <v-btn class="btn-block-icon" width="58px" icon v-on="on">
            <v-icon color="black">$buttonArrowRight</v-icon>
          </v-btn>
        </template>
        <v-carousel-item v-for="page in pages" :key="page">
          <div v-for="item in getItemsForPage(page)" :key="item.type">
            <product-card :item="item" />
          </div>
        </v-carousel-item>
      </v-carousel>
    </div>
    <div v-else class="mobile">
      <div v-for="item in items" :key="item.type">
        <product-card :item="item" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import Product from "@/models/api/products/product";

//MyComponent
import ProductCard from "@/components/shared/layout/ProductCard.vue";

@Component({
  components: {
    ProductCard,
  },
})
export default class Carousel extends Vue {
  @Prop({ required: true })
  items!: Array<Product>;
  @Prop({ default: false })
  isMobile!: boolean;

  currentPage = 0;

  itemsCount = 3; //Need to be initialized to get style data

  pages = 2;

  getItemsForPage(page: number): Array<Product> {
    const items = this.items.slice((page - 1) * this.itemsCount, page * this.itemsCount);
    return items;
  }

  @Watch("items")
  setItemsCount() {
    setTimeout(() => {
      const itemsCount = this.getWindowContainer();
      this.itemsCount = itemsCount;
      if (this.itemsCount == 0) this.pages = 0;
      else this.pages = Math.ceil(this.items.length / this.itemsCount);
      this.currentPage = 0;
    }, 400);
  }

  getWindowContainer() {
    if (typeof this.$refs.carousel === "undefined") return 1;
    const y = Array.from((this.$refs.carousel as Vue).$el.children).filter(
      (x) => x.className === "v-window__container"
    )[0];

    var styles1 = window.getComputedStyle(y);
    var padding = parseFloat(styles1["paddingLeft"]) + parseFloat(styles1["paddingRight"]);

    const b = y.querySelectorAll(".product-card");
    const n = Array.from(b).filter((x) => x.clientHeight != 0)[0];

    let c = 0;

    var styles2 = window.getComputedStyle(n);
    var margin = parseFloat(styles2["marginLeft"]) + parseFloat(styles2["marginRight"]);
    c = n.clientWidth + margin;

    let result = Math.floor((y.clientWidth - padding) / c);
    if (result > 3) result = 3;
    return result;
  }

  // mounted() {
  //   this.setItemsCount();
  // }
}
</script>

<style lang="scss" scoped>
.carousel {
  width: 100%;
  max-width: 1250px;
}

.desktop {
}

.mobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.v-window {
  max-width: 1250px;
}

::v-deep .v-window__container {
  padding: 0 90px;
  .v-window__prev {
    height: 100%;
    top: 0;
    border-radius: unset;
    background: transparent;
    .v-btn {
      top: calc(50% - 20px);
    }
  }
  .v-window__next {
    height: 100%;
    top: 0;
    border-radius: unset;
    background: transparent;
    .v-btn {
      top: calc(50% - 20px);
    }
  }
}

::v-deep .v-responsive__content {
  display: flex;
  justify-content: center;
}

.v-window {
  &-x-transition,
  &-x-reverse-transition,
  &-y-transition,
  &-y-reverse-transition {
    &-enter-active {
      animation: fadeIn 1s;
      transition: 1s cubic-bezier(0.25, 0.8, 0.5, 1) !important;
    }
    &-leave-active {
      animation: fadeOut 1s;
      transition: 1s cubic-bezier(0.25, 0.8, 0.5, 1) !important;
    }
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
</style>
