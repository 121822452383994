export enum FILTER_NAMES {
  DefaultFilter = "DefaultFilter",
  ReportCopiesBasicFilter = "ReportCopiesBasicFilter",
  LicensesBasicFilter = "LicensesBasicFilter",

  // Administration
  AdministrationHierarchyBasicFilter = "AdministrationHierarchyBasicFilter",
  AdministrationReportsFilter = "AdministrationReportsFilter",

  // TransactionHistory
  TransactionHistoryBasicFilter = "TransactionHistoryBasicFilter",
}
