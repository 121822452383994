<template>
  <span class="scaling-svg-container">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19.752"
      height="19.954"
      viewBox="0 0 19.752 19.954"
      class="scaling-svg"
    >
      <g transform="translate(0.038)" fill="none">
        <path
          d="M.691,19.954a.685.685,0,0,1-.715-.65L-.038,8.871a.569.569,0,0,1,.024-.145,1.881,1.881,0,0,1,.641-1.2L8.34.585a2.4,2.4,0,0,1,3.141,0l7.74,6.907a1.206,1.206,0,0,1,.27.453,2.353,2.353,0,0,1,.219.8V19.3c0,.359.063.65-.332.65Z"
          stroke="none"
        />
        <path
          d="M 18.21051216125488 18.45382881164551 L 18.21051216125488 8.808719635009766 C 18.19796943664551 8.757030487060547 18.16852569580078 8.65952205657959 18.12309265136719 8.558538436889648 L 18.0954647064209 8.496697425842285 L 10.47910308837891 1.699548125267029 C 10.33777332305908 1.572728157043457 10.13102340698242 1.499998092651367 9.911852836608887 1.499998092651367 C 9.692182540893555 1.499998092651367 9.485142707824707 1.572978138923645 9.343752861022949 1.700268149375916 L 1.631242990493774 8.643318176269531 C 1.537893056869507 8.727348327636719 1.486073017120361 8.810627937316895 1.477223038673401 8.890848159790039 L 1.462388396263123 9.016143798828125 L 1.474318265914917 18.45382881164551 L 18.21051216125488 18.45382881164551 M 19.37810325622559 19.95382881164551 L 0.6907430291175842 19.95382881164551 C 0.2955830097198486 19.95382881164551 -0.02460699900984764 19.6628475189209 -0.02460699900984764 19.30402755737305 L -0.0377969965338707 8.871357917785645 C -0.0377969965338707 8.831997871398926 -0.02932699769735336 8.782757759094238 -0.01372699812054634 8.726298332214355 C 0.03310300037264824 8.30197811126709 0.2365230023860931 7.880598068237305 0.6276730298995972 7.528488159179688 L 8.340163230895996 0.5854580998420715 C 9.206122398376465 -0.1941817104816437 10.6133337020874 -0.1953519731760025 11.4808931350708 0.5831181406974792 L 19.22074317932129 7.49046802520752 C 19.33918380737305 7.61102819442749 19.42938232421875 7.766098022460938 19.49104309082031 7.943128108978271 C 19.62419319152832 8.23909854888916 19.71051216125488 8.566938400268555 19.71051216125488 8.738298416137695 L 19.71051216125488 19.30402755737305 C 19.71051216125488 19.6628475189209 19.77326393127441 19.95382881164551 19.37810325622559 19.95382881164551 Z"
          stroke="none"
          fill="#000"
        />
      </g>
    </svg>
  </span>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

@Component
export default class HomeIcon extends Vue {}
</script>

<style></style>
