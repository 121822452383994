import { RuleFunctionExtend } from "@/rules/RuleFunction";
import { filterCapitalize } from "@/filters/capitalize";
import i18n from "@/plugins/i18n";

const passwordCompareValidator: RuleFunctionExtend = (value1: unknown | string, value2): boolean | string => {
  if (typeof value1 === "string" && typeof value2 === "string") {
    if (value1 != value2) return filterCapitalize(i18n.t("validation.passwordMismatch").toString());
    return true;
  }
  return filterCapitalize(i18n.t("validation.wrongDataType").toString());
};

export { passwordCompareValidator };
export default passwordCompareValidator;
