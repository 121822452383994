<template>
  <div class="contact">
    <div class="contact-header">
      <div class="text-h3 text-md-h2 font-weight-bold">Kontakt</div>
      <div class="text-subtitle-2 text-md-subtitle-1 font-weight-regular grey--text">Wypełnij formularz kontaktowy</div>
    </div>
    <div class="contact-form mt-10">
      <v-form ref="form" @submit.prevent.stop="send()">
        <v-row v-if="!$store.direct.getters.user.isLogged">
          <v-col class="col" cols="12" sm="6">
            <v-text-field
              :id="`id-name`"
              v-model="formData.name"
              v-maxchars="100"
              flat
              dense
              outlined
              shaped
              hide-details="auto"
              color="black"
              label="Imię/nazwa firmy"
              :rules="[requiredRule, (v) => maxChar(v, 100)]"
              validate-on-blur
              @blur="resetInputValidation('id-name')"
            />
          </v-col>
          <v-col class="col" cols="12" sm="6">
            <v-text-field
              :id="`id-email`"
              v-model="formData.email"
              flat
              dense
              outlined
              shaped
              hide-details="auto"
              color="black"
              label="Email"
              :rules="[requiredRule, emailRule]"
              validate-on-blur
              @blur="resetInputValidation('id-email')"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-text-field
              id="id-title"
              v-model="formData.title"
              v-maxchars="100"
              flat
              dense
              outlined
              shaped
              hide-details="auto"
              color="black"
              label="Tytuł"
              :rules="[requiredRule, (v) => maxChar(v, 100)]"
              validate-on-blur
              @blur="resetInputValidation('id-title')"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-textarea
              :id="`id-message`"
              v-model="formData.message"
              v-maxchars="1000"
              flat
              dense
              outlined
              shaped
              no-resize
              hide-details="auto"
              color="black"
              label="Pytanie"
              :rules="[requiredRule, (v) => maxChar(v, 1000)]"
              validate-on-blur
              counter="1000"
              :spellcheck="false"
              @blur="resetInputValidation('id-message')"
            />
          </v-col>
        </v-row>
        <v-row class="mt-10" justify="center">
          <v-btn class="btn main-light" outlined type="submit" v-text="'Wyślij'" />
        </v-row>
      </v-form>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { FormValidation } from "customTypes/vuetify";
import ContactForm from "@/models/api/user/ContactForm";

//Validation
import required from "@/rules/requiredValidator";
import emailValidatorSimple from "@/rules/emailValidatorSimple";
import maxChar from "@/rules/maxCharsValidator";

@Component({
  components: {},
})
export default class Contact extends Vue {
  formData: ContactForm = new ContactForm();

  requiredRule = required;
  emailRule = emailValidatorSimple;
  maxChar = maxChar;

  async send() {
    if (this.validate()) {
      await this.$store.direct.dispatch.user.sendContactForm(this.formData);
      this.reset();
    }
  }

  resetValidation() {
    (this.$refs.form as FormValidation).resetValidation();
  }

  reset() {
    (this.$refs.form as FormValidation).reset();
  }

  validate() {
    return (this.$refs.form as FormValidation).validate();
  }

  resetInputValidation(id: string) {
    // eslint-disable-next-line
    const input = (this.$refs.form as HTMLFormElement).inputs.filter(
      // eslint-disable-next-line
      (x: any) => x.$refs.input.id === id
    )[0];
    input.resetValidation();
  }
}
</script>

<style lang="scss" scoped>
.contact {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 100px;
  margin-bottom: 100px;
}

.contact-form {
  width: 100%;
  max-width: 700px;
}

.row {
  margin: 0;
}
</style>
