import { DateTimeFormat } from "vue-i18n";
import CompanyDetails from "@/models/api/company/CompanyDetails";
import InvoiceDetails from "@/models/api/document/invoice";

export default class TransactionHistory {
  id!: string;
  recordStampId!: number;
  licenseName!: string;
  companyName!: string;
  productName!: string;
  amount!: string;
  transactionStatus!: TransactionStatusEnum;
  createdAt!: DateTimeFormat;
  sentAt!: DateTimeFormat | null;
  provider!: string;
}

export enum TransactionStatusEnum {
  Created = 1,
  Pending = 2,
  Success = 3,
  Failure = 4,
}

export class TransactionDetails {
  id!: string;
  orderId!: string;
  product!: string;
  amount!: string;
  status!: TransactionStatusEnum;
  createdAt!: DateTimeFormat;
  paymentDate!: DateTimeFormat;
  companyDetails!: CompanyDetails;
  invoiceDetails!: InvoiceDetails;
}
