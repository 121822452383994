<template>
  <span class="scaling-svg-container">
    <svg xmlns="http://www.w3.org/2000/svg" width="15.2" height="19.2" viewBox="0 0 15.2 19.2">
      <g transform="translate(-4.4 -2.4)">
        <path
          d="M0,0V4H12V0H10V2H8V0H4V2H2V0Z"
          transform="translate(6 3)"
          fill="none"
          stroke="#000"
          stroke-width="1.2"
        />
        <rect width="14" height="5" rx="1" transform="translate(5 16)" fill="none" stroke="#000" stroke-width="1.2" />
        <path
          d="M10,0a21.547,21.547,0,0,0,2,9H0A22.607,22.607,0,0,0,2,0Z"
          transform="translate(6 7)"
          fill="none"
          stroke="#000"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1.2"
        />
      </g>
    </svg>
  </span>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

@Component
export default class StatisticIcon extends Vue {}
</script>

<style></style>
