import Vue from "vue";
import App from "./App.vue";
import store from "@/store";

//plugins
import router from "@/plugins/router";
import vuetify from "@/plugins/vuetify";
import i18n from "./plugins/i18n";
import "./plugins/apexcharts";
import VueGtag from "vue-gtag";

//scss
import "@/scss/style.scss";

//Filters
import "./filters/dateFormat";

//Directives
import "./directives/maxchars";

import { RequestErrorName } from "./requests/RequestError";

Vue.config.productionTip = false;

import Maska from "maska";
Vue.use(Maska);

//$Handling error from Vue components on cancellation previous request
Vue.config.errorHandler = function (err) {
  if (err.name !== RequestErrorName) throw err;
};

if (process.env.NODE_ENV === "production") {
  Vue.use(
    VueGtag,
    {
      config: {
        id: "G-VYE9F0FRKS",
      },
    },
    router
  );
}

export const app = new Vue({
  router,
  store: store.original,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
