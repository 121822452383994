<template>
  <div class="transaction">
    <v-card class="transaction-card">
      <v-card-title class="transaction-card__title text-h4 font-weight-bold">
        {{ getTitle() }}
      </v-card-title>
      <v-card-text class="transaction-card__text">
        <div style="padding: 15px 0">
          <v-icon size="100"> {{ getIcon() }} </v-icon>
        </div>
        <span class="text-body-1">
          {{ getText() }}
        </span>
      </v-card-text>
      <v-card-actions class="transaction-card__action">
        <v-btn class="btn main-light" to="/"> Wróć do strony głównej</v-btn>
        <v-btn class="btn main-light--reverse" to="/licenses/license"> Przejdź do panelu </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

//Models
import TransactionStatus from "@/models/api/transaction/transactionStatus";

@Component
export default class TransactionRedirectionCard extends Vue {
  get transactionStatus(): TransactionStatus | null {
    return this.$store.direct.state.transaction.transactionStatus as TransactionStatus;
  }

  getIcon() {
    if (this.transactionStatus != null) {
      switch (this.transactionStatus.status) {
        case "CREATED": {
          return "$transactionPending";
        }
        case "SUCCESS": {
          return "$transactionSuccess";
        }
        case "PENDING": {
          return "$transactionPending";
        }
        case "FAILURE": {
          return "$transactionFailure";
        }
      }
    }
  }

  getTitle() {
    if (this.transactionStatus != null) {
      switch (this.transactionStatus.status) {
        case "CREATED": {
          return "Płatność rozpoczęta";
        }
        case "SUCCESS": {
          return "Płatność zakończyła się sukcesem";
        }
        case "PENDING": {
          return "Płatność w trakcie realizacji";
        }
        case "FAILURE": {
          return "Płatność zakończyła się błędem";
        }
      }
    }
  }

  getText() {
    if (this.transactionStatus != null) {
      switch (this.transactionStatus.status) {
        case "CREATED": {
          return "Płatność rozpoczęta";
        }
        case "SUCCESS": {
          return "Na maila wysłaliśmy potwierdzenie płatności.";
        }
        case "PENDING": {
          return "Na maila wysłaliśmy link do ukończenia płatności.";
        }
        case "FAILURE": {
          return "Na maila wysłaliśmy informację dotyczącą błędu płatności.";
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.transaction {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  padding: 40px;
  margin: 10px;
}

.transaction-card {
  border: 1px solid black;
  border-radius: 20px;
  min-width: 320px;
  width: 700px;
  max-width: 100%;
  min-height: 350px;
}

.transaction-card__title {
  display: flex;
  justify-content: center;
  padding-top: 30px;
  min-height: 86px;
  @media screen and (max-width: 400px) {
    word-break: normal;
    text-align: center;
  }
}

.transaction-card__text {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  min-height: 190px;
}

.transaction-card__action {
  display: flex;
  justify-content: center;
  gap: 15px;
  padding: 20px 0 0 0;
  margin-bottom: 20px;
  @media screen and (max-width: 400px) {
    flex-direction: column;
    a {
      margin-left: 0 !important;
    }
  }
}
</style>
