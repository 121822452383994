<template>
  <div style="z-index: 9999">
    <app-bar-desktop v-if="!$vuetify.breakpoint.mobile && canShow()" />
    <app-bar-mobile v-if="$vuetify.breakpoint.mobile" :drawer.sync="_drawer" />
  </div>
</template>

<script lang="ts">
import { Component, Vue, PropSync } from "vue-property-decorator";

import AppBarDesktop from "@/components/app/appBar/AppBarDesktop.vue";
import AppBarMobile from "@/components/app/appBar/AppBarMobile.vue";

@Component({
  components: {
    AppBarDesktop,
    AppBarMobile,
  },
})
export default class AppBar extends Vue {
  @PropSync("drawer", { required: true })
  _drawer!: boolean;

  canShow() {
    return (
      this.$route.path === "/" ||
      this.$route.path === "/login" ||
      this.$route.path === "/register" ||
      this.$route.path === "/remind-password" ||
      this.$route.path === "/faq"
    );
  }
}
</script>

<style scoped></style>
