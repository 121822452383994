<template>
  <span class="scaling-svg-container">
    <svg xmlns="http://www.w3.org/2000/svg" width="16.729" height="16.729" viewBox="0 0 16.729 16.729">
      <g transform="translate(-3.635 -3.635)">
        <g>
          <path
            d="M12.236,4.008A1.605,1.605,0,0,1,15.07,5.182h0A1.605,1.605,0,0,0,16.818,6.93h0a1.605,1.605,0,0,1,1.174,2.834h0a1.605,1.605,0,0,0,0,2.472h0a1.605,1.605,0,0,1-1.174,2.834h0a1.605,1.605,0,0,0-1.748,1.748h0a1.605,1.605,0,0,1-2.834,1.174h0a1.605,1.605,0,0,0-2.472,0h0A1.605,1.605,0,0,1,6.93,16.818h0A1.605,1.605,0,0,0,5.182,15.07h0a1.605,1.605,0,0,1-1.174-2.834h0a1.605,1.605,0,0,0,0-2.472h0A1.605,1.605,0,0,1,5.182,6.93h0A1.605,1.605,0,0,0,6.93,5.182h0A1.605,1.605,0,0,1,9.764,4.008h0a1.605,1.605,0,0,0,2.472,0Z"
            transform="translate(1 1)"
            fill="none"
            stroke="#000"
            stroke-width="1.5"
          />
          <circle cx="3" cy="3" r="3" transform="translate(9 9)" fill="none" stroke="#000" stroke-width="1.5" />
        </g>
      </g>
    </svg>
  </span>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

@Component
export default class SettingsIcon extends Vue {}
</script>

<style></style>
