<template>
  <div>
    <custom-dialog
      :title="$t('dialog.users') | capitalize"
      width="700px"
      :buttons="false"
      :value.sync="showMainDialog"
      @cancel="cancel"
    >
      <div class="d-flex justify-space-between pb-5 pt-5" style="gap: 10px">
        <v-btn :width="150" class="btn main-light font-11" @click="addUser()">
          <v-icon left x-small>$plus</v-icon>
          {{ $t("actions.addUser") | capitalize }}
        </v-btn>
        <div>
          <v-text-field
            v-model="search"
            color="black"
            dense
            label="Szukaj użytkownika"
            hide-details="auto"
            class="pb-0"
          ></v-text-field>
        </div>
      </div>
      <v-data-table
        ref="table"
        :items="companyUsers"
        :headers="headers"
        :options.sync="tableOptions"
        hide-default-footer
        single-select
        :search="search"
        disable-sort
        :items-per-page="5"
        :no-data-text="'Brak danych'"
        no-results-text="Nie znaleziono użytkownika"
        :height="233"
        fixed-header
      >
        <template #[`item.isActive`]="{ item }">
          <v-simple-checkbox :value="item.isActive" :ripple="false" hide-details="auto"></v-simple-checkbox>
        </template>
        <template #[`item.name`]="{ item }"> ({{ item.shortRoleName }}) {{ item.name }} </template>
        <template #[`item.actions`]="{ item }">
          <v-tooltip v-if="$store.direct.state.user.role > roles.Admin" bottom>
            <template #activator="{ on }">
              <v-btn
                v-if="item.canSetAsAdmin"
                icon
                color="button"
                :ripple="false"
                @click="setUserAsAdmin(item)"
                v-on="on"
              >
                <v-icon>$play</v-icon>
              </v-btn>
              <v-btn v-else icon color="button" :disabled="!item.canSetAsAdmin" :ripple="false" v-on="on">
                <v-icon :disabled="!item.canSetAsAdmin">$play</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("tooltip.setAsAdmin") | capitalize }}</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template #activator="{ on }">
              <v-btn icon color="button" :ripple="false" @click="editUser(item)" v-on="on">
                <v-icon>$edit</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("tooltip.edit") | capitalize }}</span>
          </v-tooltip>
          <v-tooltip v-if="item.isActive" bottom>
            <template #activator="{ on }">
              <v-btn icon color="button" :ripple="false" @click="lockUser(item)" v-on="on">
                <v-icon>$lock</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("tooltip.lock") | capitalize }}</span>
          </v-tooltip>
          <v-tooltip v-else bottom>
            <template #activator="{ on }">
              <v-btn icon color="error" :ripple="false" @click="unlockUser(item)" v-on="on">
                <v-icon>$unlock</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("tooltip.unlock") | capitalize }}</span>
          </v-tooltip>
        </template>
        <template #footer>
          <custom-pagination :options.sync="tableOptions" :server-items-length="companyUsers.length">
          </custom-pagination>
        </template>
      </v-data-table>
    </custom-dialog>
    <administration-add-user-dialog v-if="showAddUserDialog" :value.sync="showAddUserDialog" />
    <administration-edit-user-dialog
      v-if="showEditUserDialog"
      :value.sync="showEditUserDialog"
      :user.sync="selectedUser"
    />
  </div>
</template>

<script lang="ts">
import { Component, Vue, PropSync, Prop, Watch } from "vue-property-decorator";
import { DataTableHeader } from "vuetify";
import DefaultDataOptions from "@/models/components/dataTable/DataTableOptions";

//Components
import CustomDialog from "@/components/shared/layout/CustomDialog.vue";
import CustomPagination from "@/components/shared/dataTable/CustomPagination.vue";

import AdministrationAddUserDialog from "@/components/administration/AdministrationAddUserDialog.vue";
import AdministrationEditUserDialog from "@/components/administration/AdministrationEditUserDialog.vue";

//Translation
import { filterCapitalize } from "@/filters/capitalize";
import i18n from "@/plugins/i18n";

//Models
import UserInfoResponse from "@/models/api/user/UserInfoResponse";
import UserDetails from "@/models/api/user/UserDetails";

import ROLE from "@/static/role";

@Component({
  components: {
    CustomDialog,
    CustomPagination,
    AdministrationAddUserDialog,
    AdministrationEditUserDialog,
  },
})
export default class AdministrationUserDialog extends Vue {
  @PropSync("value", { required: true })
  show?: boolean;
  @Prop()
  companyId!: string;

  get companyUsers(): Array<UserInfoResponse> {
    return this.$store.direct.state.administration.selectedCompanyUsersDetails;
  }

  roles = ROLE;

  tableOptions = new DefaultDataOptions();

  //Datatable
  search = "";
  headers: Array<DataTableHeader> = [
    {
      text: filterCapitalize(i18n.t("header.userName")),
      value: "name",
      align: "start",
    },
    {
      text: filterCapitalize(i18n.t("header.userSurname")),
      value: "surname",
      align: "start",
    },
    {
      text: filterCapitalize(i18n.t("header.userEmail")),
      value: "email",
      align: "start",
    },
    {
      text: "",
      value: "actions",
      align: "end",
    },
  ];

  selectedUser: UserDetails | null = null;

  addUser() {
    this.showAddUserDialog = true;
    this.showMainDialog = false;
  }

  async setUserAsAdmin(item: UserInfoResponse) {
    await this.$store.direct.dispatch.administration.setUserAsAdmin(item);
  }

  editUser(item: UserInfoResponse) {
    this.selectedUser = {
      id: item.id,
      name: item.name,
      surname: item.surname,
      email: item.email,
      phoneNumber: "",
    };
    this.showEditUserDialog = true;
  }

  lockUser(item: UserInfoResponse) {
    this.$store.direct.dispatch.administration.lockUser(item);
  }

  unlockUser(item: UserInfoResponse) {
    this.$store.direct.dispatch.administration.unlockUser(item);
  }

  cancel() {
    this.show = false;
  }

  //Current dialog - hide when other are open (addUser or editUser)
  showMainDialog = true;

  //Add, Edit dailogs
  showAddUserDialog = false;
  showEditUserDialog = false;

  @Watch("showAddUserDialog")
  @Watch("showEditUserDialog")
  setMain() {
    if (this.showAddUserDialog || this.showEditUserDialog) this.showMainDialog = false;
    else this.showMainDialog = true;
  }

  async created() {
    await this.$store.direct.dispatch.administration.getSelectedCompanyUserDetails();
  }
}
</script>

<style scoped></style>
