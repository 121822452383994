<template>
  <div :style="`width: ${width}px`">
    <span class="solo-label">{{ _criterion.label }}</span>
    <v-autocomplete
      v-model="_criterion.value"
      :items="_criterion.items"
      item-value="key"
      width="200"
      :solo="true"
      flat
      append-icon="$arrowDown"
      color="#f4f4f4f4"
      :menu-props="{
        auto: false,
        overflowY: false,
        contentClass: 'select-menu',
      }"
      hide-details="auto"
    >
    </v-autocomplete>
  </div>
</template>

<script lang="ts">
import { Component, Vue, PropSync, Prop } from "vue-property-decorator";

//Components
import InputSelect from "@/components/shared/inputs/InputSelect.vue";

//Models
import { CriterionBase } from "@/models/api/filters/CriterionBase";

export interface EnumStringCriterionSettings extends CriterionBase {
  items: Array<EnumItemStringCriterion>;
  value: string;
}

export interface EnumItemStringCriterion {
  key: string;
  text: string;
}

@Component({
  components: {
    InputSelect,
  },
})
export default class EnumStringCriterion extends Vue {
  @PropSync("criterion")
  _criterion!: EnumStringCriterionSettings;
  @Prop({ default: 200 })
  width!: number;
}
</script>

<style lang="scss" scoped>
.solo-label {
  padding-left: 12px;
  font-size: 10px;
}

::v-deep .v-text-field--solo {
  border-radius: 10px;
  .v-input__control {
    font-size: 12px;
    min-height: 35px;
    max-height: 35px;
    .v-input__slot {
      background-color: #f4f4f4f4 !important;
    }
  }
}

::v-deep .v-list-item {
  min-height: 25px !important;
}

::v-deep .v-list-item__content {
  padding: unset;
  .v-list-item__title {
    font-size: 12px !important;
  }
}

::v-deep .v-input__slot {
  background-color: #f4f4f4 !important;
}

::v-deep .v-input__control {
  min-height: 35px !important;
  .v-text-field__slot input {
    color: black;
  }
}

::v-deep .v-list-item--active {
  border-left: 3px solid black;
  background-color: #f4f4f4f4;
  .v-list-item__content {
    .v-list-item__title {
      color: black;
      font-weight: 500 !important;
    }
  }
}

::v-deep .v-icon__component {
  height: 16px;
  width: 16px;
}
</style>
