<template>
  <span class="scaling-svg-container">
    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" class="scaling-svg">
      <g id="Feature" transform="translate(-269 -607)">
        <g id="Group_1000001830" data-name="Group 1000001830">
          <g
            id="Ellipse_1165"
            data-name="Ellipse 1165"
            transform="translate(269 607)"
            fill="none"
            stroke="#21201f"
            stroke-width="1"
          >
            <circle cx="14" cy="14" r="14" stroke="none" />
            <circle cx="14" cy="14" r="13.5" fill="none" />
          </g>
        </g>
      </g>
      <path
        id="icon"
        d="M0,5.5,3.5,9l9-9"
        transform="translate(8.414 9.614)"
        fill="none"
        stroke="#d4ab5b"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
      />
    </svg>
  </span>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

@Component
export default class CheckBoxIcon extends Vue {}
</script>

<style></style>
