<template>
  <div class="input-text" :style="width ? `width: ${width}px` : ''">
    <span v-if="solo" class="solo-label">{{ label }}</span>
    <v-text-field
      ref="input"
      v-model="fieldValue"
      :label="!solo ? label : ''"
      :solo="solo"
      flat
      :error-messages="errorMessages"
      :rules="rules"
      :clearable="clearable"
      :color="color"
      :hide-details="hideDetails"
      :readonly="readonly"
      :disabled="disabled"
      :type="type"
      :autocomplete="type != 'password' ? 'off' : 'new-password'"
      :prepend-icon="prependIcon"
      :prepend-inner-icon="prependInnerIcon"
      :validate-on-blur="validateOnBlur"
      @keypress="checkPressedKey($event)"
      @focus="focus"
      @blur="blur"
      @keyup.native.enter="enter"
      v-on="_on"
    />
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch, PropSync } from "vue-property-decorator";
import maxChar from "@/rules/maxCharsValidator";

@Component
export default class InputText extends Vue {
  @Prop({ required: true })
  value!: string | number | null;
  @Prop()
  label!: string;
  @Prop({ default: "black" })
  color?: string;
  @Prop()
  solo?: boolean;
  @Prop()
  clearable?: boolean;
  @Prop()
  disabled?: boolean;
  @Prop()
  width?: number;
  @Prop()
  readonly?: boolean;
  @Prop({ default: "text" })
  type?: string;
  @Prop({ default: "auto" })
  hideDetails?: boolean | string;
  @Prop({ default: true })
  allowText!: boolean;
  @Prop({ default: true })
  allowNumber!: boolean;
  @Prop({ default: true })
  allowSymbol!: boolean;
  @Prop({ default: null })
  maxLength!: number | null;
  @Prop({ default: false })
  showCounter!: boolean;
  @Prop({ default: true })
  showError!: boolean;
  @Prop({ default: true })
  validateOnBlur!: boolean;
  @Prop()
  // eslint-disable-next-line
  rules?: any;
  @Prop({ default: 12 })
  cols!: number;
  @Prop({ default: true })
  padding!: boolean;
  @PropSync("on")
  // eslint-disable-next-line
  _on!: any;
  @Prop({ default: "" })
  prependIcon!: string;
  @Prop({ default: "" })
  prependInnerIcon!: string;

  errorMessages: Array<string> = [];
  errorMessageLength = "Osiagnięto limit znaków";
  errorMessageType = "";
  letter = "";
  number = "";
  symbol = "";

  //Validation
  maxChar = maxChar;

  get fieldValue(): string | number {
    return this.value ?? "";
  }

  set fieldValue(value: string | number) {
    if (value == null) {
      this.$emit("input", "");
    } else {
      this.$emit("input", value);
    }
  }

  checkPressedKey(e: KeyboardEvent) {
    if (e.key == "Enter") return;
    if (this.checkAllowedLength()) {
      e.preventDefault();
      this.addErrorMessage(1);
    }
    if (this.checkIsNumber(e) && !this.allowNumber) {
      e.preventDefault();
      //this.addErrorMessage(0);
      return;
    }
    if (this.checkIsLetter(e) && !this.allowText) {
      e.preventDefault();
      //this.addErrorMessage(0);
      return;
    }
    if (this.checkIsSymbol(e) && !this.allowSymbol) {
      e.preventDefault();
      //this.addErrorMessage(0);
      return;
    }
    this.checkSelectionRange(e);
  }

  checkIsNumber(e: KeyboardEvent) {
    let char = String.fromCharCode(e.keyCode);
    return /^[0-9]+$/.test(char);
  }

  checkIsLetter(e: KeyboardEvent) {
    let char = String.fromCharCode(e.keyCode);
    return /^[A-Za-z]+$/.test(char);
  }

  checkIsSymbol(e: KeyboardEvent) {
    let char = String.fromCharCode(e.keyCode);
    return !/^[A-Za-z0-9]+$/.test(char);
  }

  checkAllowedLength() {
    if (this.maxLength == null) return false;
    if (this.value != undefined) return this.value.toString().length >= this.maxLength;
  }

  // eslint-disable-next-line
  checkSelectionRange(e: KeyboardEvent) {
    let target = e.target as HTMLTextAreaElement;
    //$ Check length of selected value and remove selected characters
    if (this.value?.toString().length != this.maxLength) return;
    if (target.selectionEnd != target.selectionStart) {
      // this.fieldValue = target.value.slice(0, target.selectionStart) + e.key; TODO: Check fields work properly
      this.fieldValue = target.value.slice(0, target.selectionStart);
      return;
    }
  }

  addErrorMessage(type: number) {
    if (!this.showError) return;
    switch (type) {
      case 0: {
        this.errorMessages.push(this.errorMessageType);
        break;
      }
      case 1: {
        this.errorMessages.push(this.errorMessageLength);
        break;
      }
    }
  }

  @Watch("value")
  removeErrorMessage() {
    if (this.errorMessages.length != 0) {
      setTimeout(() => {
        this.errorMessages = [];
      }, 200);
    }
  }

  prepareErrorMessage() {
    this.letter = this.allowText ? "litery," : "";
    this.number = this.allowNumber ? "cyfry," : "";
    this.symbol = this.allowSymbol ? "symbole" : "";
    this.errorMessageType = `${"Pole akceptuje tylko: "} ${this.letter} ${this.number} ${this.symbol}`;
  }

  created() {
    if (this.showError) this.prepareErrorMessage();
  }

  focus() {
    this.$emit("focus");
  }

  blur() {
    this.$emit("blur");
    this.removeErrorMessage();
  }

  enter() {
    this.$emit("enter");
  }

  removeFocus() {
    (this.$refs.input as HTMLFormElement).blur();
  }
}
</script>

<style lang="scss" scoped>
.v-text-field__slot {
  input {
    padding-bottom: 0px;
  }
}

.solo-label {
  padding-left: 12px;
  font-size: 10px;
}

::v-deep .v-text-field--solo {
  border-radius: 10px;
  .v-input__control {
    font-size: 12px;
    min-height: 35px;
    max-height: 35px;
    .v-input__slot {
      background-color: #f4f4f4f4 !important;
    }
  }
}
</style>
