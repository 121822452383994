<template>
  <v-menu
    v-model="showPicker"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    max-width="400px"
    min-width="320px"
    @input="onMenuOpen"
  >
    <template #activator="{ on }">
      <input-text
        v-model="dateFormated"
        :solo="solo"
        :on="on"
        :label="label"
        :readonly="true"
        :rules="!showPicker ? rules : []"
        :padding="false"
        prepend-inner-icon="$calendar"
        color="black"
        :validate-on-blur="false"
      ></input-text>
    </template>
    <v-date-picker
      v-model="date"
      :min="min"
      :max="max"
      locale="pl"
      flat
      color="black"
      header-color="white"
      first-day-of-week="1"
      width="300px"
      show-adjacent-months
      :show-current="true"
      :active-picker.sync="picker"
      @click:date="closePicker"
    ></v-date-picker>
  </v-menu>
</template>

<script lang="ts">
import { Component, Vue, Watch, Prop } from "vue-property-decorator";

//Components
import InputText from "@/components/shared/inputs/InputText.vue";

@Component({
  components: {
    InputText,
  },
})
export default class InputDate extends Vue {
  @Prop()
  value!: string;
  @Prop()
  min!: string;
  @Prop()
  max!: string;
  @Prop()
  label!: string;
  @Prop()
  solo!: boolean;
  @Prop({ default: false })
  now!: boolean;
  @Prop({ default: true })
  closeOnDay!: boolean;
  @Prop({ default: null })
  activePicker!: string | null;

  @Prop()
  // eslint-disable-next-line
  rules?: any;

  showPicker = false;

  date = "";
  dateFormated = "";

  picker = "YEAR";

  // Set picker to year, when date selector is inactive and we update selectors year list, list will render incorrectly.
  onMenuOpen() {
    if (this.showPicker) {
      setTimeout(() => {
        this.picker = "YEAR";
      }, 100);
    }
  }

  @Watch("date")
  updateValue() {
    this.$emit("input", this.date);
  }

  @Watch("value")
  formatDate() {
    if (this.value != "") {
      this.dateFormated = this.$d(new Date(this.value.substring(0, 10)), "custom", "pl");
      this.date = this.value.substring(0, 10);
    }
  }

  closePicker() {
    if (this.closeOnDay) {
      this.showPicker = false;
    }
  }

  getCurrentDate(): string {
    const date = new Date();
    return date.toJSON().substring(0, 10);
  }

  mounted() {
    this.$nextTick();
    if (this.now) {
      this.$emit("input", this.getCurrentDate());
    }
    this.formatDate();
  }
}
</script>

<style lang="scss" scoped>
.v-menu__content {
  box-shadow: unset !important;
}

::v-deep .v-picker {
  border: 1px solid black;
  border-radius: 10px;
  .v-picker__title {
    color: black;
    display: grid;
    justify-content: center;
    padding-top: 20px;
    .v-date-picker-title__date {
      height: 55px; //$ add height for bottom-border as divider line
      border-bottom: 1px solid black;
    }
  }
  .v-picker__body {
    background: unset !important;
    // margin-bottom: 20px; //$ bottom-margin for table with (years, months, days)
    div {
      .v-date-picker-header {
        padding: 0 68px; //$ padding for arrows to select (year, month) - maximum 68px for polish months name or will be wrappper
        .v-date-picker-header__value {
          .black--text {
            font-size: 20px;
            button {
              // text-transform: capitalize;  //$ small or big first letter
            }
          }
        }
      }
    }
    .v-date-picker-table {
      height: auto;
      padding: 0 35px; //$ left and right padding for table with (years, months, days)
      .v-btn--active {
        .v-btn__content {
          color: white; //$ set white color on disabled item if show-adjacent-months is set on true
        }
      }
    }
  }
}

::v-deep .v-date-picker-years {
  scrollbar-width: none; //remove scrollbar
  overflow-y: scroll;
  margin: 10px 0 25px 0;
}

::v-deep .v-date-picker-table--month {
  margin-bottom: 25px;
}

::v-deep .v-date-picker-table--date {
  margin-bottom: 25px;
}

//For chrome
::v-deep .v-date-picker-years::-webkit-scrollbar {
  display: none;
  overflow: hidden;
}
</style>
