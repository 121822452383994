<template>
  <div>
    <div class="autocomplete-label">
      <span>Użytkownik</span>
    </div>
    <v-autocomplete
      v-model="selected"
      :items="users"
      item-text="email"
      dense
      solo
      hide-details="auto"
      height="15"
      append-icon="$arrowDown"
      no-data-text="Brak użytkowników"
      return-object
      :rules="[required]"
    >
    </v-autocomplete>
  </div>
</template>

<script lang="ts">
import { Component, Vue, PropSync, Prop } from "vue-property-decorator";

//Models
import UserBasic from "@/models/api/user/UserBasic";

//Requests
import { downloadCompanyUsersBasic } from "@/requests/general";

//Validators
import requiredValidator from "@/rules/requiredValidator";

@Component
export default class AutocompleteUsers extends Vue {
  @PropSync("value", { required: true })
  selected!: UserBasic;
  @Prop()
  companyId!: string;

  users: Array<UserBasic> = [];

  //Validators
  required = requiredValidator;

  async companyUsers(): Promise<void> {
    const response = await downloadCompanyUsersBasic(this.companyId);
    this.users = response.model;
  }

  mounted() {
    this.companyUsers();
  }
}
</script>

<style lang="scss" scoped>
.autocomplete-label {
  margin: 0 0 -5px 15px;
  font-size: 9px;
  color: black;
}

::v-deep .v-autocomplete {
  font-size: 12px;
  border-radius: 10px !important;
}

::v-deep .v-list-item {
  min-height: 10px !important;
}

::v-deep .v-list-item__content {
  padding: unset;
  .v-list-item__title {
    font-size: 12px !important;
  }
}

.v-text-field--solo {
  border-radius: 10px !important;
  font-size: 12px;
}

::v-deep .v-input__slot {
  background-color: #f4f4f4 !important;
}

::v-deep .v-input__control {
  min-height: 35px !important;
  .v-text-field__slot input {
    color: black;
  }
}

::v-deep .scaling-svg {
  height: 50% !important;
  width: 50% !important;
  transform: translate(50%, 50%) !important;
}
</style>
