<template>
  <span class="scaling-svg-container">
    <svg xmlns="http://www.w3.org/2000/svg" width="11.555" height="11.896" viewBox="0 0 11.555 11.896">
      <g id="Pyramid_12" data-name="Pyramid 12" transform="translate(0.152 0.628)">
        <g id="Group_21" data-name="Group 21" transform="translate(0 -0.408)">
          <circle
            id="Ellipse_1"
            data-name="Ellipse 1"
            cx="5"
            cy="5"
            r="5"
            transform="translate(0.348 0.28)"
            fill="none"
            stroke="#000"
            stroke-width="1"
          />
          <path
            id="Vector_1"
            data-name="Vector 1"
            d="M0,0,2.005,2.005"
            transform="translate(8.69 8.963)"
            fill="none"
            stroke="#000"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1"
          />
        </g>
      </g>
    </svg>
  </span>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

@Component
export default class LoupeIcon extends Vue {}
</script>

<style></style>
