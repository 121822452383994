import { defineModule } from "direct-vuex";
import { moduleActionContext, moduleGetterContext } from "@/store/index";

// Models
import Product from "@/models/api/products/product";

// Requests
import { getProducts } from "@/requests/product";

export interface ProductStateInterface {
  products: Array<Product>;
  selectedProduct: Product | null;
}

export const ProductState: ProductStateInterface = {
  products: [],
  selectedProduct: null,
};

const productModule = defineModule({
  state: ProductState as ProductStateInterface,
  namespaced: true as const,
  getters: {},
  mutations: {
    setProducts(state, products: Array<Product>) {
      state.products = products;
    },
    setSelectedProduct(state, product: Product) {
      state.selectedProduct = product;
    },
  },
  actions: {
    async getProductList(context) {
      const { commit } = _moduleActionContext(context);
      const response = await getProducts();
      commit.setProducts(response.model);
    },
  },
});

export default productModule;

/* eslint-disable */
const _moduleGetterContext = (args: [any, any, any, any]) =>
  moduleGetterContext(args, productModule);
const _moduleActionContext = (context: any) =>
  moduleActionContext(context, productModule);
