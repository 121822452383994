<template>
  <span class="scaling-svg-container">
    <svg id="Button" xmlns="http://www.w3.org/2000/svg" width="25.949" height="14.777" viewBox="0 0 25.949 14.777">
      <g id="icon" transform="translate(0 0)">
        <path
          d="M0,0H13.223"
          transform="translate(11.726 7.388)"
          fill="none"
          stroke="currentColor"
          stroke-linecap="square"
          stroke-linejoin="round"
          stroke-width="2"
        />
        <path d="M11.726,14.777,0,7.388,11.726,0Z" transform="translate(0)" fill="currentColor" />
      </g>
    </svg>
  </span>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

@Component
export default class ButtonLeftArrowIcon extends Vue {}
</script>

<style></style>
