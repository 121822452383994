<template>
  <custom-dialog :title="title" width="680px" :buttons="buttons" @cancel="cancel" @save="save">
    <div>
      <v-data-table
        :items="licenses"
        :headers="headers"
        hide-default-footer
        disable-sort
        :mobile-breakpoint="0"
      ></v-data-table>
    </div>
    <v-divider style="margin: 40px 0 30px 0"></v-divider>
    <input-selector
      :value.sync="switchState"
      :left-label="$t('transferToCompany') | capitalize"
      :right-label="$t('transferToUser') | capitalize"
    />
    <div>
      <v-container>
        <v-form ref="form">
          <v-flex row align="start" justify="start" wrap>
            <v-col xs="12" sm="10" md="6" lg="5" xl="5">
              <autocomplete-users v-if="switchState" :value.sync="selectedUser" :company-id="license.companyId" />
              <autocomplete-companies
                v-else
                :value.sync="selectedCompany"
                :company-id="license.companyId"
                :show-parent="false"
              />
            </v-col>
            <v-col xs="12" sm="10" md="6" lg="5" xl="5">
              <v-text-field
                v-model="numberOfLicenses"
                hide-details="auto"
                color="accent"
                :label="$t('numberOfReportsToTransfer') | capitalize"
                persistent-placeholder
                :rules="[required, number, numberIsIntiger, numberCompare(numberOfLicenses, license.toUse, '<=')]"
              ></v-text-field>
            </v-col>
          </v-flex>
        </v-form>
      </v-container>
    </div>
    <div style="height: 40px"></div>
  </custom-dialog>
</template>

<script lang="ts">
import { Vue, Component, PropSync, Prop } from "vue-property-decorator";
import { DataTableHeader } from "vuetify";
import { filterCapitalize } from "@/filters/capitalize";
import i18n from "@/plugins/i18n";

//Components
import CustomDialog, { DialogButtonInterface } from "@/components/shared/layout/CustomDialog.vue";
import AutocompleteUsers from "@/components/shared/inputs/AutocompleteUsers.vue";
import AutocompleteCompanies from "@/components/shared/inputs/AutocompleteCompanies.vue";
import InputSelector from "@/components/shared/inputs/InputSelector.vue";

//Validators
import requiredValidator from "@/rules/requiredValidator";
import numberValidator from "@/rules/numberValidator";
import numberIsIntigerValidator from "@/rules/numberIsIntiger";
import numberCompareValidator from "@/rules/numberCompareValidator";

//Models
import License from "@/models/api/licenses/license";
import UserBasic from "@/models/api/user/UserBasic";
import CompanyBasic from "@/models/api/company/CompanyBasic";
import LicenseAssignUser from "@/models/api/licenses/licenseAssignUser";
import LicenseAssignCompany from "@/models/api/licenses/licenseAssignCompany";
import { LicenseAssignUserInfo, LicenseAssignCompanyInfo } from "@/models/components/license/licenseInfo";

@Component({
  components: {
    CustomDialog,
    AutocompleteUsers,
    AutocompleteCompanies,
    InputSelector,
  },
})
export default class LicenseTransferDialog extends Vue {
  @PropSync("showDialog", { required: true })
  _showDialog?: boolean;
  @Prop({ required: true })
  title!: string;
  @Prop()
  license!: License;

  //Array for datatable
  licenses: Array<License> = [];

  //Validators
  required = requiredValidator;
  number = numberValidator;
  numberCompare = numberCompareValidator;
  numberIsIntiger = numberIsIntigerValidator;

  //DataTable
  headers: Array<DataTableHeader> = [
    {
      text: filterCapitalize(i18n.t("header.companyName")),
      value: "companyName",
      align: "center",
    },
    {
      text: filterCapitalize(i18n.t("header.license")),
      value: "name",
      align: "center",
    },
    {
      text: filterCapitalize(i18n.t("header.toUse")),
      value: "toUse",
      align: "center",
    },
  ];

  switchState = false;

  selectedUser: UserBasic | null = null;
  selectedCompany: CompanyBasic | null = null;
  numberOfLicenses = 0;

  async save() {
    const valid = (this.$refs.form as HTMLFormElement).validate();
    if (valid) {
      if (this.switchState) {
        if (this.selectedUser != null) {
          const assignUserModel: LicenseAssignUser = {
            companyId: this.license.companyId,
            userId: this.selectedUser.id,
            licenseId: this.license.id,
            numberOfLicenseItems: this.numberOfLicenses,
          };

          const assignInfo: LicenseAssignUserInfo = {
            licenseName: this.license.name,
            toUse: this.license.toUse,
            userName: this.selectedUser.email,
            assignUser: assignUserModel,
          };

          //if the user selects cancel, the dialog will still be open
          this._showDialog = !(await this.$store.direct.dispatch.license.assignLicenseItemToUser(assignInfo));
        }
      } else {
        if (this.selectedCompany != null) {
          const assignCompanyModel: LicenseAssignCompany = {
            fromCompanyId: this.license.companyId,
            toCompanyId: this.selectedCompany.id,
            licenseId: this.license.id,
            numberOfLicenseItems: this.numberOfLicenses,
          };

          const assignInfo: LicenseAssignCompanyInfo = {
            licenseName: this.license.name,
            toUse: this.license.toUse,
            companyName: this.selectedCompany.name,
            assignCompany: assignCompanyModel,
          };

          this._showDialog = !(await this.$store.direct.dispatch.license.assignLicenseItemToCompany(assignInfo));
        }
      }
    } else {
      this.$store.direct.dispatch.popup.addSnackbarFormError();
    }
  }

  cancel() {
    this._showDialog = false;
  }

  buttons: Array<DialogButtonInterface> = [
    {
      text: "Anuluj",
      color: "",
      class: "btn main-light--reverse",
      align: "",
      disabled: false,
      action: () => {
        this.cancel();
      },
    },
    {
      text: "Zastosuj",
      color: "",
      class: "btn main-light",
      align: "",
      disabled: false,
      action: async () => {
        this.save();
      },
    },
  ];

  created() {
    this.licenses.push(this.license);
  }
}
</script>

<style lang="scss" scoped>
.v-text-field {
  margin-bottom: 0;
}

::v-deep .v-label--active {
  font-size: 12px;
}

::v-deep .v-input__control {
  min-height: 35px !important;
  .v-text-field__slot input {
    color: black;
  }
}
</style>
