<template>
  <custom-dialog
    :value.sync="_show"
    :title="$t('dialog.recordStampList') | capitalize"
    :buttons="buttons"
    :width="width"
    @cancel="_show = false"
  >
    <div v-if="recordStamp != null" class="mt-5">
      <div>
        <div>
          <span class="text-body-2 font-weight-regular"> Utworzony: {{ recordStamp.createdAt | date }}</span>
        </div>
        <div>
          <span class="text-body-2 font-weight-regular"> Utworzony przez: {{ recordStamp.userName }}</span>
        </div>
        <div>
          <span class="text-body-2 font-weight-regular"> Opis: {{ recordStamp.description }}</span>
        </div>
      </div>
      <div class="mt-5">
        <v-data-table
          :items="recordStamp.recordStampModifications"
          :headers="headers"
          :items-per-page="-1"
          :height="400"
          hide-default-footer
          fixed-header
          disable-sort
        >
          <template #[`item.modifyAt`]="{ item }">
            {{ item.modifyAt | date }}
          </template>
        </v-data-table>
      </div>
    </div>
  </custom-dialog>
</template>

<script lang="ts">
import { Vue, Component, Prop, PropSync, Watch } from "vue-property-decorator";
import CustomDialog, { DialogButtonInterface } from "@/components/shared/layout/CustomDialog.vue";

//Models
import RecordStamp from "@/models/api/recordStamp/recordStamp";

//Requests
import { getRecordStamp } from "@/requests/recordStamp";
import { DataTableHeader } from "vuetify";
import { filterCapitalize } from "../../../filters/capitalize";
import i18n from "../../../plugins/i18n";

@Component({
  components: {
    CustomDialog,
  },
})
export default class RecordStampDialog extends Vue {
  @PropSync("show")
  _show!: boolean;
  @PropSync("id")
  _id!: number | null;
  @Prop()
  width?: number;

  recordStamp: RecordStamp | null = null;

  headers: Array<DataTableHeader> = [
    {
      text: filterCapitalize(i18n.t("header.modifiedAt")),
      value: "modifyAt",
      align: "center",
      width: 130,
    },
    {
      text: filterCapitalize(i18n.t("header.userLogin")),
      value: "userName",
      align: "center",
    },
    {
      text: filterCapitalize(i18n.t("header.description")),
      value: "description",
      align: "start",
    },
  ];

  buttons: Array<DialogButtonInterface> = [
    {
      text: "Zamknij",
      color: "",
      class: "btn main-light--reverse",
      align: "left",
      disabled: false,
      action: async () => {
        this.closeDialog();
      },
    },
  ];

  closeDialog() {
    this._show = false;
  }

  @Watch("show")
  async removeRecordStampId(state: boolean) {
    if (!state) {
      this._id = null;
    } else {
      this.recordStamp = (await getRecordStamp(this._id as number)).model;
    }
  }
}
</script>

<style lang="scss" scoped></style>
