<template>
  <span class="scaling-svg-container">
    <svg xmlns="http://www.w3.org/2000/svg" width="11.136" height="9.744" viewBox="0 0 11.136 9.744">
      <path
        d="M11.136,1.154a4.5,4.5,0,0,1-1.315.388,2.421,2.421,0,0,0,1-1.359,4.37,4.37,0,0,1-1.447.6A2.25,2.25,0,0,0,8.211.06a2.13,2.13,0,0,0-1.34.113,2.322,2.322,0,0,0-1.049.9A2.593,2.593,0,0,0,5.428,2.46a2.719,2.719,0,0,0,.053.56,6.1,6.1,0,0,1-2.608-.745A6.579,6.579,0,0,1,.775.449,2.624,2.624,0,0,0,.521,2.242a2.469,2.469,0,0,0,.956,1.493,2.133,2.133,0,0,1-1.031-.3v.027A2.589,2.589,0,0,0,.961,5.017a2.273,2.273,0,0,0,1.313.858,2.125,2.125,0,0,1-.6.081,1.879,1.879,0,0,1-.432-.042,2.484,2.484,0,0,0,.814,1.221,2.2,2.2,0,0,0,1.319.491A4.383,4.383,0,0,1,.546,8.675,3.975,3.975,0,0,1,0,8.641a6.1,6.1,0,0,0,3.5,1.1c4.2,0,6.5-3.747,6.5-7,0-.109,0-.214-.008-.318a4.764,4.764,0,0,0,1.144-1.276Z"
        fill="#000"
      />
    </svg>
  </span>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

@Component
export default class TrashIcon extends Vue {}
</script>

<style></style>
