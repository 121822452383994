<template>
  <span class="scaling-svg-container">
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="10" viewBox="0 0 22 10">
      <path
        d="M15.071,10H1.428a1.428,1.428,0,1,1,0-2.857H15.071a1.428,1.428,0,1,1,0,2.857Zm5.5-7.142H1.428A1.429,1.429,0,0,1,1.428,0H20.571a1.429,1.429,0,0,1,0,2.857Z"
        opacity="0.8"
      />
    </svg>
  </span>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

@Component
export default class MenuIcon extends Vue {}
</script>

<style></style>
