import { VuetifyIcons } from "vuetify/types/services/icons";

//----------Imported icons----------//
//Common
import MenuIcon from "@/components/shared/icons/common/MenuIcon.vue";
import StatisticIcon from "@/components/shared/icons/common/StatisticIcon.vue";
import LicensesIcon from "@/components/shared/icons/common/LicensesIcon.vue";
import AdministrationIcon from "@/components/shared/icons/common/AdministrationIcon.vue";
import ReportIcon from "@/components/shared/icons/common/ReportIcon.vue";
import SettingsIcon from "@/components/shared/icons/common/SettingsIcon.vue";
import HelpIcon from "@/components/shared/icons/common/HelpIcon.vue";
import LoupeIcon from "@/components/shared/icons/common/LoupeIcon.vue";
import LogoIcon from "@/components/shared/icons/common/LogoIcon.vue";
import LogoFullIcon from "@/components/shared/icons/common/LogoFullIcon.vue";
import LogoSignatureIcon from "@/components/shared/icons/common/LogoSignatureIcon.vue";
import MenuBarIcon from "@/components/shared/icons/common/MenuBarIcon.vue";
import CheckBoxIcon from "@/components/shared/icons/common/CheckBoxIcon.vue";
import ProductCardMarkIcon from "@/components/shared/icons/common/ProductCardMarkIcon.vue";
import TransactionFailureIcon from "@/components/shared/icons/common/TransactionFailureIcon.vue";
import TransactionPendingIcon from "@/components/shared/icons/common/TransactionPendingIcon.vue";
import TransactionSuccessIcon from "@/components/shared/icons/common/TransactionSuccessIcon.vue";
import CompanyIcon from "@/components/shared/icons/common/CompanyIcon.vue";
import PieIcon from "@/components/shared/icons/common/PieIcon.vue";
import ShieldIcon from "@/components/shared/icons/common/ShieldIcon.vue";
import CopyIcon from "@/components/shared/icons/common/CopyIcon.vue";
import DownloadIcon from "@/components/shared/icons/common/DownloadIcon.vue";

//Alerts
import ErrorIcon from "@/components/shared/icons/alerts/ErrorIcon.vue";
import WarningIcon from "@/components/shared/icons/alerts/WarningIcon.vue";
import SuccessIcon from "@/components/shared/icons/alerts/SuccessIcon.vue";

//Buttons
import SaveIcon from "@/components/shared/icons/buttons/SaveIcon.vue";
import CheckBoxOnIcon from "@/components/shared/icons/buttons/CheckBoxOnIcon.vue";
import CheckBoxOffIcon from "@/components/shared/icons/buttons/CheckBoxOffIcon.vue";
import CheckBoxOnIconBlack from "@/components/shared/icons/buttons/CheckBoxOnIconBlack.vue";
import CheckBoxOffIconBlack from "@/components/shared/icons/buttons/CheckBoxOffIconBlack.vue";
import CloseIcon from "@/components/shared/icons/buttons/CloseIcon.vue";
import PowerIcon from "@/components/shared/icons/buttons/PowerIcon.vue";
import ShowIcon from "@/components/shared/icons/buttons/ShowIcon.vue";
import EditIcon from "@/components/shared/icons/buttons/EditIcon.vue";
import DownloadReportIcon from "@/components/shared/icons/buttons/DownloadReportIcon.vue";
import TrashIcon from "@/components/shared/icons/buttons/TrashIcon.vue";
import PlayIcon from "@/components/shared/icons/buttons/PlayIcon.vue";
import BlockIcon from "@/components/shared/icons/buttons/BlockIcon.vue";
import ArrowDownIcon from "@/components/shared/icons/buttons/ArrowDownIcon.vue";
import ArrowUpIcon from "@/components/shared/icons/buttons/ArrowUpIcon.vue";
import ArrowLeftIcon from "@/components/shared/icons/buttons/ArrowLeftIcon.vue";
import ArrowRightIcon from "@/components/shared/icons/buttons/ArrowRightIcon.vue";
import RepeatIcon from "@/components/shared/icons/buttons/RepeatIcon.vue";
import PlusIcon from "@/components/shared/icons/buttons/PlusIcon.vue";
import LockIcon from "@/components/shared/icons/buttons/LockIcon.vue";
import UnlockIcon from "@/components/shared/icons/buttons/UnlockIcon.vue";
import RefreshIcon from "@/components/shared/icons/buttons/RefreshIcon.vue";
import CalendarIcon from "@/components/shared/icons/buttons/CalendarIcon.vue";
import MediaFacebookIcon from "@/components/shared/icons/buttons/MediaFacebookIcon.vue";
import MediaTwitterIcon from "@/components/shared/icons/buttons/MediaTwitterIcon.vue";
import MediaLinkedInIcon from "@/components/shared/icons/buttons/MediaLinkedInIcon.vue";
import ButtonRightArrowIcon from "@/components/shared/icons/buttons/ButtonRightArrowIcon.vue";
import ButtonLeftArrowIcon from "@/components/shared/icons/buttons/ButtonLeftArrowIcon.vue";
import HomeIcon from "@/components/shared/icons/buttons/HomeIcon.vue";
import PrinterIcon from "@/components/shared/icons/buttons/PrinterIcon.vue";
import FileIcon from "@/components/shared/icons/buttons/FileIcon.vue";

export const commonIcons = {
  menu: {
    component: MenuIcon,
  },
  statistic: {
    component: StatisticIcon,
  },
  licenses: {
    component: LicensesIcon,
  },
  administration: {
    component: AdministrationIcon,
  },
  report: {
    component: ReportIcon,
  },
  settings: {
    component: SettingsIcon,
  },
  help: {
    component: HelpIcon,
  },
  loupe: {
    component: LoupeIcon,
  },
  logo: {
    component: LogoIcon,
  },
  logoFull: {
    component: LogoFullIcon,
  },
  logoSignature: {
    component: LogoSignatureIcon,
  },
  menuBar: {
    component: MenuBarIcon,
  },
  checkBox: {
    component: CheckBoxIcon,
  },
  productCardMarkIcon: {
    component: ProductCardMarkIcon,
  },
  transactionFailure: {
    component: TransactionFailureIcon,
  },
  transactionPending: {
    component: TransactionPendingIcon,
  },
  transactionSuccess: {
    component: TransactionSuccessIcon,
  },
  company: {
    component: CompanyIcon,
  },
  pie: {
    component: PieIcon,
  },
  shield: {
    component: ShieldIcon,
  },
  copy: {
    component: CopyIcon,
  },
  download: {
    component: DownloadIcon,
  },
};

export const alertsIcons = {
  error: {
    component: ErrorIcon,
  },
  warning: {
    component: WarningIcon,
  },
  success: {
    component: SuccessIcon,
  },
};

export const buttonIcons = {
  save: {
    component: SaveIcon,
  },
  checkboxOn: {
    component: CheckBoxOnIcon,
  },
  checkboxOff: {
    component: CheckBoxOffIcon,
  },
  checkboxOnBlack: {
    component: CheckBoxOnIconBlack,
  },
  checkboxOffBlack: {
    component: CheckBoxOffIconBlack,
  },
  close: {
    component: CloseIcon,
  },
  power: {
    component: PowerIcon,
  },
  edit: {
    component: EditIcon,
  },
  downloadReport: {
    component: DownloadReportIcon,
  },
  show: {
    component: ShowIcon,
  },
  trash: {
    component: TrashIcon,
  },
  play: {
    component: PlayIcon,
  },
  block: {
    component: BlockIcon,
  },
  arrowDown: {
    component: ArrowDownIcon,
  },
  arrowUp: {
    component: ArrowUpIcon,
  },
  arrowLeft: {
    component: ArrowLeftIcon,
  },
  arrowRight: {
    component: ArrowRightIcon,
  },
  repeat: {
    component: RepeatIcon,
  },
  plus: {
    component: PlusIcon,
  },
  lock: {
    component: LockIcon,
  },
  unlock: {
    component: UnlockIcon,
  },
  refresh: {
    component: RefreshIcon,
  },
  calendar: {
    component: CalendarIcon,
  },
  facebook: {
    component: MediaFacebookIcon,
  },
  twitter: {
    component: MediaTwitterIcon,
  },
  linkedIn: {
    component: MediaLinkedInIcon,
  },
  buttonArrowRight: {
    component: ButtonRightArrowIcon,
  },
  buttonArrowLeft: {
    component: ButtonLeftArrowIcon,
  },
  home: {
    component: HomeIcon,
  },
  printer: {
    component: PrinterIcon,
  },
  file: {
    component: FileIcon,
  },
};

export const icons = {
  ...commonIcons,
  ...buttonIcons,
  ...alertsIcons,
} as Partial<VuetifyIcons>;
