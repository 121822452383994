<template>
  <div class="pagination-container">
    <div class="pagination-pager">
      <v-pagination v-model="_options.page" :length="pageCount" :total-visible="7" circle />
      <div class="pagination-select">
        <v-select
          v-model="_options.itemsPerPage"
          :items="allowedItems"
          append-icon="$arrowDown"
          :menu-props="{
            auto: false,
            overflowY: true,
            minWidth: 80,
            contentClass: 'select-menu',
          }"
        >
        </v-select>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, PropSync, Prop, Watch } from "vue-property-decorator";
import { DataOptions, DataPagination } from "vuetify";

@Component
export default class CustomPagination extends Vue {
  @PropSync("options", { required: true })
  _options!: DataOptions;
  @PropSync("pagination", { required: false })
  _pagination!: DataPagination;
  @Prop()
  serverItemsLength!: number;

  pageCount = 0;

  allowedItems = [1, 5, 10, 15, 20];

  @Watch("options.itemsPerPage")
  updateCurrentPage() {
    this._options.page = 1;
  }

  @Watch("options", { deep: true })
  @Watch("serverItemsLength")
  updatePageCount() {
    if (this.serverItemsLength != undefined) {
      this.pageCount = Math.ceil(this.serverItemsLength / this._options.itemsPerPage);
      this.$store.direct.dispatch.vuetify.goTo(0);
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .v-pagination {
  font-weight: 400;
  .v-pagination__item {
    box-shadow: unset;
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent !important;
  }
  .v-pagination__item--active {
    background-color: #4d4b4d !important;
  }
  .v-pagination__navigation {
    box-shadow: unset;
    border: 1px solid #4d4b4d;
    .v-icon__component {
      display: flex;
      align-items: center;
      width: 13px;
    }
  }
}

.pagination-container {
  // padding: 20px 40px 0 0;
  padding-top: 20px;
  min-width: 300px;
}

.pagination-pager {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  // min-width: 300px;
}

.pagination-select {
  margin: 0 0 0 40px;
  width: 45px;
}

::v-deep .v-input {
  padding: 0;
  margin-top: 0;
  font-size: 16px;
  font-weight: 400;
  .v-input__control {
    .v-input__slot {
      margin: 0;
    }
  }
}

::v-deep .v-input__slot::after {
  border-style: none !important;
}

::v-deep .v-list-item {
  min-height: 25px !important;
  .v-list-item__content {
    .v-list-item__title {
      color: black;
      display: flex;
      justify-content: center;
    }
  }
}

::v-deep .v-list-item__content {
  padding: unset;
  .v-list-item__title {
    font-size: 12px !important;
  }
}

::v-deep .v-list-item--active {
  border-left: 3px solid black;
  background-color: #f4f4f4f4;
  .v-list-item__content {
    margin-left: -3px;
    .v-list-item__title {
      color: black;
      font-weight: 500 !important;
    }
  }
}

::v-deep .v-select__selections {
  display: flex;
  justify-content: center;
  align-items: center;
  .v-select__selection {
    margin: 0;
  }
  input {
    padding: 0;
    max-width: 0;
    height: 0;
  }
}

::v-deep .v-input__append-inner {
  padding: 0 !important;
  margin-left: -5px;
}
</style>
