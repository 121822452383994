<template>
  <span class="scaling-svg-container">
    <!-- <svg xmlns="http://www.w3.org/2000/svg" width="6.198" height="3.69" viewBox="0 0 6.198 3.69" class="scaling-svg">
      <path
        id="Right"
        d="M0,0,2.241,2.248,0,4.5"
        transform="translate(5.349 0.849) rotate(90)"
        fill="none"
        stroke="#453c3c"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.2"
      />
    </svg> -->
    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 6.198 3.69">
      <path
        id="Right"
        d="M0,0,2.241,2.248,0,4.5"
        transform="translate(5.349 0.849) rotate(90)"
        fill="none"
        stroke="#453c3c"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1"
      />
    </svg>
  </span>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

@Component
export default class ArrowDownIcon extends Vue {}
</script>

<style></style>
