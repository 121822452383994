<template>
  <div class="products">
    <div class="d-flex justify-center">
      <div class="text-h3 text-md-h2 font-weight-bold">Wybierz plan</div>
    </div>
    <div class="d-flex justify-center">
      <carousel :items="products" />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import Carousel from "@/components/shared/layout/Carousel.vue";
import Product from "@/models/api/products/product";

@Component({
  components: {
    Carousel,
  },
})
export default class Products extends Vue {
  get products(): Array<Product> {
    return this.$store.direct.state.product.products;
  }

  created() {
    this.$store.direct.dispatch.product.getProductList();
  }
}
</script>

<style lang="scss" scoped>
.products {
  margin-top: 100px;
}
</style>
