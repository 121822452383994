<template>
  <div>
    <div class="group">
      <div class="el_1"></div>
      <div class="el_2"></div>
      <div class="el_3"></div>
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Component } from "vue-property-decorator";

@Component
export default class BackgroundImage extends Vue {}
</script>

<style scoped>
.group {
  position: fixed;
  right: 0;
  width: 100%;
  height: 100vh;
  z-index: 0;
}

.el_1 {
  background: transparent url("@/assets/layout/background/el_1.svg") 0% 0% no-repeat padding-box;
  bottom: 0px;
  right: 0px;
  width: 333.5px;
  height: 434px;
  position: absolute;
}

.el_2 {
  background: transparent url("@/assets/layout/background/el_2.svg") 0% 0% no-repeat padding-box;
  bottom: 345px;
  left: 446px;
  width: 113px;
  height: 121px;
  position: absolute;
}

.el_3 {
  background: transparent url("@/assets/layout/background/el_3.svg") 0% 0% no-repeat padding-box;
  bottom: 543px;
  right: 452px;
  width: 131px;
  height: 139px;
  position: absolute;
}
</style>
