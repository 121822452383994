<template>
  <div>
    <div>
      <v-dialog
        v-model="show"
        persistent
        max-width="500px"
        :overlay-opacity="0.5"
        elevation
        content-class="elevation-1"
        rounded="xl"
      >
        <v-card v-for="(confirm, index) in popups.filter((v) => v.show)" :key="index + Math.random()">
          <v-card-title>
            <h4>{{ confirm.title }}</h4>
          </v-card-title>
          <v-card-text>
            <div v-for="(text, num) in confirm.content.split('\n')" :key="num">
              <div v-html="text"></div></div
          ></v-card-text>
          <v-card-actions class="card-actions">
            <v-btn :width="150" class="btn main-light--reverse ml-0" @click="cancel(confirm)">{{
              $t("actions.cancel") | capitalize
            }}</v-btn>
            <v-btn ref="myBtn" :width="150" :disabled="!unlock" class="btn main-light" @click="accept(confirm)"
              ><div style="margin-right: 5px">
                {{ $t("actions.accept") | capitalize }}
              </div>
              <timer v-show="!unlock" :time="confirm.time" @unlock="unlock = true"
            /></v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Watch } from "vue-property-decorator";
import { ConfirmModel } from "@/models/components/popup/Confirm";
import { Resolver } from "@/models/components/popup/Resolver";

import Timer from "@/components/shared/layout/Timer.vue";

@Component({
  components: {
    Timer,
  },
})
export default class ConfirmPopup extends Vue {
  get popups(): Array<ConfirmModel & Resolver> {
    return this.$store.direct.state.popup.confirms;
  }

  @Watch("popups", { deep: true })
  update() {
    if (this.popups.filter((v) => v.show).length == 0) this.$store.direct.commit.popup.HIDE_CONFIRMS();
  }

  accept(popup: ConfirmModel & Resolver): void {
    popup.resolver(true);
    popup.show = false;
    this.show = false;
  }

  cancel(popup: ConfirmModel & Resolver): void {
    popup.resolver(false);
    popup.show = false;
    this.show = false;
  }

  unlock = false;
  show = true;

  destroyed() {
    this.$store.direct.commit.popup.CLEAR_CONFIRMS();
  }
}
</script>

<style lang="scss" scoped>
::v-deep .v-dialog {
  border-radius: 25px !important;
  overflow: hidden;
}

.card-actions {
  display: flex;
  gap: 10px;
  flex-direction: row;
  justify-content: center;
  @media screen and (max-width: 400px) {
    flex-direction: column;
  }
}
</style>
