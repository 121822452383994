<template>
  <span class="scaling-svg-container">
    <svg
      id="Icon_close"
      xmlns="http://www.w3.org/2000/svg"
      width="11.501"
      height="14.376"
      viewBox="0 0 11.501 14.376"
      class="scaling-svg"
    >
      <path
        id="Fill_1"
        data-name="Fill_1"
        d="M9.345,14.376H2.156A2.159,2.159,0,0,1,0,12.219V6.469A2.159,2.159,0,0,1,2.156,4.313h.719V2.955A2.919,2.919,0,0,1,5.75,0,2.919,2.919,0,0,1,8.626,2.955V4.313h.719A2.159,2.159,0,0,1,11.5,6.469v5.75A2.159,2.159,0,0,1,9.345,14.376ZM2.156,5.75a.72.72,0,0,0-.719.719v5.75a.72.72,0,0,0,.719.719H9.345a.719.719,0,0,0,.718-.719V6.469a.719.719,0,0,0-.718-.719H2.156ZM5.75,1.437A1.481,1.481,0,0,0,4.313,2.955V4.313H7.188V2.955A1.481,1.481,0,0,0,5.75,1.437Zm0,10.064A2.156,2.156,0,1,1,7.907,9.345,2.159,2.159,0,0,1,5.75,11.5Zm0-2.875a.719.719,0,1,0,.719.719A.719.719,0,0,0,5.75,8.626Z"
        transform="translate(0 0)"
        fill="currentColor"
      />
    </svg>
  </span>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

@Component
export default class LockIcon extends Vue {}
</script>

<style></style>
