<template>
  <v-container>
    <frequently-asked-questions-item v-for="(item, index) in questions.items" :key="index" :item="item" />
  </v-container>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import FrequentlyAskedQuestionsItem from "@/components/faq/FrequentlyAskedQuestionsItem.vue";

//Static
import { frequentlyAskedQuestions } from "@/static/faq/frequentlyAskedQuestions";

@Component({
  components: {
    FrequentlyAskedQuestionsItem,
  },
})
export default class FrequentlyAskedQuestionsView extends Vue {
  questions = frequentlyAskedQuestions;
}
</script>

<style lang="scss" scoped></style>
