<template>
  <span class="scaling-svg-container">
    <svg xmlns="http://www.w3.org/2000/svg" width="103.848" height="103.848" viewBox="0 0 103.848 103.848">
      <g transform="translate(-653.576 -371.576)">
        <g transform="translate(284.422 -115.578)">
          <circle cx="51.924" cy="51.924" r="51.924" transform="translate(369.154 487.154)" fill="#d4ab5b" />
        </g>
        <g id="Group_6813" data-name="Group 6813" transform="translate(1 1)">
          <rect width="13" height="41" rx="3" transform="translate(698 392)" fill="#fff" />
          <rect width="13" height="13" rx="3" transform="translate(698 441)" fill="#fff" />
        </g>
      </g>
    </svg>
  </span>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

@Component
export default class TransactionPendingIcon extends Vue {}
</script>

<style></style>
