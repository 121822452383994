<template>
  <div class="bs-container">
    <div class="bs-container__text">
      <div class="d-flex flex-column">
        <div>
          <div class="text-h3 text-md-h2 font-weight-bold">Sukcesja</div>
          <div class="text-h3 text-md-h2 font-weight-bold">biznesowa</div>
        </div>
        <div class="paragraphs text-body-2 text-md-body-1 pt-5">
          <p>
            Sukcesja biznesowa to proces przekazania własności biznesu, który może rozpocząć się w&nbsp;sposób
            zaplanowany, lub mimowolny – wskutek następstw wynikających z&nbsp;dziedziczenia ustawowego.
          </p>
          <p>
            Planowanie w&nbsp;biznesie to podstawa. Czytając te słowa wykonujesz niezwykle istotny krok, który pomoże Ci
            przeprowadzić skuteczną sukcesję i&nbsp;lepiej zrozumieć sytuację Twojej firmy. Już dziś zadbaj o przyszłość
            przedsiębiorstwa i&nbsp;swoich bliskich – zaplanuj z&nbsp;nami sukcesję!
          </p>
          <p>
            Każdy raport jest spersonalizowany i&nbsp;przygotowywany indywidualnie, uwzględniając zarówno sytuację
            biznesową, jak i&nbsp;rodzinną. Powstanie raportu poprzedza uzupełnienie formularza, który umożliwi nam
            rozpoznanie Twojej sytuacji.
          </p>
        </div>
      </div>
    </div>
    <div class="bs-container__image-wrapper">
      <div class="bs-container__image-item">
        <img src="@/assets/images/SP_P2.png" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

// Components
import Btn from "@/components/shared/buttons/Btn.vue";

@Component({
  components: {
    Btn,
  },
})
export default class BusinessSuccession extends Vue {}
</script>

<style lang="scss" scoped>
.bs-container {
  display: flex;
  justify-content: flex-end;
  // position: relative;
  margin-top: 100px;
  @media screen and (max-width: 1292px) {
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
    .bs-container__text {
      margin: 0;
    }
    .bs-container__image-item {
      top: -80px;
      left: -290px;
      img {
        height: 600px;
      }
    }
  }
  @media screen and (max-width: 540px) {
    .bs-container__image-item {
      top: -50px;
      left: -250px;
      img {
        height: 500px;
      }
    }
    .bs-container__image-wrapper {
      height: 350px;
    }
  }
  @media screen and (max-width: 440px) {
    .bs-container__image-item {
      top: -50px;
      left: -200px;
      img {
        height: 400px;
      }
    }
    .bs-container__image-wrapper {
      height: 300px;
    }
  }
}

.bs-container__text {
  width: 600px;
  max-width: 100%;
  margin-right: 50px;
}

.bs-container__image-wrapper {
  position: relative;
  height: 500px;
}

.bs-container__image-item {
  position: absolute;
  top: -150px;
  left: -1350px;
  img {
    height: 700px;
  }
}

.buttons-group {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
}

.paragraphs {
  max-width: 650px;
  padding: 2% 0;
  text-align: justify;
}
</style>
