<template>
  <div class="report-contains-item">
    <v-icon size="25">$checkBox</v-icon>
    <p class="text-body-2 text-md-body-1">
      {{ item.text }}
    </p>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";

export interface IReportContainsItem {
  id: number;
  text: string;
}

@Component
export default class ReportContainsItem extends Vue {
  @Prop()
  item!: IReportContainsItem;
}
</script>

<style lang="scss" scoped>
.report-contains-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0.5rem;
  height: calc(100% + 10px);
}

p {
  margin: 0 0 0 10px;
}
</style>
