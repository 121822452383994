import Vue from "vue";
import VueRouter, { RouteMeta, Route } from "vue-router";
import { CustomRouteConfig } from "@/models/plugins/CustomRouterTypes";
import ROUTE_NAMES from "@/static/routeNames";

//routes
import GeneralRoutes from "@/routes/general";
import ErrorRoutes from "@/routes/error";
import NavigationRoutes from "@/routes/navigation";
import DeveloperRoutes from "@/routes/developer";

//views
import store from "@/store";

export class CustomVueRouter extends VueRouter {
  redirectToPage(url: string): void {
    window.open(url, "_blank");
  }
  redirectToFacebook(): void {
    const a = document.createElement("a");
    a.target = "_blank";
    a.href = "https://www.facebook.com/profile.php?id=100069020070355";
    a.click();
  }
  redirectToTwitter(): void {
    const a = document.createElement("a");
    a.target = "_blank";
    a.href = "https://www.twitter.com";
    a.click();
  }
  redirectToLinkedIn(): void {
    const a = document.createElement("a");
    a.target = "_blank";
    a.href = "https://www.linkedin.com/company/79583010";
    a.click();
  }
}

const generalRoutes: Array<CustomRouteConfig> = [];

const routes: Array<CustomRouteConfig> = generalRoutes
  .concat(GeneralRoutes)
  .concat(ErrorRoutes)
  .concat(NavigationRoutes)
  .concat(DeveloperRoutes);

const routerInstance = new CustomVueRouter({
  mode: "history",
  routes: routes,
});

const defaultTitle = process.env.VUE_APP_DOCUMENT_TITLE;

routerInstance.afterEach((to) => {
  let title = "";
  const metaTitle = to.matched[0].meta.title;
  if (metaTitle != undefined) title = " - " + metaTitle;
  document.title = defaultTitle + title;
});

routerInstance.beforeEach((to, from, next) => {
  if (checkRouteExist(to) && typeof to.meta !== "undefined") {
    if (checkIsAnonymous(to.meta)) {
      next();
      return;
    }
    if (!checkUserIsLogged()) {
      store.commit.user.SET_PREVIOUS_PAGE(to); // Save route where user should be redirected after login
      next({ name: ROUTE_NAMES.GENERAL.LOGIN });
      return;
    }
    if (checkUserRole(to.meta)) {
      next();
    } else {
      next({ name: ROUTE_NAMES.GENERAL.WELCOME });
      // next({ name: ROUTE_NAMES.ERROR.UNAUTHORIZED }); // redirect to custom view or show alert
      //alert(i18n.t("router.noPermission"));
    }
  } else {
    next({ name: ROUTE_NAMES.ERROR.NOTFOUND });
  }
});

routerInstance.beforeResolve((to, from, next) => {
  if (checkUserIsLogged()) {
    if (checkRoute(to)) {
      next({ name: ROUTE_NAMES.GENERAL.WELCOME });
      return;
    }
    next();
    return;
  }
  next();
});

function checkRouteExist(to: Route): boolean {
  return to.matched.length > 0;
}

function checkIsAnonymous(meta: RouteMeta): boolean {
  return meta.isAnonymousAccess;
}

function checkUserIsLogged(): boolean {
  return store.getters.user.isLogged;
}

function checkUserRole(meta: RouteMeta): boolean {
  const userRole = store.state.user.role;
  return userRole >= meta.userRole;
}

function checkRoute(to: Route): boolean {
  return to.name === ROUTE_NAMES.GENERAL.LOGIN || to.name === ROUTE_NAMES.GENERAL.REGISTER;
}

Vue.use(CustomVueRouter);

export { routerInstance };
export default routerInstance;
