import { RuleFunction } from "@/rules/RuleFunction";
import { filterCapitalize } from "@/filters/capitalize";
import i18n from "@/plugins/i18n";
import { phoneNumberRegex } from "@/static/regex";

const isPhoneNumber = phoneNumberRegex;

const phoneNumberValidator: RuleFunction = (value: unknown | string | number | null): boolean | string => {
  if (value == null) return true;
  else if (typeof value === "number") {
    if (value.toString().length == 0) return true;
    else if (!isPhoneNumber.test(value.toString())) {
      return filterCapitalize(i18n.t("validation.phoneNumberWrong").toString());
    }
  } else if (typeof value === "string") {
    if (value.length == 0) return true;
    else if (!isPhoneNumber.test(value)) {
      return filterCapitalize(i18n.t("validation.phoneNumberWrong").toString());
    }
  }
  return true;
};

export { phoneNumberValidator };
export default phoneNumberValidator;
