import { render, staticRenderFns } from "./FrequentlyAskedQuestionsItem.vue?vue&type=template&id=075c5cc5&scoped=true"
import script from "./FrequentlyAskedQuestionsItem.vue?vue&type=script&lang=ts"
export * from "./FrequentlyAskedQuestionsItem.vue?vue&type=script&lang=ts"
import style0 from "./FrequentlyAskedQuestionsItem.vue?vue&type=style&index=0&id=075c5cc5&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "075c5cc5",
  null
  
)

export default component.exports