<template>
  <input-text
    v-model="phone"
    :label="label"
    :allow-text="false"
    :allow-number="true"
    :allow-symbol="true"
    :show-counter="false"
    :clerable="true"
    :rules="allRules"
    :color="color"
    :show-error="false"
    :cols="cols"
  />
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";

// Components
import InputText from "@/components/shared/inputs/InputText.vue";

// Validators
import phoneValidator from "@/rules/phoneNumberValidator";

@Component({ components: { InputText } })
export default class InputPhone extends Vue {
  @Prop({ required: true })
  value!: string;
  @Prop()
  label!: string;
  @Prop()
  color!: string;
  @Prop()
  cols!: number;
  @Prop({ default: () => [] })
  // eslint-disable-next-line
  rules!: any[];

  phoneValidator = phoneValidator;

  get phone(): string {
    return this.value;
  }

  set phone(value: string) {
    this.$emit("input", value);
  }

  get allRules() {
    return [...this.rules, phoneValidator];
  }
}
</script>

<style scoped></style>
