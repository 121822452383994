import { defineModule } from "direct-vuex";
import { moduleActionContext, moduleGetterContext } from "@/store/index";

//Requests
import {
  createTransaction,
  payForTransaction,
  checkBlueMediaRedirectionParameters,
  checkPayURedirectionParameters,
  checkTransactionStatus,
  addFreeSamples,
  transactionHistory,
  transactionDetails,
  addInvoiceFile,
  getInvoiceFile,
  sendInvoiceEmail,
} from "@/requests/transaction";

//Models
import TransactionBlueMediaRedirectionParams from "@/models/api/transaction/transactionBlueMediaRedirectionParams";
import TransactionStatus from "@/models/api/transaction/transactionStatus";
import { ConfirmModel } from "@/models/components/popup/Confirm";
import { filterCapitalize } from "@/filters/capitalize";
import i18n from "@/plugins/i18n";
import { ListResult } from "@/models/api/filters/ListResult";
import TransactionHistory, { TransactionDetails } from "@/models/api/transactionHistory/transaction";
import { bold } from "@/filters/bold";
import TransactionPayURedirectionParams from "@/models/api/transaction/transactionPayURedirectionParams";

export interface TransactionStateInterface {
  info: string;
  transactionUrl: string | null;
  hashIsValid: boolean;
  transactionStatus: TransactionStatus | null | string;

  // TransactionHistory
  transactionHistory: ListResult<TransactionHistory> | null;
  transactionHistoryDialog: boolean;
  selectedTransactionId: string | null;
  selectedTransactionDetails: TransactionDetails | null;
}

export const TransactionState: TransactionStateInterface = {
  info: "",
  transactionUrl: null,
  hashIsValid: false,
  transactionStatus: new TransactionStatus(),

  // Transaction history
  transactionHistory: null,
  transactionHistoryDialog: false,
  selectedTransactionId: null,
  selectedTransactionDetails: null,
};

const transactionModule = defineModule({
  state: TransactionState as TransactionStateInterface,
  namespaced: true as const,
  getters: {},
  mutations: {
    setTransactionUrl(state, url: string) {
      state.transactionUrl = url;
    },
    setHashValue(state, value: boolean) {
      state.hashIsValid = value;
    },
    setTransactionStatus(state, status: TransactionStatus) {
      state.transactionStatus = status;
    },
    setTransactionHistory(state, response: ListResult<TransactionHistory>) {
      state.transactionHistory = response;
    },
    setTransactionHistoryDialogState(state, show: boolean) {
      state.transactionHistoryDialog = show;
    },
    setSelectedTransactionId(state, id: string | null) {
      state.selectedTransactionId = id;
    },
    setSelectedTransactionDetails(state, details: TransactionDetails | null) {
      state.selectedTransactionDetails = details;
    },
  },
  actions: {
    async createTransaction(context, productId: string) {
      const { commit, rootDispatch } = _moduleActionContext(context);
      const confirmModel = new ConfirmModel(
        filterCapitalize(i18n.t("confirm.information")),
        filterCapitalize(i18n.t("confirm.transaction.buy"))
      );
      const isConfirmed = (await rootDispatch.popup.addConfirm(confirmModel)) as boolean;
      if (isConfirmed) {
        const result = await createTransaction(productId);
        const url = await payForTransaction(result.model);
        commit.setTransactionUrl(url.model);
      }
      return isConfirmed;
    },
    async checkBlueMediaRedirectionParameters(context, params: TransactionBlueMediaRedirectionParams) {
      const { commit } = _moduleActionContext(context);
      const result = await checkBlueMediaRedirectionParameters(params);
      commit.setHashValue(result.model);
    },
    async checkPayURedirectionParameters(context, params: TransactionPayURedirectionParams) {
      const { commit } = _moduleActionContext(context);
      const result = await checkPayURedirectionParameters(params);
      commit.setHashValue(result.model);
    },
    async checkTransactionStatus(context, orderId: string) {
      const { commit } = _moduleActionContext(context);
      const result = await checkTransactionStatus(orderId);
      commit.setTransactionStatus(result.model);
    },
    async addFreeSamples(context, params: { number: number; days: number; companyId: string }) {
      const { rootDispatch } = _moduleActionContext(context);
      const result = await addFreeSamples(params.companyId, params.number, params.days);
      if (!result.isError) {
        rootDispatch.user.userLicenseItemsCount();
        return false;
      }
      return true;
    },
    async getTransactionHistory(context) {
      const { rootState, commit } = _moduleActionContext(context);
      const filter = rootState.filter.transactionHistoryFilterRequest;
      const result = await transactionHistory(filter);
      commit.setTransactionHistory(result.model);
    },
    async getTransactionDetails(context) {
      const { state, commit } = _moduleActionContext(context);
      const selectedTransaction = state.selectedTransactionId;
      if (selectedTransaction != null) {
        const response = await transactionDetails(selectedTransaction);
        commit.setSelectedTransactionDetails(response.model);
      }
    },
    async addInvoiceFile(context, file: File) {
      const { state, dispatch } = _moduleActionContext(context);
      const transactionId = state.selectedTransactionId;
      if (transactionId != null) {
        await addInvoiceFile(transactionId, file);
        dispatch.getTransactionDetails();
      }
    },
    async getInvoice(context) {
      const { state } = _moduleActionContext(context);
      const transactionId = state.selectedTransactionId;
      if (transactionId != null) {
        const response = await getInvoiceFile(transactionId);
        if (!response.isError) {
          const byteArray = new Uint8Array(response.model.data);
          const urlLink = window.URL.createObjectURL(new Blob([byteArray], { type: response.model.mimeType }));
          window.open(urlLink);
        }
      }
    },
    async sendInvoiceEmail(context) {
      const { state, dispatch, rootDispatch } = _moduleActionContext(context);
      const selectedTransaction = state.selectedTransactionDetails;
      const transactionId = state.selectedTransactionId;
      if (transactionId != null && selectedTransaction != null && selectedTransaction.invoiceDetails.name != null) {
        const confirmModel = new ConfirmModel(
          filterCapitalize(i18n.t("confirm.information")),
          filterCapitalize(
            i18n.t("confirm.transaction.sendInvoiceEmail", {
              invoiceName: bold(selectedTransaction.invoiceDetails.name),
              userEmail: bold(selectedTransaction.invoiceDetails.mailTo),
            })
          ),
          2
        );

        const isConfirmed = await rootDispatch.popup.addConfirm(confirmModel);
        if (isConfirmed) {
          await sendInvoiceEmail(transactionId);
          await dispatch.getTransactionDetails();
          await dispatch.getTransactionHistory();
        }
      }
    },
  },
});

export default transactionModule;

/* eslint-disable */
const _moduleGetterContext = (args: [any, any, any, any]) =>
  moduleGetterContext(args, transactionModule);
const _moduleActionContext = (context: any) =>
  moduleActionContext(context, transactionModule);
