import { ExtensionBase } from "@/models/api/reports/workFlow/BaseExtension";

export default interface BasePrototype {
  id: number;
  text: string;
  shortText: string;
  type: PrototypeTypeEnum;
  previousQuestionText: string;
  nextQuestionText: string | null;
  isFirst: boolean;
  isLast: boolean;
  questionItems: Array<QuestionItemPrototype>;
}

export interface QuestionItemPrototype {
  id: number;
  nextQuestionId: number;
  ordinal: number;
  label: string;
  value: string | number;
  isChecked: boolean;
  extensionObject: ExtensionBase;
  isValid?: boolean;
  validateCodes?: string[];
  nextQuestionText: string | null;
}

export enum PrototypeTypeEnum {
  CommonPrototype = 1,
  SingleChooserPrototype = 2,
  MultipleChooserPrototype = 3,
}
