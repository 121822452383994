import { CustomRouteConfig } from "@/models/plugins/CustomRouterTypes";
import ROLE from "@/static/role";
import routeNames from "@/static/routeNames";

//Views
import NotFoundView from "@/views/error/NotFoundView.vue";
import NoPermissions from "@/views/error/NoPermissionsView.vue";

const ErrorRoutes: Array<CustomRouteConfig> = [
  {
    name: routeNames.ERROR.UNAUTHORIZED,
    path: "/403",
    component: NoPermissions,
    meta: {
      isAnonymousAccess: true,
      userRole: ROLE.Guest,
    },
  },
  {
    name: routeNames.ERROR.NOTFOUND,
    path: "/404",
    component: NotFoundView,
    meta: {
      isAnonymousAccess: true,
      userRole: ROLE.Guest,
    },
  },
];

export { ErrorRoutes };
export default ErrorRoutes;
