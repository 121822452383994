<template>
  <span class="scaling-svg-container">
    <svg xmlns="http://www.w3.org/2000/svg" width="7.237" height="7.84" viewBox="0 0 7.237 7.84" class="scaling-svg">
      <g transform="translate(-1838 -200)">
        <path
          d="M5.428,7.84H1.809A1.054,1.054,0,0,1,.724,6.771V2.138H.362A.34.34,0,0,1,0,1.782a.34.34,0,0,1,.362-.357H1.809V1.069A1.054,1.054,0,0,1,2.895,0H4.342A1.054,1.054,0,0,1,5.428,1.069v.356H6.875a.34.34,0,0,1,.362.357.34.34,0,0,1-.362.356H6.514V6.771A1.054,1.054,0,0,1,5.428,7.84Zm-3.981-5.7V6.771a.34.34,0,0,0,.362.356H5.428a.34.34,0,0,0,.362-.356V2.138ZM2.895.713a.34.34,0,0,0-.362.356v.356H4.7V1.069A.34.34,0,0,0,4.342.713ZM4.342,6.059A.34.34,0,0,1,3.98,5.7V3.564a.34.34,0,0,1,.362-.356.34.34,0,0,1,.362.356V5.7A.34.34,0,0,1,4.342,6.059Zm-1.447,0A.34.34,0,0,1,2.533,5.7V3.564a.34.34,0,0,1,.362-.356.34.34,0,0,1,.362.356V5.7A.34.34,0,0,1,2.895,6.059Z"
          transform="translate(1838 200)"
          fill="currentColor"
        />
      </g>
    </svg>
  </span>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

@Component
export default class TrashIcon extends Vue {}
</script>

<style></style>
