<template>
  <div>
    <v-snackbar
      v-for="(snackbar, index) of snackbars.filter((v) => v.show)"
      :key="snackbar.content[0] + Math.random()"
      v-model="snackbar.show"
      multi-line
      :elevation="0"
      :timeout="snackbarTimeOut"
      :style="`bottom: ${index * 85 + 8}px`"
      :color="snackbar.color.toLowerCase()"
    >
      <div class="snack-icon">
        <v-icon v-show="snackbars.length != 0" large>${{ snackbar.color.toLowerCase() }} </v-icon>
      </div>
      <div class="snack-content">
        <div v-for="(text, num) in snackbar.content" :key="num">
          <div v-html="getTranslation(text, snackbar.params)"></div>
        </div>
      </div>
      <div slot="action">
        <v-btn icon @click="snackbar.show = false">
          <v-icon> $close </v-icon>
        </v-btn>
      </div>
    </v-snackbar>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Watch } from "vue-property-decorator";
import { SnackbarModel } from "@/models/components/popup/Snackbar";
import { filterCapitalize } from "../../filters/capitalize";
import i18n from "../../plugins/i18n";

@Component
export default class Snackbar extends Vue {
  get snackbars(): Array<SnackbarModel> {
    return this.$store.direct.state.popup.snackbars;
  }

  get snackbarTimeOut(): number {
    return this.$store.direct.state.popup.snackbarTimeOut;
  }

  getTranslation(message: string, params: { [key: string]: string } | undefined): string {
    return filterCapitalize(i18n.t("snackbars." + message, params));
  }

  @Watch("snackbars", { deep: true })
  update() {
    if (this.snackbars.filter((v) => v.show).length == 0) this.$store.direct.commit.popup.HIDE_SNACKBARS();
  }

  destroyed() {
    this.$store.direct.commit.popup.CLEAR_SNACKBARS();
  }
}
</script>

<style lang="scss" scoped>
::v-deep .v-snack__content {
  padding-left: 55px;
  padding-right: 35px;
}

::v-deep .v-snack--multi-line .v-snack__wrapper {
  min-height: 75px !important;
  max-height: 75px !important; //TODO -> auto update height
  overflow: hidden;
  border-radius: 15px;
}

.snack-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  div {
    width: 100%;
  }
}

.snack-icon {
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
}
</style>
