<template>
  <div class="filter">
    <filter-criterions :criterions.sync="criterions" :values.sync="values" @onEnter="downloadOnFilter()" />
    <div v-show="filterName != filterNames.DefaultFilter" class="filter__menu">
      <v-tooltip bottom>
        <template #activator="{ on }">
          <v-btn icon :ripple="false" @click="refresh" v-on="on">
            <v-icon dense color="black">$refresh</v-icon>
          </v-btn>
        </template>
        {{ $t("tooltip.refresh") | capitalize }}
      </v-tooltip>
      <v-btn class="btn main-light--reverse" @click="downloadOnFilter()">Filtruj</v-btn>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, PropSync, Watch } from "vue-property-decorator";
import { DataOptions } from "vuetify";

//Criterions
import FilterCriterions from "@/components/shared/filter/FilterCriterions.vue";

//Static
import { FILTER_NAMES } from "@/static/filterNames";

//Models
import { CriterionBase } from "@/models/api/filters/CriterionBase";
import FilterRequest, { FilterCriterionRequest } from "@/models/api/filters/FilterRequest";
// import DefaultDataOptions from "@/models/components/dataTable/DataTableOptions";
import { SortDirectionEnum } from "../../../models/api/filters/Filter";

@Component({
  components: {
    FilterCriterions,
  },
})
export default class Filters extends Vue {
  filterNames = FILTER_NAMES;

  @Prop()
  filterName!: FILTER_NAMES;

  //$ Optiosn to sync dataTable
  @PropSync("options")
  _options!: DataOptions;

  filter!: FilterRequest;
  criterions: Array<CriterionBase> = [];
  values: Array<FilterCriterionRequest> = [];

  setFilterOptions(sortBy: string, sortDirection: SortDirectionEnum) {
    this._options = {
      ...this._options,
      sortBy: [sortBy],
      sortDesc: [sortDirection === SortDirectionEnum.Asc ? false : true],
      page: 1,
    };
  }

  prepareFilterRequest() {
    const request = new FilterRequest();
    request.name = this.filterName;
    request.criterions = this.values;
    request.page = this._options.page - 1;
    request.count = this._options.itemsPerPage;
    request.orderBy = this._options.sortBy.length > 0 ? this._options.sortBy[0] : null;
    request.orderDirection = this._options.sortDesc[0] == false ? SortDirectionEnum.Asc : SortDirectionEnum.Desc;
    this.filter = request;
    this.$emit("setFilterRequest", this.filter);
  }

  async getFilter(): Promise<void> {
    const response = await this.$store.direct.dispatch.filter.getFilter(this.filterName);
    this.criterions = response.criterions;
    setTimeout(() => {
      this.setFilterOptions(response.sortBy, response.sortDirection);
    }, 200);
  }

  @Watch("options", { deep: true })
  async download(): Promise<void> {
    this.prepareFilterRequest();
    this.$nextTick();
    this.$emit("download");
  }

  downloadOnFilter() {
    if (this._options.page != 1) {
      this._options.page = 1;
    } else {
      this.prepareFilterRequest();
      this.$emit("download");
    }
  }

  async refresh(): Promise<void> {
    await this.getFilter();
    await this.download();
  }

  async created() {
    await this.getFilter();
  }
}
</script>

<style lang="scss" scoped>
.filter {
  display: inline-flex;
  flex-wrap: wrap-reverse;
  gap: 20px;
  &__menu {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    align-content: center;
    flex-grow: 1;
    flex-shrink: 1;
    gap: 15px;
  }
  .v-btn {
    // margin: 17px 10px 0 10px; //to align filter button to end (filter criterions)
  }
}
</style>
