<template>
  <div :class="`product-card ${item.isBest ? 'best' : ''}`">
    <div class="product-card-price">
      <span class="product-card-price-value">{{ item.price }}PLN</span>
    </div>
    <div class="product-card-text">
      <div class="product-card-text-title">
        <span> {{ item.name }} </span>
      </div>
      <div class="product-card-text-content">
        <span> {{ item.content }} </span>
      </div>
    </div>
    <div class="product-card-spacer" />
    <div class="product-card-description">
      <div class="product-card-description-text">
        <v-icon size="30">$productCardMarkIcon</v-icon>
        <span> {{ getReportCount(item.itemsCount) }} </span>
      </div>
    </div>
    <div class="product-card-action">
      <v-btn v-show="canSelect" class="btn main-light" width="154" @click="select(item)">
        Wybierz
        <!-- <v-icon dark class="product-card-action-icon"> $buttonArrowRight </v-icon> -->
      </v-btn>
    </div>
    <div v-if="item.isBest" class="product-card-icon">
      <img src="@/assets/images/stamp.png" />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";
import Product from "@/models/api/products/product";
import ROLE from "../../../static/role";
import { SnackbarModel } from "../../../models/components/popup/Snackbar";

@Component
export default class ProductCard extends Vue {
  @Prop({ required: true })
  item!: Product;
  @Prop({ default: true })
  canSelect!: boolean;

  getReportCount(count: number): string {
    if (count == 1) return count + " raport";
    else if (count > 1 && count < 5) return count + " raporty";
    else return count + " raportów";
  }

  select(product: Product) {
    const userRole = this.$store.direct.state.user.role;
    const route = this.$router.resolve("/transaction");
    if (userRole == ROLE.Guest) {
      this.$store.direct.commit.product.setSelectedProduct(product);
      this.$store.direct.commit.user.SET_PREVIOUS_PAGE(route.route);
      this.$router.push("/login");
      return;
    }
    if (userRole == ROLE.User) {
      this.$store.direct.dispatch.popup.addSnackbar(new SnackbarModel(["cannotBuyLicense"], "error"));
      return;
    }
    if (userRole >= ROLE.Admin) {
      this.$store.direct.commit.product.setSelectedProduct(product);
      this.$router.push("/transaction");
      return;
    }
  }
}
</script>

<style lang="scss" scoped>
.product-card {
  display: flex;
  flex-direction: column;
  padding: 31px 34px;
  margin: 45px 30px 5px 30px;
  border: 1px solid black;
  border-radius: 10px;
  height: 100%;
  max-height: 330px;
  max-width: 284px;
  min-width: 284px;
  position: relative;
  &.best {
    // border: 1px solid #d4ab5b;  // TODO param to set
    // box-shadow: 0px 0px 20px 5px rgba(212, 171, 91, 0.7);
    // .product-card-text-title {
    //   color: #d4ab5b;
    // }
  }
}

.product-card-icon {
  position: absolute;
  right: -30px;
  top: -45px;
  width: 58px;
  height: 106px;
  img {
    width: 58px;
    height: 106px;
  }
}

.product-card-price {
  display: flex;
  height: max-content;
  font-family: "Rubik";
  align-items: baseline;
  &-value {
    color: black;
    font-size: 3.575em;
    font-weight: 600;
  }
  &-report {
    color: black;
    font-weight: 100;
    padding-left: 5px;
    font-weight: 600;
  }
}

.product-card-text {
  &-title {
    color: black;
    font-size: 40px;
    span {
      font-weight: 600;
    }
  }
  &-content {
    color: black;
    font-size: 14px;
    font-weight: 100;
  }
}

.product-card-spacer {
  height: 2px;
  width: auto;
  min-height: 1px;
  background: #d4ab5b;
  margin: 20px 0;
}

.product-card-description {
  &-text {
    font-size: 1.175rem;
    font-weight: 400;
    display: flex;
    align-items: center;
    gap: 15px;
  }
}

.product-card-action {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-grow: 1;
  &-icon {
    position: absolute;
    right: 0;
  }
}

@media screen and (max-width: 412px) {
  .product-card {
    width: 250px;
    height: 365px;
    padding: 20px;
  }

  .product-card-price-value {
    font-size: 2.95rem;
    font-weight: 500;
  }

  .product-card-price-report {
    font-size: 0.875rem;
    letter-spacing: -0.02rem;
  }

  .product-card-text-title {
    font-size: 2.55rem;
    span {
      font-weight: 600;
    }
  }

  .product-card-text-content {
    color: black;
    font-size: 0.875rem;
  }

  .product-card-icon {
    right: -35px;
    top: -35px;
    img {
      width: 45px;
      height: auto;
    }
  }

  .product-card-description {
    &-text {
      font-size: 1rem;
      font-weight: 400;
    }
  }
}
</style>
