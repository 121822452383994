<template>
  <custom-dialog
    :title="$t(edit ? 'dialog.editCompany' : 'dialog.showCompany') | capitalize"
    width="500px"
    :show-buttons="edit"
    @cancel="cancel"
    @save="save"
  >
    <company-info-form ref="companyForm" :edit="edit" :valid.sync="valid" />
  </custom-dialog>
</template>

<script lang="ts">
import { Component, Vue, PropSync, Prop } from "vue-property-decorator";

//Components
import CustomDialog from "@/components/shared/layout/CustomDialog.vue";

import CompanyInfoForm from "@/components/administration/forms/CompanyInfoForm.vue";

//Models

@Component({
  components: {
    CustomDialog,
    CompanyInfoForm,
  },
})
export default class AdministrationCompanyDialog extends Vue {
  @PropSync("value", { required: true })
  show?: boolean;
  @Prop()
  edit!: boolean;

  valid = true;

  async save() {
    const valid = (this.$refs.companyForm as HTMLFormElement).validate();
    if (!valid) return;
    if (this.$store.direct.state.administration.selectedCompanyDetails != null) {
      const response = await this.$store.direct.dispatch.administration.editCompany(
        this.$store.direct.state.administration.selectedCompanyDetails
      );
      this.show = !response;
    }
  }

  cancel() {
    this.show = false;
  }
}
</script>

<style lang="scss" scoped></style>
