<template>
  <span style="width: 145.993px; height: 26.897px">
    <svg xmlns="http://www.w3.org/2000/svg" width="145.993" height="26.897" viewBox="0 0 145.993 26.897">
      <text
        transform="translate(0 0)"
        fill="currentColor"
        font-size="24"
        font-family="Rubik-Regular, Rubik"
        font-weight="100"
      >
        <tspan x="0" y="22">SUKCESJA</tspan>
      </text>
      <text
        transform="translate(125.993 12.897)"
        fill="currentColor"
        font-size="10"
        font-family="Rubik-Regular, Rubik"
        font-weight="100"
      >
        <tspan x="-4" y="9">.BIZ</tspan>
      </text>
    </svg>
  </span>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

@Component
export default class LogoSignatureIcon extends Vue {}
</script>

<style></style>
