<template>
  <div>
    <div>
      <v-form ref="userForm" :disabled="disabled">
        <v-row justify="space-between">
          <v-col align-self="center">
            <span class="text-subtitle-2">{{ $t("form.userData") | capitalize }}</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <input-text
              v-model="userDetails.email"
              :label="$t('form.userEmail') | capitalize"
              :readonly="true"
              :disabled="true"
              color="accent"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col class="col" cols="12" sm="6">
            <input-text
              v-model="userDetails.name"
              v-maxchars="30"
              :label="$t('form.userName') | capitalize"
              color="accent"
              :cols="6"
              :allow-number="false"
              :allow-symbol="true"
              :rules="[required]"
            />
          </v-col>
          <v-col class="col" cols="12" sm="6">
            <input-text
              v-model="userDetails.surname"
              v-maxchars="30"
              :label="$t('form.userSurname') | capitalize"
              color="accent"
              :cols="6"
              :allow-number="false"
              :allow-symbol="true"
              :rules="[required]"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <input-phone
              v-model="userDetails.phoneNumber"
              :label="$t('form.userPhoneNumber') | capitalize"
              color="accent"
              :cols="6"
            />
          </v-col>
        </v-row>
      </v-form>
    </div>
    <div class="mt-10">
      <v-form ref="companyForm" :disabled="disabled">
        <v-row justify="space-between">
          <v-col align-self="center">
            <span class="text-subtitle-2">{{ $t("form.companyData") | capitalize }}</span>
          </v-col>
          <v-col no-gutters justify="end">
            <slot name="button"></slot>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <input-text
              v-model="companyDetails.name"
              :label="$t('form.companyName') | capitalize"
              color="accent"
              :rules="[required]"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col class="col" cols="12" sm="6">
            <input-text
              v-model="companyDetails.country"
              :label="$t('form.companyCountry') | capitalize"
              color="accent"
              :cols="6"
              :allow-number="false"
              :allow-symbol="true"
              :rules="[required]"
            />
          </v-col>
          <v-col class="col" cols="12" sm="6">
            <input-text
              v-model="companyDetails.city"
              :label="$t('form.companyCity') | capitalize"
              color="accent"
              :cols="6"
              :allow-number="false"
              :allow-symbol="true"
              :rules="[required]"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col class="col" cols="12" sm="6">
            <input-postal-code
              v-model="companyDetails.postalCode"
              :label="$t('form.companyPostalCode') | capitalize"
              color="accent"
              :cols="6"
              :max-length="12"
              :rules="[required]"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col class="col" cols="12" sm="6">
            <input-text
              v-model="companyDetails.streetName"
              :label="$t('form.companyStreetName') | capitalize"
              color="accent"
              :cols="6"
              :rules="[required]"
            />
          </v-col>
          <v-col class="col" cols="12" sm="6">
            <input-text
              v-model="companyDetails.streetNumber"
              :label="$t('form.companyStreetNumber') | capitalize"
              color="accent"
              :cols="6"
              :rules="[required]"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col class="col" cols="12" sm="6">
            <input-tin
              v-model="companyDetails.tin"
              :label="$t('form.companyTIN') | capitalize"
              color="accent"
              :cols="6"
              :rules="[required]"
            />
          </v-col>
          <v-col class="col" cols="12" sm="6">
            <input-phone
              v-model="companyDetails.phoneNumber"
              :label="$t('form.companyPhoneNumber') | capitalize"
              color="accent"
              :cols="6"
            />
          </v-col>
        </v-row>
      </v-form>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";

//Components
import InputText from "@/components/shared/inputs/InputText.vue";
import InputPostalCode from "@/components/shared/inputs/InputPostalCode.vue";
import RouterLinkNewTab from "@/components/shared/buttons/RouterLinkNewTab.vue";
import InputTin from "@/components/shared/inputs/InputTin.vue";
import InputPhone from "@/components/shared/inputs/InputPhone.vue";

//Models
import UserDetails from "@/models/api/user/UserDetails";
import CompanyDetails from "@/models/api/company/CompanyDetails";

//Validators
import requiredValidator from "@/rules/requiredValidator";
import phoneNumberValidator from "@/rules/phoneNumberValidator";
import { FormValidation } from "../../../customTypes/vuetify";

@Component({
  components: {
    InputText,
    InputPostalCode,
    RouterLinkNewTab,
    InputPhone,
    InputTin,
  },
})
export default class TransactionForm extends Vue {
  @Prop()
  disabled!: boolean;

  //Validator
  required = requiredValidator;
  phoneNumber = phoneNumberValidator;

  userDetailsTmp: UserDetails = new UserDetails();
  companyDetailsTmp: CompanyDetails = new CompanyDetails();

  get userDetails(): UserDetails {
    const userDetails = this.$store.direct.state.user.userDetails;
    if (userDetails != null) return userDetails;
    return new UserDetails();
  }

  set userDetails(item: UserDetails) {
    this.$store.direct.commit.user.SET_USER_DETAILS(JSON.parse(JSON.stringify(item)));
  }

  get companyDetails(): CompanyDetails {
    const companyDetails = this.$store.direct.state.user.userCompanyDetails;
    if (companyDetails != null) return companyDetails;
    return new CompanyDetails();
  }

  set companyDetails(item: CompanyDetails) {
    this.$store.direct.commit.user.SET_USER_COMPANY_DETAILS(JSON.parse(JSON.stringify(item)));
  }

  async validate(): Promise<boolean> {
    const userForm = this.validateUserForm();
    const companyForm = this.validateCompanyForm();
    const isValid = userForm && companyForm;
    if (!isValid) {
      this.$store.direct.dispatch.popup.addSnackbarFormError();
      return isValid;
    }
    if (this.checkDataChanged()) {
      const isConfirmed = await this.$store.direct.dispatch.user.updateProfileFormData({
        user: this.userDetails,
        company: this.companyDetails,
      });
      if (isConfirmed) {
        this.setUserTmpData(this.userDetails);
        this.setCompanyTmpData(this.companyDetails);
      } else {
        // to restore data on cancel
        // this.userDetails = this.userDetailsTmp;
        // this.companyDetails = this.companyDetailsTmp;
      }
      return isConfirmed;
    }
    return isValid;
  }

  validateForms() {
    const userFormValid = (this.$refs.userForm as FormValidation).validate();
    const companyFormValid = (this.$refs.companyForm as FormValidation).validate();
    return userFormValid && companyFormValid;
  }

  validateUserForm(): boolean {
    return (this.$refs.userForm as FormValidation).validate();
  }

  validateCompanyForm(): boolean {
    return (this.$refs.companyForm as FormValidation).validate();
  }

  checkDataChanged() {
    return (
      !this.compareModels<UserDetails>(this.userDetailsTmp, this.userDetails) ||
      !this.compareModels<CompanyDetails>(this.companyDetailsTmp, this.companyDetails)
    );
  }

  compareModels<T>(obj1: T, obj2: T): boolean {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
  }

  setUserTmpData(object: UserDetails) {
    var data = JSON.parse(JSON.stringify(object)) as UserDetails;
    this.userDetailsTmp = data;
  }

  setCompanyTmpData(object: CompanyDetails) {
    var data = JSON.parse(JSON.stringify(object)) as CompanyDetails;
    this.companyDetailsTmp = data;
  }

  async mounted() {
    this.setUserTmpData(this.userDetails);
    this.setCompanyTmpData(this.companyDetails);
  }
}
</script>

<style scoped></style>
