<template>
  <div>
    <p class="paragraph text-body-2 font-weight-medium">{{ item.title }}</p>
    <div v-if="typeof item.items != 'undefined'">
      <privacy-policy-paragraph-list :item="item" />
    </div>
    <p></p>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";
import PrivacyPolicyParagraphList from "@/components/privacyPolicy/PrivacyPolicyParagraphList.vue";

import { IPrivacyPolicyParagraph } from "@/models/components/privacyPolicy/privacyPolicy";

@Component({
  components: {
    PrivacyPolicyParagraphList,
  },
})
export default class PrivacyPolicyParagraph extends Vue {
  @Prop({ required: true })
  item!: IPrivacyPolicyParagraph;
}
</script>

<style lang="scss" scoped>
.paragraph {
  text-align: left;
  margin-top: 40px;
  margin-bottom: 20px;
}
</style>
