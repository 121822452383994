import { render, staticRenderFns } from "./TrashIcon.vue?vue&type=template&id=3a66a3dd"
import script from "./TrashIcon.vue?vue&type=script&lang=ts"
export * from "./TrashIcon.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports