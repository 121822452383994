<template>
  <span class="scaling-svg-container">
    <svg xmlns="http://www.w3.org/2000/svg" width="103.848" height="103.848" viewBox="0 0 103.848 103.848">
      <g transform="translate(-369.154 -487.154)">
        <circle cx="51.924" cy="51.924" r="51.924" transform="translate(369.154 487.154)" fill="#00d9ad" />
        <g id="Check" transform="translate(391.395 517.306)">
          <path
            d="M59.121,6.493,22.314,43.3a.828.828,0,0,1-1.173,0L.243,22.4a.829.829,0,0,1,0-1.172l5.078-5.078a.83.83,0,0,1,1.173,0L21.729,31.385,52.871.243a.83.83,0,0,1,1.173,0l5.077,5.078A.828.828,0,0,1,59.121,6.493Z"
            transform="translate(0 0)"
            fill="#fff"
          />
        </g>
      </g>
    </svg>
  </span>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

@Component
export default class TransactionSuccessIcon extends Vue {}
</script>

<style></style>
