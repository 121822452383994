export default class UserCompanyDetailsResponse {
  id!: string;
  name!: string;
  city!: string;
  country!: string;
  postalCode!: string;
  streetName!: string;
  streetNumber!: string;
  tin!: string;
  phoneNumber!: string;

  constructor(companyDetails?: UserCompanyDetailsResponse | null) {
    if (companyDetails != undefined) {
      this.id = companyDetails.id;
      this.name = companyDetails.name;
      this.city = companyDetails.city;
      this.country = companyDetails.country;
      this.postalCode = companyDetails.postalCode;
      this.streetName = companyDetails.streetName;
      this.streetNumber = companyDetails.streetNumber;
      this.tin = companyDetails.tin;
      this.phoneNumber = companyDetails.phoneNumber;
    } else {
      this.id = "";
      this.name = "";
      this.city = "";
      this.country = "";
      this.postalCode = "";
      this.streetName = "";
      this.streetNumber = "";
      this.tin = "";
      this.phoneNumber = "";
    }
  }
}
