<template>
  <span class="scaling-svg-container">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9.333 12">
      <path
        d="M4.667,0A.667.667,0,0,0,4,.667V7.019L1.188,4.2A.7.7,0,1,0,.2,5.189L4.14,9.129a.693.693,0,0,0,.527.2.694.694,0,0,0,.527-.2l3.936-3.94A.7.7,0,0,0,8.145,4.2L5.333,7.019V.667A.667.667,0,0,0,4.667,0Z"
        transform="translate(0)"
        fill="currentColor"
      />
      <path
        d="M0,.667A.667.667,0,0,1,.667,0h8a.667.667,0,1,1,0,1.333h-8A.667.667,0,0,1,0,.667Z"
        transform="translate(0 10.666)"
        fill="currentColor"
      />
    </svg>
  </span>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

@Component
export default class DownloadIcon extends Vue {}
</script>

<style></style>
