<template>
  <div class="view-container__wrap">
    <header-buttons :buttons="buttons"></header-buttons>
    <report-copies-filter :options.sync="tableOptions" />
    <div class="table-container">
      <v-data-table
        class="table"
        :items="forms.items"
        :headers="headers"
        :header-props="{ sortByText: 'Sortuj po', sortIcon: '$arrowUp' }"
        :options.sync="tableOptions"
        :server-items-length="forms.count"
        hide-default-footer
        single-select
        item-key="id"
      >
        <template #[`item.actions`]="{ item }">
          <v-tooltip bottom>
            <template #activator="{ on }">
              <v-btn icon :ripple="false" @click="continueForm(item)" v-on="on">
                <v-icon color="black" small>$play</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("tooltip.continueForm") | capitalize }}</span>
          </v-tooltip>
        </template>
      </v-data-table>
      <div class="table__pager">
        <custom-pagination :options.sync="tableOptions" :server-items-length="forms.count"> </custom-pagination>
      </div>
    </div>
    <add-form-dialog :show.sync="showAddReportDialog" />
    <form-dialog :show.sync="showReportDialog" />
    <form-summary-dialog v-if="showSummaryReportDialog" :show.sync="showSummaryReportDialog" />
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

//Components
import AddFormDialog from "@/components/reports/AddFormDialog.vue";
import FormDialog from "@/components/reports/FormDialog.vue";
import FormSummaryDialog from "@/components/reports/FormSummaryDialog.vue";

import ResizableContainer from "@/components/shared/layout/ResizableContainer.vue";
import ReportCopiesFilter from "@/components/reports/workingCopies/ReportCopiesFilter.vue";
import CustomPagination from "@/components/shared/dataTable/CustomPagination.vue";

//Models
import Form from "@/models/api/reports/form";
import { ListResult } from "@/models/api/filters/ListResult";
import HeaderButtons, { HeaderButtonsInterface } from "@/components/shared/buttons/HeaderButtons.vue";

//Vuetify models
import { DataTableHeader, DataOptions } from "vuetify";

//Other
import { filterCapitalize } from "@/filters/capitalize";
import i18n from "@/plugins/i18n";

@Component({
  components: {
    AddFormDialog,
    FormDialog,
    FormSummaryDialog,
    ReportCopiesFilter,
    CustomPagination,
    ResizableContainer,
    HeaderButtons,
  },
})
export default class ReportsCopiesView extends Vue {
  get forms(): ListResult<Form> {
    return this.$store.direct.state.report.forms ?? new ListResult<Form>();
  }

  get tableOptions(): DataOptions {
    return this.$store.direct.state.filter.reportCopiesFilterOptions;
  }

  set tableOptions(opt: DataOptions) {
    this.$store.direct.commit.filter.setReportCopiesFilterDataOptions(opt);
  }

  headers: Array<DataTableHeader> = [
    {
      text: filterCapitalize(i18n.t("header.name")),
      value: "name",
    },
    {
      text: filterCapitalize(i18n.t("header.createdAt")),
      value: "createAtStr",
      width: 200,
      align: "center",
    },
    {
      text: filterCapitalize(i18n.t("header.modifiedAt")),
      value: "lastModifyAtStr",
      width: 200,
      align: "center",
    },
    {
      text: "",
      value: "actions",
      align: "center",
      width: 160,
      sortable: false,
    },
  ];

  buttons: Array<HeaderButtonsInterface> = [
    {
      text: filterCapitalize(i18n.t("actions.addForm")),
      color: "black",
      icon: "$plus",
      action: () => {
        this.addReport();
      },
      class: "btn main-light",
      disabled: false,
      align: "left",
      width: 150,
    },
  ];

  get showAddReportDialog() {
    return this.$store.direct.state.report.showAddFormDialog;
  }

  set showAddReportDialog(param: boolean) {
    this.$store.direct.commit.report.setStateAddFormDialog(param);
  }

  get showReportDialog() {
    return this.$store.direct.state.report.showFormDialog;
  }

  set showReportDialog(param: boolean) {
    this.$store.direct.commit.report.setStateFormDialog(param);
  }

  get showSummaryReportDialog() {
    return this.$store.direct.state.report.showSummaryFormDialog;
  }

  set showSummaryReportDialog(param: boolean) {
    this.$store.direct.commit.report.setStateSummaryFormDialog(param);
  }

  addReport() {
    this.$store.direct.commit.report.setStateAddFormDialog(true);
  }

  async continueForm(item: Form) {
    this.showReportDialog = false;
    this.$store.direct.commit.report.setSelectedFormId(item.id);
    this.$store.direct.commit.report.setSelectedParentFormId(item.id);
    await this.$store.direct.dispatch.report.getFormActiveQuestion();
  }
}
</script>

<style lang="scss" scoped>
::v-deep .align-center {
  text-align: center;
  vertical-align: middle;
}

.item-children {
  td:nth-child(2) {
    display: flex;
    align-items: center;
  }
}

.item-children-icon {
  position: relative;
  left: 0;
  width: 20px;
  height: 1px;
  background-color: black;
  border-radius: 4px;
  margin-right: 15px;
}
</style>
