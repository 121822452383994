import { RuleFunction } from "@/rules/RuleFunction";
import { filterCapitalize } from "@/filters/capitalize";
import i18n from "@/plugins/i18n";

const passwordValidator: RuleFunction = (value: unknown | string): boolean | string => {
  if (typeof value === "string") {
    const isWhitespace = /^(?=.*\s)/;
    if (isWhitespace.test(value)) {
      return filterCapitalize(i18n.t("validation.passwordSpace").toString());
    }

    const isContainsUppercase = /^(?=.*[A-Z])/;
    if (!isContainsUppercase.test(value)) {
      return filterCapitalize(i18n.t("validation.passwordUppercase").toString());
    }

    const isContainsLowercase = /^(?=.*[a-z])/;
    if (!isContainsLowercase.test(value)) {
      return filterCapitalize(i18n.t("validation.passwordLowercase").toString());
    }

    const isContainsNumber = /^(?=.*[0-9])/;
    if (!isContainsNumber.test(value)) {
      return filterCapitalize(i18n.t("validation.passwordDigit").toString());
    }
    // eslint-disable-next-line
    const isContainsSymbol = /^(?=.*[~`!@#$%^&*()--+={}\[\]|\\:;"'<>,.?/_₹])/;
    if (!isContainsSymbol.test(value)) {
      return filterCapitalize(i18n.t("validation.passwordSymbol").toString());
    }

    const isValidLength = /^.{10,16}$/;
    if (!isValidLength.test(value)) {
      return filterCapitalize(i18n.t("validation.passwordLength", { length: "10 - 16" }).toString());
    }
    return true;
  }
  return filterCapitalize(i18n.t("validation.wrongDataType").toString());
};

export { passwordValidator };
export default passwordValidator;
