<template>
  <span class="scaling-svg-container">
    <svg xmlns="http://www.w3.org/2000/svg" width="19.5" height="19.5" viewBox="0 0 19.5 19.5">
      <g transform="translate(-2.25 -2.25)">
        <g>
          <rect width="7" height="7" rx="2.5" transform="translate(3 3)" fill="none" stroke="#000" stroke-width="1.5" />
          <rect
            width="7"
            height="7"
            rx="2.5"
            transform="translate(3 14)"
            fill="none"
            stroke="#000"
            stroke-width="1.5"
          />
          <rect
            width="7"
            height="7"
            rx="2.5"
            transform="translate(14 3)"
            fill="none"
            stroke="#000"
            stroke-width="1.5"
          />
          <rect
            width="7"
            height="7"
            rx="3.5"
            transform="translate(14 14)"
            fill="none"
            stroke="#000"
            stroke-width="1.5"
          />
        </g>
      </g>
    </svg>
  </span>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

@Component
export default class StatisticIcon extends Vue {}
</script>

<style></style>
