<template>
  <div>
    <div :style="`width: ${width}px`">
      <v-form ref="form" v-model="valid" lazy-validation :disabled="disabled">
        <v-flex v-if="flex" xl3 lg4 md6 sm8>
          <slot></slot>
        </v-flex>
        <div v-else>
          <slot></slot>
        </div>
      </v-form>
      <div v-if="showTestButtons">
        <v-btn @click="validate">Validate</v-btn>
        <v-btn @click="resetValidation">resetValidation</v-btn>
        <v-btn @click="resetErrorBag">resetErrorBag</v-btn>
        <v-btn @click="reset">Reset</v-btn>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

//Helper
import Validate from "@/models/helpers/Validate";

@Component
export default class FormInput extends Vue {
  @Prop()
  value!: boolean;
  @Prop({ default: "auto" })
  width!: string | number;
  @Prop({ default: true })
  flex!: boolean;
  @Prop({ default: false })
  disabled!: boolean;
  @Prop({ default: false }) //Only for testing
  showTestButtons!: boolean;

  get valid(): boolean {
    return this.value;
  }

  set valid(value: boolean) {
    this.$emit("input", value);
  }

  //$ Check form validation
  validate() {
    const valid = (this.$refs.form as Vue & Validate).validate();
    this.valid = valid;
    return valid;
  }

  //$ Reset form validation
  resetValidation() {
    (this.$refs.form as Vue & Validate).resetValidation();
  }

  //$ Reset form errorBag only when lazy-validation is true
  resetErrorBag() {
    (this.$refs.form as Vue & Validate).resetErrorBag();
  }

  //$ Reset form fields
  reset() {
    (this.$refs.form as Vue & Validate).reset();
  }
}
</script>

<style scoped></style>
