<template>
  <div>
    <footer-desktop v-if="!$vuetify.breakpoint.mobile" />
    <footer-mobile v-else />
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import FooterDesktop from "@/components/anonymous/startPage/footer/FooterDesktop.vue";
import FooterMobile from "@/components/anonymous/startPage/footer/FooterMobile.vue";

@Component({
  components: {
    FooterDesktop,
    FooterMobile,
  },
})
export default class Footer extends Vue {}
</script>

<style scoped></style>
