<template>
  <span class="scaling-svg-container">
    <svg xmlns="http://www.w3.org/2000/svg" width="103.848" height="103.848" viewBox="0 0 103.848 103.848">
      <g transform="translate(-653.576 -371.576)">
        <g transform="translate(284.422 -115.578)">
          <circle cx="51.924" cy="51.924" r="51.924" transform="translate(369.154 487.154)" fill="#bd0555" />
        </g>
        <g id="Group_6320" data-name="Group 6320" transform="translate(-730.579 253.421)">
          <g transform="translate(1407.579 141.579)" fill="none" stroke="#fff" stroke-width="5">
            <circle cx="28.5" cy="28.5" r="28.5" stroke="none" />
            <circle cx="28.5" cy="28.5" r="26" fill="none" />
          </g>
          <line
            x1="40.015"
            y2="40.015"
            transform="translate(1416.072 150.072)"
            fill="none"
            stroke="#fff"
            stroke-width="5"
          />
        </g>
      </g>
    </svg>
  </span>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

@Component
export default class TransactionFailureIcon extends Vue {}
</script>

<style></style>
