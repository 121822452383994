<template>
  <div>
    <company-details-form ref="companyForm" :value.sync="companyDetails" />
  </div>
</template>

<script lang="ts">
import { Component, Vue, PropSync } from "vue-property-decorator";

//Components
import CompanyDetailsForm from "@/components/administration/account/forms/CompanyDetailsForm.vue";

//Models
import UserCompanyDetailsResponse from "@/models/api/user/UserCompanyDetailsResponse";

@Component({
  components: {
    CompanyDetailsForm,
  },
})
export default class CompanyDetailsTemplate extends Vue {
  @PropSync("value", { required: true })
  companyDetails!: UserCompanyDetailsResponse;

  showChangePasswordDialog = false;

  //Form validation
  validate() {
    return (this.$refs.companyForm as HTMLFormElement).validate();
  }

  resetValidation() {
    (this.$refs.companyForm as HTMLFormElement).resetValidation();
  }
}
</script>

<style scoped></style>
