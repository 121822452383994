import { filterCapitalize } from "@/filters/capitalize";
import i18n from "@/plugins/i18n";
import { validateUserExist } from "@/requests/validation";

const pattern =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const emailValidator = async (value: string): Promise<boolean | string> => {
  if (pattern.test(value)) {
    if (!(await userValidate(value))) return true;
    return filterCapitalize(i18n.t("validation.userExist").toString());
  }
  return filterCapitalize(i18n.t("validation.invalidEmail").toString());
};

const userValidate = async (value: string): Promise<boolean> => {
  const x = await validateUserExist(value);
  return x.model;
};

export { emailValidator };
export default emailValidator;
