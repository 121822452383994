<template>
  <span class="scaling-svg-container">
    <svg
      id="Icon_Edit_Pen"
      data-name="Icon/Edit Pen"
      xmlns="http://www.w3.org/2000/svg"
      width="6.374"
      height="6.356"
      viewBox="0 0 6.374 6.356"
      class="scaling-svg"
    >
      <path
        id="Icon_Icon_Edit"
        data-name="Icon/Icon/Edit"
        d="M.4,6.356a.4.4,0,0,1-.282-.117A.4.4,0,0,1,0,5.922L.152,4.264A.784.784,0,0,1,.38,3.781L3.964.2A.717.717,0,0,1,4.478,0a.809.809,0,0,1,.569.229L6.137,1.318A.766.766,0,0,1,6.165,2.4L2.58,5.977A.786.786,0,0,1,2.1,6.2L.435,6.354ZM3.181,2.109h0L.943,4.343.838,5.519l1.186-.108,2.231-2.23L3.181,2.109ZM4.491.8l-.776.775L4.789,2.648l.775-.776Z"
        transform="translate(0 0)"
        fill="currentColor"
      />
    </svg>
  </span>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

@Component
export default class EditIcon extends Vue {}
</script>

<style></style>
