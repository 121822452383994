<template>
  <span class="scaling-svg-container">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="47.451"
      height="43.648"
      viewBox="0 0 47.451 43.648"
      class="scaling-svg"
    >
      <path
        d="M142.727,170.712h-2.955V144c0-4.154-2.37-5.939-3.862-6.231l-9.478-2.955V131.54a1.635,1.635,0,0,0-.585-1.2,1.8,1.8,0,0,0-1.2-.293l-19.864,5.646s-3.569.878-3.569,5.646v29.371H98.26a1.492,1.492,0,1,0,0,2.984h44.467a1.413,1.413,0,0,0,1.492-1.492,1.4,1.4,0,0,0-1.492-1.492Zm-19.279,0H104.169V141.34c0-2.077,1.2-2.662,1.492-2.662l17.787-5.032v37.065Zm13.369,0H126.432V137.771l9.157,3.042.2.088s.907.38,1.053,3.1v26.709Zm-24.34-30.951h-2.955v2.955h2.955Zm6.816,0h-2.955v2.955h2.955Zm-6.816,6.465h-2.955v2.955h2.955Zm6.816,0h-2.955v2.955h2.955Zm-6.816,6.465h-2.955v2.955h2.955Zm6.816,0h-2.955v2.955h2.955Zm-6.816,6.436h-2.955v2.984h2.955Zm6.816,0h-2.955v2.984h2.955Zm-6.816,6.465h-2.955v2.955h2.955Zm6.816,0h-2.955v2.955h2.955Zm11-17.143h2.955v-2.955h-2.955Zm0,6.816h2.955v-2.955h-2.955Zm0,6.495h2.955v-2.955h-2.955Zm0,6.582h2.955v-2.955h-2.955Z"
        transform="translate(-96.768 -130.048)"
        fill="#d4ab5b"
      />
    </svg>
  </span>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

@Component
export default class CompanyIcon extends Vue {}
</script>

<style></style>
