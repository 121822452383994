<template>
  <div>
    <div v-for="(value, index) of extension.values" :key="index">
      <int-extension :value="value" />
    </div>
    <v-form ref="form">
      <div class="array-validator">
        <v-text-field ref="input" flat :rules="[extensionValidator(extension)]"> </v-text-field>
      </div>
    </v-form>
  </div>
</template>

<script lang="ts">
import { Component, Vue, PropSync, Watch } from "vue-property-decorator";

//Components
import IntExtension from "@/components/reports/workFlow/components/extensions/IntExtension.vue";

//Models
import { ArrayIntExtension as ArrayIntExt } from "@/models/api/reports/workFlow/BaseExtension";

//Validation
import requiredValidator from "@/rules/requiredValidator";
import extensionValidator from "@/rules/extensionValidator";

@Component({
  components: {
    IntExtension,
  },
})
export default class ArrayIntExtension extends Vue {
  @PropSync("value")
  extension!: ArrayIntExt;

  //TODO remove after tests!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! -> validation implenented in Base
  @Watch("extension", { deep: true })
  validate() {
    const form = this.$refs.form as HTMLFormElement;
    form.validate();
  }

  //Validation
  required = requiredValidator;
  extensionValidator = extensionValidator;
}
</script>

<style lang="scss" scoped>
::v-deep .array-validator {
  .v-text-field {
    pointer-events: none !important;
    height: 0 !important;
    margin-bottom: 20px !important;
    padding: 0 !important;
    .v-input__control {
      display: block !important;
      height: 0 !important;
      .v-input__slot {
        height: 0 !important;
      }
      .v-input__slot::before {
        border-style: none !important;
        height: 0 !important;
      }
    }
  }
}
</style>
