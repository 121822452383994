import axios, { AxiosResponse } from "axios";
import { vuexStore } from "@/store";
import { messageTypesToName } from "@/static/messageTypes";

axios.defaults.baseURL = process.env.VUE_APP_API_ADDRESS;
axios.defaults.headers.post["Content-Type"] = "application/json";

export const setAuthAxios = (token: string): void => {
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;
};

export const removeAuthAxios = (): void => {
  delete axios.defaults.headers.common["Authorization"];
};

export const prepareUrlWithParams = (params: unknown, url: string): string => {
  if (typeof params === "object" && params != null) {
    Object.entries(params).forEach(([key, value]) => {
      if (typeof value === "string" || typeof value === "number") {
        if (url?.includes("${" + key + "}")) {
          url = url?.replace("${" + key + "}", typeof value === "string" ? value : value.toString());
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          delete params[key];
        }
      }
    });
  }
  return url;
};

// $You can intercept requests or responses before they are handled by 'then' or 'catch'
// $Request
axios.interceptors.request.use(
  (request) => {
    vuexStore.commit.INCREASE_PENDING_CALLS();
    return request;
  },
  (error) => {
    return error;
  }
);

// $Response
axios.interceptors.response.use(
  async (response) => {
    vuexStore.commit.DECREASE_PENDING_CALLS();
    checkResponseStatus(response);
    return response;
  },
  async (error) => {
    vuexStore.commit.DECREASE_PENDING_CALLS();
    if (typeof error.response !== "undefined") {
      switch (error.response.status) {
        case 401:
          await vuexStore.dispatch.user.logout();
          break;
        case 403:
          await vuexStore.dispatch.user.noPermissions();
          break;
      }
      checkResponseStatus(error.response);
      error.response = checkMessageFromResponse(error.response);
    }
    return error.response;
  }
);

// $Check post method response status
// $This allows us to show appropriate snackbar
// $-------------------------------------------
// $For response type 200. If header "Snackbar" value is "true" snackbar will be displayed -> property configured in request (RequestHelper constructor)
const checkResponseStatus = (response: AxiosResponse) => {
  switch (response.status) {
    case 200:
      if (response.config.headers?.Snackbar === "true") vuexStore.dispatch.popup.addSnackbarSuccess();
      break;
    case 500:
      vuexStore.dispatch.popup.addSnackbarError();
      break;
  }
};

const errorResponse = (response: AxiosResponse): boolean => {
  return typeof response.data.messages !== "undefined";
};

const checkMessageFromResponse = (response: AxiosResponse): AxiosResponse => {
  if (!errorResponse) return response;
  const content: Array<string> = [];
  for (const message of response.data.messages) {
    content.push(message.message);
  }
  switch (response.status) {
    case 400:
      vuexStore.commit.popup.ADD_SNACKBAR({
        content: content,
        color: messageTypesToName(response.data.messages[0].type),
        show: true,
      });
      break;
    case 500:
      vuexStore.commit.popup.ADD_ALERT({
        content: content,
        title: messageTypesToName(response.data.messages[0].type),
        show: true,
      });
      break;
  }

  response.data = response.data.model;
  return response;
};

export { axios as axiosInstance };
export default axios;
