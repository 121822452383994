<template>
  <div>
    <v-dialog
      v-for="(alert, index) of alerts.filter((v) => v.show)"
      :key="index + Math.random()"
      :value.sync="alert.show"
      persistent
      width="450px"
      :overlay-opacity="0.4"
      elevation
      content-class="elevation-0"
      transition="dialog-top-transition"
      rounded="xl"
    >
      <v-card>
        <v-card-title>
          {{ $t("alerts." + alert.title.toLowerCase()) | capitalize }}
        </v-card-title>
        <v-card-text>
          <div>
            <div v-for="(text, num) in alert.content" :key="num">
              <div v-html="getTranslation(text)"></div>
            </div>
          </div>
        </v-card-text>
        <v-card-actions class="pb-3">
          <v-row justify="center" no-gutters>
            <v-btn class="btn main-light" :width="150" plain @click="alert.show = false">
              {{ $t("alerts.close") | capitalize }}
            </v-btn>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Watch } from "vue-property-decorator";
import { AlertModel } from "@/models/components/popup/Alert";
import { filterCapitalize } from "@/filters/capitalize";
import i18n from "@/plugins/i18n";

@Component
export default class Alert extends Vue {
  get alerts(): Array<AlertModel> {
    return this.$store.direct.state.popup.alerts;
  }

  get alertTimeOut(): number {
    return this.$store.direct.state.popup.alertTimeOut;
  }

  getTranslation(message: string): string {
    return filterCapitalize(i18n.t("alerts." + message));
  }

  @Watch("alerts", { deep: true })
  update() {
    if (this.alerts.filter((v) => v.show).length == 0) this.$store.direct.commit.popup.HIDE_ALERTS();
  }

  destroyed() {
    this.$store.direct.commit.popup.CLEAR_ALERTS();
  }
}
</script>

<style lang="scss" scoped>
::v-deep .v-dialog {
  border-radius: 25px !important;
  transition: 0.4s;
}

.v-card {
  border-radius: 25px !important;
}
</style>
