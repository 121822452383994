<template>
  <svg></svg>
</template>

<script lang="ts">
import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class IconHelper extends Vue {
  @Prop({ default: undefined })
  color!: string | undefined;
}
</script>

<style scoped></style>
