<template>
  <div>
    <v-container>
      <v-form ref="form">
        <v-flex>
          <v-flex row>
            <v-col cols="12">
              <v-text-field
                v-model="_user.name"
                autocomplete="new-password"
                label="Imię"
                :rules="[required]"
                type="text"
              ></v-text-field>
            </v-col>
          </v-flex>
          <v-flex row>
            <v-col cols="12">
              <v-text-field
                v-model="_user.surname"
                autocomplete="new-password"
                label="Nazwisko"
                :rules="[required]"
                type="text"
              ></v-text-field>
            </v-col>
          </v-flex>
        </v-flex>
      </v-form>
    </v-container>
  </div>
</template>

<script lang="ts">
import { Component, Vue, PropSync } from "vue-property-decorator";

//Models
import UserRequest from "@/models/api/user/UserRequest";

//Validation
import requiredValidator from "@/rules/requiredValidator";
import emailValidator from "@/rules/emailValidator";
import passwordValidator from "@/rules/passwordValidator";

@Component
export default class UserInfoForm extends Vue {
  @PropSync("user", { required: true })
  _user!: UserRequest;
  @PropSync("valid")
  _valid!: boolean;

  //Validators
  required = requiredValidator;
  password = passwordValidator;
  email: string | boolean = true;

  async checkEmail() {
    this.email = await emailValidator(this._user.email);
  }

  //Form validation
  validate() {
    const valid = (this.$refs.form as HTMLFormElement).validate();
    this._valid = valid;
  }
}
</script>

<style scoped></style>
