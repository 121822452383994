export interface SnackbarModelInterface {
  content: string[];
  color: string;
  show: boolean;
  params?: { [key: string]: string };
}

export class SnackbarModel implements SnackbarModelInterface {
  content: string[];
  color: string;
  show: boolean;
  params?: { [key: string]: string };

  constructor(content: string[], color = "success", params?: { [key: string]: string }) {
    this.content = content;
    this.color = color;
    this.show = true;
    this.params = params;
  }
}
