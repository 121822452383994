import { defineModule } from "direct-vuex";
import { moduleActionContext, moduleGetterContext } from "@/store/index";

//Requests
import { settings, setSetting } from "@/requests/setting";
import Setting from "@/models/api/setting/Setting";

export interface SettingState {
  settings: Array<Setting>;
  selectedSetting: Setting;
}

export const SettingState: SettingState = {
  settings: [],
  selectedSetting: new Setting(),
};

const developerModule = defineModule({
  state: SettingState,
  namespaced: true as const,
  getters: {},
  mutations: {
    setSettings(state, settingsList: Array<Setting>) {
      state.settings = settingsList;
    },
  },
  actions: {
    async settings(context) {
      const { commit } = _moduleActionContext(context);
      const response = await settings();
      commit.setSettings(response.model);
    },
    async setSetting(context, model: Setting) {
      await setSetting(model);
    },
  },
});

export default developerModule;

/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable  @typescript-eslint/no-unused-vars */
const _moduleGetterContext = (args: [any, any, any, any]) => moduleGetterContext(args, developerModule);
const _moduleActionContext = (context: any) => moduleActionContext(context, developerModule);
