import { filterCapitalize } from "@/filters/capitalize";
import i18n from "@/plugins/i18n";
import { RuleFunction } from "./RuleFunction";

const verificationTable = [6, 5, 7, 2, 3, 4, 5, 6, 7];

const tinValidator: RuleFunction = (value: unknown | string | number): string | boolean => {
  if (typeof value === "string" || typeof value === "number") {
    const tinToValidate = value.toString().replace(/[ -]/gi, "");
    if (tinToValidate.length == 0) {
      return true;
    }

    if (tinToValidate.length != 10) {
      return filterCapitalize(i18n.t("validation.tinLengthIsIncorrect"));
    }

    const result = verificationTable.reduce((val, curr: number, index: number) => {
      return (val += curr * Number(tinToValidate[index]));
    }, 0);

    if (result % 11 == Number(tinToValidate[9])) {
      return true;
    }

    return filterCapitalize(i18n.t("validation.tinIsIncorrect").toString());
  }
  return true;
};

export { tinValidator };
export default tinValidator;
