import { ITermsOfUse, ITermsOfUseParagraph } from "@/models/components/termsOfUse/termsOfUse";

const paymentProvider = "PayU S.A";

const paragraph1: ITermsOfUseParagraph = {
  paragraph: "§1 Postanowienia Ogólne",
  items: [
    {
      id: 1,
      text: "Niniejszy regulamin reguluje zasady korzystania z serwisu prowadzonego pod adresem system.sukcesja.biz. REGON: 121536103",
    },
    {
      id: 2,
      text: "Serwis, działający pod adresem system.sukcesja.biz (zwany dalej: Sukcesja.biz), jest prowadzony przez FollowUp Sp. z o.o. wpisaną do KRS pod nr 0000386913, NIP: 676-244-14-67",
    },
    {
      id: 3,
      text: "Dane teleadresowe przedsiębiorcy, umożliwiające Użytkownikowi kontakt ze Sprzedawcą:",
      items: [
        {
          id: 31,
          text: "adres pocztowy: Kielnarowa 108B, 36-020 Tyczyn",
        },
        {
          id: 32,
          text: "numer telefonu: tel.: 17 779 64 13",
        },
        {
          id: 33,
          text: "adres poczty elektronicznej: biuro@sukcesja.biz",
        },
      ],
    },
    {
      id: 4,
      text: "Regulamin serwisu Sukcesja.biz umieszczony jest nieprzerwanie na stronie internetowej system.sukcesja.biz w sposób umożliwiający Klientom jego pozyskanie, odtwarzanie i utrwalanie jego treści. Regulamin jest również udostępniany Klientom przed zawarciem umowy.",
    },
  ],
};

const paragraph2: ITermsOfUseParagraph = {
  paragraph: "§2 Definicje",
  text: "Pojęcia, którymi posługuje się niniejszy regulamin oznaczają:",
  items: [
    {
      id: 1,
      text: "Konsument – jest nią osoba fizyczna w rozumieniu art. 221 Kodeksu cywilnego,",
    },
    {
      id: 2,
      text: "Przedsiębiorca na prawach konsumenta – jest nią osoba fizyczna zawierająca umowę bezpośrednio związaną z jej działalnością gospodarczą, gdy z treści tej umowy wynika, że nie posiada ona dla tej osoby charakteru zawodowego, wynikającego w szczególności z przedmiotu wykonywanej przez nią działalności gospodarczej, udostępnionego na podstawie przepisów o Centralnej Ewidencji i Informacji o Działalności Gospodarczej,",
    },
    {
      id: 3,
      text: "Przedsiębiorca - oznacza osobę fizyczną, osobę prawną lub jednostkę organizacyjną niebędącą osobą prawną, której ustawa przyznaje zdolność prawną, prowadzącą we własnym imieniu działalność gospodarczą lub zawodową i dokonującą czynności prawnej związanej bezpośrednio z jej działalnością gospodarczą lub zawodową,",
    },
    {
      id: 4,
      text: "Sprzedawca – FollowUp Sp. z o.o., Kielnarowa 108B, 36-020 Tyczyn, NIP: 676-244-14-67, REGON: 121536103",
    },
    {
      id: 5,
      text: "Serwis Sukcesja – serwis prowadzony pod adresem system.sukcesja.biz przez FollowUp Sp. z o.o.",
    },
    {
      id: 6,
      text: "Kodeks cywilny – ustawa z dnia 23 Kwietnia 1964r. (Dz.U.2020.1740),",
    },
    {
      id: 7,
      text: "Produkty – wszelkie produkty będące przedmiotem umowy sprzedaży między sprzedawcą a użytkownikiem.",
    },
    {
      id: 8,
      text: "Regulamin – niniejszy regulamin świadczenia usług sprzedaży drogą elektroniczną w rozumieniu art. 8 ustawy o świadczeniu usług drogą elektroniczną z dnia 18 lipca 2002 r. (Dz.U.2020.344),",
    },
    {
      id: 9,
      text: "Użytkownik/Nabywca treści cyfrowych – osoba fizyczna, osoba prawna lub jednostka organizacyjna niebędąca osobą prawną, której przepisy szczególne przyznają zdolność prawną, która dokonuje lub planuje dokonać zamówienia w ramach serwisu z wykorzystaniem drogi elektronicznej, w tym również Konsumenta, Przedsiębiorcę na prawach konsumenta oraz Przedsiębiorcę,",
    },
    {
      id: 10,
      text: "Zamówienie – oświadczenie woli Użytkownika, w którym to Nabywca treści cyfrowych akceptuje ofertę sprzedaży Sprzedawcy, która zawiera w szczególności cenę, rodzaj, ilość Produktów w ramach umowy sprzedaży za pośrednictwem serwisu.",
    },
    {
      id: 11,
      text: "Ustawa o prawach konsumenta - ustawa z dnia 30 maja 2014 r. o prawach konsumenta (Dz.U.2020.287).",
    },
  ],
};

const paragraph3: ITermsOfUseParagraph = {
  paragraph: "§3 Zasady korzystania z serwisu",
  items: [
    {
      id: 1,
      text: "Ceny wszystkich oferowanych przez serwis produktów są cenami brutto (zawierają podatek VAT) oraz są wyrażone w złotych polskich. Odstępstwa od zasady waluty polskiej są wyraźnie zaznaczone.",
    },
    {
      id: 2,
      text: "Ceny widniejące na stronie internetowej serwisu Sukcesja, jak również opisy produktów stanowią jedynie informację handlową, a nie ofertę w rozumieniu Kodeksu Cywilnego. Charakter wiążący – na potrzeby zawarcia konkretnej umowy – zyskują one dopiero z chwilą potwierdzenia przyjęcia zamówienia do realizacji przez Sprzedającego.",
    },
    {
      id: 3,
      text: "Do złożenia zamówienia w serwisie Sukcesja.biz konieczna jest akceptacja niniejszego Regulaminu.",
    },
    {
      id: 4,
      text: "Do skutecznego złożenia zamówienia w Sukcesja.biz konieczne jest posiadanie urządzenia z dostępem do Internetu umożliwiające przeglądanie stron internetowych, a także klawiatura lub inne rządzenie umożliwiające poprawne wypełnienie formularzy elektronicznych. Zaleca się korzystanie z najnowszych wersji przeglądarek internetowych: Firefox, Chrome, Microsoft Edge, Opera, Safari dla systemu OS, itd.",
    },
    {
      id: 5,
      text: "Strona serwisu korzysta z technologii Responsive Web Design (RWD), która dopasowuje treść strony do urządzenia na którym jest ona wyświetlana. Strona serwisu jest przygotowana do wyświetlenia zarówno na komputerach stacjonarnych jak i również urządzeniach mobilnych.",
    },
    {
      id: 6,
      text: "Strona system.sukcesja.biz posiada certyfikat SSL – bezpieczny protokół szyfrowania komunikacji.",
    },
  ],
};

const paragraph4: ITermsOfUseParagraph = {
  paragraph: "§4 Warunki zawierania umowy sprzedaży",
  items: [
    {
      id: 1,
      text: "Zamówienia w serwisie Sukcesja.biz można dokonywać poprzez wypełnienie odpowiednich formularzy dostępnych na stronach serwisu.",
    },
    {
      id: 2,
      text: "Użytkownik podczas składania zamówienia jest zobowiązany do podania prawidłowych danych osobowych.",
    },
    {
      id: 3,
      text: "Po otrzymaniu zamówienia, Sprzedawca informuje o utworzeniu transakcji. Potwierdzenie transakcji odbywa się poprzez przesłanie przez sprzedającego do Użytkownika odpowiedniej wiadomości mailowej na adres podany w formularzu zamówienia. Z chwilą potwierdzenia otrzymania wpłaty przez Sprzedającego, umowa uważana jest za zawartą.",
    },
    {
      id: 4,
      text: "Udostępnienie oraz utrwalenie treści umowy sprzedaży następuje poprzez udostępnienie niniejszego Regulaminu na stronie system.sukcesja.biz",
    },
    {
      id: 5,
      text: "Dokonywanie Zamówień w Sukcesja.biz jest możliwe 24 godziny na dobę przez wszystkie dni w roku.",
    },
  ],
};

const paragraph5: ITermsOfUseParagraph = {
  paragraph: "§5 Sposób płatności i termin płatności",
  items: [
    {
      id: 1,
      text: `Podmiotem świadczącym obsługę płatności online jest <a href="https://poland.payu.com/" target="_blank">${paymentProvider}</a>, dostępne metody płatności: płatność kartą (Visa, Mastercard, Maestro), przelew internetowy, przelew tradycyjny),`,
      images: [
        {
          path: "payu/Pasek_banki_karty_11_2022.png",
          position: "center",
          maxHeight: 200,
          maxWidth: 1215,
        },
      ],
    },
  ],
};

const paragraph6: ITermsOfUseParagraph = {
  paragraph: "§6 Dostawa",
  items: [
    {
      id: 1,
      text: "Zamówione produkty są dostarczane wyłącznie za pośrednictwem strony Sukcesja.biz w formie wirtualnych licencji.",
    },
    {
      id: 2,
      text: "Czas dostarczenia licencji to maksymalnie 48h, jednakże zazwyczaj następuje niezwłocznie po zaksięgowaniu przez Sukcesja.biz należności.",
    },
  ],
};

const paragraph7: ITermsOfUseParagraph = {
  paragraph: "§7 Odstąpienie do umowy",
  items: [
    {
      id: 1,
      text: "Jeżeli produkty cyfrowe są niezgodne z umową, nabywca treści cyfrowych może złożyć oświadczenie o obniżeniu ceny albo odstąpieniu od umowy, gdy:",
      items: [
        {
          id: 1.1,
          text: "Doprowadzenie do zgodności produktów cyfrowych z umową jest niemożliwe albo wymaga nadmiernych kosztów stosownie do art. 43m ust. 2 i 3",
        },
        {
          id: 1.1,
          text: "Sprzedawca nie doprowadził produktów cyfrowych do zgodności z umową zgodnie z art. 43m ust. 4",
        },
        {
          id: 1.1,
          text: "Brak zgodności produktów cyfrowych z umową występuje nadal, mimo że przedsiębiorca próbował doprowadzić produkty cyfrowe do zgodności z umową",
        },
        {
          id: 1.1,
          text: "Brak zgodności produktów cyfrowych z umową jest na tyle istotny, że uzasadnia obniżenie ceny albo odstąpienie od umowy bez uprzedniego skorzystania ze środka ochrony określonego w art. 43m",
        },
        {
          id: 1.1,
          text: "Z oświadczenia przedsiębiorcy lub okoliczności wyraźnie wynika, że nie doprowadzi on produktów cyfrowych do zgodności z umową w rozsądnym czasie lub bez nadmiernych niedogodności dla konsumenta",
        },
      ],
    },
    {
      id: 2,
      text: "Obniżona cena musi pozostawać w takiej proporcji do ceny wynikającej z umowy, w jakiej wartość produktów cyfrowych niezgodnych z umową pozostaje do wartości produktów cyfrowych zgodnych z umową. Przy obniżeniu ceny uwzględniony będzie czas, w którym produkty cyfrowe pozostawały niezgodne z umową.",
    },
    {
      id: 3,
      text: "Użytkownik nie może odstąpić od umowy, jeżeli produkty cyfrowe są dostarczane w zamian za zapłatę ceny, a brak zgodności produktów cyfrowych z umową jest nieistotny. Domniemywa się, że brak zgodności produktów cyfrowych z umową jest istotny.",
    },
  ],
};

const paragraph8: ITermsOfUseParagraph = {
  paragraph: "§8 Reklamacje",
  items: [
    {
      id: 1,
      text: "Podstawa i zakres odpowiedzialności Sprzedawcy wobec Użytkownika, z tytułu niezgodności Produktu z Umową są określone powszechnie obowiązującymi przepisami prawa oraz poniższymi postanowieniami Regulaminu.",
    },
    {
      id: 2,
      text: "Sprzedawca jest obowiązany dostarczyć produkt bez wad. W przypadku gdy produkt jest niezgodny z umową lub posiada wady, Użytkownik ma prawo skorzystać z reklamacji zgodnie o przepisami ustawy z dnia 27 lipca 2002 r. o szczególnych warunkach sprzedaży konsumenckiej oraz o zmianie Kodeksu cywilnego (Dz. U. Nr 141, poz. 1176 z późn. zm.) lub regulacjami w z zakresu kodeksu cywilnego z zakresu rękojmi za wady",
    },
    {
      id: 3,
      text: "W przypadku stwierdzenia nieprawidłowości lub w przypadku skierowania reklamacji do Sprzedającego, Nabywca treści cyfrowych powinien przesłać informacje o tym fakcie na adres biuro@sukcesja.biz. W zgłoszeniu zaleca się wskazać wadę, jaką zdaniem Użytkownika produkt posiada, oraz jeżeli jest to możliwe – udokumentować rzeczoną wadę, datę wystąpienia wady, żądanie sposobu doprowadzenia do zgodności produktu z umową zgodnie z art. 560 i nast. Kodeksu cywilnego oraz podanie danych kontaktowych. Powyższe wymogi mają charakter zaleceń, ich brak nie ma wpływu na skuteczność reklamacji.",
    },
    {
      id: 4,
      text: "Sprzedawca w terminie 14 dni od otrzymania zgłoszenia ustosunkuje się do zgłoszenia. Brak ustosunkowania się Sprzedawcy w powyższym terminie oznacza, że Sprzedawca uznał reklamację za uzasadnioną.",
    },
    {
      id: 5,
      text: "Szczegółowe informacje dotyczące możliwości skorzystania przez Użytkownika będącego Konsumentem z pozasądowych sposobów rozpatrywania reklamacji i dochodzenia roszczeń oraz zasady dostępu do tych procedur dostępne są w siedzibach oraz na stronach internetowych powiatowych (miejskich) rzeczników konsumentów, organizacji społecznych, do których zadań statutowych należy ochrona konsumentów, Wojewódzkich Inspektoratów Inspekcji Handlowej oraz pod następującymi adresami internetowymi Urzędu Ochrony Konkurencji i Konsumentów:",
    },
    {
      id: 6,
      text: "Sprzedawca odpowiada za wady produktu na podstawie rękojmi (art 1 ust b), w sytuacjach i na zasadach określonych prawem.",
    },
    {
      id: 7,
      text: "Jeżeli produkty cyfrowe są niezgodne z umową, uzytkownik może żądać doprowadzenia do ich zgodności z umową.",
    },
    {
      id: 8,
      text: "Sprzedawca może odmówić doprowadzenia produktów cyfrowych do zgodności z umową, jeżeli doprowadzenie do zgodności produktów cyfrowych z umową jest niemożliwe albo wymagałoby nadmiernych kosztów dla Sprzedawcy.",
    },
    {
      id: 9,
      text: "Przy ocenie nadmierności kosztów dla Sprzedawcy uwzględnia się wszelkie okoliczności sprawy, w szczególności znaczenie braku zgodności produktów cyfrowych z umową oraz wartość produktów cyfrowych zgodnych z umową.",
    },
    {
      id: 10,
      text: "Sprzedawca doprowadza produkty cyfrowe do zgodności z umową w rozsądnym czasie od chwili, w której Sprzedawca został poinformowany przez Uzytkownika o braku zgodności z umową, i bez nadmiernych niedogodności dla Użytkownika, uwzględniając ich charakter oraz cel, w jakim są wykorzystywane. Koszty doprowadzenia produktów cyfrowych do zgodności z umową ponosi Sprzedawca.",
    },
    {
      id: 11,
      text: "Wszystkie spory związane z niniejszym regulaminem oraz dotyczące umów sprzedaży mogą być rozstrzygane:",
      items: [
        {
          id: 11.1,
          text: "Jeśli Użytkownik jest konsumentem na drodze mediacji, szczegółowe informacje znajdując się pod adresem: https://uokik.gov.pl/wojewodzkie_inspektoraty_inspekcji_handlowej.php lub za pomocą Sądu Powszechnego.",
        },
        {
          id: 11.2,
          text: "Jeśli Użytkownik jest przedsiębiorcą na drodze mediacji, Sądu Arbitrażowego lub Sądu Powszechnego.",
        },
      ],
    },
  ],
};

const paragraph9: ITermsOfUseParagraph = {
  paragraph: "§9 Dane osobowe",
  items: [
    {
      id: 1,
      text: "Administratorem danych osobowych jest Sprzedawca.",
    },
    {
      id: 2,
      text: "Do zakupu w serwisie Sukcesja niezbędne jest podanie danych Użytkownika oraz akceptacja niniejszego regulaminu, a więc także wyrażenie zgodny na przetwarzanie danych osobowych.",
    },
    {
      id: 3,
      text: "Wszystkie dane osobowe, które zostają podane w serwisie Sukcesja będą wykorzystywane tylko w celu realizacji zamówień oraz do celów badań naukowych i analiz.",
    },
    {
      id: 4,
      text: "Wszystkie dane osobowe są przetwarzane przez Sprzedawcę zgodnie z obowiązującym prawem (Rozporządzenia Parlamentu Europejskiego i Rady (UE) 2016/679 z 27.04.2016r. i akty towarzyszące)",
    },
    {
      id: 5,
      text: `Dane przetwarzane są również przez podmiot odpowiedzialny za obsługę płatności tj. ${paymentProvider}, jednakże przekazywane dane są ograniczone do niezbędnego minimum.`,
    },
    {
      id: 6,
      text: "Administrator nie ponosi odpowiedzialności za podanie nieprawidłowych lub niepełnych danych osobowych.",
    },
    {
      id: 7,
      text: "Użytkownicy mają prawo do zmiany lub usunięcia swoich danych osobowych, w tym celu należy skontaktować się z Sprzedawcą poprzez adres biuro@sukcesja.biz.",
    },
    {
      id: 8,
      text: 'Serwis Sukcesja używa tzw. "cookies", Użytkownik może zablokować pliki cookies w każdym momencie na swojej przeglądarce.',
    },
  ],
};

const paragraph10: ITermsOfUseParagraph = {
  paragraph: "§10 Postanowienia końcowe",
  items: [
    {
      id: 1,
      text: "Sprzedawca zastrzega sobie prawo do wprowadzania z ważnych przyczyn zmian w niniejszym Regulaminie. Wszelkie zmiany Regulaminu wchodzą w życie w odpowiednim terminie wskazanym przez Sprzedawcę, nie krótszym jednak niż 7 dni.",
    },
    {
      id: 2,
      text: "Zmieniony Regulamin wiąże Użytkownika, jeżeli zostały zachowane wymagania określone w art. 384 oraz 384[1] Kodeksu cywilnego, to jest został on prawidłowo powiadomiony o zmianach i nie wypowiedział umowy w terminie 14 dni kalendarzowych od dnia powiadomienia.",
    },
    {
      id: 3,
      text: "Zgodnie z art. 8 ust. 3 pkt 2 lit. b ustawy z 18.7.2002 r. o świadczeniu usług drogą elektroniczną podmiot korzystający ze strony serwisu Sukcesja.biz zobowiązany jest do nieumieszczania na tej stronie treści o charakterze bezprawnym.",
    },
    {
      id: 4,
      text: "W sprawach nieuregulowanych niniejszym regulaminem zastosowanie mieć będą odpowiednie przepisy Kodeku cywilnego oraz ustawy z 30.5.2014 r. – o prawach konsumenta.",
    },
    {
      id: 5,
      text: "Niniejszy Regulamin jest dostępny pod adresem internetowym system.sukcesja.biz",
    },
    {
      id: 6,
      text: "Umowy ze sprzedawcą zawierane są w języku polskim.",
    },
    {
      id: 7,
      text: "Regulamin wchodzi w życie z dniem 23.12.2022.",
    },
  ],
};

export const paragraphs: ITermsOfUse = {
  items: [
    paragraph1,
    paragraph2,
    paragraph3,
    paragraph4,
    paragraph5,
    paragraph6,
    paragraph7,
    paragraph8,
    paragraph9,
    paragraph10,
  ],
};
