import { SortDirectionEnum } from "./Filter";

export default class FilterRequest {
  name!: string;
  page!: number;
  count!: number;
  orderBy!: string | null;
  orderDirection!: SortDirectionEnum;
  criterions!: Array<FilterCriterionRequest>;
}

export class FilterCriterionRequest {
  key!: string;
  type!: string;
  value!: unknown;
}
