<template>
  <div class="no-container">
    <background-image />
    <div class="mt-10">
      <logo-image />
    </div>
    <div class="no-container_items">
      <div class="no-container_text">
        <span class="text-h2 font-weight-bold">403</span>
        <span class="text-h4 font-weight-bold">Brak uprawnień, skontaktuj się z administratiorem</span>
      </div>
      <div class="no-container_button">
        <v-btn class="btn main-light--reverse" :to="{ name: route.WELCOME }">{{ $t("form.back") | capitalize }}</v-btn>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

//Components
import BackgroundImage from "@/components/shared/layout/BackgroundImage.vue";
import LogoImage from "@/components/shared/layout/LogoImage.vue";

import ROUTE_NAMES from "@/static/routeNames";

@Component({
  components: { BackgroundImage, LogoImage },
})
export default class NoPermissionsView extends Vue {
  route = ROUTE_NAMES.GENERAL;
}
</script>

<style lang="scss" scoped></style>
