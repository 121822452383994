<template>
  <span class="scaling-svg-container">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 42.376 36.197">
      <path
        d="M18.1,42.376a2.388,2.388,0,0,1-2.262-2.492V2.493A2.389,2.389,0,0,1,18.1,0a2.389,2.389,0,0,1,2.263,2.493V39.884A2.388,2.388,0,0,1,18.1,42.376Zm-15.836,0C1.015,42.376,0,40.913,0,39.116V3.26C0,1.462,1.015,0,2.263,0S4.524,1.462,4.524,3.26V39.116C4.524,40.913,3.51,42.376,2.263,42.376ZM-13.574,31.622a2.355,2.355,0,0,1-2.263-2.433V2.433A2.355,2.355,0,0,1-13.574,0a2.355,2.355,0,0,1,2.263,2.433V29.189A2.355,2.355,0,0,1-13.574,31.622Z"
        transform="translate(0 20.361) rotate(-90)"
        fill="currentColor"
      />
    </svg>
  </span>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

@Component
export default class MenuBarIcon extends Vue {}
</script>

<style></style>
