<template>
  <v-form ref="loginForm" class="form" lazy-validation @submit.prevent="clickLogin">
    <v-text-field
      v-model="model.login"
      :label="$t('form.userEmail') | capitalize"
      hide-details="auto"
      :rules="[required]"
      flat
      color="black"
      spellcheck="false"
      @keypress:enter="clickLogin"
    ></v-text-field>
    <v-text-field
      v-model="model.password"
      :label="$t('form.userPassword') | capitalize"
      type="password"
      hide-details="auto"
      color="black"
      :rules="[required]"
      @keypress:enter="clickLogin"
    ></v-text-field>
    <div class="form-checkbox">
      <v-checkbox
        v-model="rememberMe"
        class="text-caption"
        color="black"
        label="Zapamiętaj mnie"
        dense
        :ripple="false"
        hide-details="auto"
      />
      <router-link class="text-caption error--text" to="/remind-password" v-text="'Zapomniałeś hasła?'" />
    </div>
    <div class="form-buttons">
      <v-btn class="btn main-light--reverse" :disabled="buttonDisabled" :width="120" :height="35" type="submit">{{
        $t("form.login") | capitalize
      }}</v-btn>
      <v-btn class="btn main-light" :width="120" :height="35" @click="clickCancel">{{
        $t("form.cancel") | capitalize
      }}</v-btn>
    </div>
    <div class="form-link">
      <router-link class="text-caption gray--text" :to="{ name: route.REGISTER }">{{
        $t("form.noAccount") | capitalize
      }}</router-link>
    </div>
  </v-form>
</template>

<script lang="ts">
import { Vue, Component } from "vue-property-decorator";
import ROUTE_NAMES from "@/static/routeNames";

//Models
import UserLoginRequest from "@/models/api/user/UserLoginRequest";

//Validators
import requiredValidator from "@/rules/requiredValidator";

@Component({
  components: {},
})
export default class LoginForm extends Vue {
  route = ROUTE_NAMES.GENERAL;

  model: UserLoginRequest = {
    login: "",
    password: "",
  };

  buttonDisabled = false;

  //Validation
  required = requiredValidator;
  rememberMe = false;

  validateForm(): boolean {
    return (this.$refs.loginForm as HTMLFormElement).validate();
  }

  //Actions
  clickLogin = async (): Promise<void> => {
    if (this.validateForm()) {
      this.setButton(true);
      const error = await this.$store.direct.dispatch.user.login(this.model);
      if (error) {
        this.setButton(false);
      }
    }
  };

  setButton(state: boolean) {
    this.buttonDisabled = state;
  }

  clickCancel() {
    this.$router.push({ name: this.route.WELCOME });
  }
}
</script>

<style lang="scss" scoped>
::v-deep .form-checkbox {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 10px 0;
}

.form-buttons {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 30px 0;
}

.form-link {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
}
</style>
