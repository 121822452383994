<template>
  <v-container fluid class="view-container">
    <div class="view-container__wrap">
      <transaction-history-filter :options.sync="tableOptions" />
      <div class="table-container">
        <v-data-table
          class="table"
          :items="transactions.items"
          :headers="headers"
          :header-props="{ sortByText: 'Sortuj po', sortIcon: '$arrowUp' }"
          :options.sync="tableOptions"
          :server-items-length="transactions.count"
          hide-default-footer
          single-select
          item-key="id"
        >
          <template #[`item.createdAt`]="{ item }">
            {{ item.createdAt | date }}
          </template>
          <template #[`item.sentAt`]="{ item }">
            {{ item.sentAt | date }}
          </template>
          <template #[`item.transactionStatus`]="{ item }">
            {{ getStatusName(item) }}
          </template>
          <template #[`item.actions`]="{ item }">
            <v-tooltip bottom>
              <template #activator="{ on }">
                <v-btn icon :ripple="false" @click="openDialog(item)" v-on="on">
                  <v-icon color="black">$loupe</v-icon>
                </v-btn>
              </template>
              <span>{{ $t("tooltip.showDetails") | capitalize }}</span>
            </v-tooltip>
            <v-tooltip v-if="checkPermission(ROLE.Developer)" bottom>
              <template #activator="{ on }">
                <v-btn icon :ripple="false" @click="openRecordStampDialog(item)" v-on="on">
                  <v-icon color="black">$info</v-icon>
                </v-btn>
              </template>
              <span>{{ $t("tooltip.showRecordStamp") | capitalize }}</span>
            </v-tooltip>
          </template>
        </v-data-table>
        <div class="table__pager">
          <custom-pagination :options.sync="tableOptions" :server-items-length="transactions.count"></custom-pagination>
        </div>
      </div>
      <transaction-history-dialog v-if="transactionDialog" />
      <record-stamp-dialog :id="recordStampId" :show.sync="showRecordStampDialog" :width="900" />
    </div>
  </v-container>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { DataOptions, DataTableHeader } from "vuetify";

// Conponents
import TransactionHistoryFilter from "@/components/transactionHistory/TransactionHistoryFilter.vue";
import CustomPagination from "@/components/shared/dataTable/CustomPagination.vue";
import TransactionHistoryDialog from "@/components/transactionHistory/TransactionHistoryDialog.vue";
import RecordStampDialog from "@/components/shared/layout/RecordStampDialog.vue";

// Models
import TransactionHistory, { TransactionStatusEnum } from "@/models/api/transactionHistory/transaction";
import { ListResult } from "@/models/api/filters/ListResult";
import ROLE from "../../../static/role";

//Translations
import { filterCapitalize } from "@/filters/capitalize";
import i18n from "@/plugins/i18n";

@Component({
  components: {
    TransactionHistoryFilter,
    CustomPagination,
    TransactionHistoryDialog,
    RecordStampDialog,
  },
})
export default class TransactionHistoryView extends Vue {
  // Static
  ROLE = ROLE;

  get transactions(): ListResult<TransactionHistory> {
    return this.$store.direct.state.transaction.transactionHistory ?? new ListResult<TransactionHistory>();
  }

  get tableOptions(): DataOptions {
    return this.$store.direct.state.filter.transactionHistoryFilterOptions;
  }

  set tableOptions(opt: DataOptions) {
    this.$store.direct.commit.filter.setTransactionHistoryBasicFilterOptions(opt);
  }

  get transactionDialog(): boolean {
    return this.$store.direct.state.transaction.transactionHistoryDialog;
  }

  set transactionDialog(state: boolean) {
    this.$store.direct.commit.transaction.setTransactionHistoryDialogState(state);
  }

  headers: Array<DataTableHeader> = [
    {
      text: filterCapitalize(i18n.t("header.product")),
      value: "productName",
    },
    {
      text: filterCapitalize(i18n.t("header.license")),
      value: "licenseName",
    },
    {
      text: filterCapitalize(i18n.t("header.company")),
      value: "companyName",
    },
    {
      text: filterCapitalize(i18n.t("header.amount")),
      value: "amount",
    },
    {
      text: filterCapitalize(i18n.t("header.status")),
      value: "transactionStatus",
    },
    {
      text: filterCapitalize(i18n.t("header.createdAt")),
      value: "createdAt",
    },
    {
      text: filterCapitalize(i18n.t("header.sentAt")),
      value: "sentAt",
    },
    {
      text: filterCapitalize(i18n.t("header.provider")),
      value: "provider",
    },
    {
      text: "",
      value: "actions",
      width: "150px",
      align: "end",
      sortable: false,
    },
  ];

  getStatusName(item: TransactionHistory): string {
    return i18n.t(`transaction.status.${TransactionStatusEnum[item.transactionStatus].toLowerCase()}`).toString();
  }

  openDialog(item: TransactionHistory) {
    this.$store.direct.commit.transaction.setSelectedTransactionId(item.id);
    this.transactionDialog = true;
  }

  showRecordStampDialog = false;
  recordStampId: number | null = null;

  openRecordStampDialog(item: TransactionHistory) {
    this.recordStampId = item.recordStampId;
    this.showRecordStampDialog = true;
  }

  checkPermission(role: ROLE): boolean {
    return this.$store.direct.state.user.role >= role;
  }
}
</script>

<style scoped></style>
