<template>
  <span class="tt">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10.813 10.813">
      <path
        d="M10.813.618,10.2,0,5.407,4.789.618,0,0,.618,4.789,5.407,0,10.2l.618.618L5.407,6.025,10.2,10.813l.618-.618L6.025,5.407Z"
      />
    </svg>
  </span>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

@Component
export default class CloseIcon extends Vue {}
</script>

<style lang="scss" scoped>
.tt {
  width: 10px;
  height: auto;
  display: flex;
}
</style>
