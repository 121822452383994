<template>
  <div class="filter__criterions">
    <template v-for="(criterion, index) in _criterions">
      <div :key="index" class="filter__criterion">
        <div v-if="criterion.type === criterionTypes.IntCriterion">
          <int-criterion :criterion="criterion" />
        </div>
        <div v-else-if="criterion.type === criterionTypes.StringCriterion">
          <string-criterion :criterion="criterion" @keydown.enter.native="$emit('onEnter')" />
        </div>
        <div v-else-if="criterion.type === criterionTypes.DateCriterion">
          <date-criterion :criterion="criterion" />
        </div>
        <div v-else-if="criterion.type === criterionTypes.PeriodCriterion">
          <period-criterion :criterion="criterion" />
        </div>
        <div v-else-if="criterion.type === criterionTypes.EnumStringCriterion">
          <enum-string-criterion :criterion="criterion" />
        </div>
        <div v-else-if="criterion.type === criterionTypes.AutocompleteTreeTextCriterion">
          <autocomplete-company-criterion :criterion="criterion" />
        </div>
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import { Component, Vue, PropSync, Watch } from "vue-property-decorator";

//Criterions
import IntCriterion from "@/components/shared/filter/criterions/IntCriterion.vue";
import StringCriterion from "@/components/shared/filter/criterions/StringCriterion.vue";
import DateCriterion from "@/components/shared/filter/criterions/DateCriterion.vue";
import PeriodCriterion from "@/components/shared/filter/criterions/PeriodCriterion.vue";
import EnumStringCriterion from "@/components/shared/filter/criterions/EnumStringCriterion.vue";
import AutocompleteCompanyCriterion from "@/components/shared/filter/criterions/AutocompleteCompanyCriterion.vue";

//Models
import { CriterionBase } from "@/models/api/filters/CriterionBase";
import { FilterCriterionRequest } from "@/models/api/filters/FilterRequest";

//Static
import { FilterCriterionTypes } from "@/static/filterCriterionTypes";

@Component({
  components: {
    IntCriterion,
    StringCriterion,
    DateCriterion,
    PeriodCriterion,
    EnumStringCriterion,
    AutocompleteCompanyCriterion,
  },
})
export default class FilterCriterions extends Vue {
  criterionTypes = FilterCriterionTypes;

  @PropSync("criterions", { required: true })
  _criterions!: Array<CriterionBase>;

  @PropSync("values", { default: [] })
  _values!: Array<FilterCriterionRequest>;

  @Watch("criterions", { deep: true })
  addFilterValue(criterions: Array<CriterionBase>): void {
    const filter = new Array<FilterCriterionRequest>();
    for (const criterion of criterions) {
      if (criterion.value != "") {
        filter.push({
          key: criterion.key,
          type: criterion.type,
          value: criterion.value,
        });
      }
    }
    this._values = filter;
  }

  mounted(): void {
    this.addFilterValue(this._criterions);
  }
}
</script>

<style lang="scss" scoped>
.filter__criterions {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
}

.filter__criterion {
  // padding: 0 10px;
}
</style>
