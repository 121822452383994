export default class UserDetails {
  id!: string;
  email!: string;
  name!: string | null;
  surname!: string | null;
  phoneNumber!: string | null;

  constructor(userDetails?: UserDetails | null) {
    this.id = userDetails != undefined ? userDetails.id : "";
    this.email = userDetails != undefined ? userDetails.email : "";
    this.name = userDetails != undefined ? userDetails.name : "";
    this.surname = userDetails != undefined ? userDetails.surname : "";
    this.phoneNumber = userDetails != undefined ? userDetails.phoneNumber : "";
  }
}
