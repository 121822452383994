<template>
  <div :style="`width: ${width}px`">
    <input-date v-model="_criterion.value" :close-on-day="true" :label="_criterion.label" :solo="true" />
  </div>
</template>

<script lang="ts">
import { Component, Vue, PropSync, Prop } from "vue-property-decorator";

//Components
import InputDate from "@/components/shared/inputs/InputDate.vue";

//Models
import { CriterionBase } from "@/models/api/filters/CriterionBase";

export interface DateCriterionSettings extends CriterionBase {
  value: string;
}

@Component({
  components: {
    InputDate,
  },
})
export default class DateCriterion extends Vue {
  @PropSync("criterion")
  _criterion!: DateCriterionSettings;
  @Prop({ default: 150 })
  width!: number;
}
</script>

<style scoped></style>
