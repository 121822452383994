<template>
  <div>
    <v-card v-if="lastReports.length == 0" elevation="2" tile>
      <v-card-title>Brak wygenerowanych raportów</v-card-title>
    </v-card>
    <div v-else>
      <span class="text-h6 font-weight-medium">Ostatnio wygenerowane raporty</span>
      <div v-for="(item, i) in lastReports" :key="i" class="mt-5">
        <div class="last-report__item">
          <span class="last-report__company text-h6 font-weight-medium">{{ item.reportName }}</span>
          <span class="last-report__date text-body-2">{{ item.date }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import LastReport from "../../models/api/statistic/lastReport";

@Component
export default class StatisticsLastReports extends Vue {
  get lastReports(): LastReport[] {
    return this.$store.direct.state.statistic.lastCreatedReports;
  }
}
</script>

<style lang="scss" scoped>
.last-report__item {
  min-height: 64px;
  border: 1px solid black;
  border-radius: 10px;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media screen and (max-width: 440px) {
    flex-direction: column;
    align-items: baseline;
  }
}
</style>
