<template>
  <input-text
    v-model="postal"
    v-maxchars="6"
    :label="label"
    :allow-text="false"
    :allow-number="true"
    :allow-symbol="false"
    :show-counter="false"
    :clerable="true"
    :rules="allRules"
    :color="color"
    :show-error="false"
    :cols="cols"
  >
  </input-text>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";

import InputText from "@/components/shared/inputs/InputText.vue";

@Component({ components: { InputText } })
export default class InputPostalCode extends Vue {
  @Prop({ required: true })
  value!: string;
  @Prop()
  label!: string;
  @Prop({ default: 6 })
  maxLenght!: number;
  @Prop()
  color!: string;
  @Prop()
  cols!: number;
  @Prop({ default: [] })
  // eslint-disable-next-line
  rules!: any[];

  get postal(): string {
    return this.value;
  }

  set postal(value: string) {
    this.$emit("input", this.postalCode(value));
  }

  validatePostalCode(input: string): boolean | string {
    let regex = /(?=^.{6}$)([0-9]{2})(-{1})([0-9]{3})/;
    if (this.value != null && this.value.length != 0) return regex.test(input) || "Niepoprawny format kodu";
    return true;
  }

  postalCode(value: string): string {
    if (!value) return "";
    const array = value.split("-");

    if (array.length < 2) {
      if (array[0].length < 2) return array[0].substring(0, 2);
      else return array[0].substring(0, 2) + "-";
    } else return (array[0].substring(0, 2) + "-" + array[1].substring(0, 3)).toString();
  }

  // eslint-disable-next-line
  allRules: any[] = [];

  addRules() {
    if (this.rules.length > 0) {
      for (let rule of this.rules) {
        this.allRules.push(rule);
      }
    }
    this.allRules.push(this.validatePostalCode);
  }

  async mounted() {
    await this.addRules();
  }
}
</script>

<style scoped></style>
