import { render, staticRenderFns } from "./FormInput.vue?vue&type=template&id=265b6758&scoped=true"
import script from "./FormInput.vue?vue&type=script&lang=ts"
export * from "./FormInput.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "265b6758",
  null
  
)

export default component.exports