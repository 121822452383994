import Vue from "vue";
import VueI18n from "vue-i18n";

const filterCapitalize = (value: string | VueI18n.TranslateResult): string => {
  if (!value) return "";
  value = value.toString();
  return value.charAt(0).toUpperCase() + value.slice(1);
};

Vue.filter("capitalize", filterCapitalize);

export { filterCapitalize };
