import { defineModule } from "direct-vuex";
import { moduleActionContext, moduleGetterContext } from "@/store/index";

//Requests
import { getCharData } from "@/requests/statistic";

import LicenseChartData from "@/models/api/statistic/licenseChartData";
import CreatedReportChartData from "@/models/api/statistic/createdRedportChartData";
import LastReport from "@/models/api/statistic/lastReport";

export interface StatisticState {
  licenseChartData: LicenseChartData;
  createdReportsChartData: CreatedReportChartData;
  lastCreatedReports: Array<LastReport>;
  isLoading: boolean;
}

export const StatisticState: StatisticState = {
  licenseChartData: new LicenseChartData(),
  createdReportsChartData: new CreatedReportChartData(),
  lastCreatedReports: [],
  isLoading: false,
};

const statisticModule = defineModule({
  state: StatisticState,
  namespaced: true as const,
  getters: {
    isLoading(...args): boolean {
      const { state } = _moduleGetterContext(args);
      return state.isLoading;
    },
  },
  mutations: {
    setLicenseChartData(state, data: LicenseChartData) {
      state.licenseChartData = data;
    },
    setCreatedReportsChartData(state, data: CreatedReportChartData) {
      state.createdReportsChartData = data;
    },
    setLastReports(state, data: LastReport[]) {
      state.lastCreatedReports = data;
    },
    setLoading(state, isLoading) {
      state.isLoading = isLoading;
    },
  },
  actions: {
    async getChartsData(context) {
      const { commit } = _moduleActionContext(context);
      commit.setLoading(true);
      const response = await getCharData();
      const generated = new CreatedReportChartData();
      generated.series = response.model.series;
      generated.xaxis = response.model.xaxis;
      commit.setCreatedReportsChartData(generated);
      commit.setLicenseChartData(response.model.licenseChartData);
      commit.setLastReports(response.model.lastReport);
      commit.setLoading(false);
    },
  },
});

export default statisticModule;

/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable  @typescript-eslint/no-unused-vars */
const _moduleGetterContext = (args: [any, any, any, any]) => moduleGetterContext(args, statisticModule);
const _moduleActionContext = (context: any) => moduleActionContext(context, statisticModule);
