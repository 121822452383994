<template>
  <v-container fluid class="view-container">
    <router-view />
  </v-container>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {},
})
export default class StatisticView extends Vue {}
</script>

<style lang="scss" scoped></style>
