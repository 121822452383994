<template>
  <span class="scaling-svg-container">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15.53"
      height="14.676"
      viewBox="0 0 15.53 14.676"
      class="scaling-svg"
    >
      <g id="Group_6334" data-name="Group 6334" transform="translate(0 -8.785)">
        <path
          id="Path_10"
          data-name="Path 10"
          d="M30.175,170.338l-.5-1.928a1.163,1.163,0,0,0-1.655-.753l-1.956.973a.973.973,0,0,0,.477,1.844,5.262,5.262,0,0,1-9.2-1.662.974.974,0,1,0-1.862.568,7.26,7.26,0,0,0,2.565,3.655,7.2,7.2,0,0,0,8.65,0,7.258,7.258,0,0,0,1.75-1.9.973.973,0,0,0,1.74-.8Z"
          transform="translate(-14.677 -151.019)"
        />
        <path
          id="Path_11"
          data-name="Path 11"
          d="M1.181,15.628a1.155,1.155,0,0,0,.479.1,1.189,1.189,0,0,0,.53-.126l1.956-.973a.973.973,0,0,0-.477-1.844,5.262,5.262,0,0,1,9.2,1.662.974.974,0,1,0,1.862-.568,7.26,7.26,0,0,0-2.565-3.655,7.2,7.2,0,0,0-8.65,0,7.258,7.258,0,0,0-1.751,1.9.973.973,0,0,0-1.74.8l.5,1.928A1.169,1.169,0,0,0,1.181,15.628Z"
        />
      </g>
    </svg>
  </span>
</template>

<script lang="ts">
import { Component } from "vue-property-decorator";
import IconHelper from "@/components/shared/icons/IconHelper.vue";

@Component
export default class RefreshIcon extends IconHelper {}
</script>

<style></style>
