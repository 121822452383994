<template>
  <v-container fluid class="view-container">
    <router-view />
  </v-container>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

//Components
import BackgroundImage from "@/components/shared/layout/BackgroundImage.vue";

@Component({
  components: { BackgroundImage },
})
export default class AdministrationView extends Vue {
  async created() {
    await this.$store.direct.dispatch.user.getUserDetails();
    await this.$store.direct.dispatch.user.getUserCompanyDetails();
    await this.$store.direct.dispatch.user.getUserCompanyLogoImage();
  }
}
</script>

<style lang="scss" scoped>
::v-deep .v-data-table {
  tbody {
    tr:hover {
      background-color: transparent !important;
    }
  }
}
</style>
