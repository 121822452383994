<template>
  <span class="scaling-svg-container">
    <svg xmlns="http://www.w3.org/2000/svg" width="19.5" height="18.75" viewBox="0 0 19.5 18.75">
      <g transform="translate(-2.25 -2.25)">
        <g>
          <path
            d="M0,0,1.875,10h11.25L15,0Z"
            transform="translate(6 7)"
            fill="none"
            stroke="#000"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.5"
          />
          <path
            d="M3,4,2,0H0"
            transform="translate(3 3)"
            fill="none"
            stroke="#000"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.5"
          />
          <circle cx="1" cy="1" r="1" transform="translate(7 19)" fill="#000" />
          <circle cx="1" cy="1" r="1" transform="translate(18 19)" fill="#000" />
        </g>
      </g>
    </svg>
  </span>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

@Component
export default class LicensesIcon extends Vue {}
</script>

<style></style>
