<template>
  <custom-dialog :title="$t('dialog.addUser') | capitalize" width="500px" @cancel="cancel" @save="save">
    <user-add-form ref="userForm" :user.sync="userModel" :valid.sync="valid" />
  </custom-dialog>
</template>

<script lang="ts">
import { Component, Vue, PropSync } from "vue-property-decorator";

//Components
import CustomDialog from "@/components/shared/layout/CustomDialog.vue";
import UserAddForm from "@/components/administration/forms/UserAddForm.vue";

//Models
import UserRequest from "@/models/api/user/UserRequest";

@Component({
  components: {
    CustomDialog,
    UserAddForm,
  },
})
export default class AdministrationAddUserDialog extends Vue {
  @PropSync("value", { required: true })
  show?: boolean;

  userModel: UserRequest = new UserRequest();
  valid = true;

  async save() {
    (this.$refs.userForm as HTMLFormElement).validate();
    if (!this.valid) return;
    const response = await this.$store.direct.dispatch.administration.addNewUser(this.userModel);
    this.show = !response;
  }

  cancel() {
    this.show = false;
  }
}
</script>

<style lang="scss" scoped></style>
