<template>
  <span class="scaling-svg-container">
    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="7" viewBox="0 0 6 7" class="scaling-svg">
      <path d="M3.5,0,7,6H0Z" transform="translate(6) rotate(90)" fill="currentColor" />
    </svg>
  </span>
</template>

<script lang="ts">
import { Component } from "vue-property-decorator";
import IconHelper from "@/components/shared/icons/IconHelper.vue";

@Component
export default class PlayIcon extends IconHelper {}
</script>

<style></style>
