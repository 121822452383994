<template>
  <div>
    <div class="autocomplete-label mb-1 mt-3 text-caption">
      <span>{{ label | capitalize }}</span>
    </div>
    <v-autocomplete
      v-model="selected"
      :items="companies"
      :item-text="getName"
      item-value="id"
      dense
      solo
      hide-details="auto"
      height="15"
      append-icon="$arrowDown"
      no-data-text="Brak firm"
      return-object
      :rules="[required]"
    >
      <template #item="{ item }">
        <v-list-item-icon :style="`padding-left: ${15 * item.depth}px`">
          <v-icon>mdi-minus</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ item.name }}</v-list-item-title>
        </v-list-item-content>
      </template>
    </v-autocomplete>
  </div>
</template>

<script lang="ts">
import { Component, Vue, PropSync, Prop } from "vue-property-decorator";

//Models
import CompanyBasic from "@/models/api/company/CompanyBasic";

//Requests
import { downloadCompanyChildrenBasic } from "@/requests/general";

//Validators
import requiredValidator from "@/rules/requiredValidator";

@Component
export default class AutocompleteCompanies extends Vue {
  @PropSync("value", { required: true })
  selected!: CompanyBasic;
  @Prop({ required: true })
  companyId!: string;
  @Prop({ default: false })
  showParent!: boolean;
  @Prop({ default: "Firma" })
  label!: string;

  companies: Array<CompanyBasic> = [];

  //Validators
  required = requiredValidator;

  async companyChildren(): Promise<void> {
    const response = await downloadCompanyChildrenBasic(this.companyId);
    this.companies = response.model;
    if (!this.showParent) {
      this.companies.shift();
      this.companies.forEach((v) => (v.depth = v.depth - 1));
    }
  }

  getName(x: CompanyBasic): string {
    return x.name;
  }

  mounted() {
    this.companyChildren();
  }
}
</script>

<style lang="scss" scoped>
.autocomplete-label {
  margin: 0 0 -5px 15px;
  font-size: 9px;
  color: black;
}

::v-deep .v-autocomplete {
  font-size: 12px;
  border-radius: 10px !important;
}

::v-deep .v-list-item {
  min-height: 10px !important;
}

::v-deep .v-list-item__content {
  padding: unset;
  .v-list-item__title {
    font-size: 12px !important;
  }
}

::v-deep .v-list-item__icon:first-child {
  margin-right: 10px;
}

.v-text-field--solo {
  border-radius: 10px !important;
  font-size: 12px;
}

::v-deep .v-input__slot {
  background-color: #f4f4f4 !important;
}

::v-deep .v-input__control {
  min-height: 35px !important;
  .v-text-field__slot input {
    color: black;
  }
}

::v-deep .scaling-svg {
  height: 50% !important;
  width: 50% !important;
  transform: translate(50%, 50%) !important;
}
</style>
