const ROUTE_NAMES = {
  GENERAL: {
    LOGIN: "LoginView",
    REGISTER: "RegisterView",
    WELCOME: "WelcomeView",
    TERMS: "TermsOfUseView",
    PRIVACY: "PrivacyPolicyView",
    REMIND: "RemindPasswordView",
    TRANSACTION: "TransactionView",
    FAQ: "FrequentlyAskedQuestionsView",
  },
  STATISTICS: {
    MAIN: "StatisticView",
    STATISTIC: "StatisticsStatisticView",
  },
  LICENSES: {
    MAIN: "LicensesView",
    LICENSE: "LicensesLicenseView",
    HISTORY: "LicensesHistoryView",
    BUY: "LicensesBuyView",
  },
  ADMINISTRATION: {
    MAIN: "AdministrationView",
    ACCOUNT: "AdministrationAccountView",
    HIERARCHY: "AdministrationHierarchyView",
    USERS: "AdministrationUsersView",
  },
  REPORTS: {
    MAIN: "ReportsView",
    GENERATED: "ReportsGeneratedView",
  },
  SETTINGS: {
    MAIN: "SettingsView",
  },
  HELP: {
    MAIN: "HelpView",
  },
  USER: {
    PROFILE: "ProfileView",
  },
  ERROR: {
    //Client error responses
    UNAUTHORIZED: "UnauthorizedView", //401
    NOTFOUND: "NotFoundView", //404
  },
  DEVELOPER: {
    ICONS: "IconsVIew",
    TEST: "TestView",
    MAIN: "DeveloperView",
  },
  TRANSACTION: {
    BLUEMEDIA: "TransactionBlueMediaStatusView",
    PAYU: "TransactionPayUStatusView",
  },
  TRANSACTION_HISTORY: {
    MAIN: "TransactionHistoryView",
  },
};

export { ROUTE_NAMES };
export default ROUTE_NAMES;
