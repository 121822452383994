import { render, staticRenderFns } from "./RegisterView.vue?vue&type=template&id=5033c2ce&scoped=true"
import script from "./RegisterView.vue?vue&type=script&lang=ts"
export * from "./RegisterView.vue?vue&type=script&lang=ts"
import style0 from "./RegisterView.vue?vue&type=style&index=0&id=5033c2ce&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5033c2ce",
  null
  
)

export default component.exports