<template>
  <custom-dialog :value.sync="_show" :title="title" :buttons="buttons" @cancel="cancel">
    <prototype-base ref="prototypeForm" :value.sync="currentQuestion" />
  </custom-dialog>
</template>

<script lang="ts">
import { Component, Vue, PropSync, Watch } from "vue-property-decorator";

//Models
import Prototype from "@/models/api/reports/Prototype";

//Components
import PrototypeBase from "@/components/reports/workFlow/components/prototypes/PrototypeBase.vue";
import CustomDialog, { DialogButtonInterface } from "@/components/shared/layout/CustomDialog.vue";
import { PrototypeTypeEnum } from "../../models/api/reports/workFlow/BasePrototype";

@Component({
  components: {
    CustomDialog,
    PrototypeBase,
  },
})
export default class FormDialog extends Vue {
  @PropSync("show")
  _show!: boolean;
  @PropSync("step")
  _step!: number;

  title = "";
  buttons: Array<DialogButtonInterface> = [
    {
      text: "Poprzednie",
      color: "",
      class: "btn main-light",
      align: "",
      disabled: false,
      action: async () => {
        this.prev();
      },
    },
    {
      text: "Następne",
      color: "",
      class: "btn main-light--reverse",
      align: "",
      disabled: false,
      action: () => {
        this.next();
      },
    },
  ];

  lockButton(index: number) {
    this.buttons[index].disabled = true;
  }

  unlockButton(index: number) {
    this.buttons[index].disabled = false;
  }

  async prev() {
    if (this.currentQuestion?.previousQuestionId == null) {
      this.buttons[0].disabled = true;
    }
    if (this.currentQuestion?.previousQuestionId != null) {
      (this.$refs.prototypeForm as HTMLFormElement).resetValidation() as boolean; //Only when user want to check previous question and current question have error
      await this.$store.direct.dispatch.report.undoQuestion();
    }
  }

  async next() {
    if (!(this.$refs.prototypeForm as HTMLFormElement).validate() as boolean) return;
    this.lockButton(1);
    if (this.currentQuestion != null) {
      if (this.currentQuestion.type === PrototypeTypeEnum.CommonPrototype) {
        this.currentQuestion.questionItems[0].isChecked = true; //Change this on backend to set this automatically
      }
      await this.$store.direct.dispatch.report.checkFormQuestionAnswer(this.currentQuestion);
      this.buttons[0].disabled = false;
      this.unlockButton(1);
    }
    if (this.currentQuestion == null) {
      this._step = 3;
    }
  }

  async cancel() {
    this._show = false;
    await this.$store.direct.dispatch.report.getFormSummary();
    this.currentQuestion = null;
    if (this.$store.direct.state.report.formSummary?.formItems.length) {
      this.$store.direct.commit.report.setStateSummaryFormDialog(true);
    }
  }

  get currentQuestion(): Prototype | null {
    const currentQuestion = this.$store.direct.state.report.formActiveQuestion;
    if (currentQuestion?.previousQuestionId == null) this.buttons[0].disabled = true;
    return this.$store.direct.state.report.formActiveQuestion;
  }

  set currentQuestion(val: Prototype | null) {
    this.$store.direct.commit.report.setFormActiveQuestion(val);
  }

  @Watch("show")
  init(val: boolean) {
    if (!val) return;
    this.$store.direct.dispatch.report.getFormActiveQuestion();
  }
}
</script>

<style scoped></style>
