import RequestHelper from "./RequestHelper";
import { Response } from "@/requests/RequestHelper";

//Models
import Setting from "@/models/api/setting/Setting";

//Urls
export const URL_GET_SETTINGS = "/Settings";
export const URL_POST_SETTINGS = "/Settings/Edit";

const _settings = new RequestHelper<Array<Setting>>(URL_GET_SETTINGS);
export const settings = async (): Promise<Response<Array<Setting>>> => {
  return _settings.get();
};

const _setSetting = new RequestHelper<null>(URL_POST_SETTINGS, true);
export const setSetting = async (model: Setting): Promise<Response<null>> => {
  return _setSetting.post(null, model);
};
