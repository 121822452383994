<template>
  <div class="window-divider" :style="`justify-content: ${justify}`">
    <div class="window-divider-item pr-0 pr-md-10">
      <slot name="left"></slot>
    </div>
    <div class="window-divider-item">
      <slot name="right"></slot>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class WindowDivider extends Vue {
  @Prop({ default: "center" })
  justify!: string;
}
</script>

<style lang="scss" scoped>
.window-divider {
  display: flex;
  flex-wrap: wrap;
  padding: 20px 0;
}

.window-divider-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  // padding: 0 2%;
  position: relative;
  img {
    width: 100%;
    object-fit: contain;
  }
  max-width: 600px;
  width: 100%;
}
</style>
