<template>
  <span class="scaling-svg-container" style="margin-left: 4px; margin-bottom: 2px">
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" class="scaling-svg">
      <g transform="translate(-587 -577.5)">
        <circle cx="7" cy="7" r="7" transform="translate(587 577.5)" />
        <path
          d="M8.1.89,3.059,5.935a.114.114,0,0,1-.161,0L.033,3.07a.114.114,0,0,1,0-.161l.7-.7a.114.114,0,0,1,.161,0L2.978,4.3,7.247.033a.114.114,0,0,1,.161,0l.7.7A.114.114,0,0,1,8.1.89Z"
          transform="translate(589.932 581.516)"
          fill="#fff"
        />
      </g>
    </svg>
  </span>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

@Component
export default class CheckBoxOnIconBlack extends Vue {}
</script>

<style></style>
