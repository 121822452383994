<template>
  <div class="view-container__wrap">
    <licenses-history-filter :options.sync="tableOptions" />
    <div class="table-container">
      <v-data-table
        class="table data-table-items-italic"
        :items="licenses.items"
        :headers="headers"
        :header-props="{ sortByText: 'Sortuj po', sortIcon: '$arrowUp' }"
        :options.sync="tableOptions"
        :server-items-length="licenses.count"
        single-select
        hide-default-footer
      >
        <template #[`item.paymentDate`]="{ item }">
          {{ item.paymentDate | date }}
        </template>
        <template #[`item.expirationDate`]="{ item }">
          {{ item.expirationDate | date }}
        </template>
      </v-data-table>
      <div class="table__pager">
        <custom-pagination :options.sync="tableOptions" :server-items-length="licenses.count"> </custom-pagination>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

//Components
import LicensesHistoryFilter from "@/components/license/licensesHistory/LicensesHistoryFilter.vue";
import CustomPagination from "@/components/shared/dataTable/CustomPagination.vue";

//Models
import License from "@/models/api/licenses/license";
import { DataTableHeader, DataOptions } from "vuetify";
import { filterCapitalize } from "@/filters/capitalize";
import i18n from "@/plugins/i18n";
import { ListResult } from "@/models/api/filters/ListResult";

@Component({
  components: {
    LicensesHistoryFilter,
    CustomPagination,
  },
})
export default class LicensesHistoryView extends Vue {
  get licenses(): ListResult<License> {
    return this.$store.direct.state.license.licensesHistory ?? new ListResult<License>();
  }

  get tableOptions(): DataOptions {
    return this.$store.direct.state.filter.licenseHistoryFilterOptions;
  }

  set tableOptions(opt: DataOptions) {
    this.$store.direct.commit.filter.setLicenseHistoryFilterDataOptions(opt);
  }

  headers: Array<DataTableHeader> = [
    {
      text: filterCapitalize(i18n.t("header.companyName")),
      value: "companyName",
    },
    {
      text: filterCapitalize(i18n.t("header.license")),
      value: "name",
    },
    {
      text: filterCapitalize(i18n.t("header.paymentDate")),
      value: "paymentDate",
      width: "160px",
    },
    {
      text: filterCapitalize(i18n.t("header.expirationDate")),
      value: "expirationDate",
      width: "160px",
    },
    {
      text: filterCapitalize(i18n.t("header.initialValue")),
      value: "initValue",
      align: "center",
    },
    {
      text: filterCapitalize(i18n.t("header.used")),
      value: "used",
      align: "center",
    },
    {
      text: filterCapitalize(i18n.t("header.transferredToCompanies")),
      value: "assigneToCompanies",
      align: "center",
    },
    {
      text: filterCapitalize(i18n.t("header.transferredToUsers")),
      value: "assigneToUsers",
      align: "center",
    },
    {
      text: filterCapitalize(i18n.t("header.unused")),
      value: "toUse",
      align: "center",
    },
  ];

  // created() {
  //   this.$store.direct.dispatch.license.getLicensesHistory();
  // }
}
</script>

<style lang="scss" scoped></style>
