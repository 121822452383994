<template>
  <span class="scaling-svg-container">
    <svg xmlns="http://www.w3.org/2000/svg" width="8.376" height="8.376" viewBox="0 0 8.376 8.376" class="scaling-svg">
      <path
        d="M7.852,3.664H4.711V.523a.523.523,0,0,0-1.047,0V3.664H.523a.523.523,0,0,0,0,1.047H3.664V7.852a.523.523,0,0,0,1.047,0V4.711H7.852a.523.523,0,0,0,0-1.047"
        transform="translate(0 0)"
      />
    </svg>
  </span>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

@Component
export default class TrashIcon extends Vue {}
</script>

<style></style>
