<template>
  <div class="header-buttons">
    <template v-for="(button, index) of buttons">
      <v-btn
        :key="index"
        :disabled="button.disabled"
        :class="button.class"
        :style="`margin-left: ${buttons.length > 1 ? ' ' : button.align === 'left' ? '' : 'auto'}`"
        @click="buttonClick(button)"
        ><v-icon :left="button.icon ? true : false" x-small>
          {{ button.icon }}
        </v-icon>
        {{ button.text | capitalize }}
      </v-btn>
    </template>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop } from "vue-property-decorator";

export interface HeaderButtonsInterface {
  text: string;
  color: string;
  icon?: string;
  action: () => void | undefined | Promise<void>;
  class: string;
  align?: string; //$ when we have one button we can specify where it should be aligned -> left or right
  width?: number;
  disabled: boolean;
}

@Component
export default class HeaderButtons extends Vue {
  @Prop({ required: true })
  buttons!: Array<HeaderButtonsInterface>;

  buttonClick(button: HeaderButtonsInterface) {
    if (typeof button.action === "function") {
      button.action();
    }
  }
}
</script>

<style lang="scss" scoped>
.header-buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  justify-content: flex-start;
  align-items: center;
}
</style>
