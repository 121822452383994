import RequestHelper from "@/requests/RequestHelper";
import { Response } from "@/requests/RequestHelper";

//Models
import Form from "@/models/api/reports/form";
import Prototype from "@/models/api/reports/Prototype";
import FilterRequest from "@/models/api/filters/FilterRequest";
import { ListResult } from "@/models/api/filters/ListResult";
import FileResultResponse from "@/models/api/reports/fileResultResponse";
import AddForm from "@/models/api/reports/addForm";
import FormSummary from "@/models/api/reports/formSummary";
import FormBasic from "@/models/api/reports/formBasic";

//Urls
export const URL_POST_ADD_FORM = "/Forms/Add";
export const URL_GET_FORMS_PAGED = "/Forms/Paged";
export const URL_GET_FORM_ACTIVE_QUESTION = "/Forms/${FormId}/Questions/Active";
export const URL_POST_CHECK_FORM_QUESTION_ANSWER = "/Forms/Questions/CheckAnswer";
export const URL_POST_GENERATE_REPORT = "/Forms/${FormId}/GenerateReport";
export const URL_POST_ASSIGN_LICENCE_TO_REPORT = "/Forms/${FormId}/Assign/Licenses/${LicenceId}";
export const URL_GET_FORM_SUMMARY = "/Forms/${FormId}/Summary";
export const URL_GET_FORMS_COMPLETED_PREVIEW = "/Forms/Company/${CompanyId}/Completed";
export const URL_POST_UNDO_QUESTION = "/Forms/${FormId}/Questions/Undo";

//Request
const _addForm = new RequestHelper<number>(URL_POST_ADD_FORM);
export const addForm = async (model: AddForm): Promise<Response<number>> => {
  return await _addForm.post(null, model);
};

const _getForms = new RequestHelper<ListResult<Form>>(URL_GET_FORMS_PAGED);
export const getForms = async (filter: FilterRequest): Promise<Response<ListResult<Form>>> => {
  return await _getForms.get({ Filter: filter });
};

const _getFormActiveQuestion = new RequestHelper<Prototype>(URL_GET_FORM_ACTIVE_QUESTION);
export const getFormActiveQuestion = async (formId: number): Promise<Response<Prototype>> => {
  return await _getFormActiveQuestion.get({ FormId: formId });
};

const _checkFormQuestionAnswer = new RequestHelper<Prototype>(URL_POST_CHECK_FORM_QUESTION_ANSWER);
export const checkFormQuestionAnswer = async (answer: Prototype): Promise<Response<Prototype>> => {
  return await _checkFormQuestionAnswer.post(null, answer);
};

const _undoQuestion = new RequestHelper<Prototype>(URL_POST_UNDO_QUESTION);
export const undoQuestion = async (formId: number): Promise<Response<Prototype>> => {
  return await _undoQuestion.post({ FormId: formId });
};

const _generateReport = new RequestHelper<FileResultResponse>(URL_POST_GENERATE_REPORT);
export const generateReport = async (formId: number): Promise<Response<FileResultResponse>> => {
  return await _generateReport.post({ FormId: formId });
};

const _formAssignLicense = new RequestHelper<null>(URL_POST_ASSIGN_LICENCE_TO_REPORT);
export const formAssignLicense = async (
  formId: number,
  licenceId: number,
  assignToUser: boolean
): Promise<Response<null>> => {
  return await _formAssignLicense.post({
    FormId: formId,
    LicenceId: licenceId,
    AssignToUser: assignToUser,
  });
};

const _getFormSummary = new RequestHelper<FormSummary>(URL_GET_FORM_SUMMARY);
export const getFormSummary = async (formId: number): Promise<Response<FormSummary>> => {
  return await _getFormSummary.get({ FormId: formId });
};

const _getFormsCompleted = new RequestHelper<ListResult<FormBasic>>(URL_GET_FORMS_COMPLETED_PREVIEW);
export const getFormsCompleted = async (
  filter: FilterRequest,
  companyId: string
): Promise<Response<ListResult<FormBasic>>> => {
  return await _getFormsCompleted.get({ Filter: filter, CompanyId: companyId });
};
