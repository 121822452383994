<template>
  <custom-dialog :title="$t('dialog.addFreeLicenses') | capitalize" width="500px" @cancel="cancel" @save="save">
    <v-form ref="samplesForm" class="mt-5 mb-5">
      <v-row>
        <v-col cols="12">
          <input-text
            v-model="params.number"
            label="Ilość"
            :rules="[required, number]"
            :allow-symbol="false"
            :allow-text="false"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <input-text
            v-model="params.days"
            label="Ważność - liczba dni"
            :rules="[required, number]"
            :allow-symbol="false"
            :allow-text="false"
          />
        </v-col>
      </v-row>
    </v-form>
  </custom-dialog>
</template>

<script lang="ts">
import { Component, Vue, PropSync, Prop } from "vue-property-decorator";

//Components
import InputText from "@/components/shared/inputs/InputText.vue";
import CustomDialog from "@/components/shared/layout/CustomDialog.vue";

//Validation
import requiredValidator from "@/rules/requiredValidator";
import numberValidator from "@/rules/numberValidator";

@Component({
  components: {
    InputText,
    CustomDialog,
  },
})
export default class AdministrationAddFreeSamplesDialog extends Vue {
  @PropSync("value", { required: true })
  show?: boolean;
  @Prop({ required: true })
  companyId!: string;

  params = {
    number: 0,
    days: 0,
  };

  async save() {
    const isValid = (this.$refs.samplesForm as HTMLFormElement).validate();
    if (!isValid) return;
    const response = await this.$store.direct.dispatch.transaction.addFreeSamples({
      ...this.params,
      companyId: this.companyId,
    });
    this.show = response;
  }

  cancel() {
    this.show = false;
  }

  required = requiredValidator;
  number = numberValidator;
}
</script>

<style lang="scss" scoped></style>
