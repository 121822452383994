<template>
  <div>{{ getTimer() }}</div>
</template>

<script lang="ts">
import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class Timer extends Vue {
  @Prop()
  time!: number;

  timer = 0;

  countDownTimer() {
    if (this.timer > 0) {
      setTimeout(() => {
        this.timer -= 1;
        this.countDownTimer();
      }, 1000);
    } else this.$emit("unlock");
  }

  getTimer(): string {
    if (this.timer > 0) return "(" + this.timer + ")";
    return "";
  }

  mounted() {
    this.timer = this.time;
    this.countDownTimer();
  }
}
</script>
