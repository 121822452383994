import RequestHelper from "@/requests/RequestHelper";
import { Response } from "@/requests/RequestHelper";

//Models
import License, { LicenseBasicInfo } from "@/models/api/licenses/license";
import LicenseAssignUser from "@/models/api/licenses/licenseAssignUser";
import LicenseAssignCompany from "@/models/api/licenses/licenseAssignCompany";
import FilterRequest from "@/models/api/filters/FilterRequest";
import { ListResult } from "@/models/api/filters/ListResult";

//Urls
export const URL_GET_COMPANY_LICENSES_PAGED = "/License/CompaniesLicenses";
export const URL_GET_COMPANY_LICENSES_HISTORY = "/License/CompaniesLicensesHistory";
export const URL_GET_USER_LICENSE_ITEM_COUNT = "/License/UserLicenseItemsCount";
export const URL_GET_USER_LICENSES = "/License/UserActiveLicenses";
export const URL_POST_ASSIGN_LICENSE_ITEM_TO_USER = "/License/AssignLicenseItemToUser";
export const URL_POST_ASSIGN_LICENSE_ITEM_TO_COMPANY = "/License/AssignLicenseItemToCompany";
export const URL_POST_LOCK_COMPANY_LICENSE = "/License/LockCompanyLicense";
export const URL_POST_UNLOCK_COMPANY_LICENSE = "/License/UnlockCompanyLicense";

//Requests
const _licenses = new RequestHelper<ListResult<License>>(URL_GET_COMPANY_LICENSES_PAGED);
export const getLicenses = async (filter: FilterRequest): Promise<Response<ListResult<License>>> => {
  return await _licenses.get({ Filter: filter });
};

const _licensesHistory = new RequestHelper<ListResult<License>>(URL_GET_COMPANY_LICENSES_HISTORY);
export const getLicensesHistory = async (filter: FilterRequest): Promise<Response<ListResult<License>>> => {
  return await _licensesHistory.get({ Filter: filter });
};

const _assignToUser = new RequestHelper<null>(URL_POST_ASSIGN_LICENSE_ITEM_TO_USER, true);
export const assignToUser = async (model: LicenseAssignUser): Promise<Response<null>> => {
  return await _assignToUser.post(null, model);
};

const _assignToCompany = new RequestHelper<null>(URL_POST_ASSIGN_LICENSE_ITEM_TO_COMPANY, true);
export const assignToCompany = async (model: LicenseAssignCompany): Promise<Response<null>> => {
  return await _assignToCompany.post(null, model);
};

const _lockCompanyLicense = new RequestHelper<null>(URL_POST_LOCK_COMPANY_LICENSE, true);
export const lockCompanyLicense = async (companyId: string, licenseId: number): Promise<Response<null>> => {
  return await _lockCompanyLicense.post({
    CompanyId: companyId,
    LicenseId: licenseId,
  });
};

const _unlockCompanyLicense = new RequestHelper<null>(URL_POST_UNLOCK_COMPANY_LICENSE, true);
export const unlockCompanyLicense = async (companyId: string, licenseId: number): Promise<Response<null>> => {
  return await _unlockCompanyLicense.post({
    CompanyId: companyId,
    LicenseId: licenseId,
  });
};

const _userLicenseItemsCount = new RequestHelper<number>(URL_GET_USER_LICENSE_ITEM_COUNT);
export const userLicenseItemsCount = async (): Promise<Response<number>> => {
  return _userLicenseItemsCount.get();
};

const _userActiveLicenses = new RequestHelper<Array<LicenseBasicInfo>>(URL_GET_USER_LICENSES);
export const userActiveLicenses = async (): Promise<Response<Array<LicenseBasicInfo>>> => {
  return _userActiveLicenses.get();
};
