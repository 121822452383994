import { defineModule } from "direct-vuex";
import { moduleActionContext, moduleGetterContext } from "@/store/index";

//Requests
import {
  checkAlert,
  migrate,
  dateTimeNow,
  migrations,
  checkTransactionConfigurationIsLoaded,
  updateTransactionConfiguration,
  testBodyResponse,
} from "@/requests/developer";
import Migrations from "@/models/api/developer/Migrations";

export interface DeveloperState {
  info: string;
  dateTimeNow: string;
  migrations: Migrations;
  transactionConfigurationIsLoaded: boolean;
  testResponse: string;
}

export const DeveloperState: DeveloperState = {
  info: "",
  dateTimeNow: "",
  migrations: new Migrations(),
  transactionConfigurationIsLoaded: false,
  testResponse: "",
};

const developerModule = defineModule({
  state: DeveloperState,
  namespaced: true as const,
  getters: {},
  mutations: {
    setMigrations(state, migrations: Migrations) {
      state.migrations = migrations;
    },
    setDateTime(state, value: string) {
      state.dateTimeNow = value;
    },
    setTransactionStatus(state, value: boolean) {
      state.transactionConfigurationIsLoaded = value;
    },
    setTestResponse(state, text: string) {
      state.testResponse = text;
    },
  },
  actions: {
    async migrate(context) {
      const { dispatch } = _moduleActionContext(context);
      await migrate();
      dispatch.migrations();
    },
    async migrations(context) {
      const { commit } = _moduleActionContext(context);
      const response = await migrations();
      commit.setMigrations(response.model);
    },
    async dateTimeNow(context) {
      const { commit } = _moduleActionContext(context);
      const response = await dateTimeNow();
      commit.setDateTime(response.model);
    },
    async updateTransactionConfiguration() {
      await updateTransactionConfiguration();
    },
    async checkTransactionConfigurationIsLoaded(context) {
      const { commit } = _moduleActionContext(context);
      const response = await checkTransactionConfigurationIsLoaded();
      commit.setTransactionStatus(response.model);
    },
    async testBodyResponse(context, text: string) {
      const { commit } = _moduleActionContext(context);
      const response = await testBodyResponse(text);
      commit.setTestResponse(response.model);
    },
    async checkAlert() {
      await checkAlert();
    },
  },
});

export default developerModule;

/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable  @typescript-eslint/no-unused-vars */
const _moduleGetterContext = (args: [any, any, any, any]) => moduleGetterContext(args, developerModule);
const _moduleActionContext = (context: any) => moduleActionContext(context, developerModule);
