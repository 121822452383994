<template>
  <v-navigation-drawer
    v-model="expandDrawer"
    :mini-variant.sync="miniDrawer"
    :app="drawerApp"
    :absolute="drawerAbsolute"
    :width="width"
    :mobile-breakpoint="mobileBreakpoint"
    :clipped="$vuetify.breakpoint.mobile"
    :bottom="bottom"
    :temporary="temporary"
    :touchless="touchless"
  >
    <template #default>
      <navigation-link class="mt-10" @closeDrawer="closeMenu()" @colapseDrawer="colapseDrawer()" />
    </template>
    <template #prepend>
      <header class="navigation-toolbar">
        <v-list-item class="navigation-list-item">
          <div class="navigation-toolbar-button">
            <v-btn icon :ripple="false" @click="menuClick()">
              <v-icon color="black">$menuBar</v-icon>
            </v-btn>
          </div>
          <div class="navigation-toolbar__content">
            <div class="navigation-toolbar-image">
              <img src="@/assets/layout/logo_full.svg" />
            </div>
          </div>
        </v-list-item>
        <v-list-item class="d-flex flex-column align-center mt-3" style="height: max-content">
          <div></div>
          <span class="text-body-2">{{ userName }}</span>
          <v-chip text-color="error" class="mt-3"> {{ userRole }} </v-chip>
          <div class="navigation-divider__top mt-5"></div>
        </v-list-item>
      </header>
    </template>
    <template #append>
      <div class="navigation-bottom">
        <div class="navigation-bottom-stamp hidden-xs-only">
          <img src="@/assets/images/stamp.png" />
        </div>
        <v-list-item class="navigation-list-item">
          <div>
            <!-- Empty div first element of v-list-item is visible when mini-variant is used -->
          </div>
          <div class="navigation-bottom__content">
            <div class="navigation-bottom-counter">
              <span> Pozostało Ci {{ userReportCounter }} raportów do wykorzystania </span>
            </div>
            <div class="navigation-bottom-divider" />
            <div class="navigation-bottom-button">
              <v-btn text color="#D11164" :ripple="false" @click="logoutClick()">
                <v-icon left>$power</v-icon>
                <span>Wyloguj</span>
              </v-btn>
            </div>
          </div>
        </v-list-item>
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script lang="ts">
import { Component, Vue, Prop, PropSync, Watch } from "vue-property-decorator";
import NavigationLink from "@/components/app/navigationDrawer/NavigationLink.vue";
import ROLE from "../../../static/role";
import { filterCapitalize } from "@/filters/capitalize";

// Components
@Component({
  components: {
    NavigationLink,
  },
})
export default class NavigationDrawer extends Vue {
  @PropSync("expand", { required: true, default: false })
  expandDrawer!: boolean;
  @PropSync("mini", { required: true, default: false })
  miniDrawer!: boolean;
  @Prop({ default: false })
  colapseOnScroll!: boolean;
  @Prop({ default: false })
  colapseOnClick!: boolean;

  // Props
  drawerAbsolute = false;
  drawerApp = false;
  width = 0;
  defaultWidth = 300;
  clipped = !this.$vuetify.breakpoint.mobile;
  bottom = false;
  temporary = false;
  touchless = true; // Disable mobile touch functionality
  mobileBreakpoint = 0; // Set 0 to turn off temporary and bottom props

  menuClick() {
    this.$vuetify.breakpoint.mobile ? this.menuClickMobile() : this.menuClickDesktop();
  }

  menuClickMobile() {
    this.expandDrawer = !this.expandDrawer;
    this.miniDrawer = false;
  }

  menuClickDesktop() {
    this.expandDrawer = true;
    this.miniDrawer = !this.miniDrawer;
  }

  closeMenu() {
    this.expandDrawer = false;
    this.miniDrawer = false;
  }

  //TODO add settings -> create store userSettings and add settings to settingsView
  colapseDrawer() {
    if (this.colapseOnClick) {
      this.miniDrawer = true;
    }
  }

  async logoutClick() {
    await this.$store.direct.dispatch.user.logout();
    this.$store.direct.commit.SET_NAVIGATION({ show: false, mini: false });
  }

  @Watch("$vuetify.breakpoint.mobile")
  breakpointWatcher() {
    this.$vuetify.breakpoint.mobile ? this.setMobile() : this.setDesktop();
    this.setDrawerWidth();
  }

  setMobile() {
    this.drawerAbsolute = true;
    this.drawerApp = false;
    this.closeMenu();
  }

  setDesktop() {
    this.drawerAbsolute = false;
    this.drawerApp = true;
    // this.closeMenu();
    // this.menuClickDesktop();
    this.expandDrawer = true;
    this.miniDrawer = false; //initial state of drawer for desktop
  }

  setDrawerWidth() {
    this.width = this.$vuetify.breakpoint.mobile ? this.$vuetify.breakpoint.width : this.defaultWidth;
  }

  async mounted() {
    this.breakpointWatcher();
    await this.$store.direct.dispatch.user.userLicenseItemsCount();
  }

  // TODO Colapse on scroll -> implement debounce function
  // addScrollEventListener() {
  //   window.document.getElementsByClassName("v-main__wrap")[0].addEventListener("scroll", () => {
  //     console.log("event");
  //     this.$vuetify.breakpoint.mobile ? this.menuClickMobile() : this.menuClickDesktop();
  //   });
  // }

  // Computed properties
  get userReportCounter(): number {
    return this.$store.direct.state.user.userLicenseItemsCount;
  }

  get userRole(): string {
    const role = this.$store.direct.state.user.role;
    return filterCapitalize(this.$i18n.t(`userRole.${ROLE[role].toLowerCase()}`));
  }

  get userName(): string | null {
    return this.$store.direct.state.user.userLogin;
  }

  canShow() {
    return this.$route.path !== "/";
  }
}
</script>

<style lang="scss" scoped>
.navigation-bottom-stamp {
  width: 50px;
  height: 90px;
  position: absolute;
  right: 0;
  bottom: 10px;
  transform: translate(50%, -50%);
  z-index: 9999;
  img {
    width: 100%;
    height: 100%;
  }
}

.v-list-item::after {
  display: none;
}

.navigation-divider__top {
  width: 200px;
  border-bottom: 2px solid #707070;
}

.navigation-bottom-counter {
  span {
    display: block;
    text-align: center;
  }
}
</style>
