import i18n from "@/plugins/i18n";
import { filterCapitalize } from "@/filters/capitalize";
import { RuleFunction } from "@/rules/RuleFunction";

const numberValidator: RuleFunction = (value: unknown | string | number): boolean | string => {
  if (typeof value === "string") {
    const commaReplace = value.replace(",", ".");
    if (!isNaN(Number(commaReplace))) {
      return true;
    }
  }
  if (typeof value === "number") {
    return true;
  }
  return filterCapitalize(i18n.t("validation.notANumber").toString());
};

export { numberValidator };
export default numberValidator;
