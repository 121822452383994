<template>
  <span class="scaling-svg-container default-icon">
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 10.938 10.376">
      <path
        d="M9.115,10.376H1.823A1.708,1.708,0,0,1,0,8.819V2.594A1.708,1.708,0,0,1,1.823,1.037h.608V.519A.569.569,0,0,1,3.038,0a.569.569,0,0,1,.608.519v.518H7.292V.519A.569.569,0,0,1,7.9,0a.569.569,0,0,1,.607.519v.518h.608a1.708,1.708,0,0,1,1.823,1.556V8.819A1.708,1.708,0,0,1,9.115,10.376Zm-7.9-4.669V8.819a.569.569,0,0,0,.608.519H9.115a.569.569,0,0,0,.608-.519V5.707Zm.608-3.631a.569.569,0,0,0-.608.518V4.669H9.723V2.594a.569.569,0,0,0-.608-.518H8.507v.518a.569.569,0,0,1-.607.519.569.569,0,0,1-.608-.519V2.075H3.646v.518a.569.569,0,0,1-.608.519.569.569,0,0,1-.607-.519V2.075ZM7.9,7.782H5.469a.569.569,0,0,1-.608-.519.569.569,0,0,1,.608-.518H7.9a.569.569,0,0,1,.607.518A.569.569,0,0,1,7.9,7.782Zm-4.862,0a.569.569,0,0,1-.607-.519.569.569,0,0,1,.607-.518.569.569,0,0,1,.608.518A.569.569,0,0,1,3.038,7.782Z"
        transform="translate(0)"
      />
    </svg>
  </span>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

@Component
export default class CalendarIcon extends Vue {}
</script>

<style></style>
