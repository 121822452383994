<template>
  <div class="d-flex justify-center align-center flex-column" style="height: 100%">
    <div class="d-flex justify-center">
      <div class="text-h3 text-md-h2 font-weight-bold">Wybierz plan</div>
    </div>
    <div class="d-flex justify-center" style="width: 100%">
      <carousel :items="products" />
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Component } from "vue-property-decorator";
import Product from "@/models/api/products/product";
import Carousel from "@/components/shared/layout/Carousel.vue";

@Component({
  components: {
    Carousel,
  },
})
export default class LicenseBuyView extends Vue {
  get products(): Array<Product> {
    return this.$store.direct.state.product.products;
  }

  created() {
    this.$store.direct.dispatch.product.getProductList();
  }
}
</script>

<style>
.container-products {
  display: flex;
  justify-content: center;
}

.container-product {
  margin: 0 20px;
  height: 429px;
  width: 294px;
  background-color: grey;
}

.product-list {
  position: relative;
}
</style>
