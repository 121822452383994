<template>
  <div>
    <div v-if="formSummary != null">
      <div v-if="formSummary.formItems.length > 1">
        <span class="text-h6 font-weight-bold"> Wspólnicy:</span>
      </div>
      <div v-else>
        <span class="text-h6 font-weight-bold"> Właściciel:</span>
      </div>
      <div class="x">
        <div v-for="item in formSummary.formItems" :key="item.id" class="y">
          <span v-if="formSummary != null" class="text-body-2 font-weight-regular">
            {{ replaceFormName(formSummary.name, item.name) }}
          </span>
          <span v-if="!item.isCompleted" class="z">
            <span>
              <span class="text-black pa-2">Nie gotowy</span>
              <v-icon>$checkboxOff</v-icon>
            </span>
            <v-btn v-if="!item.isCompleted" class="btn main-light ml-5" width="116px" @click="continueForm(item.id)"
              >Uzupełnij
            </v-btn>
          </span>
          <span v-else class="z">
            <span>
              <span class="text-black pa-2">Gotowy</span>
              <v-icon>$checkboxOn</v-icon>
            </span>
            <v-tooltip right>
              <template #activator="{ on }">
                <div v-on="!formSummary ? !formSummary.canGenerateReport : false ? on : ''">
                  <v-btn
                    :disabled="formSummary ? !formSummary.canGenerateReport : true"
                    class="btn main-light ml-5"
                    @click="generateReport(item.id)"
                    >Generuj raport
                  </v-btn>
                </div>
              </template>
              <span> Aby wygenerować raport uzupełnij dane wszystkich wspólników </span>
            </v-tooltip>
          </span>
        </div>
      </div>
    </div>
    <license-user-dialog
      v-show="showUserLicenseDialog"
      :show-dialog.sync="showUserLicenseDialog"
      :selected-item.sync="selectedLicense"
      @save="selected"
    />
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

//Components
import LicenseUserDialog from "@/components/license/LicenseUserDialog.vue";
import { LicenseBasicInfo } from "@/models/api/licenses/license";
import FormSummaryModel from "@/models/api/reports/formSummary";

@Component({
  components: {
    LicenseUserDialog,
  },
})
export default class FormSummaryData extends Vue {
  get formSummary(): FormSummaryModel | null {
    return this.$store.direct.state.report.formSummary;
  }

  selectedFormId = 0;
  showUserLicenseDialog = false;

  selectedLicense: Array<LicenseBasicInfo> = [];

  async continueForm(formId: number) {
    this.$store.direct.commit.report.setSelectedFormId(formId);
    await this.$store.direct.dispatch.report.getFormActiveQuestion();
  }

  replaceFormName(formName: string, itemName: string): string {
    const name = itemName.replace(formName + " wspólnik", "");
    return name === "JDG" ? "Właściciel" : name;
  }

  async selected() {
    await this.$store.direct.dispatch.report.assignLicenceToForm(this.selectedLicense[0]);
    await this.$store.direct.dispatch.report.generateReport(this.selectedFormId);
  }

  async generateReport(formId: number) {
    await this.$store.direct.dispatch.report.getFormSummary();
    if (this.formSummary?.licenceItemId == null) {
      this.showUserLicenseDialog = true;
      this.selectedFormId = formId;
      return;
    }
    await this.$store.direct.dispatch.report.generateReport(formId);
  }
}
</script>

<style lang="scss" scoped>
.x {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: flex-start;
  padding: 10px 0;
  gap: 10px;
}

.y {
  width: calc(100%);
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.z {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
