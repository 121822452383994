<template>
  <div>
    <v-row align="center" justify="start">
      <div class="switchLabel">
        {{ leftLabel }}
      </div>
      <v-switch v-model="_value" class="black--text" :ripple="false" color="black" />
      <div class="switchLabel">
        {{ rightLabel }}
      </div>
    </v-row>
  </div>
</template>

<script lang="ts">
import { Vue, Component, PropSync, Prop } from "vue-property-decorator";

@Component
export default class InputSelector extends Vue {
  @PropSync("value")
  _value!: boolean;
  @Prop()
  leftLabel!: string;
  @Prop()
  rightLabel!: string;
}
</script>

<style lang="scss" scoped>
.switchLabel {
  color: $label;
  margin: 0 10px 0 0px;
  font-size: 11px;
}

::v-deep .v-input--switch__thumb {
  color: black !important;
}

::v-deep .v-input--switch__track {
  background-color: black;
  opacity: 0.1;
}
</style>
