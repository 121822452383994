<template>
  <span class="scaling-svg-container">
    <svg xmlns="http://www.w3.org/2000/svg" width="9" height="6.999" viewBox="0 0 9 6.999">
      <g id="Group_6343" data-name="Group 6343" transform="translate(-1343.584 -142.501)">
        <path
          id="Path"
          d="M1.065.095a.323.323,0,0,1,.457.457h0L1.1.97H4.983a.322.322,0,0,1,.35.335.3.3,0,0,1-.35.311h0c-.706-.006-3.879,0-3.879,0l.418.418a.323.323,0,1,1-.457.457h0l-.97-.97a.323.323,0,0,1,0-.457h0Z"
          transform="translate(1349.997 148.667) rotate(-90)"
        />
        <path
          id="Icon_Icon_Book"
          data-name="Icon/Icon/Book"
          d="M5.762,7H1.152A1.161,1.161,0,0,1,0,5.832V1.166A1.161,1.161,0,0,1,1.152,0h4.61a.387.387,0,0,1,.384.389V6.61A.387.387,0,0,1,5.762,7ZM1.152,5.444a.389.389,0,0,0,0,.777H5.378V5.444Zm0-4.666a.387.387,0,0,0-.384.389V4.737a1.12,1.12,0,0,1,.384-.071H5.378V.778Z"
          transform="translate(1343.584 142.501)"
        />
      </g>
    </svg>
  </span>
</template>

<script lang="ts">
import { Component } from "vue-property-decorator";
import IconHelper from "@/components/shared/icons/IconHelper.vue";

@Component
export default class DownloadReportIcon extends IconHelper {}
</script>

<style></style>
