import { DataOptions } from "vuetify/types";

export default class DefaultDataOptions implements DataOptions {
  page = 1;
  itemsPerPage = 10;
  sortBy: string[] = [];
  sortDesc: boolean[] = [true];
  groupBy = [];
  groupDesc = [];
  mustSort = true;
  multiSort = false;

  /**
   *
   */
  constructor(number?: number) {
    this.itemsPerPage = number ?? 10;
  }
}

//$ Don't change order of properties
