<template>
  <v-container fluid class="view-container">
    <router-view />
  </v-container>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

//Components
import BackgroundImage from "@/components/shared/layout/BackgroundImage.vue";

@Component({
  components: { BackgroundImage },
})
export default class ReportsView extends Vue {}
</script>

<style lang="scss" scoped></style>
