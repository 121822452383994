<template>
  <span class="scaling-svg-container">
    <svg xmlns="http://www.w3.org/2000/svg" width="39" height="39" viewBox="0 0 39 39" class="scaling-svg">
      <g transform="translate(-624 -862)">
        <g transform="translate(109.321 196.321)">
          <circle cx="19.5" cy="19.5" r="19.5" transform="translate(514.679 665.679)" fill="#fff" />
        </g>
        <g transform="translate(641.457 872.044)">
          <path
            d="M-1.331-.54H2.5V-13.41H-1.331ZM2.629,5.5V1.9H-1.458V5.5Z"
            transform="translate(1.458 13.41)"
            fill="#5b5665"
          />
        </g>
      </g>
    </svg>
  </span>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

@Component
export default class WarningIcon extends Vue {}
</script>

<style></style>
