export default class CompanyModel {
  id!: string;
  parentCompanyId!: string;
  name!: string;
  city!: string;
  country!: string;
  postalCode!: string;
  streetName!: string;
  streetNumber!: string;
  tin!: string;
  phoneNumber!: string;

  constructor() {
    this.id = "00000000-0000-0000-0000-000000000000";
    this.parentCompanyId = "00000000-0000-0000-0000-000000000000";
    this.name = "";
    this.city = "";
    this.country = "";
    this.postalCode = "";
    this.streetName = "";
    this.streetNumber = "";
    this.tin = "";
    this.phoneNumber = "";
  }
}
