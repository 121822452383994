<template>
  <div class="array">
    <div v-for="(value, index) of extension.values" :key="index" class="array-box">
      <decimal-extension :value="value" />
    </div>
    <div class="array-validator">
      <v-text-field ref="input" flat :rules="[extensionValidator(extension)]"> </v-text-field>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, PropSync } from "vue-property-decorator";

//Components
import DecimalExtension from "@/components/reports/workFlow/components/extensions/DecimalExtension.vue";

//Models
import { DecimalArrayExtension as DecArrayExt } from "@/models/api/reports/workFlow/BaseExtension";

//Validation
import requiredValidator from "@/rules/requiredValidator";
import extensionValidator from "@/rules/extensionValidator";

@Component({
  components: {
    DecimalExtension,
  },
})
export default class DecimalArrayExtension extends Vue {
  @PropSync("value")
  extension!: DecArrayExt;

  //Validation
  required = requiredValidator;
  extensionValidator = extensionValidator;
}
</script>

<style lang="scss" scoped>
.array {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.array-box {
  max-width: 180px;
  padding: 0 5px;
}

::v-deep .array-validator {
  position: absolute;
  bottom: -15px;
  .v-text-field {
    pointer-events: none !important;
    height: 0 !important;
    margin-bottom: 20px !important;
    padding: 0 !important;
    .v-input__control {
      display: block !important;
      height: 0 !important;
      .v-input__slot {
        height: 0 !important;
      }
      .v-input__slot::before {
        border-style: none !important;
        height: 0 !important;
      }
    }
  }
}

::v-deep .v-text-field {
  .v-input__control {
    .v-text-field__details {
      position: initial !important;
      .v-messages__wrapper {
        padding-top: 6px !important;
      }
    }
  }
}
</style>
