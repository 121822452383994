import { filterCapitalize } from "@/filters/capitalize";
import i18n from "@/plugins/i18n";

const pattern =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const emailValidator = (value: string): boolean | string => {
  if (pattern.test(value)) {
    return true;
  }
  return filterCapitalize(i18n.t("validation.invalidEmail").toString());
};

export { emailValidator };
export default emailValidator;
