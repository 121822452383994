<template>
  <span class="scaling-svg-container">
    <svg xmlns="http://www.w3.org/2000/svg" width="9.744" height="9.745" viewBox="0 0 9.744 9.745">
      <g transform="translate(-209.656 -1010.656)">
        <path
          d="M8.526,0H1.218A1.219,1.219,0,0,0,0,1.218V8.526A1.219,1.219,0,0,0,1.218,9.744H8.526A1.219,1.219,0,0,0,9.744,8.526V1.218A1.219,1.219,0,0,0,8.526,0Z"
          transform="translate(209.656 1010.656)"
          fill="#000"
        />
        <path
          d="M4.568,3.045H3.045V1.827c0-.336.273-.3.609-.3h.609V0H3.045A1.827,1.827,0,0,0,1.218,1.827V3.045H0V4.568H1.218V7.917H3.045V4.568h.914Z"
          transform="translate(213.31 1012.484)"
          fill="#fff"
        />
      </g>
    </svg>
  </span>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

@Component
export default class MediaFacebookIcon extends Vue {}
</script>

<style></style>
