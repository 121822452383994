import RequestHelper from "./RequestHelper";
import { Response } from "@/requests/RequestHelper";

//Models
import CompanyInfoResponse from "@/models/api/company/CompanyInfoResponse";
import UserInfoResponse from "@/models/api/user/UserInfoResponse";
import CompanyModel from "@/models/api/company/CompanyModel";
import CompanyDetails from "@/models/api/company/CompanyDetails";
import FileResultResponse from "@/models/api/reports/fileResultResponse";
import FilterRequest from "@/models/api/filters/FilterRequest";
import { ListResult } from "@/models/api/filters/ListResult";
import CompanyTreeBasicInfo from "@/models/api/company/CompanyTreeBasicInfo";

//Urls
export const URL_GET_COMPANY_CHILDREN = "/Company/GetCompanyChilds";
export const URL_GET_COMPANY_USERS = "/Company/GetCompanyUsers";
export const URL_GET_COMPANY_DETAILS = "/Company/CompanyDetails";

export const URL_GET_COMPANIES = "/Company/GetCompanies";

export const URL_POST_ADD_COMPANY = "/Companies/AddCompany";
export const URL_POST_EDIT_COMPANY = "/Companies/EditCompany";
export const URL_POST_LOCK_COMPANY = "/Companies/${CompanyId}/Lock";

export const URL_GET_GET_IMAGE_FILE = "/Companies/${CompanyId}/GetImage";
export const URL_POST_UPLOAD_IMAGE_FILE = "/Companies/${CompanyId}/UploadImage";
export const URL_POST_REMOVE_IMAGE_FILE = "/Companies/${CompanyId}/RemoveImage";

//Requests
const _companyChildren = new RequestHelper<Array<CompanyInfoResponse>>(URL_GET_COMPANY_CHILDREN);
export const downloadCompanyChildren = async (companyId: string): Promise<Response<Array<CompanyInfoResponse>>> => {
  return await _companyChildren.get({ CompanyId: companyId });
};

const _companyUsers = new RequestHelper<Array<UserInfoResponse>>(URL_GET_COMPANY_USERS);
export const downloadCompanyUsers = async (companyId: string): Promise<Response<Array<UserInfoResponse>>> => {
  return await _companyUsers.get({ CompanyId: companyId });
};

const _companyDetails = new RequestHelper<CompanyDetails>(URL_GET_COMPANY_DETAILS);
export const downloadCompanyDetails = async (companyId: string): Promise<Response<CompanyDetails>> => {
  return _companyDetails.get({ CompanyId: companyId });
};

const _addCompany = new RequestHelper<null>(URL_POST_ADD_COMPANY, true);
export const addCompany = async (model: CompanyModel): Promise<Response<null>> => {
  return _addCompany.post(null, model);
};

const _editCompany = new RequestHelper<null>(URL_POST_EDIT_COMPANY);
export const editCompany = async (model: CompanyDetails): Promise<Response<null>> => {
  return _editCompany.post(null, model);
};

const _lockCompany = new RequestHelper<null>(URL_POST_LOCK_COMPANY, true);
export const lockCompany = async (companyId: string): Promise<Response<null>> => {
  return _lockCompany.post({ CompanyId: companyId });
};

const _uploadCompanyLogo = new RequestHelper<null>(URL_POST_UPLOAD_IMAGE_FILE, true);
export const uploadCompanyLogo = async (file: File, officeId: string): Promise<Response<null>> => {
  return _uploadCompanyLogo.sendFile({ CompanyId: officeId }, file);
};

const _getLogoImage = new RequestHelper<FileResultResponse>(URL_GET_GET_IMAGE_FILE);
export const getLogoImage = async (companyId: string): Promise<Response<FileResultResponse>> => {
  return _getLogoImage.get({ CompanyId: companyId });
};

const _removetLogoImage = new RequestHelper<null>(URL_POST_REMOVE_IMAGE_FILE, true);
export const removeLogoImage = async (companyId: string): Promise<Response<null>> => {
  return _removetLogoImage.post({ CompanyId: companyId });
};

const _getCompanies = new RequestHelper<ListResult<CompanyTreeBasicInfo>>(URL_GET_COMPANIES);
export const getCompanies = async (filter: FilterRequest): Promise<Response<ListResult<CompanyTreeBasicInfo>>> => {
  return _getCompanies.get({ Filter: filter });
};
