<template>
  <div class="view-container__wrap">
    <header-buttons :buttons="buttons"></header-buttons>
    <administration-hierarchy-basic-filter :options.sync="tableOptions" />
    <div class="table-container">
      <v-data-table
        class="table data-table-items-italic"
        :items="companies.items"
        :headers="headers"
        :options.sync="tableOptions"
        :server-items-length="companies.count"
        :item-class="getItemClass"
        single-select
        disable-sort
        hide-default-footer
        @click:row="selectCompany"
      >
        <template #[`item.name`]="{ item }">
          <span :style="`padding-left: ${30 * item.depth}px`">{{ item.name }}</span>
        </template>
        <template #[`item.actions`]="{ item }">
          <v-tooltip bottom>
            <template #activator="{ on }">
              <v-btn v-if="checkPermission(ROLE.SuperAdmin)" icon @click="showFreeSamplesDialog(item)" v-on="on">
                <v-icon color="black">$plus</v-icon>
              </v-btn>
            </template>
            {{ $t("tooltip.addFreeSamples") | capitalize }}
          </v-tooltip>
          <v-tooltip bottom>
            <template #activator="{ on }">
              <v-btn v-if="checkPermission(ROLE.Admin)" icon @click="showReportFileDialog(item)" v-on="on">
                <v-icon color="black">$file</v-icon>
              </v-btn>
            </template>
            {{ $t("tooltip.showReports") | capitalize }}
          </v-tooltip>
          <v-tooltip bottom>
            <template #activator="{ on }">
              <v-btn :disabled="item.isLocked" icon @click="showCompanyUsers(item)" v-on="on">
                <v-icon color="black">mdi-account</v-icon>
              </v-btn>
            </template>
            {{ $t("tooltip.showCompanyUsers") | capitalize }}
          </v-tooltip>
          <v-tooltip bottom>
            <template #activator="{ on }">
              <v-btn color="black" :disabled="item.isLocked" icon @click="showCompanyDetails(item)" v-on="on">
                <v-icon>$show</v-icon>
              </v-btn>
            </template>
            {{ $t("tooltip.showCompanyDetails") | capitalize }}
          </v-tooltip>
          <v-tooltip bottom>
            <template #activator="{ on }">
              <v-btn color="black" :disabled="item.isLocked" icon @click="editCompanyDetails(item)" v-on="on">
                <v-icon>$edit</v-icon>
              </v-btn>
            </template>
            {{ $t("tooltip.editCompanyDetails") | capitalize }}
          </v-tooltip>
          <v-tooltip bottom>
            <template #activator="{ on }">
              <v-btn color="black" :disabled="item.isLocked" icon @click="lockCompany(item)" v-on="on">
                <v-icon>$lock</v-icon>
              </v-btn>
            </template>
            {{ $t("tooltip.lockCompany") | capitalize }}
          </v-tooltip>
        </template>
      </v-data-table>
      <div class="table__pager">
        <custom-pagination :options.sync="tableOptions" :server-items-length="companies.count"> </custom-pagination>
      </div>
    </div>
    <administration-add-company-dialog v-if="showAddCompanyDialog" :value.sync="showAddCompanyDialog" />
    <administration-user-dialog v-if="showUserDialog" :value.sync="showUserDialog" />
    <administration-company-dialog v-if="showCompanyDialog" :value.sync="showCompanyDialog" :edit="editCompany" />
    <administration-add-free-samples-dialog
      v-if="showAddFreeSamplesDialog"
      :value.sync="showAddFreeSamplesDialog"
      :company-id="selectedCompanyId"
    />
    <administration-report-files-dialog
      v-if="showReportFilesDialog"
      :value.sync="showReportFilesDialog"
      :company-id="selectedCompanyId"
    />
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { DataTableHeader, DataOptions } from "vuetify";

//Components
import AddCompanyDialog from "@/components/administration/hierarchy/AddCompanyDialog.vue";
import TreeDataTable from "@/components/shared/layout/TreeDataTable.vue";
import HeaderButtons, { HeaderButtonsInterface } from "@/components/shared/buttons/HeaderButtons.vue";

import AdministrationUserDialog from "@/components/administration/AdministrationUserDialog.vue";

import AdministrationCompanyDialog from "@/components/administration/AdministrationCompanyDialog.vue";
import AdministrationAddCompanyDialog from "@/components/administration/AdministrationAddCompanyDialog.vue";
import AdministrationAddFreeSamplesDialog from "@/components/administration/AdministrationAddFreeSamplesDialog.vue";
import AdministrationReportFilesDialog from "@/components/administration/AdministrationReportFilesDialog.vue";
import AdministrationHierarchyBasicFilter from "@/components/administration/hierarchy/AdministrationHierarchyBasicFilter.vue";
import CustomPagination from "@/components/shared/dataTable/CustomPagination.vue";

// Models
import { ListResult } from "@/models/api/filters/ListResult";
import CompanyTreeBasicInfo from "@/models/api/company/CompanyTreeBasicInfo";

// Interfaces
import Row from "@/models/vuetify/row";

// Staic
import ROLE from "@/static/role";

//Translations
import { filterCapitalize } from "@/filters/capitalize";
import i18n from "@/plugins/i18n";

@Component({
  components: {
    HeaderButtons,
    AddCompanyDialog,
    TreeDataTable,
    AdministrationCompanyDialog,
    AdministrationAddCompanyDialog,
    AdministrationAddFreeSamplesDialog,
    AdministrationUserDialog,
    AdministrationReportFilesDialog,
    AdministrationHierarchyBasicFilter,
    CustomPagination,
  },
})
export default class AdministrationHierarchyView extends Vue {
  // Static
  ROLE = ROLE;

  // Computed
  get companies(): ListResult<CompanyTreeBasicInfo> {
    return this.$store.direct.state.administration.companyTableItems ?? new ListResult<CompanyTreeBasicInfo>();
  }

  get selectedCompanyId(): string | null {
    return this.$store.direct.state.administration.selectedCompanyId;
  }

  set selectedCompanyId(id: string | null) {
    this.$store.direct.commit.administration.setSelectedCompanyId(id);
  }

  get tableOptions(): DataOptions {
    return this.$store.direct.state.filter.administrationHierarchyBasicFilterOptions;
  }

  set tableOptions(options: DataOptions) {
    this.$store.direct.commit.filter.setAdministrationHierarchyBasicFilterOptions(options);
  }

  buttons: Array<HeaderButtonsInterface> = [
    {
      text: filterCapitalize(i18n.t("actions.addCompany")),
      color: "black",
      icon: "$plus",
      action: () => {
        this.addCompany();
      },
      class: "btn main-light",
      disabled: false,
      align: "left",
      width: 150,
    },
  ];

  headers: Array<DataTableHeader> = [
    {
      text: "Nazwa firmy",
      value: "name",
    },
    {
      text: "",
      value: "actions",
      align: "end",
      width: 300,
    },
  ];

  getItemClass = (item: CompanyTreeBasicInfo): string => {
    if (item.isLocked) return "locked";
    return "";
  };

  selectCompany(item: CompanyTreeBasicInfo, row: Row<CompanyTreeBasicInfo>) {
    row.select(true);
    this.selectedCompanyId = item.id;
  }

  editCompany = false;
  showAddCompanyDialog = false;
  showUserDialog = false;
  showCompanyDialog = false;
  showAddFreeSamplesDialog = false;
  showReportFilesDialog = false;

  checkPermission(role: ROLE): boolean {
    return this.$store.direct.state.user.role >= role;
  }

  addCompany() {
    this.showAddCompanyDialog = true;
  }

  showCompanyUsers(item: CompanyTreeBasicInfo) {
    this.$store.direct.commit.company.setSelectedCompanyId(item.id);
    this.selectedCompanyId = item.id;
    this.showUserDialog = true;
  }

  showCompanyDetails(item: CompanyTreeBasicInfo) {
    this.selectedCompanyId = item.id;
    this.editCompany = false;
    this.showCompanyDialog = true;
  }

  editCompanyDetails(item: CompanyTreeBasicInfo) {
    this.selectedCompanyId = item.id;
    this.editCompany = true;
    this.showCompanyDialog = true;
  }

  showFreeSamplesDialog(item: CompanyTreeBasicInfo) {
    this.selectedCompanyId = item.id;
    this.showAddFreeSamplesDialog = true;
  }

  showReportFileDialog(item: CompanyTreeBasicInfo) {
    this.selectedCompanyId = item.id;
    this.showReportFilesDialog = true;
  }

  async lockCompany(item: CompanyTreeBasicInfo) {
    this.selectedCompanyId = item.id;
    await this.$store.direct.dispatch.administration.lockCompany(item);
  }
}
</script>

<style lang="scss" scoped></style>
