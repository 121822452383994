import Vue from "vue";
import VueApexCharts from "vue-apexcharts";

Vue.use(VueApexCharts);
Vue.component("ApexChart", VueApexCharts);

// Import custom locales
import pl from "@/locales/apex_pl.json";

Vue.use(VueApexCharts);

// Set default values for chart locales
window.Apex.chart = {
  locales: [pl],
  defaultLocale: "pl",
};
