import { defineModule } from "direct-vuex";
import { moduleActionContext, moduleGetterContext } from "@/store/index";

//Translations
import { filterCapitalize } from "@/filters/capitalize";
import i18n from "@/plugins/i18n";

//Plugins
import { setAuthAxios, removeAuthAxios } from "@/plugins/axios";
import routerInstance from "@/plugins/router";

//Static
import ROUTE_NAMES from "@/static/routeNames";
import ROLE from "@/static/role";

//Models
import UserLoginRequest from "@/models/api/user/UserLoginRequest";
import RegisterUserRequest from "@/models/api/user/RegisterUserRequest";
import PasswordEdit from "@/models/api/administration/account/PasswordEdit";
import UserDetails from "@/models/api/user/UserDetails";
import CompanyDetails from "@/models/api/company/CompanyDetails";

//Request
import {
  postLogin,
  postLogout,
  postRegister,
  getUserRoles,
  getUserDetails,
  getUserCompanyDetails,
  editUser,
  postChangePassword,
  remindPassword,
  sendLoggedUserHelpMail,
  sendUnloggedUserHelpMail,
} from "@/requests/user";
import { editCompany, uploadCompanyLogo, getLogoImage, removeLogoImage } from "@/requests/company";

//Confirm
import { ConfirmModel } from "@/models/components/popup/Confirm";
import { Route } from "vue-router";
import ContactForm from "@/models/api/user/ContactForm";
import { SnackbarModel } from "@/models/components/popup/Snackbar";
import { userLicenseItemsCount } from "@/requests/licenses";
import { getReportDemo } from "@/requests/user";
import RemindPassword from "@/models/api/user/RemindPassword";
import FileResultResponse from "@/models/api/reports/fileResultResponse";

export interface UserStateInterface {
  userLogin: string | null;
  token: string | null;
  role: ROLE;
  userCompanyDetails: CompanyDetails | null;
  userDetails: UserDetails | null;
  userCompanyLogoImage: FileResultResponse | null;
  routeToRedirectAfterLogin: Route | null;
  userLicenseItemsCount: number;
}

export const UserState: UserStateInterface = {
  userLogin: null,
  token: null,
  role: ROLE.Guest,
  userCompanyDetails: null,
  userDetails: null,
  userCompanyLogoImage: null,
  routeToRedirectAfterLogin: null,
  userLicenseItemsCount: 0,
};

const storeToken = (token: string) => {
  localStorage.setItem("token", token);
};

const getToken = (): string | null => {
  return localStorage.getItem("token");
};

const removeToken = (): void => {
  localStorage.removeItem("token");
};

const userModule = defineModule({
  state: UserState as UserStateInterface,
  namespaced: true as const,
  getters: {
    isLogged(...args): boolean {
      const { state } = _moduleGetterContext(args);
      return state.token !== null;
    },
    userCompanyId(...args): string {
      const { state } = _moduleGetterContext(args);
      if (state.userCompanyDetails != null) {
        return state.userCompanyDetails.id;
      }
      return "";
    },
  },
  mutations: {
    SET_LOGIN(state, login: string | null) {
      state.userLogin = login;
    },
    SET_TOKEN(state, token: string | null) {
      state.token = token;
    },
    SET_ROLE(state, roles: Array<string>) {
      let x: ROLE = ROLE.Guest;
      for (const r of roles) {
        const y: ROLE = ROLE[r as keyof typeof ROLE];
        if (x < y) x = y;
      }
      state.role = x;
    },
    SET_USER_DETAILS(state, userDetails: UserDetails) {
      state.userDetails = userDetails;
    },
    SET_USER_COMPANY_DETAILS(state, userCompanyDetails: CompanyDetails) {
      state.userCompanyDetails = userCompanyDetails;
    },
    SET_USER_COMPANY_LOGO_IMAGE(state, file: FileResultResponse | null) {
      state.userCompanyLogoImage = file;
    },
    SET_PREVIOUS_PAGE(state, route: Route | null) {
      state.routeToRedirectAfterLogin = route;
    },
    SET_USER_LICENSE_ITEMS_COUNT(state, count: number) {
      state.userLicenseItemsCount = count;
    },
  },
  actions: {
    async restoreToken(): Promise<string | null> {
      // $restore auth token for axios after refresh window
      const token = localStorage.getItem("token");
      if (typeof token === "string") {
        setAuthAxios(token);
      }
      return token;
    },
    async login(context, payload: UserLoginRequest) {
      const { state, commit, dispatch } = _moduleActionContext(context);
      const response = await postLogin(payload);
      switch (response.status) {
        case 200: {
          const responseModel = response.model;
          const token = responseModel.token;
          storeToken(token);
          setAuthAxios(token);
          await dispatch.getUserRole();
          await dispatch.getUserDetails();
          await dispatch.getUserCompanyDetails();
          commit.SET_TOKEN(token);
          commit.SET_LOGIN(payload.login);
          // Redirect user
          if (state.routeToRedirectAfterLogin != null) {
            const x = routerInstance.resolve({
              path: state.routeToRedirectAfterLogin.path,
              query: state.routeToRedirectAfterLogin.query,
            });
            await routerInstance.push(x.location);
          } else {
            await routerInstance.push({
              name: ROUTE_NAMES.STATISTICS.STATISTIC,
            });
          }
          commit.SET_PREVIOUS_PAGE(null);
        }
      }
      return response.isError;
    },
    async logout(context) {
      const { commit } = _moduleActionContext(context);
      const token = getToken();
      if (token !== null) {
        setAuthAxios(token);
        postLogout();
        removeToken();
        removeAuthAxios();
      }
      localStorage.clear();
      sessionStorage.clear();
      commit.SET_TOKEN(null);
      commit.SET_ROLE([ROLE[ROLE.Guest]]);
      // $Router go -> allows to refresh window and remove data from store because persistedState for user is empty object after logout
      routerInstance.push({ name: ROUTE_NAMES.GENERAL.LOGIN });
      routerInstance.go(0);
    },
    async register(context, model: RegisterUserRequest) {
      const { dispatch } = _moduleActionContext(context);
      const response = await postRegister(model);
      if (response.status === 200) {
        const loginModel: UserLoginRequest = {
          login: model.email,
          password: model.password,
        };
        await dispatch.login(loginModel);
      }
      return response.isError;
    },
    async noPermissions() {
      await routerInstance.push({ name: ROUTE_NAMES.ERROR.UNAUTHORIZED });
    },
    async getUserRole(context) {
      const { commit } = _moduleActionContext(context);
      const response = await getUserRoles();
      commit.SET_ROLE(response.model);
    },
    async getUserDetails(context) {
      const { commit } = _moduleActionContext(context);
      const response = await getUserDetails();
      commit.SET_USER_DETAILS(response.model);
    },
    async getUserCompanyDetails(context) {
      const { commit } = _moduleActionContext(context);
      const response = await getUserCompanyDetails();
      commit.SET_USER_COMPANY_DETAILS(response.model);
    },
    async editUser(context, model: UserDetails) {
      const { rootDispatch } = _moduleActionContext(context);
      const confirmModel = new ConfirmModel(
        filterCapitalize(i18n.t("confirm.information")),
        filterCapitalize(i18n.t("confirm.administration.editUserDetails"))
      );
      const isConfirmed = (await rootDispatch.popup.addConfirm(confirmModel)) as boolean;
      if (isConfirmed) {
        await editUser(model);
        await rootDispatch.user.getUserDetails();
      }
      return isConfirmed;
    },
    async changePassword(context, model: PasswordEdit) {
      const result = await postChangePassword(model);
      return result.status == 200 ? true : false;
    },
    async editCompany(context, model: CompanyDetails) {
      const { rootDispatch } = _moduleActionContext(context);
      const confirmModel = new ConfirmModel(
        filterCapitalize(i18n.t("confirm.information")),
        filterCapitalize(i18n.t("confirm.administration.editCompanyDetails"))
      );
      const isConfirmed = (await rootDispatch.popup.addConfirm(confirmModel)) as boolean;
      if (isConfirmed) {
        await editCompany(model);
        await rootDispatch.user.getUserCompanyDetails();
      }
      return isConfirmed;
    },
    async updateProfileFormData(context, models: { user: UserDetails; company: CompanyDetails }) {
      const { rootDispatch, dispatch } = _moduleActionContext(context);
      const confirmModel = new ConfirmModel(
        filterCapitalize(i18n.t("confirm.information")),
        filterCapitalize(i18n.t("confirm.administration.updateProfileData"))
      );
      const isConfirmed = (await rootDispatch.popup.addConfirm(confirmModel)) as boolean;
      if (isConfirmed) {
        const x = await Promise.all([editUser(models.user), editCompany(models.company)]);
        if (!x[0].isError && !x[1].isError) {
          await rootDispatch.popup.addSnackbarSuccess();
          await dispatch.getUserDetails();
          await dispatch.getUserCompanyDetails();
        }
      }
      return isConfirmed;
    },
    async sendContactForm(context, form: ContactForm) {
      const { rootDispatch, getters } = _moduleActionContext(context);
      if (getters.isLogged) {
        await sendLoggedUserHelpMail(form);
      } else {
        await sendUnloggedUserHelpMail(form);
      }
      await rootDispatch.popup.addSnackbar(new SnackbarModel(["messageSent"]));
    },
    async userLicenseItemsCount(context) {
      const { commit } = _moduleActionContext(context);
      const response = await userLicenseItemsCount();
      commit.SET_USER_LICENSE_ITEMS_COUNT(response.model);
    },
    async remindPassword(context, model: RemindPassword) {
      const { rootDispatch } = _moduleActionContext(context);
      const response = await remindPassword(model);
      if (response.status == 200) {
        await routerInstance.push("/login");
        await rootDispatch.popup.addSnackbar(new SnackbarModel(["checkYourEmailRemindPassword"]));
      }
    },
    async uploadCompanyLogo(context, file: File) {
      const { state, dispatch, rootDispatch } = _moduleActionContext(context);
      const confirmModel = new ConfirmModel(
        filterCapitalize(i18n.t("confirm.information")),
        filterCapitalize(i18n.t("confirm.administration.editUserCompanyLogoImage"))
      );
      const isConfirmed = (await rootDispatch.popup.addConfirm(confirmModel)) as boolean;
      if (isConfirmed) {
        if (state.userCompanyDetails != null) {
          await uploadCompanyLogo(file, state.userCompanyDetails.id);
          await dispatch.getUserCompanyLogoImage();
        }
      }
    },
    async getUserCompanyLogoImage(context) {
      const { state, commit } = _moduleActionContext(context);
      if (state.userCompanyDetails != null) {
        const response = await getLogoImage(state.userCompanyDetails.id);
        if (response.model.data != null) commit.SET_USER_COMPANY_LOGO_IMAGE(response.model);
        else commit.SET_USER_COMPANY_LOGO_IMAGE(null);
      }
    },
    async removeCompanyLogo(context) {
      const { state, dispatch, commit, rootDispatch } = _moduleActionContext(context);
      const confirmModel = new ConfirmModel(
        filterCapitalize(i18n.t("confirm.information")),
        filterCapitalize(i18n.t("confirm.administration.removeUserCompanyLogoImage"))
      );
      const isConfirmed = (await rootDispatch.popup.addConfirm(confirmModel)) as boolean;
      if (isConfirmed) {
        if (state.userCompanyDetails != null) {
          await removeLogoImage(state.userCompanyDetails.id);
          commit.SET_USER_COMPANY_LOGO_IMAGE(null);
          await dispatch.getUserCompanyLogoImage();
        }
      }
    },
    async getReportDemo() {
      const response = await getReportDemo();
      const byteArray = new Uint8Array(response.model.data);
      const urlLink = window.URL.createObjectURL(new Blob([byteArray], { type: response.model.mimeType }));

      const a = document.createElement("a");
      document.body.appendChild(a);
      a.href = urlLink;
      a.download = response.model.name;
      a.click();
    },
  },
});

export default userModule;

/* eslint-disable  @typescript-eslint/no-explicit-any */
const _moduleGetterContext = (args: [any, any, any, any]) => moduleGetterContext(args, userModule);
const _moduleActionContext = (context: any) => moduleActionContext(context, userModule);
