<template>
  <span class="scaling-svg-container">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15.205"
      height="15.205"
      viewBox="0 0 15.205 15.205"
      class="scaling-svg"
    >
      <g id="floppy-disk" transform="translate(0.3 0.3)">
        <path
          d="M12.931,14.6H1.674A1.675,1.675,0,0,1,0,12.931V1.674A1.675,1.675,0,0,1,1.674,0h9.437a2.294,2.294,0,0,1,1.613.669l1.212,1.212A2.268,2.268,0,0,1,14.6,3.495v9.437A1.675,1.675,0,0,1,12.931,14.6ZM1.674.913a.762.762,0,0,0-.761.761V12.931a.762.762,0,0,0,.761.761H12.931a.762.762,0,0,0,.761-.761V3.495a1.36,1.36,0,0,0-.4-.968L12.079,1.314a1.376,1.376,0,0,0-.968-.4Zm0,0"
          stroke="currentColor"
          stroke-width="0.6"
        />
        <path
          d="M92.178,4.26h-6.39a.457.457,0,0,1-.456-.456V.456a.456.456,0,0,1,.913,0V3.347h5.477V.456a.456.456,0,0,1,.913,0V3.8A.457.457,0,0,1,92.178,4.26Zm0,0"
          transform="translate(-82.898)"
          stroke="currentColor"
          stroke-width="0.6"
        />
        <path
          d="M162.738,229.477a2.738,2.738,0,1,1,2.738-2.738A2.742,2.742,0,0,1,162.738,229.477Zm0-4.564a1.826,1.826,0,1,0,1.826,1.826A1.828,1.828,0,0,0,162.738,224.913Zm0,0"
          transform="translate(-155.436 -217.61)"
          stroke="currentColor"
          stroke-width="0.6"
        />
      </g>
    </svg>
  </span>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

@Component
export default class SaveIcon extends Vue {}
</script>

<style></style>
