<template>
  <span class="scaling-svg-container">
    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="16" viewBox="0 0 15 16" class="scaling-svg">
      <g transform="translate(-816 -951)">
        <g transform="translate(816 951)">
          <g transform="translate(1.714 0.857)">
            <path
              d="M7,10H1A1,1,0,0,1,0,9V1A1,1,0,0,1,1,0H6L8,2V9A1,1,0,0,1,7,10ZM1.5,1a.5.5,0,0,0-.5.5v7a.5.5,0,0,0,.5.5h5A.5.5,0,0,0,7,8.5V3H5V1ZM6,1V2H7Z"
              transform="translate(0.286 0.143)"
            />
          </g>
        </g>
        <g id="Group_79" data-name="Group 79" transform="translate(376 158)">
          <rect width="6" height="8" transform="translate(446 799)" fill="#fff" />
          <g transform="translate(443 797)">
            <g transform="translate(1.714 0.857)">
              <path
                d="M7,10H1A1,1,0,0,1,0,9V1A1,1,0,0,1,1,0H6L8,2V9A1,1,0,0,1,7,10ZM1.5,1a.5.5,0,0,0-.5.5v7a.5.5,0,0,0,.5.5h5A.5.5,0,0,0,7,8.5V3H5V1ZM6,1V2H7Z"
                transform="translate(0.286 0.143)"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  </span>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

@Component
export default class CopyIcon extends Vue {}
</script>

<style></style>
