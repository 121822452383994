<template>
  <v-container class="d-flex justify-center align-center">
    <div class="form-container">
      <div class="text-h4">{{ $t("header.remindPassword") | capitalize }}</div>
      <div class="remind-password">
        <v-form ref="remindPasswordForm" @submit.prevent.stop="remindPassword()">
          <v-text-field
            v-model="model.email"
            :label="$t('form.userEmail') | capitalize"
            hide-details="auto"
            autocomplete="new-password"
            :rules="[required]"
            flat
            color="black"
            spellcheck="false"
          ></v-text-field>
          <div class="form-buttons">
            <v-btn class="btn main-light--reverse" :width="120" :height="35" type="submit">{{
              $t("form.remindPassword") | capitalize
            }}</v-btn>
            <v-btn class="btn main-light" :width="120" :height="35" to="/login">{{
              $t("form.cancel") | capitalize
            }}</v-btn>
          </div>
        </v-form>
      </div>
      <div class="form-stamp">
        <img src="@/assets/images/stamp.png" alt="" />
      </div>
    </div>
  </v-container>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { FormValidation } from "../../../customTypes/vuetify";

// Validation
import requiredValidator from "@/rules/requiredValidator";

//Models
import RemindPassword from "@/models/api/user/RemindPassword";

@Component
export default class RemindPasswordView extends Vue {
  model: RemindPassword = {
    email: "",
  };

  //Validation
  required = requiredValidator;

  remindPassword() {
    const form = this.$refs.remindPasswordForm as FormValidation;
    if (form.validate()) {
      this.$store.direct.dispatch.user.remindPassword(this.model);
      form.reset();
    }
  }
}
</script>

<style lang="scss" scoped>
.form-container {
  align-self: center;
  max-width: 350px;
  width: 100%;
  margin: 30px 0;
  position: relative;
}

.form-stamp {
  position: absolute;
  top: -30px;
  right: 0;
  height: 106px;
  width: 58px;
  img {
    height: 100%;
    width: 100%;
  }
}

.form-buttons {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 30px 0;
}
</style>
