var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('v-app',[_c('device-info'),_c('v-progress-linear',{attrs:{"active":_vm.isLoading,"indeterminate":_vm.isLoading,"color":"success","absolute":"","height":"6"}}),_c('app-bar',{attrs:{"drawer":_vm.navigation.show},on:{"update:drawer":function($event){return _vm.$set(_vm.navigation, "show", $event)}}}),(
        _vm.isLogged &&
        ((_vm.$route.path !== '/' &&
          _vm.$route.path !== '/login' &&
          _vm.$route.path !== '/terms-of-use' &&
          _vm.$route.path !== '/privacy-policy' &&
          _vm.$route.path !== '/faq') ||
          _vm.$vuetify.breakpoint.mobile)
      )?_c('navigation-drawer',{attrs:{"expand":_vm.navigation.show,"mini":_vm.navigation.mini,"colapse-on-scroll":true},on:{"update:expand":function($event){return _vm.$set(_vm.navigation, "show", $event)},"update:mini":function($event){return _vm.$set(_vm.navigation, "mini", $event)}}}):_vm._e(),_c('v-main',[_c('v-container',{staticClass:"pb-0",attrs:{"fluid":""}},[_c('router-view')],1),(_vm.showAlerts)?_c('alert'):_vm._e(),(_vm.showConfirms)?_c('confirm-popup'):_vm._e(),(_vm.showSnackbars)?_c('snackbar'):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }