<template>
  <input-text
    v-model="tin"
    v-maska="options"
    :label="label"
    :allow-text="false"
    :allow-number="true"
    :allow-symbol="false"
    :show-counter="false"
    :clerable="true"
    :rules="allRules"
    :color="color"
    :show-error="false"
    :cols="cols"
  />
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";

// Components
import InputText from "@/components/shared/inputs/InputText.vue";

// Validators
import tinValidator from "@/rules/tinValidator";

@Component({ components: { InputText } })
export default class InputTin extends Vue {
  @Prop({ required: true })
  value!: string;
  @Prop()
  label!: string;
  @Prop()
  color!: string;
  @Prop()
  cols!: number;
  @Prop({ default: () => [] })
  // eslint-disable-next-line
  rules!: any[];

  options = {
    mask: "### ### ## ##",
  };

  tinValidator = tinValidator;

  get tin(): string {
    return this.value;
  }

  set tin(value: string) {
    this.$emit("input", value);
  }

  get allRules() {
    return [...this.rules, tinValidator];
  }
}
</script>

<style scoped></style>
