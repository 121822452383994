<template>
  <div class="goto">
    <a class="goto-label" href @click.prevent.stop="action()">
      {{ label }}
    </a>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";
import { VuetifyGoToTarget } from "vuetify/types/services/goto";

@Component
export default class GoTo extends Vue {
  // @Prop({ required: true })
  // to!: string;
  @Prop({ required: true })
  label!: string;
  @Prop({ required: true })
  name!: VuetifyGoToTarget;

  async action() {
    if (this.$route.path !== "/") {
      await this.$router.push("/");
      setTimeout(() => {
        this.$store.direct.dispatch.vuetify.goTo(this.name);
      }, 500);
    } else {
      this.$store.direct.dispatch.vuetify.goTo(this.name);
    }
  }
}
</script>

<style lang="scss" scoped>
.goto {
  cursor: pointer;
}

.goto-label {
  cursor: unset;
  text-transform: uppercase;
}
</style>
