<template>
  <div>
    <header-buttons :buttons="buttons" />
    <div v-if="isLoading">
      <v-overlay absolute>
        Ładowanie...
        <v-progress-circular indeterminate color="success"></v-progress-circular>
      </v-overlay>
    </div>
    <div v-else>
      <statistics-created-reports-chart class="mt-10" />
      <v-row>
        <v-col sm="12" md="12" lg="6" cols="12">
          <last-reports class="mt-10" />
        </v-col>
        <v-col sm="12" md="12" lg="6" cols="12">
          <statistics-licenses-chart class="mt-10" />
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

//Components
import HeaderButtons, { HeaderButtonsInterface } from "@/components/shared/buttons/HeaderButtons.vue";
import StatisticsCreatedReportsChart from "@/components/statistics/StatisticsCreatedReportsChart.vue";
import StatisticsLicensesChart from "@/components/statistics/StatisticsLicensesChart.vue";
import LastReports from "@/components/statistics/StatisticsLastReports.vue";

//Static
import ROUTE_NAMES from "@/static/routeNames";

//Translations
import { filterCapitalize } from "../../../filters/capitalize";
import i18n from "../../../plugins/i18n";

@Component({
  components: {
    HeaderButtons,
    StatisticsCreatedReportsChart,
    StatisticsLicensesChart,
    LastReports,
  },
})
export default class StatisticsStatisticView extends Vue {
  buttons: Array<HeaderButtonsInterface> = [
    {
      text: filterCapitalize(i18n.t("actions.createReport")),
      color: "white",
      action: () => {
        this.$router.push({ name: ROUTE_NAMES.REPORTS.GENERATED });
      },
      class: "btn main-light",
      disabled: false,
      align: "left",
      width: 150,
    },
    {
      text: filterCapitalize(i18n.t("actions.buyLicense")),
      color: "black",
      action: () => {
        this.$router.push({ name: ROUTE_NAMES.LICENSES.BUY });
      },
      class: "btn main-light--reverse",
      disabled: false,
      align: "left",
      width: 150,
    },
  ];

  get isLoading(): boolean {
    return this.$store.direct.getters.statistic.isLoading;
  }

  async beforeCreate() {
    await this.$store.direct.dispatch.statistic.getChartsData();
  }
}
</script>

<style lang="scss" scoped></style>
