<template>
  <div class="rc-container">
    <div class="rc-container__text">
      <div class="d-flex flex-column">
        <div>
          <div class="text-h3 text-md-h2 font-weight-bold">
            Co
            <text-mark :width="15" :height="15" :tx="7" :ty="3" item-class="text-h3 text-md-h2 font-weight-bold"
              >zawiera</text-mark
            >
            raport
          </div>
          <div class="text-h3 text-md-h2 font-weight-bold">sukcesyjny?</div>
        </div>
        <div class="items-group mt-10">
          <report-contains-item v-for="item in items" :key="item.id" :item="item" />
        </div>
        <div class="buttons-group mt-10">
          <btn type="white" upper-text="Chcesz wiedzieć więcej?" @click="redirectToFaq">Przeczytaj FAQ</btn>
          <btn type="black" @click="$store.direct.dispatch.user.getReportDemo()">Raport demo</btn>
        </div>
      </div>
    </div>
    <div class="rc-container__image-wrapper">
      <div class="rc-container__image-item">
        <img src="@/assets/images/SP_P1.png" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

import Btn from "@/components/shared/buttons/Btn.vue";
import TextMark from "@/components/shared/layout/TextMark.vue";
import ReportContainsItem, {
  IReportContainsItem,
} from "@/components/anonymous/startPage/reportContains/ReportContainsItem.vue";
import { CustomVueRouter } from "../../../../plugins/router";

@Component({
  components: {
    Btn,
    TextMark,
    ReportContainsItem,
  },
})
export default class ReportContains extends Vue {
  items: Array<IReportContainsItem> = [
    {
      id: 1,
      text: "Opis aktualnej sytuacji biznesowej i rodzinnej,",
    },
    {
      id: 2,
      text: "Scenariusze krótkoterminowe (na wypadek nagłej śmieci właściciela),",
    },
    {
      id: 3,
      text: "Scenariusze długoterminowe (planowane odejście z firmy),",
    },
    {
      id: 4,
      text: "Zagrożenia i ryzyka związane z obecną sytuacją,",
    },
    {
      id: 5,
      text: "Rekomendacje usprawniające przeprowadzenie procesu przekazania majątku,",
    },
    {
      id: 6,
      text: "Możliwości zabezpieczenia środków na wypadek spłaty spadkobierców i wierzycieli,",
    },
    {
      id: 7,
      text: "Przewodnik spadkowy dla Ciebie, Twoich bliskich i partnerów biznesowych.",
    },
  ];

  redirectToFaq() {
    var href = this.$router.resolve("/faq").href;
    (this.$router as CustomVueRouter).redirectToPage(href);
  }
}
</script>

<style lang="scss" scoped>
.rc-container {
  display: flex;
  margin-top: 100px;
  margin-bottom: 50px;
  @media screen and (max-width: 1292px) {
    flex-direction: column;
    align-items: center;
    .rc-container__text {
      margin: 0;
    }
    .rc-container__image-item {
      top: -100px;
      left: -450px;
      img {
        height: 600px;
      }
    }
  }
  @media screen and (max-width: 540px) {
    margin-top: 60px;
    .rc-container__image-item {
      top: -50px;
      left: -400px;
      img {
        height: 500px;
      }
    }
    .rc-container__image-wrapper {
      height: 420px;
    }
  }
  @media screen and (max-width: 440px) {
    margin-top: 30px;
    .rc-container__image-item {
      top: -30px;
      left: -330px;
      img {
        height: 400px;
      }
    }
    .rc-container__image-wrapper {
      height: 300px;
    }
  }
}

.rc-container__text {
  width: 600px;
  max-width: 100%;
  margin-left: 200px;
  z-index: 5;
}

.rc-container__image-wrapper {
  position: relative;
  height: 450px;
}

.rc-container__image-item {
  position: absolute;
  top: -150px;
  left: -250px;
  img {
    height: 700px;
  }
}

.buttons-group {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
}

.paragraphs {
  max-width: 650px;
  padding: 2% 0;
  text-align: justify;
}
</style>
