<template>
  <div>
    <p></p>
    <p class="text-body-1 font-weight-bold">{{ item.header }}</p>
    <p class="text-body-1" v-html="getText(item)"></p>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";

//Interface
import {
  IFrequentlyAskedQuestionsItem,
  IFrequentlyAskedQuestionsItemParam,
} from "../../models/components/faq/frequentlyAskedQuestions";

@Component
export default class FrequentlyAskedQuestionsItem extends Vue {
  @Prop({ required: true })
  item!: IFrequentlyAskedQuestionsItem;

  getText(item: IFrequentlyAskedQuestionsItem): string {
    if (typeof item.params === "object" && item.params != null) {
      Object.entries(item.params).forEach(([key, object]) => {
        if (typeof object.text === "string" || typeof object.text === "number") {
          if (item.text.includes("{" + key + "}")) {
            if (object.type === "string") {
              item.text = item.text.replace("{" + key + "}", object.text.toString());
            } else if (object.type === "url" && object.url) {
              item.text = item.text.replace("{" + key + "}", this.addUrl(object.text, object.url));
            } else if (object.type === "path" && object.path) {
              item.text = item.text.replace("{" + key + "}", this.addUrl(object.text, object.path));
            } else if (object.type === "action" && object.action) {
              item.text = item.text.replace("{" + key + "}", this.addButton(object));
            }
          }
        }
      });
    }
    return item.text;
  }

  addUrl(text: string, url: string) {
    return `<a href="${url}">${text}</a>`;
  }

  addPath(text: string, path: string) {
    const href = this.$router.resolve(path).href;
    return `<a href="${href}">${text}</a>`;
  }

  addButton(object: IFrequentlyAskedQuestionsItemParam) {
    return `<button id="bt${object.id}" class="button-link" type"button">${object.text}</button>`;
  }

  addbuttonEventListeners() {
    const item = this.item;
    if (typeof item.params === "object" && this.item.params != null) {
      // eslint-disable-next-line
      Object.entries(item.params).forEach(([key, object]) => {
        if (typeof object.text === "string" || typeof object.text === "number") {
          if (object.type === "action" && object.action) {
            const element = document.getElementById(`bt${object.id}`);
            element?.addEventListener("click", object.action);
          }
        }
      });
    }
  }

  mounted() {
    this.addbuttonEventListeners();
  }
}
</script>

<style lang="scss" scoped>
p + p {
  margin-bottom: 0;
}
</style>
