import { render, staticRenderFns } from "./IntCriterion.vue?vue&type=template&id=d87b2294&scoped=true"
import script from "./IntCriterion.vue?vue&type=script&lang=ts"
export * from "./IntCriterion.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d87b2294",
  null
  
)

export default component.exports