<template>
  <div class="period-container">
    <div class="input-date" :style="`width: ${width}px;`">
      <input-date
        :key="_criterion.value.from"
        v-model="_criterion.value.from"
        :close-on-day="true"
        :label="_criterion.label + ' ' + $t('criterion.from')"
        :solo="true"
      />
    </div>
    <div class="input-date" :style="`width: ${width}px`">
      <input-date
        :key="_criterion.value.to"
        v-model="_criterion.value.to"
        :close-on-day="true"
        :label="_criterion.label + ' ' + $t('criterion.to')"
        :solo="true"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, PropSync, Prop } from "vue-property-decorator";

//Components
import InputDate from "@/components/shared/inputs/InputDate.vue";

//Models
import { CriterionBase } from "@/models/api/filters/CriterionBase";

export interface PeriodCriterionSettings extends CriterionBase {
  value: PeriodCriterionValue;
}

export interface PeriodCriterionValue {
  from: string;
  to?: string;
}

@Component({
  components: {
    InputDate,
  },
})
export default class PeriodCriterion extends Vue {
  @PropSync("criterion")
  _criterion!: PeriodCriterionSettings;
  @Prop({ default: 150 })
  width!: number;
}
</script>

<style lang="scss" scoped>
.period-container {
  display: flex;
  gap: 10px;
}
</style>
