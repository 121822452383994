import { IPrivacyPolicy, IPrivacyPolicyParagraph } from "@/models/components/privacyPolicy/privacyPolicy";

const paragraph1: IPrivacyPolicyParagraph = {
  id: "1",
  title: "Wprowadzenie",
  items: [
    {
      id: "1.1",
      text: "FollowUp sp. z o. o. wpisaną do KRS pod nr 0000386913, NIP: 676-244-14-67 (dalej FU) przykłada dużą wagę do Twojej prywatności. Czujemy się szczególnie odpowiedzialni za bezpieczeństwo danych osobowych przetwarzanych przez nas w związku z naszą działalnością. Naszym celem jest również należyte informowanie Państwa o sprawach związanych z przetwarzaniem danych osobowych, szczególnie wobec treści nowych przepisów o ochronie danych osobowych, w tym rozporządzenia Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27.04.2016r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE („RODO”). Z tego względu, w niniejszym dokumencie informujemy o podstawach prawnych przetwarzania  danych osobowych, sposobach ich zbierania i wykorzystywania, a także o prawach podmiotów danych z tym związanych.",
    },
  ],
};

const paragraph2: IPrivacyPolicyParagraph = {
  id: "2",
  title: "Czym są dane osobowe i co oznacza ich przetwarzanie?",
  items: [
    {
      id: "2.1",
      text: "Dane osobowe oznaczają informacje o zidentyfikowanej lub możliwej do zidentyfikowania osobie fizycznej. Przetwarzaniem danych osobowych jest w zasadzie każda czynność na danych osobowych, niezależnie od tego, czy jest dokonywana w sposób zautomatyzowany czy nie, np. zbieranie, przechowywanie, utrwalanie, porządkowanie, modyfikowanie, przeglądanie, wykorzystywanie, udostępnianie, ograniczanie, usuwanie lub niszczenie. FU przetwarza dane osobowe w różnych celach, przy czym w zależności od celu, zastosowanie mogą mieć różne sposoby zbierania, podstawy prawne przetwarzania, wykorzystywania, ujawniania oraz okresy przechowywania.",
    },
  ],
};

const paragraph3: IPrivacyPolicyParagraph = {
  id: "3",
  title: "Kiedy niniejsza Polityka prywatności ma zastosowanie?",
  items: [
    {
      id: "3.1",
      text: "Niniejsza polityka prywatności ma zastosowanie do wszystkich przypadków, w których FU jest administratorem danych osobowych i przetwarza dane osobowe. Dotyczy to zarówno przypadków, w których FU przetwarza dane osobowe pozyskane bezpośrednio od osoby, której dane dotyczą, jak i przypadków, w których dane osobowe pozyskaliśmy z innych źródeł. FU realizuje swoje obowiązki informacyjne w obu powyższych przypadkach, określone odpowiednio w art. 13 i art. 14 RODO zgodnie z tymi przepisami. Poniżej wskazujemy pełne dane FU jako administratora danych osobowych:",
      items: [
        {
          id: "3.1.1",
          text: "FollowUp Sp. z o.o., Kielnarowa 108B, 36-020 Tyczyn, NIP: 676-244-14-67, REGON: 121536103.",
        },
      ],
    },
  ],
};

const paragraph4: IPrivacyPolicyParagraph = {
  id: "4",
  title: "W jaki sposób, w oparciu o jakie podstawy prawne i jakiego typu dane osobowe przetwarza FU?",
  items: [
    {
      id: "4.1",
      text: "Pragniemy zachować przejrzystość w zakresie sposobów i podstaw prawnych przetwarzania danych osobowych, a także celów dla których FU przetwarza dane osobowe. Dbamy o to, by każdorazowo wskazać niezbędne informacje w tym zakresie każdej osobie, której dane osobowe przetwarzamy jako administrator danych. Kierując się tym, by nasze wyjaśnienie tych kwestii było jak najbardziej czytelne, przedstawiamy poniższe zestawienie operacji przetwarzania danych osobowych.",
    },
    {
      id: "4.2",
      text: "Jednocześnie pragniemy wskazać, że ilekroć przetwarzamy dane osobowe na podstawie prawnie uzasadnionego interesu administratora danych, staramy się przeanalizować i zrównoważyć nasz interes oraz potencjalny wpływ na osobę, której dane dotyczą (pozytywny, jak i negatywny) oraz prawa tej osoby wynikające z przepisów o ochronie danych osobowych. Nie przetwarzamy danych osobowych w oparciu o nasz uzasadniony interes w przypadku, gdy dojdziemy do wniosku że wpływ na osobę, której dane dotyczą przeważałby nad naszymi interesami (wówczas możemy przetwarzać dane osobowe jeśli np. posiadamy odpowiednią zgodę lub wymagają tego lub zezwalają na to przepisy prawa).",
    },
  ],
};

const paragraph5: IPrivacyPolicyParagraph = {
  id: "5",
  title: "Informacje ogólne",
  items: [
    {
      id: "5.1",
      text: "Osoby fizyczne odwiedzające strony internetowe FU lub korzystające z usług świadczonych przez FU drogą elektroniczną, które nazywamy dalej łącznie „Serwisami”, mają kontrolę nad danymi osobowymi, które nam podają. Serwisy ograniczają zbieranie i wykorzystywanie informacji o ich użytkownikach do niezbędnego minimum, wymaganego do świadczenia na ich rzecz usług na pożądanym poziomie, stosownie do art. 18 ustawy z dnia 18 lipca 2002 r. o świadczeniu usług drogą elektroniczną.",
    },
  ],
};

const paragraph6: IPrivacyPolicyParagraph = {
  id: "6",
  title: "Cookies",
  items: [
    {
      id: "6.1",
      text: "W ograniczonym zakresie możemy zbierać dane osobowe automatycznie za pośrednictwem plików cookies znajdujących się na naszych stronach internetowych. Cookies to małe pliki tekstowe zapisywane na komputerze użytkownika lub innym urządzeniu mobilnym, w czasie korzystania przez niego z serwisów internetowych. Pliki te służą m.in. korzystaniu z różnych funkcji przewidzianych na danej stronie internetowej lub potwierdzeniu, że dany użytkownik widział określone treści z danej witryny internetowej. Wśród plików cookies wyróżnić możne te, które są niezbędne do działania serwisów FU. Należące do tej kategorii pliki cookies wykorzystywane są, aby zapewnić:",
      items: [
        {
          id: "6.1.1",
          text: "utrzymanie sesji użytkownika,",
        },
        {
          id: "6.1.2",
          text: "zapisanie stanu sesji użytkownika,",
        },
        {
          id: "6.1.3",
          text: "itp.",
        },
      ],
    },
    {
      id: "6.2",
      text: "Kolejna kategoria plików cookies, to pliki, które wprawdzie nie są niezbędne do korzystania z Serwisów, ale ułatwiają korzystanie z nich. Wykorzystuje się je, aby umożliwić:",
      items: [
        {
          id: "6.2.1",
          text: "przywrócenie ostatnio odwiedzanego widoku,",
        },
        {
          id: "6.2.2",
          text: "zapamiętanie wyboru użytkownika w kwestii zaprzestania wyświetlania wybranego komunikatu lub wyświetlenia go określoną liczbę razy,",
        },
        {
          id: "6.2.3",
          text: "przywrócenie sesji użytkownika,",
        },
        {
          id: "6.2.4",
          text: "sprawdzenie czy zapis do plików cookies funkcjonuje prawidłowo,",
        },
        {
          id: "6.2.5",
          text: "umożliwienie automatycznego zalogowania do produktu (opcja „zapamiętaj mnie”),",
        },
        {
          id: "6.2.6",
          text: "dopasowanie zawartości produktów do preferencji użytkowników,",
        },
        {
          id: "6.2.7",
          text: "ustawienie preferowanego języka, waluty, rozmiaru czcionki i innych tego typu właściwości ułatwiających pracę z aplikacją,",
        },
        {
          id: "6.2.8",
          text: "wyświetlenie ostatnio wybranego parametru sortowania.",
        },
      ],
    },
    {
      id: "6.3",
      text: "Wśród podmiotów tych w dacie sporządzenia niniejszego dokumentu zaliczamy:",
      items: [
        {
          id: "6.3.1",
          text: "monitorowanie ruchu na naszych stronach WWW,",
        },
        {
          id: "6.3.2",
          text: "zbieranie anonimowych, zbiorczych statystyk, które pozwalają nam zrozumieć, w jaki sposób użytkownicy korzystają z naszej strony internetowej i umożliwiają nam ciągłe doskonalenie naszych produktów,",
        },
        {
          id: "6.3.3",
          text: "ustalanie liczby anonimowych użytkowników naszych stron WWW. Potrzebne do analizy korzystania z serwisów FU,",
        },
        {
          id: "6.3.4",
          text: "kontrolowanie jak często pokazywana jest użytkownikom wybrana treść,",
        },
        {
          id: "6.3.5",
          text: "kontrolowanie jak często użytkownicy wybierają daną usługę,",
        },
        {
          id: "6.3.6",
          text: "wykorzystanie narzędzia do komunikacji.",
        },
      ],
    },
    {
      id: "6.4",
      text: "Wśród podmiotów tych w dacie sporządzenia niniejszego dokumentu zaliczamy:",
      items: [
        {
          id: "6.4.1",
          text: "Google Analytics (więcej informacji oraz dodatek do przeglądarki blokujący Google Analytics: tools.google.com).",
        },
      ],
    },
    {
      id: "6.5",
      text: "Jednocześnie informujemy również, iż niektóre elementy serwisów FU obsługują zewnętrzni reklamodawcy umieszczający reklamy behawioralne na stronach WWW FU. Zawartości pochodzące od zewnętrznych dostawców, mogą zawierać pliki cookies innych podmiotów, w związku z czym zaleca się aby użytkownik zapoznał się z zasadami wykorzystywania tych plików cookies do obsługi witryn internetowych przez tych dostawców zewnętrznych.",
    },
    {
      id: "6.6",
      text: "Użytkownik może zarządzać plikami cookies wykorzystywanymi przez FU lub przez jakichkolwiek innych zewnętrznych dostawców, zmieniając ustawienia swojej przeglądarki internetowej. FU zastrzega jednocześnie, że po odrzuceniu plików cookies niektóre funkcje oferowane przez nasze Serwisy mogą nie działać prawidłowo, a nawet w niektórych przypadkach wiąże się to z całkowitym uniemożliwieniem korzystania z wybranego produktu.",
    },
  ],
};

const paragraph7: IPrivacyPolicyParagraph = {
  id: "7",
  title: "Logi dostępowe",
  items: [
    {
      id: "7.1",
      text: "Zbieramy informacje dotyczące korzystania z Serwisów przez jego użytkowników oraz ich adresy IP w oparciu o analizę logów dostępowych. Informacje te wykorzystujemy w diagnozowaniu problemów związanych z pracą serwera, analizą ewentualnych naruszeń bezpieczeństwa oraz w zarządzaniu stroną WWW. Adres IP jest wykorzystywany przez nas nadto w celach statystycznych, tj. do gromadzenia i analizy danych demograficznych osób odwiedzających witrynę (np. informacji o regionie, z którego nastąpiło połączenie). Na podstawie uzyskanych w podany wyżej sposób informacji w szczególnych przypadkach sporządzane są zbiorcze, ogólne zestawienia statystyczne, ujawniane osobom trzecim, współpracującym z nami. Obejmują one zwykle informacje o oglądalności serwisu. Zestawienia te nie zawierają jednak, co podkreślamy, żadnych danych pozwalających na identyfikację (określenie tożsamości) danego użytkownika Serwisu.",
    },
    {
      id: "7.2",
      text: "Informujemy jednocześnie, że możemy zostać zobowiązani do ujawnienia informacji dotyczących numeru IP danego użytkownika Serwisu na żądanie uprawnionych do tego – w oparciu obowiązujące przepisy prawne – organów państwa w związku z prowadzonymi przez nie postępowaniami.",
    },
  ],
};

const paragraph8: IPrivacyPolicyParagraph = {
  id: "8",
  title: "Podstawy prawne przetwarzania",
  items: [
    {
      id: "8.1",
      text: "W przypadku przetwarzania danych osobowych w związku z korzystaniem przez użytkowników z Serwisów FU możemy mieć do czynienia z różnymi rodzajami podstaw prawnych przetwarzania, w zależności od kategorii danych osobowych, które przetwarzamy i celu przetwarzania. I tak na przykład:",
      items: [
        {
          id: "8.1.1",
          text: "dane osobowe osób odwiedzających nasze strony internetowe przetwarzamy na podstawie prawnie uzasadnionego interesu administratora danych, np. dopasowując wyświetlane reklamy, lub na podstawie zgody w przypadku, gdy o wyrażenie takiej zgody zwróciliśmy się do osoby, której dane dotyczą,",
        },
        {
          id: "8.1.2",
          text: "dane osobowe osób, które korzystają z naszych serwisów internetowych i wypełniają w tym celu formularz internetowy przetwarzamy, ponieważ jest to niezbędne dla celu kontaktu,",
        },
        {
          id: "8.1.3",
          text: "niekiedy przepisy prawa wymagają od nas przetwarzania pewnych danych osobowych dla celów podatkowych i rachunkowych,",
        },
      ],
    },
    {
      id: "8.2",
      text: "Poza danymi, o których była mowa w poprzedniej powyżej   Przetwarzanie danych osobowych osób odwiedzających strony internetowe prowadzone przez FU lub korzystających z usług świadczonych drogą elektroniczną (np. internetowych serwisów zakupowych), od osób fizycznych, które kontaktują się z FU w celu uzyskania informacji o ofercie lub podzielenia się uwagami dotyczącymi usług lub produktów FU, a także kontaktujących się w celu zawarcia umowy z FU, zbieramy następujące dane osobowe: imię i nazwisko, stanowisko, adres firmy, adres e-mail oraz numer telefonu/ faksu. W szczególności, osoby te mogą wysłać nam e-maila za pośrednictwem strony internetowej. Wiadomości takie zawierają ustawioną nazwę użytkownika i adres e-mail, oraz dodatkowe informacje, jakie użytkownik chce zawrzeć w wiadomości.",
    },
    {
      id: "8.3",
      text: "Zwracamy się z prośbą o niepodawanie za pośrednictwem stron internetowych informacji ze szczególnych kategorii danych osobowych (takich jak informacje o rasie czy pochodzeniu etnicznym, poglądach politycznych, przekonaniach religijnych lub filozoficznych, przynależności do związków zawodowych, informacje dotyczące zdrowia fizycznego lub psychicznego, dane genetyczne, dane biometryczne, informacje o życiu seksualnym czy orientacji seksualnej oraz przeszłości kryminalnej). W przypadku podania takich informacji z jakiegokolwiek powodu, będzie to oznaczać wyraźną zgodę na zbieranie przez nas i wykorzystywanie takich informacji w sposób określony w niniejszym dokumencie lub określony w miejscu, gdzie informacje te zostały ujawnione.",
    },
  ],
};

const paragraph9: IPrivacyPolicyParagraph = {
  id: "9",
  title: "Podstawy prawne przetwarzania",
  items: [
    {
      id: "9.1",
      text: "Powyższe dane zbieramy w oparciu o zgodę wyrażoną przez użytkownika kierującego powyższą prośbę do FU lub w celu wykonania umowy (realizacji żądania) zgłoszonego przez daną osobę. Dostarczone dane możemy przetwarzać również na podstawie usprawiedliwionego celu administratora danych.",
    },
  ],
};

const paragraph10: IPrivacyPolicyParagraph = {
  id: "10",
  title: "Przetwarzanie danych osobowych klientów FU i potencjalnych klientów",
  items: [
    {
      id: "10.1",
      text: "FU przetwarza dane osobowe swoich klientów i potencjalnych klientów. Wśród tych danych mogą znajdować się również dane osobowe osób związanych z klientami FU niebędącymi osobami fizycznymi (np. osoby kontaktowe). Dane osobowe tego rodzaju są przetwarzane w systemach informatycznych, którymi posługuje się FU. Dane osobowe przetwarzane na te potrzeby obejmują między innymi: imię i nazwisko, nazwę pracodawcy, stanowisko osoby kontaktowej, numer telefonu, adres e-mail lub inne służbowe dane kontaktowe. Nadto, w przypadku klientów, którzy zawarli z FU umowy odpłatne, przetwarzamy również dane dotyczące płatności, w tym numery kart kredytowych, numery rachunków bankowych.",
    },
  ],
};

const paragraph11: IPrivacyPolicyParagraph = {
  id: "11",
  title: "Podstawy prawne przetwarzania",
  items: [
    {
      id: "11.1",
      text: "Przetwarzanie danych osobowych osób fizycznych będących naszymi klientami opiera się na:",
      items: [
        {
          id: "11.1.1",
          text: "usprawiedliwionym interesie FU jako administratora danych (np. w zakresie tworzenia bazy danych, czynności analitycznych, w tym czynności dot. analizy korzystania z produktów, marketingu bezpośredniego produktów własnych, zabezpieczeniu dokumentacji na potrzeby obrony przed ewentualnymi roszczeniami lub na potrzeby dochodzenia roszczeń),",
        },
        {
          id: "11.1.2",
          text: "zgody (w tym w szczególności zgody na e-mail marketing lub telemarketing),",
        },
        {
          id: "11.1.3",
          text: "wykonania zawartej umowy,",
        },
        {
          id: "11.1.4",
          text: "obowiązków wynikających z prawa (np. prawa podatkowego lub przepisów o rachunkowości).",
        },
      ],
    },
    {
      id: "11.2",
      text: "Przetwarzanie danych osobowych osób fizycznych będących potencjalnymi klientami opiera się na",
      items: [
        {
          id: "11.2.1",
          text: "usprawiedliwionym interesie FU jako administratora danych (np. w zakresie tworzenia bazy danych, marketingu bezpośrednim produktów własnych),",
        },
        {
          id: "11.2.2",
          text: "zgody (w tym w szczególności zgody na e-mail marketing lub telemarketing).",
        },
      ],
    },
  ],
};

const paragraph12: IPrivacyPolicyParagraph = {
  id: "12",
  title: "Jak długo przetwarzamy dane osobowe?",
  items: [
    {
      id: "12.1",
      text: "Czas, przez jaki możemy przetwarzać dane osobowe, jest uzależniony od podstawy prawnej stanowiącej legalną przesłankę przetwarzania danych osobowych przez FU. W obowiązujących w FU politykach, w tym politykach dotyczących retencji danych, określamy, iż nigdy nie wolno nam przetwarzać danych osobowych ponad okres dłuższy niż wynika to z ww. podstaw prawnych. Stosownie do tego informujemy, że:",
      items: [
        {
          id: "12.1.1",
          text: "usprawiedliwionym interesie FU jako administratora danych (np. w zakresie tworzenia bazy danych, czynności analitycznych, w tym czynności dot. analizy korzystania z produktów, marketingu bezpośredniego produktów własnych, zabezpieczeniu dokumentacji na potrzeby obrony przed ewentualnymi roszczeniami lub na potrzeby dochodzenia roszczeń),",
        },
        {
          id: "12.1.2",
          text: "w przypadku, gdy FU przetwarza dane osobowe na podstawie uzasadnionego interesu administratora danych, okres przetwarzania trwa do momentu ustania ww. interesu (np. okres przedawnienia roszczeń cywilnoprawnych) lub do momentu sprzeciwienia się osoby, której dane dotyczą, dalszemu takiemu przetwarzaniu – w sytuacjach, gdy sprzeciw taki zgodnie z przepisami prawa przysługuje,",
        },
        {
          id: "12.1.3",
          text: "przypadku, gdy FU przetwarza dane osobowe, ponieważ jest to konieczne z uwagi na obowiązujące przepisy prawa, okresy przetwarzania danych w tym celu określają te przepisy (jest to na przykład obowiązana zgodnie z przepisami prawa do przechowywania Danych osobowych Użytkownika przez dłuższy okres lub jeśli Dane osobowe Użytkownika są potrzebne do wniesienia zarzutów prawnych lub podjęcia obrony przed zarzutami prawnymi, zachowamy Dane osobowe Użytkownika do zakończenia odpowiedniego okresu przechowywania lub do momentu rozstrzygnięcia przedmiotowych zarzutów,",
        },
        {
          id: "12.1.4",
          text: "w przypadku braku konkretnych wymogów prawnych lub umownych, podstawowy okres przechowywania danych w przypadku zapisów i innej dokumentacji dowodowej sporządzonej w trakcie wykonywania umowy maksymalnie 10 lat.",
        },
      ],
    },
  ],
};

const paragraph13: IPrivacyPolicyParagraph = {
  id: "13",
  title: "Kiedy i w jaki sposób udostępniamy dane osobowe podmiotom trzecim? Czy przekazujemy dane do państw trzecich?",
  items: [
    {
      id: "13.1",
      text: "Przekazujemy dane osobowe innym wyłącznie gdy zezwalają nam na to przepisy prawa. W takim przypadku, w stosownej umowie zawieranej z podmiotem trzecim przewidujemy postanowienia i mechanizmy bezpieczeństwa w celu ochrony danych oraz zachowania naszych standardów w zakresie ochrony danych, ich poufności i bezpieczeństwa. Umowy tego rodzaju zwane są umowami powierzenia przetwarzania danych osobowych, a FU ma kontrolę nad tym, w jaki sposób i w jakim zakresie podmiot, któremu FU powierzyła przetwarzanie określonych kategorii danych osobowych dane te przetwarza. W związku z powyższym wskazujemy, że odbiorcami danych osobowych, które FU przetwarza jako administrator danych osobowych mogą być:",
      items: [
        {
          id: "13.1.1",
          text: "ww. podmioty przetwarzające dane osobowe na mocy umów powierzenia przetwarzania danych osobowych (tzw. processorzy),",
        },
        {
          id: "13.1.2",
          text: "podmioty świadczące usługi hostingowe na rzecz FU,",
        },
        {
          id: "13.1.3",
          text: "podmioty realizujące kampanie marketingowe lub sprzedażowe na rzecz FU,",
        },
        {
          id: "13.1.4",
          text: "pozostali podwykonawcy FU, świadczący usługi z zakresu dostarczania oprogramowania, usługi serwisowania oprogramowania lub sprzętu, z którego korzysta FU, a także dostawcy towarów, z pomocy których korzystamy,",
        },
        {
          id: "13.1.5",
          text: "kancelarie prawne (z tym zastrzeżeniem, że dane osobowe przekazujemy tylko w takim zakresie, w jakim jest to rzeczywiście niezbędne dla osiągnięcia danego celu),",
        },
        {
          id: "13.1.6",
          text: "audytorzy i biegli rewidenci, doradcy prawni, doradcy podatkowi,",
        },
        {
          id: "13.1.7",
          text: "organy nadzorujące przestrzeganie prawa, organy regulacyjne i inne organy administracji publicznej.",
        },
      ],
    },
    {
      id: "13.2",
      text: "(przy czym w dwóch ostatnich przypadkach dane przekazujemy tylko wówczas i tylko w takim zakresie, w jakim jest to rzeczywiście niezbędne i wymagane w myśl bezwzględnie obowiązujących przepisów prawa i w sposób zgodny z tymi przepisami).",
    },
    {
      id: "13.3",
      text: "W przypadku danych osobowych podlegających przepisom UE należy zauważyć, że: transgraniczne przekazywanie może dotyczyć państw nienależących do Europejskiego Obszaru Gospodarczego („EOG”) i państw, w których nie obowiązują przepisy określające szczególną ochronę danych osobowych. Podjęliśmy działania, aby zapewnić odpowiednią ochronę wszystkich danych osobowych oraz zgodność z prawem przekazywania danych osobowych poza teren EOG. W przypadku przekazywania danych osobowych poza teren EOG do państwa, które według Komisji Europejskiej nie zapewnia odpowiedniego poziomu ochrony danych osobowych, przekazywanie odbywa się wyłącznie na podstawie umowy uwzględniającej wymogi UE w zakresie przekazywania danych osobowych poza teren EOG.",
    },
  ],
};

const paragraph14: IPrivacyPolicyParagraph = {
  id: "14",
  title: "Jakie prawa przysługują podmiotom, których dane dotyczą i jaki jest sposób ich realizacji?",
  items: [
    {
      id: "14.1",
      text: "Osoby fizyczne posiadają określone prawa dotyczące swoich danych osobowych, a FU jako administrator danych odpowiada za realizację tych praw zgodnie z obowiązującymi przepisami prawa. W przypadku wszelkich pytań i próśb dotyczących zakresu i realizacji praw, a także w celu skontaktowania się z nami właśnie w celu skorzystania z określonego uprawnienia w zakresie ochrony danych osobowych prosimy o kontakt pod adresem e-mail: info@followup.pl. Zastrzegamy sobie prawo realizacji poniższych uprawnień po pozytywnym zweryfikowaniu tożsamości osoby ubiegającej się o dokonanie danej czynności.",
    },
  ],
};

const paragraph15: IPrivacyPolicyParagraph = {
  id: "15",
  title: "Dostęp do danych osobowych",
  items: [
    {
      id: "15.1",
      text: "Osoby fizyczne mają prawo dostępu do danych, które przechowujemy jako administrator danych. Prawo to można wykonywać poprzez wysłanie e-maila na adres: info@followup.pl.",
    },
  ],
};

const paragraph16: IPrivacyPolicyParagraph = {
  id: "16",
  title: "Zmiana danych osobowych",
  items: [
    {
      id: "16.1",
      text: "Zmiany, w tym zaktualizowana swoich danych osobowych, które przetwarza FU można dokonać poprzez wysłanie maila na adres e-mai: info@followup.pl lub – w odpowiednich przypadkach – skontaktowanie się z nami za pośrednictwem odpowiedniej strony rejestracji lub zmianę danych osobowych przechowywanych w odpowiednich aplikacjach, w których dokonano rejestracji.",
    },
  ],
};

const paragraph17: IPrivacyPolicyParagraph = {
  id: "17",
  title: "Wycofanie zgody",
  items: [
    {
      id: "17.1",
      text: "W przypadku przetwarzania danych osobowych na podstawie zgody, osoby fizyczne mają prawo w dowolnej chwili wycofać tę zgodę. Informujemy o tym prawie w każdym momencie zbierania zgód i umożliwiamy wycofanie zgody w tak łatwy sposób, jak jej udzielono. W braku odmiennej informacji, to znaczy jeśli nie podaliśmy innego adresu czy numeru kontaktowego celem wycofania zgody, prosimy o przesłanie do nas maila na adres info@followup.pl",
    },
  ],
};

const paragraph18: IPrivacyPolicyParagraph = {
  id: "18",
  title: "Prawo do ograniczenia przetwarzania lub wniesienia sprzeciwu wobec przetwarzania danych osobowych",
  items: [
    {
      id: "18.1",
      text: "Osoby fizyczne mają prawo ograniczenia przetwarzania lub wniesienia sprzeciwu wobec przetwarzania swoich danych osobowych w dowolnej chwili, ze względu na ich szczególną sytuację, chyba że przetwarzanie jest wymagane zgodnie z przepisami prawa.",
    },
    {
      id: "18.2",
      text: "Osoba fizyczna może wnieść sprzeciw wobec przetwarzania jej danych osobowych, gdy:",
      items: [
        {
          id: "18.2.1",
          text: "przetwarzanie danych osobowych odbywa się na podstawie prawnie uzasadnionego interesu lub dla celów statystycznych, a sprzeciw jest uzasadniony przez szczególną sytuację, w której się znalazła,",
        },
        {
          id: "18.2.2",
          text: "dane osobowe przetwarzane są na potrzeby marketingu bezpośredniego, w tym są profilowane dla tego celu.",
        },
      ],
    },
    {
      id: "18.3",
      text: "Z prawa sprzeciwu można skorzystać od 25 maja 2018 roku.",
    },
    {
      id: "18.4",
      text: "Z kolei w odniesieniu do żądania ograniczenia przetwarzania danych podpowiadamy, że przysługuje ono np. gdy dana osoba zauważy, że jej dane są nieprawidłowe. Wówczas może żądać ograniczenia przetwarzania Twoich danych na okres pozwalający nam sprawdzić prawidłowość tych danych.",
    },
  ],
};

const paragraph19: IPrivacyPolicyParagraph = {
  id: "19",
  title: "Pozostałe prawa: prawo żądania usunięcia danych i prawo do przenoszenia danych",
  items: [
    {
      id: "19.1",
      text: "W przypadku chęci realizacji tych praw, należy wysłać maila na adres: info@followup.pl.",
    },
    {
      id: "18.2",
      text: "Z prawa do usunięcia danych można skorzystać np. gdy dane osoby fizycznej nie będą już niezbędne do celów, dla których zostały zebrane przez FU albo gdy osoba fizyczna wycofa swoją zgodę na przetwarzanie danych przez FU. Nadto, w przypadku, gdy osoba fizyczna zgłosi sprzeciw wobec przetwarzania jej danych lub gdy jej dane będą przetwarzane niezgodnie z prawem. Dane powinny być również usunięte w celu wywiązania się z obowiązku wynikającego z przepisu prawa.",
    },
    {
      id: "18.3",
      text: "Z kolei prawo do przenoszenia danych przysługuje wówczas, gdy przetwarzanie danych osoby odbywa się na podstawie zgody osoby fizycznej lub umowy zawartej z nią oraz, gdy przetwarzanie to odbywa się w sposób automatyczny.",
    },
  ],
};

const paragraph20: IPrivacyPolicyParagraph = {
  id: "20",
  title: "Pozostałe prawa: prawo żądania usunięcia danych i prawo do przenoszenia danych",
  items: [
    {
      id: "20.1",
      text: "W razie, gdybyście mieli Państwo jakiekolwiek pytania, zastrzeżenia lub wątpliwości dotyczące treści niniejszej Polityki prywatności lub sposobu w jaki przetwarzamy dane osobowe, jak również skargi dotyczące tych kwestii (choć wyrażamy nadzieję, że nie będzie konieczności wniesienia tego rodzaju skarg), prosimy o przesłanie maila wraz ze szczegółowymi informacjami dotyczącymi skargi na adres info@followup.pl. Wszelkie otrzymane skargi zostaną rozpatrzone i udzielimy na nie odpowiedzi.",
    },
    {
      id: "20.2",
      text: "Osoby, których dane osobowe przetwarza FU, mają również prawo wnieść skargę do organu nadzorczego, którym jest Generalny Inspektor Ochrony Danych Osobowych (adres: Generalny Inspektor Ochrony Danych Osobowych, ul. Stawki 2, 00-193 Warszawa).",
    },
    {
      id: "20.3",
      text: "Naturalnie, nie ograniczamy kontaktu z nami i umożliwiamy kontakt bezpośredni w siedzibie FU, a także kontakt listowy (pocztą) lub kontakt telefoniczny i w tym celu podajemy poniższe dane kontaktowe:",
      items: [
        {
          id: "20.3.1",
          text: "FollowUp Sp. z o.o.,",
        },
        {
          id: "20.3.2",
          text: "Kielnarowa 108A,",
        },
        {
          id: "20.3.3",
          text: "36-020 Tyczyn,",
        },
        {
          id: "20.3.4",
          text: "tel. 17 779 64 13,",
        },
      ],
    },
  ],
};

const paragraph21: IPrivacyPolicyParagraph = {
  id: "21",
  title: "Czy i kiedy możliwe są zmiany niniejszej Polityki prywatności?",
  items: [
    {
      id: "21.1",
      text: "FU zapewnia iż dokonuje regularnego przeglądu niniejszej Polityki prywatności i jej zmiany w sytuacji, gdy okaże się to niezbędne lub pożądane z uwagi na: nowe przepisy prawa, nowe wytyczne organów odpowiedzialnych za nadzór nad procesami ochrony danych osobowych, najlepsze praktyki stosowane w obszarze ochrony danych osobowych (Kodeksy dobrych praktyk, jeśli FU będzie związana takimi Kodeksami, o czym wówczas będziemy informować). Zastrzegamy również możliwość zmiany niniejszej Polityki prywatności w przypadku zmian technologii, przy wykorzystaniu której przetwarzamy dane osobowe (o ile zmiana jej wpływa na brzmienie niniejszego dokumentu), a także w razie zmiany sposobów, celów lub podstaw prawnych przetwarzania przez nas danych osobowych.",
    },
  ],
};

export const paragraphs: IPrivacyPolicy = {
  items: [
    paragraph1,
    paragraph2,
    paragraph3,
    paragraph4,
    paragraph5,
    paragraph6,
    paragraph7,
    paragraph8,
    paragraph9,
    paragraph10,
    paragraph11,
    paragraph12,
    paragraph13,
    paragraph14,
    paragraph15,
    paragraph16,
    paragraph17,
    paragraph18,
    paragraph19,
    paragraph20,
    paragraph21,
  ],
};
