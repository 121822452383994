<template>
  <div>
    <p class="paragraph text-body-2 font-weight-medium">{{ item.paragraph }}</p>
    <div v-if="typeof item.text != 'undefined'">
      <p class="text-body-2">{{ item.text }}</p>
    </div>
    <terms-of-use-paragraph-list :item="item" />
    <p></p>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";
import TermsOfUseParagraphList from "@/components/termsOfUse/TermsOfUseParagraphList.vue";

import { ITermsOfUseParagraph } from "@/models/components/termsOfUse/termsOfUse";

@Component({
  components: {
    TermsOfUseParagraphList,
  },
})
export default class TermsOfUseParagraph extends Vue {
  @Prop({ required: true })
  item!: ITermsOfUseParagraph;
}
</script>

<style lang="scss" scoped>
.paragraph {
  text-align: center;
  margin-top: 50px;
  margin-bottom: 30px;
}
</style>
