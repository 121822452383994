<template>
  <div>
    <!-- use key to forceRerender component have to be unique - need when we validate inputs, rules are executed when get value -->
    <div v-if="extension != null" class="extension-base">
      <int-extension
        v-if="extension.extensionName === ExtensionType[ExtensionType.IntExtension]"
        :value.sync="extension"
      />
      <string-extension
        v-else-if="extension.extensionName === ExtensionType[ExtensionType.StringExtension]"
        :value.sync="extension"
      />
      <date-extension
        v-else-if="extension.extensionName === ExtensionType[ExtensionType.DateExtension]"
        :value.sync="extension"
      />
      <decimal-extension
        v-else-if="extension.extensionName === ExtensionType[ExtensionType.DecimalExtension]"
        :value.sync="extension"
      />
      <int-array-extension
        v-else-if="extension.extensionName === ExtensionType[ExtensionType.IntArrayExtension]"
        :value.sync="extension"
      />
      <decimal-array-extension
        v-else-if="extension.extensionName === ExtensionType[ExtensionType.DecimalArrayExtension]"
        :value.sync="extension"
      />
      <string-array-extension
        v-else-if="extension.extensionName === ExtensionType[ExtensionType.StringArrayExtension]"
        :value.sync="extension"
      />
      <!-- <v-menu z-index="9999" bottom
        left>
      <template #activator="{ on }">
        <v-text-field v-on="on"> </v-text-field>
      </template>
      <div>
        Hello
      </div>
    </v-menu> -->
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, PropSync } from "vue-property-decorator";

//Components
import IntExtension from "@/components/reports/workFlow/components/extensions/IntExtension.vue";
import StringExtension from "@/components/reports/workFlow/components/extensions/StringExtension.vue";
// import DateExtension from "@/components/reports/workFlow/components/extensions/DateExtension.vue";
import DecimalExtension from "@/components/reports/workFlow/components/extensions/DecimalExtension.vue";
import IntArrayExtension from "@/components/reports/workFlow/components/extensions/IntArrayExtension.vue";
import DecimalArrayExtension from "@/components/reports/workFlow/components/extensions/DecimalArrayExtension.vue";
import StringArrayExtension from "@/components/reports/workFlow/components/extensions/StringArrayExtension.vue";

//Models
import { ExtensionBase as Extension, ExtensionTypeEnum } from "@/models/api/reports/workFlow/BaseExtension";

@Component({
  components: {
    IntExtension,
    StringExtension,
    DateExtension: () => import("@/components/reports/workFlow/components/extensions/DateExtension.vue"),
    DecimalExtension,
    IntArrayExtension,
    DecimalArrayExtension,
    StringArrayExtension,
  },
})
export default class ExtensionBase extends Vue {
  @PropSync("value")
  extension!: Extension;

  ExtensionType = ExtensionTypeEnum;
}
</script>

<style lang="scss" scoped>
.extension-base {
  margin-left: 37px;
  width: auto;
}
</style>
