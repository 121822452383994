<template>
  <span style="width: 45.993px; height: 66.168px">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      width="40.993"
      height="66.186"
      viewBox="0 0 40.993 66.186"
    >
      <defs>
        <clipPath id="clip-path">
          <rect width="40.993" height="66.186" fill="currentColor" stroke="currentColor" stroke-width="1" />
        </clipPath>
      </defs>
      <g clip-path="url(#clip-path)">
        <path
          d="M62.88,474.391a14.761,14.761,0,0,1-20.044-13.974.919.919,0,0,0-.906-.931h-.013a.919.919,0,0,0-.919.907,16.59,16.59,0,0,0,16.364,16.813l.231,0a16.5,16.5,0,0,0,6.192-1.2.467.467,0,0,0,.261-.273.955.955,0,0,0-1.166-1.346"
          transform="translate(-37.23 -417.261)"
          fill="currentColor"
          stroke="currentColor"
          stroke-width="1"
        />
        <path
          d="M40.732,64.627l-8.654-8.891a.458.458,0,0,1,0-.644,16.589,16.589,0,0,0,.339-23.123l-.024-.025L14.329,13.38a.919.919,0,1,0-1.317,1.282L31.094,33.24l0,0a14.751,14.751,0,0,1-.288,20.52.46.46,0,0,1-.655,0L9.9,32.947l0,0A14.752,14.752,0,0,1,20.632,8.078h.2A14.769,14.769,0,0,1,35.385,23.03a.919.919,0,0,0,1.838.025A16.609,16.609,0,0,0,20.859,6.242l-.228,0a16.5,16.5,0,0,0-9.67,3.121.458.458,0,0,1-.6-.054L1.577.278A.919.919,0,1,0,.26,1.56l8.659,8.9a.459.459,0,0,1,0,.644,16.575,16.575,0,0,0-.344,23.117l.024.025,30.82,31.665a.919.919,0,1,0,1.317-1.282"
          transform="translate(0 0)"
          fill="currentColor"
          stroke="currentColor"
          stroke-width="1"
        />
      </g>
    </svg>
  </span>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

@Component
export default class LogoIcon extends Vue {}
</script>

<style></style>
