enum ROLE {
  Guest = 0,
  User = 1,
  Admin = 2,
  SuperAdmin = 3,
  Developer = 4,
}

export { ROLE };
export default ROLE;
