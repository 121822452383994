import { defineModule } from "direct-vuex";
import { moduleActionContext, moduleGetterContext } from "@/store/index";

//Requests
import { getFilter } from "@/requests/filter";

//Models
import { Filter } from "@/models/api/filters/Filter";
import { DataOptions } from "vuetify";
import DefaultDataOptions from "@/models/components/dataTable/DataTableOptions";
import FilterRequest from "@/models/api/filters/FilterRequest";

export interface FilterStateInterface {
  reportCopiesFilterOptions: DataOptions;
  reportCopiesFilterRequest: FilterRequest;
  licenseFilterOptions: DataOptions;
  licenseFilterRequest: FilterRequest;
  licenseHistoryFilterOptions: DataOptions;
  licenseHistoryFilterRequest: FilterRequest;

  //Administration
  administrationReportsFilesFilterOptions: DataOptions;
  administrationReportsFilesFilterRequest: FilterRequest;
  administrationHierarchyBasicFilterOptions: DataOptions;
  administrationHierarchyBasicFilterRequest: FilterRequest;

  //TransactionHistory
  transactionHistoryFilterOptions: DataOptions;
  transactionHistoryFilterRequest: FilterRequest;
}

export const LicenseState: FilterStateInterface = {
  reportCopiesFilterOptions: new DefaultDataOptions(),
  reportCopiesFilterRequest: new FilterRequest(),
  licenseFilterOptions: new DefaultDataOptions(),
  licenseFilterRequest: new FilterRequest(),
  licenseHistoryFilterOptions: new DefaultDataOptions(),
  licenseHistoryFilterRequest: new FilterRequest(),

  //Administration
  administrationReportsFilesFilterOptions: new DefaultDataOptions(5),
  administrationReportsFilesFilterRequest: new FilterRequest(),
  administrationHierarchyBasicFilterOptions: new DefaultDataOptions(),
  administrationHierarchyBasicFilterRequest: new FilterRequest(),

  //TransactionHistory
  transactionHistoryFilterOptions: new DefaultDataOptions(),
  transactionHistoryFilterRequest: new FilterRequest(),
};

const filterModule = defineModule({
  state: LicenseState as FilterStateInterface,
  namespaced: true as const,
  getters: {},
  mutations: {
    setReportCopiesFilterDataOptions(state, options: DataOptions) {
      state.reportCopiesFilterOptions = options;
    },
    setReportCopiesFilterRequest(state, request: FilterRequest) {
      state.reportCopiesFilterRequest = request;
    },
    setLicenseFilterDataOptions(state, options: DataOptions) {
      state.licenseFilterOptions = options;
    },
    setLicenseFilterRequest(state, request: FilterRequest) {
      state.licenseFilterRequest = request;
    },
    setLicenseHistoryFilterDataOptions(state, options: DataOptions) {
      state.licenseHistoryFilterOptions = options;
    },
    setLicenseHistoryFilterRequest(state, request: FilterRequest) {
      state.licenseHistoryFilterRequest = request;
    },
    // Administration - reports
    setAdministrationReportsFilesFilterDataOptions(state, options: DataOptions) {
      state.administrationReportsFilesFilterOptions = options;
    },
    setAdministrationReportsFilesFilterRequest(state, request: FilterRequest) {
      state.administrationReportsFilesFilterRequest = request;
    },
    setAdministrationHierarchyBasicFilterOptions(state, options: DataOptions) {
      state.administrationHierarchyBasicFilterOptions = options;
    },
    setAdministrationHierarchyBasicFilterRequest(state, request: FilterRequest) {
      state.administrationHierarchyBasicFilterRequest = request;
    },
    // Transaction - history
    setTransactionHistoryBasicFilterOptions(state, options: DataOptions) {
      state.transactionHistoryFilterOptions = options;
    },
    setTransactionHistoryBasicFilterRequest(state, request: FilterRequest) {
      state.transactionHistoryFilterRequest = request;
    },
  },
  actions: {
    async getFilter(context, filterName: string): Promise<Filter> {
      const response = await getFilter(filterName);
      return response.model;
    },
  },
});

export default filterModule;

/* eslint-disable */
const _moduleGetterContext = (args: [any, any, any, any]) =>
  moduleGetterContext(args, filterModule);
const _moduleActionContext = (context: any) =>
  moduleActionContext(context, filterModule);
