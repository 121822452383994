<template>
  <ol v-if="typeof item.items != 'undefined'">
    <li v-for="item in item.items" :key="item.id" class="text-body-2">
      <span v-html="item.text"></span>
      <div v-if="typeof item.images != 'undefined'" class="d-flex flex-column">
        <div
          v-for="(image, index) in item.images"
          :key="index"
          class="terms-of-use-paragraph-list-image"
          :style="`max-width: ${image.maxWidth}px; align-self: ${image.position}`"
        >
          <img :src="require(`@/assets/images/${image.path}`)" />
        </div>
      </div>
      <terms-of-use-paragraph-list :item="item" />
    </li>
  </ol>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";

//Interface
import { ITermsOfUseParagraphData } from "../../models/components/termsOfUse/termsOfUse";

@Component({
  components: {
    TermsOfUseParagraphList: () => import("@/components/termsOfUse/TermsOfUseParagraphList.vue"),
  },
})
export default class TermsOfUseParagraphList extends Vue {
  @Prop({ required: true })
  item!: ITermsOfUseParagraphData;
}
</script>

<style lang="scss" scoped>
li + li {
  margin-top: 15px;
}

ol li {
  margin-top: 15px;
}

::marker {
  content: counters(list-item, ".") "."; // to show nested counter 1 -> 1.1 -> 1.1.1 ...
}

li {
  padding-left: 0.5em;
}

li li {
  counter-reset: item;
}

.terms-of-use-paragraph-list-image {
  margin-top: 40px;
  width: 100%;
  img {
    width: 100%;
  }
}
</style>
