<template>
  <div class="checkbox">
    <v-checkbox v-model="_value" :ripple="ripple" :rules="rules">
      <div slot="label" style="font-size: 12px">
        {{ label }}
      </div>
    </v-checkbox>
    <a v-if="href != ''" :href="href" target="_blank"> {{ title }} </a>
    <slot></slot>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop, PropSync } from "vue-property-decorator";

@Component({
  components: {},
})
export default class Checkbox extends Vue {
  @PropSync("value", { default: false })
  _value!: boolean;
  @Prop({ required: true })
  label!: string;
  @Prop({ default: false })
  ripple!: boolean;
  @Prop({ required: false })
  href!: string;
  @Prop({ required: false })
  title!: string;
  @Prop()
  // eslint-disable-next-line
  rules?: any;
}
</script>

<style lang="scss" scoped>
.checkbox {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
</style>
