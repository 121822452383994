<template>
  <div :style="`width: ${width}px`">
    <span class="solo-label">{{ _criterion.label }}</span>
    <v-autocomplete
      v-model="value"
      :items="_criterion.items"
      :search-input.sync="search"
      item-value="id"
      item-text="name"
      :loading="loading"
      solo
      dense
      flat
      hide-details="auto"
      append-icon="$arrowDown"
      no-data-text=""
      no-filter
      :menu-props="{
        auto: false,
        overflowY: false,
        contentClass: 'menu-content ',
      }"
      @change="select"
      @focusout="setDefaultValue"
    >
      <template #item="{ parent, item }">
        <v-list-item-icon :style="`padding-left: ${15 * item.depth}px`">
          <v-icon color="black">mdi-minus</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <!--Highlight output item.name-->
          <v-list-item-title v-html="`${item.name}`"></v-list-item-title>
        </v-list-item-content>
      </template>
    </v-autocomplete>
  </div>
</template>

<script lang="ts">
import { Component, Vue, PropSync, Prop, Watch } from "vue-property-decorator";
import { CriterionBase } from "@/models/api/filters/CriterionBase";

//Request
import { companyAutocompleteTree } from "@/requests/filter";

export interface AutocompleteCompanyCriterionSettings extends CriterionBase {
  value: string;
  items: Array<AutocompleteCompanyItemCriterion>;
  pathToRequest: string;
}

export interface AutocompleteCompanyItemCriterion {
  id: string;
  name: string;
  depth: number;
}

@Component
export default class AutocompleteCompanyCriterion extends Vue {
  @PropSync("criterion")
  _criterion!: AutocompleteCompanyCriterionSettings;
  @Prop({ default: 200 })
  width!: number;

  value = "";
  previousValue = "";
  search = "";
  loading = false;
  selectedByUser = true;

  @Watch("search")
  async loadData() {
    if (this.selectedByUser) {
      this.selectedByUser = false;
      return;
    }
    if (this.search != null) {
      this.loading = true;
      this._criterion.items = (await companyAutocompleteTree(this.search)).model;
      this.loading = false;
    }
  }

  select(id: string | null) {
    if (id != null) {
      this.selectedByUser = true;
      this.previousValue = id;
      this.value = id;
      this._criterion.value = id;
    }
  }

  setDefaultValue() {
    if (this.value === null) {
      this.value = this.previousValue;
    }
  }

  @Watch("criterion.value")
  init() {
    this.value = this._criterion.value;
    this.previousValue = this._criterion.value;
  }

  mounted() {
    this.init();
  }

  /*  Information
      - loading data from api
      - synchronize search 
      - noFilter -> allow use api to filter
      - remove focus set previous value as default if value is empty string
      - prevent send request after item selection -> select function to block Watcher for "search" property
  */
}
</script>

<style lang="scss" scoped>
.solo-label {
  padding-left: 12px;
  font-size: 10px;
}

::v-deep .v-text-field--solo {
  border-radius: 10px;
  .v-input__control {
    font-size: 12px;
    min-height: 35px;
    max-height: 35px;
    .v-input__slot {
      background-color: #f4f4f4f4 !important;
    }
  }
}

::v-deep .v-list-item {
  min-height: 25px !important;
}

::v-deep .v-list-item__content {
  padding: unset;
  .v-list-item__title {
    font-size: 12px !important;
  }
}

::v-deep .v-input__slot {
  background-color: #f4f4f4 !important;
}

::v-deep .v-input__control {
  min-height: 35px !important;
  .v-text-field__slot input {
    color: black;
  }
}

::v-deep .v-list-item--active {
  border-left: 3px solid black;
  background-color: #f4f4f4f4;
  .v-list-item__content {
    .v-list-item__title {
      color: black;
      font-weight: 500 !important;
    }
  }
}

::v-deep .v-icon__component {
  height: 16px;
  width: 16px;
}
</style>
