<template>
  <v-dialog
    :value="_value"
    persistent
    :width="width"
    :overlay-opacity="0.2"
    overlay-color="black"
    elevation
    content-class="elevation-1"
    rounded="xl"
    @keydown.esc="cancel"
  >
    <v-card style="padding: 10px" @click.stop.native="">
      <v-card-title class="dialog-title">
        <v-flex
          row
          :justify-start="titlePosition === 'start'"
          :justify-center="titlePosition === 'center'"
          :justify-end="titlePosition === 'end'"
        >
          <div>
            <span class="text-h6 font-weight-bold">{{ title }}</span>
          </div>
        </v-flex>
        <v-btn v-show="showCross" class="cross" icon :ripple="false" @click="cancel">
          <v-icon color="button">$close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <slot></slot>
      </v-card-text>
      <v-card-actions v-show="showButtons">
        <div class="buttons-group">
          <template v-for="(button, index) of actionButtons">
            <v-btn
              :key="index + Math.random()"
              :width="150"
              :disabled="button.disabled"
              :class="button.class"
              :style="`margin-left: ${actionButtons.length > 1 ? ' ' : button.align === 'left' ? '' : 'auto'}`"
              @click="buttonClick(button)"
            >
              {{ button.text | capitalize }}
            </v-btn>
          </template>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { Vue, Component, Prop, PropSync } from "vue-property-decorator";
import i18n from "../../../plugins/i18n";

export interface DialogButtonInterface {
  text: string;
  color: string;
  action: () => void | undefined | Promise<void>;
  class: string;
  align?: string; //$ when we have one button we can specify where it should be aligned -> left or right
  disabled: boolean;
}

@Component({
  components: {},
})
export default class CustomDialog extends Vue {
  @PropSync("value", { default: true })
  _value!: boolean;
  @PropSync("buttons", { default: undefined })
  _buttons?: Array<DialogButtonInterface>;

  @Prop({ required: true })
  title!: string;
  @Prop({ default: "700" })
  width!: string;
  @Prop({ default: true })
  showButtons!: boolean;
  @Prop({ default: true })
  showCross!: boolean;
  @Prop()
  titlePosition!: string;

  defaultButtons: Array<DialogButtonInterface> = [];

  save() {
    this.$emit("save");
  }

  cancel() {
    this.$emit("cancel");
  }

  buttonClick(button: DialogButtonInterface) {
    if (typeof button.action === "function") {
      button.action();
    }
  }

  get actionButtons(): Array<DialogButtonInterface> {
    if (typeof this._buttons === "undefined") {
      return this.defaultButtons;
    } else {
      return this._buttons;
    }
  }

  created() {
    this.defaultButtons = [
      {
        text: i18n.t("actions.cancel").toString(),
        color: "",
        action: this.cancel,
        class: "btn main-light--reverse",
        disabled: false,
        // align: "left", for test to remove //TODO
      } as DialogButtonInterface,
      {
        text: i18n.t("actions.change").toString(),
        color: "",
        action: this.save,
        class: "btn main-light",
        disabled: false,
        // align: "right", for test to te remove //TODO
      } as DialogButtonInterface,
    ];
  }
}
</script>

<style lang="scss" scoped>
.button-spacer {
  width: 20px !important;
  flex-grow: 0 !important;
}

.button-spacer-small {
  width: 0px !important;
  flex-grow: 0 !important;
}

::v-deep .v-dialog {
  border-radius: 25px !important;
}

.cross {
  position: absolute;
  top: 10px;
  right: 10px;
}

.v-card__text {
  padding-bottom: 10px !important;
}

.dialog-title {
  word-break: break-word;
}
</style>
