<template>
  <div>
    <transaction-redirection-card />
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

//Models
import TransactionBlueMediaRedirectionParams from "@/models/api/transaction/transactionBlueMediaRedirectionParams";
import TransactionStatus from "@/models/api/transaction/transactionStatus";

import TransactionRedirectionCard from "@/components/transactionRedirection/TransactionRedirectionCard.vue";

@Component({
  components: {
    TransactionRedirectionCard,
  },
})
export default class TransactionBlueMediaRedirectionView extends Vue {
  params = new TransactionBlueMediaRedirectionParams();

  get hashIsValid(): boolean {
    return this.$store.direct.state.transaction.hashIsValid;
  }

  get transactionStatus(): TransactionStatus | null {
    return this.$store.direct.state.transaction.transactionStatus as TransactionStatus;
  }

  async refreshStatus() {
    if (this.params.orderId != null && this.hashIsValid) {
      await this.$store.direct.dispatch.transaction.checkTransactionStatus(this.params.orderId);
    }
  }

  getIcon() {
    if (this.hashIsValid && this.transactionStatus != null) {
      switch (this.transactionStatus.status) {
        case "CREATED": {
          return "$transactionPending";
        }
        case "SUCCESS": {
          return "$transactionSuccess";
        }
        case "PENDING": {
          return "$transactionPending";
        }
        case "FAILURE": {
          return "$transactionFailure";
        }
      }
    }
  }

  getTitle() {
    if (this.hashIsValid && this.transactionStatus != null) {
      switch (this.transactionStatus.status) {
        case "CREATED": {
          return "Płatność rozpoczęta";
        }
        case "SUCCESS": {
          return "Płatność zakończyła się sukcesem";
        }
        case "PENDING": {
          return "Płatność w trakcie realizacji";
        }
        case "FAILURE": {
          return "Płatność zakończyła się błędem";
        }
      }
    }
  }

  getText() {
    if (this.hashIsValid && this.transactionStatus != null) {
      switch (this.transactionStatus.status) {
        case "CREATED": {
          return "Płatność rozpoczęta";
        }
        case "SUCCESS": {
          return "Na maila wysłaliśmy potwierdzenie płatności.";
        }
        case "PENDING": {
          return "Na maila wysłaliśmy link do ukończenia płatności.";
        }
        case "FAILURE": {
          return "Na maila wysłaliśmy informację dotyczącą błędu płatności.";
        }
      }
    }
  }

  timer = 0;
  requestCounter = 0;

  created() {
    this.params.serviceId = this.$route.query.ServiceID as string;
    this.params.orderId = this.$route.query.OrderID as string;
    this.params.hash = this.$route.query.Hash as string;
  }

  async loadData() {
    await this.refreshStatus();
    this.requestCounter += 1;
    if (this.requestCounter >= 5 || this.transactionStatus?.status === "SUCCESS") {
      clearInterval(this.timer);
    }
  }

  async mounted() {
    if (!this.params.validate()) {
      this.$router.push("/statistics/statistic");
      return;
    }
    await this.$store.direct.dispatch.transaction.checkBlueMediaRedirectionParameters(this.params);
    if (this.hashIsValid) {
      await this.refreshStatus(); //Only one time
      this.timer = setInterval(this.loadData, 10000);
    } else {
      clearInterval(this.timer);
      this.$router.push("/statistics/statistic");
    }
  }

  // Do not remove!
  async beforeDestroy() {
    await clearInterval(this.timer);
  }
}
</script>

<style scoped>

</style>
