import Vue from "vue";
import { DateTimeFormat } from "vue-i18n";

const dateTime = (value: string | DateTimeFormat): string => {
  if (!value) return "";
  value = value.toString();
  return (
    value.substring(0, 4) + "-" + value.substring(5, 7) + "-" + value.substring(8, 10) + " " + value.substring(11, 16)
  );
};

export { dateTime };

const longDateTime = (value: string): string => {
  if (!value) return "";
  value = value.toString();
  return (
    value.substring(0, 4) + "-" + value.substring(5, 7) + "-" + value.substring(8, 10) + " " + value.substring(11, 19)
  );
};

export { longDateTime };

Vue.filter("date", dateTime);
Vue.filter("longDateTime", longDateTime);
