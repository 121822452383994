<template>
  <custom-dialog v-model="_showDialog" :title="title" width="680px" :buttons="getButtons()" @cancel="cancel">
    <div v-if="userActiveLicenses.length > 0">
      <v-data-table
        v-model="item"
        :items="userActiveLicenses"
        :headers="headers"
        :options.sync="tableOptions"
        height="233"
        single-select
        show-select
        disable-sort
        hide-default-footer
        item-key="index"
      >
        <template #[`item.expirationDate`]="{ item }">
          {{ item.expirationDate | date }}
        </template>
      </v-data-table>
      <div class="table__pager">
        <custom-pagination :options.sync="tableOptions" :server-items-length="userActiveLicenses.length">
        </custom-pagination>
      </div>
    </div>
    <div v-else>
      <div class="mt-4">
        <div>
          <span class="text-body-2">
            {{ getNoLicenseText() }}
          </span>
        </div>
        <div v-if="getUserRole() == role.Admin" class="d-flex justify-center mt-5">
          <v-btn class="btn main-light" to="/licenses/buy">Kup licencje</v-btn>
        </div>
      </div>
    </div>
  </custom-dialog>
</template>

<script lang="ts">
import { Component, Vue, PropSync, Watch } from "vue-property-decorator";

//Components
import CustomDialog, { DialogButtonInterface } from "@/components/shared/layout/CustomDialog.vue";
import { LicenseBasicInfo } from "../../models/api/licenses/license";
import { DataTableHeader } from "vuetify";
import DefaultDataOptions from "../../models/components/dataTable/DataTableOptions";
import CustomPagination from "@/components/shared/dataTable/CustomPagination.vue";
import { SnackbarModel } from "../../models/components/popup/Snackbar";
import ROLE from "../../static/role";

@Component({
  components: {
    CustomDialog,
    CustomPagination,
  },
})
export default class LicenseUserDialog extends Vue {
  @PropSync("showDialog", { required: true })
  _showDialog?: boolean;

  @PropSync("selectedItem", { default: [] })
  item!: Array<LicenseBasicInfo>;

  title = "Dostępne licencje";
  buttons1: Array<DialogButtonInterface> = [
    {
      text: "Anuluj",
      color: "",
      class: "btn main-light--reverse",
      align: "",
      disabled: false,
      action: () => {
        this.cancel();
      },
    },
    {
      text: "Wybierz",
      color: "",
      class: "btn main-light",
      align: "",
      disabled: false,
      action: async () => {
        this.save();
      },
    },
  ];

  buttons2: Array<DialogButtonInterface> = [
    {
      text: "Zamknij",
      color: "",
      class: "btn main-light--reverse",
      align: "",
      disabled: false,
      action: () => {
        this.cancel();
      },
    },
  ];

  getButtons(): Array<DialogButtonInterface> {
    if (this.userActiveLicenses.length) return this.buttons1;
    return this.buttons2;
  }

  tableOptions = new DefaultDataOptions(5);
  headers: Array<DataTableHeader> = [
    {
      text: "Nazwa",
      value: "name",
    },
    {
      text: "Data ważności",
      value: "expirationDate",
      align: "center",
    },
    {
      text: "Pozostało",
      value: "forUse",
      align: "center",
    },
    {
      text: "",
      value: "actions",
    },
  ];

  role = ROLE;

  getUserRole(): ROLE {
    return this.$store.direct.state.user.role;
  }

  getNoLicenseText(): string {
    if (this.getUserRole() == this.role.Admin)
      return "Niestety na twoim koncie nie ma wystarczającej liczby licencji, aby wygenerować raport, przejdź do płatności klikając poniższy przycisk.";
    return "Do twojego konta nie przypisano licencji. Skontaktuj się z administratorem w celu dodania licencji, aby wygenerować raport.";
  }

  save() {
    if (this.item.length) {
      this.$emit("save", this.item[0].id);
      this._showDialog = false;
      return;
    }
    const snackbar = new SnackbarModel(["selectOneOption"], "error");
    this.$store.direct.dispatch.popup.addSnackbar(snackbar);
  }

  cancel() {
    this._showDialog = false;
  }

  get userActiveLicenses(): Array<LicenseBasicInfo> {
    return this.$store.direct.state.license.userActiveLicenses;
  }

  @Watch("showDialog")
  async onCreate(state: boolean) {
    if (state) await this.$store.direct.dispatch.license.getUserActiveLicenses();
  }

  async created() {
    await this.onCreate(true);
  }
}
</script>

<style scoped></style>
