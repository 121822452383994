<template>
  <div :style="`width: ${width}px`">
    <span class="label">{{ label }}</span>
    <v-select :items="items" hide-details="auto"></v-select>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class InputSelectVue extends Vue {
  @Prop()
  // eslint-disable-next-line
  value!: any;
  @Prop()
  // eslint-disable-next-line
  items!: Array<any>;
  @Prop()
  label!: string;
  @Prop({ default: "auto" })
  width!: number | string;
}
</script>

<style lang="scss" scoped>
.label {
  font-size: 12px;
  margin-top: 8px;
}

::v-deep .v-select {
  padding: 0;
  margin: 0;
  margin-bottom: 15px;
}

::v-deep .v-select__selections {
  flex-direction: row-reverse;
}
</style>
