<template>
  <div>
    <div>
      <v-flex row>
        <v-btn :width="150" class="btn-main--outlined font-11" @click="addUser()">
          <v-icon left x-small>$plus</v-icon>
          {{ $t("actions.addUser") | capitalize }}
        </v-btn>
      </v-flex>
    </div>
    <v-data-table :items="companyUsers" :headers="headers" single-select :header-props="{ sortIcon: '$arrowUp' }">
      <template #[`item.isActive`]="{ item }">
        <v-simple-checkbox :value="item.isActive" :ripple="false" hide-details="auto"></v-simple-checkbox>
      </template>
      <template #[`item.actions`]="{ item }">
        <v-tooltip bottom>
          <template #activator="{ on }">
            <v-btn icon :ripple="false" @click="editUser(item)" v-on="on">
              <v-icon small>$edit</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("tooltip.edit") | capitalize }}</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <administration-add-user-dialog v-if="showAddUserDialog" :value.sync="showAddUserDialog" />
    <administration-edit-user-dialog
      v-if="showEditUserDialog"
      :value.sync="showEditUserDialog"
      :user.sync="selectedUser"
    />
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { DataTableHeader } from "vuetify";

//Components
import AdministrationAddUserDialog from "@/components/administration/AdministrationAddUserDialog.vue";
import AdministrationEditUserDialog from "@/components/administration/AdministrationEditUserDialog.vue";

//Translation
import { filterCapitalize } from "@/filters/capitalize";
import i18n from "@/plugins/i18n";

//Models
import UserInfoResponse from "@/models/api/user/UserInfoResponse";
import UserDetails from "../../../models/api/user/UserDetails";

@Component({
  components: {
    AdministrationAddUserDialog,
    AdministrationEditUserDialog,
  },
})
export default class AdministrationUserView extends Vue {
  get companyUsers(): Array<UserInfoResponse> {
    return this.$store.direct.state.company.companyUsers;
  }

  //Datatable
  headers: Array<DataTableHeader> = [
    {
      text: filterCapitalize(i18n.t("header.userName")),
      value: "name",
      align: "start",
    },
    {
      text: filterCapitalize(i18n.t("header.userSurname")),
      value: "surname",
      align: "start",
    },
    {
      text: filterCapitalize(i18n.t("header.userEmail")),
      value: "email",
      align: "start",
    },
    {
      text: filterCapitalize(i18n.t("header.userIsActive")),
      value: "isActive",
      align: "center",
    },
    {
      text: "",
      value: "actions",
      align: "end",
    },
  ];

  showAddUserDialog = false;
  showEditUserDialog = false;

  addUser() {
    this.showAddUserDialog = true;
  }

  selectedUser: UserDetails | null = null;

  editUser(item: UserInfoResponse) {
    this.selectedUser = {
      id: item.id,
      name: item.name,
      surname: item.surname,
      email: item.email,
      phoneNumber: "",
    };
    this.showEditUserDialog = true;
  }

  async created() {
    const companyId = this.$store.direct.state.company.selectedCompanyId;
    await this.$store.direct.dispatch.company.getCompanyUsers(companyId);
  }

  // $Way to pass prop to route and read companyId but after refresh we lost data, save companyId in store
  //   readCompanyIdFromRoute() {
  //     const companyId = this.$route.params.companyId;
  //     if (companyId != undefined) this.$store.direct.dispatch.company.getCompanyUsers(companyId);
  //     else this.$router.push({ name: ROUTE_NAMES.ADMINISTRATION.HIERARCHY });
  //   }

  //   async created() {
  //     await this.readCompanyIdFromRoute();
  //   }
}
</script>

<style scoped></style>
