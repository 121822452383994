<template>
  <span class="text-mark" :class="itemClass">
    <div
      class="text-mark-wrapper"
      :style="`transform: translate(-${tx}px, -${ty}px); height: calc(100% + ${height}px); width: calc(100% + ${width}px);`"
    >
      <svg
        class="text-mark-wrapper-svg"
        xmlns="http://www.w3.org/2000/svg"
        :width="`${0}`"
        :height="`${0}`"
        preserveAspectRatio="none"
        viewBox="0 0 330 100"
      >
        <path
          d="M0,24.144C83.667-10.107,373.512,0,320.722,61.764,280.487,108.839-95.619,113.422,30.877,0"
          transform="translate(0 0)"
          fill="none"
          stroke="#d4ab5b"
          stroke-width="2"
        />
      </svg>
    </div>
    <slot></slot>
  </span>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class TextMark extends Vue {
  @Prop()
  itemClass!: string;
  @Prop({ default: 10 })
  height!: number;
  @Prop({ default: 10 })
  width!: number;
  @Prop({ default: 5 })
  tx!: number;
  @Prop({ default: 5 })
  ty!: number;

  // mounted() {
  //   this.tx = this.width / 2;
  //   this.ty = this.height / 2;
  // }
}
</script>

<style lang="scss" scoped>
.text-mark {
  display: inline-block;
  width: max-content;
  position: relative;
  text-align: center;
  margin: 0 5px;
  z-index: -1;
}

.text-mark-wrapper {
  display: inline-block;
  position: absolute;
  // width: calc(100% + 10px);
  // height: calc(100% + 10px);
  // transform: translate(-10px, -8px);
  left: -9px;
  top: 2px;
}

.text-mark-wrapper-svg {
  height: 100%;
  width: inherit;
}

@media screen and (max-width: 450px) {
  .text-mark-wrapper {
    width: calc(100% + 7px);
    left: -7px;
    top: 1px;
  }

  .text-mark-wrapper-svg {
    path {
      stroke-width: 3;
    }
  }
}
</style>
