<template>
  <v-container class="d-flex justify-center align-center">
    <div class="form-container">
      <div class="text-h4">{{ $t("header.login") | capitalize }}</div>
      <login-form />
      <div class="form-stamp">
        <img src="@/assets/images/stamp.png" alt="" />
      </div>
    </div>
  </v-container>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

//Components
import LoginForm from "@/components/anonymous/LoginForm.vue";

@Component({
  components: { LoginForm },
})
export default class LoginView extends Vue {}
</script>

<style lang="scss" scoped>
.form-container {
  align-self: center;
  max-width: 350px;
  width: 100%;
  margin: 30px 0;
  position: relative;
}

.form-stamp {
  position: absolute;
  top: -30px;
  right: 0;
  height: 106px;
  width: 58px;
  img {
    height: 100%;
    width: 100%;
  }
}
</style>
