<template>
  <span class="scaling-svg-container">
    <svg xmlns="http://www.w3.org/2000/svg" width="14.376" height="12.938" viewBox="0 0 14.376 12.938">
      <path
        d="M10.423,12.938H3.954A1.439,1.439,0,0,1,2.516,11.5H1.9A1.909,1.909,0,0,1,0,9.583V4.793A1.908,1.908,0,0,1,1.9,2.876h.98V1.437A1.383,1.383,0,0,1,4.193,0h5.99A1.383,1.383,0,0,1,11.5,1.437V2.876h.979a1.908,1.908,0,0,1,1.9,1.917V9.583a1.909,1.909,0,0,1-1.9,1.918h-.62A1.44,1.44,0,0,1,10.423,12.938ZM3.954,8.626V11.5h6.47V8.626Zm0-1.437h6.469A1.439,1.439,0,0,1,11.86,8.626v1.437h.62a.47.47,0,0,0,.458-.48V4.793a.47.47,0,0,0-.458-.48H1.9a.47.47,0,0,0-.458.48V9.583a.47.47,0,0,0,.458.48h.62V8.626A1.439,1.439,0,0,1,3.954,7.188Zm.369-5.751,0,1.438h5.745V1.437Z"
        transform="translate(0 0)"
        fill="currentColor"
      />
    </svg>
  </span>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

@Component
export default class PrinterIcon extends Vue {}
</script>

<style></style>
