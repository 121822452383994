<template>
  <div class="ps-container">
    <div class="ps-container__text">
      <div class="d-flex flex-column">
        <div class="text-h3 text-md-h2 font-weight-bold">
          Zaplanuj
          <text-mark :height="20" :width="20" item-class="text-h3 text-md-h2 font-weight-bold">sukcesję</text-mark>
        </div>
        <div class="text-h3 text-md-h2 font-weight-bold">swojej firmy!</div>
      </div>
      <div class="buttons-group mt-10 mb-10">
        <btn type="white" @click="$store.direct.dispatch.vuetify.goTo('#products')">Wybierz własny plan</btn>
        <btn type="black" @click="$store.direct.dispatch.user.getReportDemo()">Raport demo</btn>
      </div>
    </div>
    <div class="ps-container__image-wrapper">
      <div class="ps-container__image-item">
        <img src="@/assets/images/SP_P1.png" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

//Components
import Btn from "@/components/shared/buttons/Btn.vue";
import TextMark from "@/components/shared/layout/TextMark.vue";

@Component({
  components: {
    Btn,
    TextMark,
  },
})
export default class PlanSuccession extends Vue {}
</script>

<style lang="scss" scoped>
.ps-container {
  display: flex;
  margin-top: 30px;
  @media screen and (max-width: 1292px) {
    flex-direction: column;
    align-items: center;
    .ps-container__text {
      margin: 0;
    }
    .ps-container__image-item {
      top: -130px;
      left: -450px;
      img {
        height: 600px;
      }
    }
  }
  @media screen and (max-width: 540px) {
    .ps-container__image-item {
      top: -50px;
      left: -400px;
      img {
        height: 500px;
      }
    }
    .ps-container__image-wrapper {
      height: 500px;
    }
  }
  @media screen and (max-width: 440px) {
    .ps-container__image-item {
      top: -100px;
      left: -330px;
      img {
        height: 400px;
      }
    }
    .ps-container__image-wrapper {
      height: 300px;
    }
  }
}

.ps-container__text {
  width: 600px;
  max-width: 100%;
  margin-left: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 5;
}

.ps-container__image-wrapper {
  position: relative;
  height: 500px;
}

.ps-container__image-item {
  position: absolute;
  top: -150px;
  left: -250px;
  img {
    height: 700px;
  }
}

.buttons-group {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
}
</style>
