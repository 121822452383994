import RequestHelper from "@/requests/RequestHelper";
import { Response } from "@/requests/RequestHelper";

//Models
import Product from "@/models/api/products/product";

//Urls
export const URL_GET_PRODUCT_PRODUCTS = "/Product/Products";

//Request
const _getProducts = new RequestHelper<Array<Product>>(URL_GET_PRODUCT_PRODUCTS, false);
export const getProducts = async (): Promise<Response<Array<Product>>> => {
  return await _getProducts.get();
};
