<template>
  <span class="scaling-svg-container" style="margin-left: 4px; margin-bottom: 2px">
    <svg xmlns="http://www.w3.org/2000/svg" width="14.999" height="14.999" viewBox="0 0 14.999 14.999">
      <g transform="translate(-709.223 -245.326)">
        <g transform="translate(709.223 245.326)" fill="none" stroke="#000" stroke-width="1">
          <circle cx="7.499" cy="7.499" r="7.499" stroke="none" />
          <circle cx="7.499" cy="7.499" r="6.999" fill="none" />
        </g>
      </g>
    </svg>
  </span>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

@Component
export default class CheckBoxOffIcon extends Vue {}
</script>

<style></style>
