<template>
  <custom-dialog
    :value.sync="_show"
    :title="title"
    :buttons="buttons"
    :width="400"
    title-position="center"
    @cancel="cancel"
  >
    <v-form ref="form" @submit.prevent.stop>
      <div class="d-flex justify-center">
        <input-text v-model="formData.name" label="Nazwa raportu" :rules="[required]" :width="300" />
      </div>
    </v-form>
  </custom-dialog>
</template>

<script lang="ts">
import { Component, Vue, PropSync, Watch } from "vue-property-decorator";

//Models
import AddForm from "@/models/api/reports/addForm";

//Components
import InputText from "@/components/shared/inputs/InputText.vue";
import CustomDialog, { DialogButtonInterface } from "@/components/shared/layout/CustomDialog.vue";

//Validators
import requiredValidator from "@/rules/requiredValidator";
import { FormValidation } from "../../../customTypes/vuetify";

@Component({
  components: {
    InputText,
    CustomDialog,
  },
})
export default class AddFormDialog extends Vue {
  @PropSync("show")
  _show!: boolean;
  @PropSync("step", { default: 1 })
  _step!: number;

  required = requiredValidator;

  formData: AddForm = {
    name: "",
  };

  title = "Nazwij raport";
  buttons: Array<DialogButtonInterface> = [
    {
      text: "Anuluj",
      color: "",
      class: "btn main-light--reverse",
      align: "",
      disabled: false,
      action: () => {
        this.cancel();
      },
    },
    {
      text: "Dodaj",
      color: "",
      class: "btn main-light",
      align: "",
      disabled: false,
      action: async () => {
        this.add();
      },
    },
  ];

  async add() {
    const isValid = this.validateForm();
    if (isValid) {
      await this.$store.direct.dispatch.report.addForm(this.formData);
    }
  }

  cancel() {
    this._show = false;
  }

  validateForm() {
    return (this.$refs.form as FormValidation).validate();
  }

  @Watch("show")
  init(val: boolean) {
    if (!val) return;
    this.formData.name = "";
  }
}
</script>

<style lang="scss" scoped></style>
