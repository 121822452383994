import RequestHelper from "./RequestHelper";
import { Response } from "@/requests/RequestHelper";

//Models
import RecordStamp from "@/models/api/recordStamp/recordStamp";

//Urls
export const URL_GET_RECORD_STAMP = "/RecordStamps/${RecordStampId}";

const _getRecordStamp = new RequestHelper<RecordStamp>(URL_GET_RECORD_STAMP, false);
export const getRecordStamp = async (recordStampId: number): Promise<Response<RecordStamp>> => {
  return _getRecordStamp.get({ RecordStampId: recordStampId });
};
