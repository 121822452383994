<template>
  <div class="form">
    <v-form ref="registerForm" lazy-validation autocomplete="off" @submit.prevent="clickRegister">
      <v-text-field
        v-model="registerModel.email"
        :label="$t('form.userEmail') | capitalize"
        autocomplete="new-password"
        hide-details="auto"
        color="black"
        :rules="[required, email]"
        validate-on-blur
        @change="userExistValidation"
      ></v-text-field>
      <v-text-field
        v-model="registerModel.password"
        :label="$t('form.userPassword') | capitalize"
        autocomplete="new-password"
        type="password"
        hide-details="auto"
        color="black"
        :rules="[required, password]"
        validate-on-blur
      ></v-text-field>
      <v-text-field
        v-model="confirmPassword"
        :label="$t('form.userPasswordConfirm') | capitalize"
        autocomplete="new-password"
        type="password"
        hide-details="auto"
        color="black"
        :rules="[required, compare(registerModel.password, confirmPassword)]"
        validate-on-blur
      ></v-text-field>
      <v-text-field
        v-model="registerModel.company"
        :label="$t('form.userCompany') | capitalize"
        autocomplete="new-password"
        color="black"
        hide-details="auto"
        :rules="[required]"
        validate-on-blur
      ></v-text-field>
      <div class="form-checkbox">
        <v-checkbox
          v-model="terms"
          class="text-caption"
          color="black"
          :label="$t('form.agree') | capitalize"
          dense
          :ripple="false"
          hide-details="auto"
          :rules="[(v) => v === true]"
        />
        <router-link-new-tab to="/terms-of-use" type="a">{{ $t("form.termsOfUse") | capitalize }} </router-link-new-tab>
      </div>
      <div class="form-buttons">
        <v-btn class="btn main-light--reverse" :disabled="buttonDisabled" :width="120" :height="35" type="submit">{{
          $t("form.register") | capitalize
        }}</v-btn>
        <v-btn class="btn main-light" :width="120" :height="35" @click="clickCancel">{{
          $t("form.cancel") | capitalize
        }}</v-btn>
      </div>
      <div class="form-link">
        <router-link class="text-caption gray--text" :to="{ name: route.LOGIN }">{{
          $t("form.haveAccount") | capitalize
        }}</router-link>
      </div>
    </v-form>
  </div>
</template>

<script lang="ts">
import { Vue, Component } from "vue-property-decorator";

import ROUTE_NAMES from "@/static/routeNames";

//Components
import Checkbox from "@/components/shared/inputs/Checkbox.vue";
import RouterLinkNewTab from "@/components/shared/buttons/RouterLinkNewTab.vue";

//Models
import RegisterUserRequest from "@/models/api/user/RegisterUserRequest";

//Validation
import requiredValidator from "@/rules/requiredValidator";
import passwordValidator from "@/rules/passwordValidator";
import passwordCompareValidator from "@/rules/passwordCompareValidator";
import emailValidator from "@/rules/emailValidator";

@Component({
  components: {
    Checkbox,
    RouterLinkNewTab,
  },
})
export default class RegisterForm extends Vue {
  route = ROUTE_NAMES.GENERAL;
  href = this.$router.resolve({ name: this.route.TERMS }).href;

  registerModel: RegisterUserRequest = {
    email: "",
    password: "",
    company: "",
  };

  confirmPassword = "";
  terms = false;

  buttonDisabled = false;

  //Validation
  required = requiredValidator;
  password = passwordValidator;
  compare = passwordCompareValidator;
  email: boolean | string = true;

  async userExistValidation() {
    this.email = "";
    await emailValidator(this.registerModel.email).then((value) => {
      this.email = value;
    });
  }

  validateForm(): boolean {
    return (this.$refs.registerForm as HTMLFormElement).validate();
  }

  setButton(state: boolean) {
    this.buttonDisabled = state;
  }

  async clickRegister(): Promise<void> {
    if (this.validateForm()) {
      this.setButton(true);
      const error = await this.$store.direct.dispatch.user.register(this.registerModel);
      if (error) {
        this.setButton(false);
      }
    }
  }

  async clickCancel() {
    await this.$router.push({ name: this.route.WELCOME });
  }
}
</script>

<style scoped>
::v-deep .form-checkbox {
  display: flex;
  /* justify-content: space-between; */
  align-items: flex-end;
  padding: 10px 0;
}

.form-buttons {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 30px 0;
}

.form-link {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
}
</style>
