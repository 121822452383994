import RequestHelper from "./RequestHelper";
import { Response } from "@/requests/RequestHelper";

// Models
import UserBasic from "@/models/api/user/UserBasic";
import CompanyBasic from "@/models/api/company/CompanyBasic";

// Urls
export const URL_GET_COMPANY_USERS_BASIC = "/Company/CompanyUsersBasic";
export const URL_GET_COMPANY_CHILDREN_BASIC = "/Company/CompanyChildrenBasic";

// Requests
const _companyUsersBasic = new RequestHelper<Array<UserBasic>>(URL_GET_COMPANY_USERS_BASIC);
export const downloadCompanyUsersBasic = async (companyId: string): Promise<Response<Array<UserBasic>>> => {
  return _companyUsersBasic.get({ CompanyId: companyId });
};

const _companyChildrenBasic = new RequestHelper<Array<CompanyBasic>>(URL_GET_COMPANY_CHILDREN_BASIC);
export const downloadCompanyChildrenBasic = async (companyId: string): Promise<Response<Array<CompanyBasic>>> => {
  return _companyChildrenBasic.get({ CompanyId: companyId });
};
