import i18n from "@/plugins/i18n";
import { filterCapitalize } from "@/filters/capitalize";
import { RuleFunction } from "@/rules/RuleFunction";

const numberIsIntigerValidator: RuleFunction = (value: unknown | string | number): boolean | string => {
  if (typeof value === "string" || typeof value === "number") {
    if ([...value.toString()].includes(",") || [...value.toString()].includes(".")) {
      return filterCapitalize(i18n.t("validation.notIntiger").toString());
    }
    return true;
  }
  return filterCapitalize(i18n.t("validation.notANumber").toString());
};

export { numberIsIntigerValidator };
export default numberIsIntigerValidator;
