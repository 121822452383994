<template>
  <div>
    <background-image />
    <div style="position: relative; display: flex">
      <v-row class="my-row">
        <v-card>
          <v-card-title>Test połaczenia</v-card-title>
          <v-card-actions>
            <v-col>
              <v-row justify="space-between" align="center">
                <v-btn class="btn-main" @click="dateTimeNow"> DateTimeNow </v-btn>
                <span>Data: {{ date | longDateTime }}</span>
              </v-row>
            </v-col>
          </v-card-actions>
        </v-card>
        <v-card>
          <v-card-title>Migracje</v-card-title>
          <v-card-text>
            <v-data-table
              :items="migrations.items"
              :headers="headers"
              :height="418"
              fixed-header
              disable-sort
              :item-class="getItemClass"
              class="background-color: transparent"
            >
              <template #[`item.date`]="{ item }">
                {{ item.date | date }}
              </template>
            </v-data-table>
          </v-card-text>
          <v-card-actions>
            <v-row>
              <v-btn class="btn-main" @click="migrate"> Migrate </v-btn>
            </v-row>
          </v-card-actions>
        </v-card>
        <v-card>
          <v-card-title>Konfiguracja płatności</v-card-title>
          <v-card-actions>
            <v-col>
              <v-row justify="space-between" align="center">
                <v-btn class="btn-main" @click="checkTransactionConfigurationIsLoaded"> Check Configuration </v-btn>
                <v-chip :color="transactionConfigurationIsLoaded ? '#66ff0063' : '#ff000071'">
                  Configuration:
                  {{ transactionConfigurationIsLoaded ? "Loaded" : "NotLoaded" }}
                </v-chip>
              </v-row>
              <v-row justify="space-between" align="center">
                <v-btn class="btn-main" @click="loadTransactionConfigurationFromDataBase"> Update Configuration </v-btn>
              </v-row>
              <v-row justify="space-between" align="center">
                <div>
                  <span class="text">Request</span>
                  <v-textarea v-model="textRequest" class="text"></v-textarea>
                </div>
                <div>
                  <span class="text">Response</span>
                  <v-textarea v-model="textResponse" class="text"></v-textarea>
                </div>
                <v-btn class="btn-main" width="140" @click="testResponse"> Check Response </v-btn>
                <v-btn class="btn-main" width="140" @click="getTransactionUrl"> Redirect </v-btn>
                <v-chip :color="compareBody() ? '#66ff0063' : '#ff000071'">Strings are: {{ compareBody() }}</v-chip>
              </v-row>
            </v-col>
          </v-card-actions>
        </v-card>
      </v-row>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { DataTableHeader } from "vuetify";

//Components
import BackgroundImage from "@/components/shared/layout/BackgroundImage.vue";

//Models
import Migrations, { MigrationItem, MigrationTypeEnum } from "@/models/api/developer/Migrations";
import { getTransactionUrl } from "@/requests/developer";

@Component({
  components: {
    BackgroundImage,
  },
})
export default class DeveloperView extends Vue {
  get date(): string {
    return this.$store.direct.state.developer.dateTimeNow;
  }

  get migrations(): Migrations {
    return this.$store.direct.state.developer.migrations;
  }

  get transactionConfigurationIsLoaded(): boolean {
    return this.$store.direct.state.developer.transactionConfigurationIsLoaded;
  }

  get textResponse(): string {
    return this.$store.direct.state.developer.testResponse;
  }

  headers: Array<DataTableHeader> = [
    {
      text: "Data",
      value: "date",
      width: 130,
    },
    {
      text: "Nazwa",
      value: "name",
    },
  ];

  compareBody() {
    return this.textRequest === this.textResponse ? "equal" : "different";
  }

  getItemClass = (item: MigrationItem): string => {
    if (item.type === MigrationTypeEnum.Applied) return "applied";
    else if (item.type === MigrationTypeEnum.Pending) return "pending";
    return "";
  };

  migrate() {
    this.$store.direct.dispatch.developer.migrate();
  }

  dateTimeNow() {
    this.$store.direct.dispatch.developer.dateTimeNow();
  }

  checkTransactionConfigurationIsLoaded() {
    this.$store.direct.dispatch.developer.checkTransactionConfigurationIsLoaded();
  }

  loadTransactionConfigurationFromDataBase() {
    this.$store.direct.dispatch.developer.updateTransactionConfiguration();
  }

  textRequest = "";

  testResponse() {
    this.$store.direct.dispatch.developer.testBodyResponse("transactions=" + this.textRequest);
  }

  async created() {
    await this.$store.direct.dispatch.developer.migrations();
  }

  async getTransactionUrl() {
    await getTransactionUrl();
  }
}
</script>

<style lang="scss" scoped>
::v-deep .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  font-size: 11px !important;
  font-weight: 400 !important;
}

::v-deep .v-data-footer {
  z-index: 1;
  position: relative;
}

.border {
  border: 1px solid black;
  border-radius: 10px;

  // padding: 30px;
}

.text {
  font-size: 12px;
}

.x {
  width: 10vw;
  height: 100vh;
}

.y {
  width: 50vw;
  height: 100vh;
}

.z {
  width: 30vw;
  height: 100vh;
}

::v-deep .v-card {
  margin: 15px;
  border-radius: 10px;
  min-height: 650px;
  min-width: 650px;
  max-width: 650px;
}

.my-row {
  justify-content: space-evenly;
}
</style>
