<template>
  <div class="mt-10 ml-4 mr-4">
    <v-file-input ref="fileInput" v-model="fileData" style="display: none" :accept="accept" @change="showPreview" />
    <div class="image-container">
      <div v-if="url != null && typeof _valid === 'boolean'" class="image">
        <v-img :src="url" contain max-width="90%"></v-img>
      </div>
      <div v-else class="image">
        <div v-if="typeof _valid === 'boolean'">
          <span> Brak logo </span>
        </div>
        <div v-else>
          <span class="error--text" v-html="_valid"> </span>
        </div>
      </div>
      <div class="image-button-container">
        <v-btn color="btn main-light--reverse" @click="openExplorer">Wczytaj logo</v-btn>
      </div>
      <div v-if="fileData != null" class="image-cross-button-container">
        <v-btn icon color="btn main-light--reverse" @click="clear">
          <v-icon>$close</v-icon>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, PropSync, Watch } from "vue-property-decorator";
import { SnackbarModel } from "@/models/components/popup/Snackbar";

@Component
export default class AdministrationImagePicker extends Vue {
  @PropSync("file")
  fileData!: File | null;
  @PropSync("valid")
  _valid!: string | boolean;

  url: string | null = null;

  async setFile(file: File | null) {
    if (file != null) {
      if (file.size > 500000) {
        const currentFileSize = (file.size / 1000).toFixed().toString() + "KB";
        const snackbar: SnackbarModel = {
          content: ["loadedFileIsToBig"],
          color: "error",
          show: true,
          params: { currentFileSize: currentFileSize, maxFileSize: "500KB" },
        };
        await this.$store.direct.dispatch.popup.addSnackbar(snackbar);
        return;
      }
    }
    this.fileData = file;
  }

  showPreview(file: File | null) {
    this.setFile(file);
    setTimeout(() => {
      if (this.fileData == null) {
        this.url = null;
        return;
      }
      this.url = URL.createObjectURL(this.fileData);
    }, 200);
  }

  openExplorer() {
    (this.$refs.fileInput as HTMLFormElement).$refs.input.click();
  }

  clear() {
    this.setFile(null);
    this.url = null;
  }

  @Watch("file")
  readImage() {
    this.showPreview(this.fileData);
    this._valid = this.imageRule(this.fileData);
  }

  allowedTypes = ["image/jpeg", "image/png", "image/jpg"];
  accept = [".jpeg", ".png", ".jpg"];

  imageRule = (item: File | undefined | null): string | boolean => {
    if (item === null) {
      return true;
    }
    if (typeof item === "undefined") {
      return true;
    }
    if (this.allowedTypes.includes(item.type)) {
      return true;
    }
    return `Nie poprawny typ pliku <br>Wybierz format ${this.accept.join(" ")}`;
  };

  setValidState = (state: boolean | string) => {
    this._valid = state;
  };
}
</script>

<style lang="scss" scoped>
.image-container {
  position: relative;
  max-width: 360px;
  min-width: 200px;
  width: 100%;
  height: 150px;
  .v-image {
    border-radius: 10px;
    width: 100%;
    height: 100%;
  }
  .image {
    border-radius: 10px;
    border: 1px solid black;
    width: 100%;
    height: 100%;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .image-button-container {
    width: 100%;
    transform: translate(calc(50%), 0);
    .btn {
      position: absolute;
      transform: translate(calc(-50%), 0);
      z-index: 1;
      bottom: -17px;
    }
  }
  .image-cross-button-container {
    position: absolute;
    top: 0;
    right: 0;
    .btn {
      z-index: 1;
    }
  }
}
</style>
