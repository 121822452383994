import { defineModule } from "direct-vuex";
import { moduleActionContext, moduleGetterContext } from "@/store/index";

// Requests
import { getFormsCompleted } from "@/requests/report";
import {
  getCompanies,
  lockCompany,
  editCompany,
  downloadCompanyDetails,
  downloadCompanyUsers,
  addCompany,
} from "@/requests/company";
import { editUser, lockUser, unlockUser, setUserAsAdmin, addUser } from "@/requests/user";

// Models
import { ConfirmModel } from "@/models/components/popup/Confirm";
import { ListResult } from "@/models/api/filters/ListResult";
import UserDetails from "@/models/api/user/UserDetails";
import CompanyDetails from "@/models/api/company/CompanyDetails";
import CompanyTreeBasicInfo from "@/models/api/company/CompanyTreeBasicInfo";
import FormBasic from "@/models/api/reports/formBasic";
import UserRequest from "@/models/api/user/UserRequest";
import CompanyModel from "@/models/api/company/CompanyModel";
import UserInfoResponse from "@/models/api/user/UserInfoResponse";

//Translations
import { filterCapitalize } from "@/filters/capitalize";
import i18n from "@/plugins/i18n";
import { bold } from "@/filters/bold";

export interface AdministrationState {
  //
  companyTableItems: ListResult<CompanyTreeBasicInfo> | null;

  //
  selectedCompanyId: string | null;
  selectedCompanyUsersDetails: Array<UserInfoResponse>;
  selectedCompanyDetails: CompanyDetails | null;
  companyReportsPreview: ListResult<FormBasic> | null;
}

export const AdministrationState: AdministrationState = {
  //
  companyTableItems: null,

  //
  selectedCompanyId: null,
  selectedCompanyUsersDetails: [],
  selectedCompanyDetails: null,
  companyReportsPreview: null,
};

const administrationModule = defineModule({
  state: AdministrationState,
  namespaced: true as const,
  getters: {},
  mutations: {
    setCompanyTableItems(state, items: ListResult<CompanyTreeBasicInfo>) {
      state.companyTableItems = items;
    },
    setCompanyDetails(state, item: CompanyDetails | null) {
      state.selectedCompanyDetails = item;
    },
    setCompanyUsersDetails(state, item: Array<UserInfoResponse>) {
      state.selectedCompanyUsersDetails = item;
    },
    setSelectedCompanyId(state, companyId: string | null) {
      state.selectedCompanyId = companyId;
    },
    setCompanyReportsPreview(state, param: ListResult<FormBasic>) {
      state.companyReportsPreview = param;
    },
  },
  actions: {
    async getCompanies(context) {
      const { commit, rootState } = _moduleActionContext(context);
      const filter = rootState.filter.administrationHierarchyBasicFilterRequest;
      const response = await getCompanies(filter);
      commit.setCompanyTableItems(response.model);
    },
    async getSelectedCompanyDetails(context) {
      const { state, commit } = _moduleActionContext(context);
      const companyId = state.selectedCompanyId;
      if (companyId != null) {
        const result = await downloadCompanyDetails(companyId);
        commit.setCompanyDetails(result.model);
      }
    },
    async getSelectedCompanyUserDetails(context) {
      const { state, commit } = _moduleActionContext(context);
      const companyId = state.selectedCompanyId;
      if (companyId != null) {
        const result = await downloadCompanyUsers(companyId);
        commit.setCompanyUsersDetails(result.model);
      }
    },
    async addNewCompany(context, model: CompanyModel): Promise<boolean> {
      const { dispatch } = _moduleActionContext(context);
      const result = await addCompany(model);
      if (result.status == 200) {
        await dispatch.getCompanies();
        return true;
      } else {
        return false;
      }
    },
    async editCompany(context, model: CompanyDetails): Promise<boolean> {
      const { rootDispatch, dispatch } = _moduleActionContext(context);
      const confirmModel = new ConfirmModel(
        filterCapitalize(i18n.t("confirm.information")),
        filterCapitalize(i18n.t("confirm.administration.editCompanyDetails"))
      );
      const isConfirmed = await rootDispatch.popup.addConfirm(confirmModel);
      if (isConfirmed) {
        const result = await editCompany(model);
        if (result.status == 200) {
          dispatch.getCompanies();
          return true;
        }
        return false;
      } else return false;
    },
    async lockCompany(context, item: CompanyTreeBasicInfo) {
      const { state, dispatch, rootDispatch } = _moduleActionContext(context);
      const companyId = state.selectedCompanyId;
      if (companyId != null) {
        const confirmModel = new ConfirmModel(
          filterCapitalize(i18n.t("confirm.information")),
          filterCapitalize(
            i18n.t("confirm.administration.lockCompany", {
              company: item.name,
            })
          )
        );
        const isConfirmed = await rootDispatch.popup.addConfirm(confirmModel);
        if (isConfirmed) {
          await lockCompany(companyId);
          await dispatch.getCompanies();
        }
      }
    },
    async addNewUser(context, user: UserRequest): Promise<boolean> {
      const { state, dispatch, rootDispatch } = _moduleActionContext(context);
      const companyId = state.selectedCompanyId;
      if (companyId != null) {
        const result = await addUser(user, companyId);
        if (result.status == 200) {
          dispatch.getSelectedCompanyUserDetails();
          rootDispatch.popup.addSnackbarSuccess();
          return true;
        } else {
          return false;
        }
      }
      return false;
    },
    async editUser(context, user: UserDetails) {
      const { dispatch } = _moduleActionContext(context);
      const result = await editUser(user);
      if (result.status == 200) {
        dispatch.getSelectedCompanyUserDetails();
        return true;
      } else {
        return false;
      }
    },
    async lockUser(context, user: UserInfoResponse) {
      const { dispatch } = _moduleActionContext(context);
      const result = await lockUser(user.id);
      if (result.status == 200) {
        dispatch.getSelectedCompanyUserDetails();
        return true;
      } else {
        return false;
      }
    },
    async unlockUser(context, user: UserInfoResponse) {
      const { dispatch } = _moduleActionContext(context);
      const result = await unlockUser(user.id);
      if (result.status == 200) {
        dispatch.getSelectedCompanyUserDetails();
        return true;
      } else {
        return false;
      }
    },
    async setUserAsAdmin(context, item: UserInfoResponse) {
      const { rootDispatch, dispatch } = _moduleActionContext(context);

      let userFullName = "";
      if (item.name === "") {
        userFullName = bold(item.email);
      } else {
        userFullName = bold(item.name + " " + item.surname);
      }

      const confirmModel = new ConfirmModel(
        filterCapitalize(i18n.t("confirm.information")),
        filterCapitalize(
          i18n.t("confirm.administration.setUserAsAdmin", {
            userName: userFullName,
          })
        )
      );
      const isConfirmed = await rootDispatch.popup.addConfirm(confirmModel);
      if (isConfirmed) {
        await setUserAsAdmin(item.id);
        await dispatch.getSelectedCompanyUserDetails();
      }
    },
    async getCompanyReportsPreview(context) {
      const { state, commit, rootState } = _moduleActionContext(context);
      const companyId = state.selectedCompanyId;
      if (companyId != null) {
        const filter = rootState.filter.administrationReportsFilesFilterRequest;
        const response = await getFormsCompleted(filter, companyId);
        commit.setCompanyReportsPreview(response.model);
      }
    },
  },
});

export default administrationModule;

/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable  @typescript-eslint/no-unused-vars */
const _moduleGetterContext = (args: [any, any, any, any]) => moduleGetterContext(args, administrationModule);
const _moduleActionContext = (context: any) => moduleActionContext(context, administrationModule);
