import { defineModule } from "direct-vuex";
import { moduleActionContext } from "@/store/index";

//Models
import { AlertModel } from "@/models/components/popup/Alert";
import { ConfirmModel } from "@/models/components/popup/Confirm";
import { Resolver } from "@/models/components/popup/Resolver";
import { SnackbarModel } from "@/models/components/popup/Snackbar";
import { bold } from "@/filters/bold";

export interface PopupStateInterface {
  showAlerts: boolean;
  alerts: AlertModel[];
  alertTimeOut: number;

  showConfirms: boolean;
  confirms: Array<ConfirmModel & Resolver>;

  showSnackbars: boolean;
  snackbars: SnackbarModel[];
  snackbarTimeOut: number;
}

export const PopupState: PopupStateInterface = {
  showAlerts: false,
  alerts: [],
  alertTimeOut: 5000,

  showConfirms: false,
  confirms: [],

  showSnackbars: false,
  snackbars: [],
  snackbarTimeOut: 2000,
};

const popupModule = defineModule({
  state: PopupState as PopupStateInterface,
  namespaced: true as const,
  getters: {},
  mutations: {
    HIDE_ALERTS(state) {
      state.showAlerts = false;
    },
    ADD_ALERT(state, alertModel: AlertModel) {
      state.showAlerts = true;
      state.alerts.push(alertModel);
    },
    CLEAR_ALERTS(state) {
      state.alerts = [];
    },
    HIDE_CONFIRMS(state) {
      state.showConfirms = false;
    },
    ADD_CONFIRM(state, confirmModel: ConfirmModel & Resolver) {
      state.showConfirms = true;
      state.confirms.push(confirmModel);
    },
    CLEAR_CONFIRMS(state) {
      state.confirms = [];
    },
    HIDE_SNACKBARS(state) {
      state.showSnackbars = false;
    },
    ADD_SNACKBAR(state, snackbarModel: SnackbarModel) {
      state.showSnackbars = true;
      state.snackbars.push(snackbarModel);
    },
    CLEAR_SNACKBARS(state) {
      state.snackbars = [];
    },
  },
  actions: {
    addConfirm(context, payload: ConfirmModel) {
      const { commit } = _moduleActionContext(context);
      return new Promise<boolean>((resolve) => {
        commit.ADD_CONFIRM({ ...payload, resolver: resolve });
      });
    },
    addSnackbar(context, payload: SnackbarModel) {
      const { commit } = _moduleActionContext(context);
      commit.ADD_SNACKBAR(payload);
    },
    addSnackbarSuccess(context) {
      const { dispatch } = _moduleActionContext(context);
      const snackbar = new SnackbarModel(["operationSuccess"]);
      dispatch.addSnackbar(snackbar);
    },
    addSnackbarError(context) {
      const { dispatch } = _moduleActionContext(context);
      const snackbar = new SnackbarModel(["operationError"], "error");
      dispatch.addSnackbar(snackbar);
    },
    addSnackbarWarning(context) {
      const { dispatch } = _moduleActionContext(context);
      const snackbar = new SnackbarModel(["operationWarning"], "warning");
      dispatch.addSnackbar(snackbar);
    },
    addSnackbarFormError(context, formName?: string) {
      const { dispatch } = _moduleActionContext(context);
      const snackbar = new SnackbarModel(["errorInForms"], "error", {
        formName: typeof formName === "undefined" ? "" : bold(formName),
      });
      dispatch.addSnackbar(snackbar);
    },
  },
});

export default popupModule;

/* eslint-disable  @typescript-eslint/no-explicit-any */
//const _moduleGetterContext = (args: [any, any, any, any]) => moduleGetterContext(args, popupModule);
const _moduleActionContext = (context: any) => moduleActionContext(context, popupModule);
