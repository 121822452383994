<template>
  <v-container class="m-footer">
    <v-col cols="12" class="pa-5">
      <v-row justify="center" class="ma-2">
        <img src="@/assets/images/logoBlack.svg" />
      </v-row>
      <v-row justify="center" class="ma-0 pa-2">
        <div class="text-h6 font-weight-bold">Siedziba</div>
      </v-row>
      <v-row align="center" class="d-flex flex-column ma-0">
        <div class="text-body-2">
          <p class="d-flex flex-column">
            <span>Kielnarowa 108b</span>
            <span>36-020 Tyczyn</span>
            <span>Tel. 17 779 64 13</span>
            <span>biuro@sukcesja.pl</span>
          </p>
          <p class="d-flex flex-column">
            <span>KRS 0000466256</span>
            <span>NIP 7010374912</span>
            <span>REGON 146615458</span>
          </p>
        </div>
      </v-row>
      <v-row justify="center">
        <v-btn icon @click="$router.redirectToFacebook()">
          <v-icon size="24">$facebook</v-icon>
        </v-btn>
        <v-btn icon @click="$router.redirectToLinkedIn()">
          <v-icon size="24">$linkedIn</v-icon>
        </v-btn>
      </v-row>
      <v-row class="pa-2">
        <img src="@/assets/images/eu1.jpg" style="width: 100%" />
      </v-row>
      <v-row class="pa-2">
        <img src="@/assets/images/eu2.jpg" style="width: 100%" />
      </v-row>
      <v-row class="pa-5" justify="space-between">
        <go-to :name="0" label="Home" />
        <go-to name="#products" label="Cennik" />
        <go-to name="#about" label="o nas" />
        <go-to name="#contact" label="Kontakt" />
        <div class="d-flex flex-column align-center" style="width: 100%">
          <router-link-new-tab class="mt-1" to="/terms-of-use" type="button"
            >{{ $t("form.termsOfUse") | capitalize }}
          </router-link-new-tab>
          <router-link-new-tab class="mt-1" to="/privacy-policy" type="button"
            >{{ $t("form.privacyPolicy") | capitalize }}
          </router-link-new-tab>
        </div>
      </v-row>
    </v-col>
  </v-container>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import GoTo from "@/components/shared/buttons/GoTo.vue";
import RouterLinkNewTab from "@/components/shared/buttons/RouterLinkNewTab.vue";

@Component({
  components: {
    GoTo,
    RouterLinkNewTab,
  },
})
export default class FooterMobile extends Vue {}
</script>

<style lang="scss" scoped>
.m-footer {
  position: relative;
  z-index: 5;
}
</style>
