import { defineModule } from "direct-vuex";
import { moduleActionContext, moduleGetterContext } from "@/store/index";

//Confirmation and translations
import { filterCapitalize } from "@/filters/capitalize";
import { ConfirmModel } from "@/models/components/popup/Confirm";
import i18n from "@/plugins/i18n";
import { bold } from "@/filters/bold";

//Models
import License, { LicenseBasicInfo } from "@/models/api/licenses/license";

//Models-Components
import {
  LicenseAssignUserInfo,
  LicenseAssignCompanyInfo,
  LicenseLockUnlockInfo,
} from "@/models/components/license/licenseInfo";

//Requests
import {
  getLicenses,
  assignToUser,
  assignToCompany,
  lockCompanyLicense,
  unlockCompanyLicense,
  getLicensesHistory,
  userActiveLicenses,
} from "@/requests/licenses";
import { ListResult } from "@/models/api/filters/ListResult";

export interface LicenseStateInterface {
  licenses: ListResult<License> | null;
  licensesHistory: ListResult<License> | null;
  userActiveLicenses: Array<LicenseBasicInfo>;
}

export const LicenseState: LicenseStateInterface = {
  licenses: null,
  licensesHistory: null,
  userActiveLicenses: [],
};

const licenseModule = defineModule({
  state: LicenseState as LicenseStateInterface,
  namespaced: true as const,
  getters: {},
  mutations: {
    setLicenses(state, param: ListResult<License>) {
      state.licenses = param;
    },
    setLicensesHistory(state, param: ListResult<License>) {
      state.licensesHistory = param;
    },
    setUserActiveLicenses(state, param: Array<LicenseBasicInfo>) {
      state.userActiveLicenses = param;
    },
  },
  actions: {
    async getLicenses(context) {
      const { commit, rootState } = _moduleActionContext(context);
      const filter = rootState.filter.licenseFilterRequest;
      const response = await getLicenses(filter);
      commit.setLicenses(response.model);
    },
    async getLicensesHistory(context) {
      const { commit, rootState } = _moduleActionContext(context);
      const filter = rootState.filter.licenseHistoryFilterRequest;
      const licenses = await getLicensesHistory(filter);
      commit.setLicensesHistory(licenses.model);
    },
    async getUserActiveLicenses(context) {
      const { commit } = _moduleActionContext(context);
      const response = await userActiveLicenses();
      commit.setUserActiveLicenses(response.model);
    },
    async assignLicenseItemToUser(context, assignInfo: LicenseAssignUserInfo) {
      const { rootDispatch, dispatch } = _moduleActionContext(context);
      const confirmModel = new ConfirmModel(
        filterCapitalize(i18n.t("confirm.information")),
        filterCapitalize(
          i18n.t("confirm.license.assignLicenseItemToUser", {
            numberOfLicenseItems: bold(assignInfo.assignUser.numberOfLicenseItems.toString()),
            toUse: bold(assignInfo.toUse.toString()),
            license: bold(assignInfo.licenseName),
            user: bold(assignInfo.userName),
          })
        ),
        2
      );

      const isConfirmed = await rootDispatch.popup.addConfirm(confirmModel);
      if (isConfirmed) {
        const result = await assignToUser(assignInfo.assignUser);
        await dispatch.getLicenses();
        await rootDispatch.user.userLicenseItemsCount();
        return result.status == 200 ? true : false;
      }
    },
    async assignLicenseItemToCompany(context, assignInfo: LicenseAssignCompanyInfo) {
      const { rootDispatch, dispatch } = _moduleActionContext(context);

      const confirmModel = new ConfirmModel(
        filterCapitalize(i18n.t("confirm.information")),
        filterCapitalize(
          i18n.t("confirm.license.assignLicenseItemToCompany", {
            numberOfLicenseItems: bold(assignInfo.assignCompany.numberOfLicenseItems.toString()),
            toUse: bold(assignInfo.toUse.toString()),
            license: bold(assignInfo.licenseName),
            company: bold(assignInfo.companyName),
          })
        ),
        2
      );

      const isConfirmed = await rootDispatch.popup.addConfirm(confirmModel);
      if (isConfirmed) {
        const result = await assignToCompany(assignInfo.assignCompany);
        await dispatch.getLicenses();
        await rootDispatch.user.userLicenseItemsCount();
        return result.status == 200 ? true : false;
      }
    },
    async lockCompanyLicense(context, licenseInfo: LicenseLockUnlockInfo) {
      const { rootDispatch } = _moduleActionContext(context);
      const confirmModel = new ConfirmModel(
        filterCapitalize(i18n.t("confirm.information")),
        filterCapitalize(
          i18n.t("confirm.license.lockCompanyLicense", {
            license: bold(licenseInfo.licenseName),
            company: bold(licenseInfo.companyName),
          })
        ),
        2
      );

      const isConfirmed = await rootDispatch.popup.addConfirm(confirmModel);
      if (isConfirmed) {
        await lockCompanyLicense(licenseInfo.companyId, licenseInfo.licenseId);
        await rootDispatch.license.getLicenses();
        await rootDispatch.user.userLicenseItemsCount();
      }
    },
    async unlockCompanyLicense(context, licenseInfo: LicenseLockUnlockInfo) {
      const { rootDispatch } = _moduleActionContext(context);
      const confirmModel = new ConfirmModel(
        filterCapitalize(i18n.t("confirm.information")),
        filterCapitalize(
          i18n.t("confirm.license.unlockCompanyLicense", {
            license: bold(licenseInfo.licenseName),
            company: bold(licenseInfo.companyName),
          })
        ),
        2
      );

      const isConfirmed = await rootDispatch.popup.addConfirm(confirmModel);
      if (isConfirmed) {
        await unlockCompanyLicense(licenseInfo.companyId, licenseInfo.licenseId);
        await rootDispatch.license.getLicenses();
        await rootDispatch.user.userLicenseItemsCount();
      }
    },
  },
});

export default licenseModule;

/* eslint-disable */
const _moduleGetterContext = (args: [any, any, any, any]) =>
  moduleGetterContext(args, licenseModule);
const _moduleActionContext = (context: any) =>
  moduleActionContext(context, licenseModule);
