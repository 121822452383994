import Vue from "vue";
import Vuex from "vuex";
import { createDirectStore } from "direct-vuex";
import createPersistedState from "vuex-persistedstate";

//stores
import user from "@/store/modules/user";
import company from "@/store/modules/company";
import popup from "@/store/modules/popup";
import administration from "@/store/modules/administration";
import license from "@/store/modules/licenses";
import transaction from "@/store/modules/transaction";
import developer from "@/store/modules/developer";
import report from "@/store/modules/report";
import filter from "@/store/modules/filter";
import product from "@/store/modules/product";
import vuetify from "@/store/modules/vuetify";
import INavigationDrawer from "@/models/components/navigation/Navigation";
import setting from "@/store/modules/setting";
import statistic from "@/store/modules/statistic";

Vue.use(Vuex);

export interface RootStateInterface {
  pendingConnections: number;
  navigation: INavigationDrawer;
}

export const rootState: RootStateInterface = {
  pendingConnections: 0,
  navigation: { mini: true, show: false },
};

const { store, rootActionContext, moduleActionContext, rootGetterContext, moduleGetterContext } = createDirectStore({
  state: rootState,
  modules: {
    user,
    company,
    popup,
    administration,
    license,
    transaction,
    developer,
    report,
    filter,
    product,
    setting,
    statistic,
    vuetify,
  },
  mutations: {
    INCREASE_PENDING_CALLS(state) {
      state.pendingConnections++;
    },
    DECREASE_PENDING_CALLS(state) {
      state.pendingConnections--;
    },
    SET_NAVIGATION(state, object: INavigationDrawer) {
      state.navigation = object;
    },
  },
  getters: {
    isLoading(...args): boolean {
      const { state } = _moduleGetterContext(args);
      return state.pendingConnections > 0;
    },
  },
  actions: {},
  plugins: [
    createPersistedState({
      storage: localStorage,
      key: "user-state",
      paths: ["user", "company"],
      /* eslint-disable  @typescript-eslint/no-explicit-any */
      reducer: (state: any) => ({
        //reducer to remove selected state to empty object can use if data is stored in localStorage to remove object
        user: state.user.token == null ? {} : state.user,
        company: state.user.token == null ? {} : state.company, //-> can assign many states
      }),
    }),
    createPersistedState({
      storage: localStorage,
      key: "popup-state",
      paths: ["popup"],
      reducer: (state: any) => ({
        popup: state.user.token == null ? {} : state.popup,
      }),
    }),
    createPersistedState({
      storage: localStorage,
      key: "common",
      paths: ["product"],
      /* eslint-disable  @typescript-eslint/no-explicit-any */
      reducer: (state: any) => ({
        product: state.user.token == null ? {} : state.product,
        // transaction: state.user.token == null ? {} : state.transaction,
      }),
    }),
  ],
});

// Export the direct-store instead of the classic Vuex store.
export { store as vuexStore };
export default store;

// The following exports will be used to enable types in the
// implementation of actions and getters.
export { rootActionContext, moduleActionContext, rootGetterContext, moduleGetterContext };

// The following lines enable types in the injected store '$store'.
export type AppStore = typeof store;
declare module "vuex" {
  //eslint-disable-next-line
  interface Store<S> {
    direct: AppStore;
  }
}

/* eslint-disable */
// @ts-ignore
const _moduleGetterContext = (args: [any, any, any, any]) =>  moduleGetterContext(args, store);
