import { render, staticRenderFns } from "./AppBarDesktop.vue?vue&type=template&id=b13749d0&scoped=true"
import script from "./AppBarDesktop.vue?vue&type=script&lang=ts"
export * from "./AppBarDesktop.vue?vue&type=script&lang=ts"
import style0 from "./AppBarDesktop.vue?vue&type=style&index=0&id=b13749d0&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b13749d0",
  null
  
)

export default component.exports