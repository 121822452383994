<template>
  <span class="scaling-svg-container">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25.949 14.777" class="scaling-svg">
      <g transform="translate(1)">
        <g transform="translate(0 0)">
          <path
            d="M13.223,0H0"
            transform="translate(0 7.388)"
            fill="none"
            stroke="currentColor"
            stroke-linecap="square"
            stroke-linejoin="round"
            stroke-width="2"
          />
          <path d="M0,14.777,11.726,7.388,0,0Z" transform="translate(13.223)" fill="currentColor" />
        </g>
      </g>
    </svg>
  </span>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

@Component
export default class ButtonRightArrowIcon extends Vue {}
</script>

<style></style>
