import { CustomRouteConfig } from "@/models/plugins/CustomRouterTypes";
import ROLE from "@/static/role";
import ROUTE_NAMES from "@/static/routeNames";

//Views
import LoginView from "@/views/anonymous/LoginView.vue";
import RegisterView from "@/views/anonymous/RegisterView.vue";
import WelcomeView from "@/views/anonymous/WelcomeView.vue";
import RemindPasswordView from "@/views/anonymous/RemindPasswordView.vue";
import TransactionView from "@/views/anonymous/TransactionView.vue";
import TermsOfUseView from "@/views/anonymous/TermsOfUseView.vue";
import PrivacyPolicyView from "@/views/anonymous/PrivacyPolicyView.vue";
import FrequentlyAskedQuestionsView from "@/views/anonymous/FrequentlyAskedQuestionsView.vue";

const GeneralRoutes: Array<CustomRouteConfig> = [
  {
    name: ROUTE_NAMES.GENERAL.WELCOME,
    path: "/",
    component: WelcomeView,
    meta: {
      isAnonymousAccess: true,
      userRole: ROLE.Guest,
    },
  },
  {
    name: ROUTE_NAMES.GENERAL.LOGIN,
    path: "/login",
    component: LoginView,
    meta: {
      isAnonymousAccess: true,
      userRole: ROLE.Guest,
    },
  },
  {
    name: ROUTE_NAMES.GENERAL.REGISTER,
    path: "/register",
    component: RegisterView,
    meta: {
      isAnonymousAccess: true,
      userRole: ROLE.Guest,
    },
  },
  {
    name: ROUTE_NAMES.GENERAL.TERMS,
    path: "/terms-of-use",
    component: TermsOfUseView,
    meta: {
      isAnonymousAccess: true,
      userRole: ROLE.Guest,
    },
  },
  {
    name: ROUTE_NAMES.GENERAL.PRIVACY,
    path: "/privacy-policy",
    component: PrivacyPolicyView,
    meta: {
      isAnonymousAccess: true,
      userRole: ROLE.Guest,
    },
  },
  {
    name: ROUTE_NAMES.GENERAL.FAQ,
    path: "/faq",
    component: FrequentlyAskedQuestionsView,
    meta: {
      isAnonymousAccess: true,
      userRole: ROLE.Guest,
    },
  },
  {
    name: ROUTE_NAMES.GENERAL.REMIND,
    path: "/remind-password",
    component: RemindPasswordView,
    meta: {
      isAnonymousAccess: true,
      userRole: ROLE.Guest,
    },
  },
  {
    name: ROUTE_NAMES.GENERAL.TRANSACTION,
    path: "/transaction",
    component: TransactionView,
    meta: {
      isAnonymousAccess: false,
      userRole: ROLE.Admin,
    },
  },
];

export { GeneralRoutes };
export default GeneralRoutes;
