<template>
  <filters
    :filter-name="filterName"
    :options.sync="_options"
    @download="download"
    @setFilterRequest="setFilterRequest"
  />
</template>

<script lang="ts">
import { Component, Vue, PropSync } from "vue-property-decorator";
import { DataOptions } from "vuetify";

//Components
import Filters from "@/components/shared/filter/Filters.vue";

//Static
import { FILTER_NAMES } from "@/static/filterNames";
import FilterRequest from "@/models/api/filters/FilterRequest";

@Component({
  components: {
    Filters,
  },
})
export default class AdministrationReportsFilesFilter extends Vue {
  //$ FilterName
  filterName = FILTER_NAMES.DefaultFilter;

  //$ Synchronize options
  @PropSync("options")
  _options!: DataOptions;

  //$ Set FilterRequest
  setFilterRequest(filter: FilterRequest) {
    this.$store.direct.commit.filter.setAdministrationReportsFilesFilterRequest(filter);
  }

  //$ Download items
  async download() {
    await this.$store.direct.dispatch.administration.getCompanyReportsPreview();
  }
}
</script>

<style lang="scss" scoped></style>
