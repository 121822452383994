<template>
  <v-container fluid class="view-container">
    <div v-if="selectedProduct != null" class="x">
      <div v-if="step == 1 || step == 2" class="d-flex align-center flex-column">
        <span class="text-h4 font-weight-bold"> Wybrałeś pakiet {{ selectedProduct.name }} </span>
        <span class="text-body-1 font-weight-regular mt-3"> Uzupełnij wymagane dane i przejdź do płatności </span>
      </div>
      <v-stepper v-model="step" elevation="0">
        <v-stepper-items>
          <v-stepper-content step="1">
            <div class="y">
              <div class="product-container" style="height: 350px">
                <product-card :item="selectedProduct" :can-select="false" class="ma-0" />
              </div>
              <v-divider vertical class="divider" />
              <div class="form-container" style="min-width: 292px; max-width: 400px">
                <transaction-form ref="transactionForm" />
              </div>
            </div>
            <div class="buttons-container">
              <div class="form-buttons">
                <v-btn class="btn main-light--reverse" @click="goToStep2"> Przejdź do podsumowania </v-btn>
              </div>
            </div>
          </v-stepper-content>
          <v-stepper-content step="2">
            <div class="y">
              <div class="product-container" style="height: 350px">
                <product-card :item="selectedProduct" :can-select="false" class="ma-0" />
              </div>
              <v-divider vertical class="divider" />
              <div class="form-container" style="min-width: 292px; max-width: 400px">
                <transaction-form :disabled="step == 2" />
                <v-checkbox v-model="lastConfirmation" label="Potwierdzam zgodność powyższych danych"> </v-checkbox>
                <v-checkbox v-model="termsConfirmation" class="text-caption mt-0" color="black" hide-details>
                  <template #label>
                    <span>
                      Oświadczam iż zapoznałem się i akceptuje regulamin sprzedaży
                      <router-link-new-tab style="display: contents" to="/terms-of-use" type="a"
                        >{{ $t("form.termsOfUse") | capitalize }}
                      </router-link-new-tab>
                    </span>
                  </template>
                </v-checkbox>
              </div>
            </div>
            <div class="buttons-container">
              <div class="form-buttons">
                <v-btn class="btn main-light" @click="prev"> Wstecz </v-btn>
                <v-btn
                  :disabled="!lastConfirmation || !termsConfirmation"
                  class="btn main-light--reverse"
                  @click="createTransaction"
                >
                  Przejdź do płatności
                </v-btn>
              </div>
            </div>
          </v-stepper-content>
          <v-stepper-content step="3">
            <div class="d-flex flex-column justify-center flex-grow-1">
              <div class="d-flex align-center flex-column">
                <span class="text-h4 font-weight-bold"> Zakupiłeś pakiet {{ selectedProduct.name }} </span>
                <span class="text-body-1 font-weight-regular mt-3">
                  Kliknij w poniższy przycisk, aby opłacić swoje zamówienie.
                </span>
              </div>
              <div class="d-flex align-center flex-column mt-10">
                <p>
                  <span>
                    Numer twojego zamówienia
                    <span class="font-weight-bold">
                      {{ order.id }}
                    </span>
                  </span>
                </p>
                <p>
                  <span> Na adres e-mail dostaniesz powiadomienie o statusie zamówienia. </span>
                </p>
              </div>
              <div class="d-flex justify-center mt-10">
                <v-btn class="btn main-light--reverse" @click="redirectToPayment"> Opłać swoje zamówienie </v-btn>
              </div>
            </div>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </div>
  </v-container>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

//Components
import ProductCard from "@/components/shared/layout/ProductCard.vue";
import Product from "@/models/api/products/product";
import RouterLinkNewTab from "@/components/shared/buttons/RouterLinkNewTab.vue";

//Forms
import TransactionForm from "@/components/transaction/TransactionForm.vue";
import routerInstance from "@/plugins/router";

@Component({
  components: {
    ProductCard,
    TransactionForm,
    RouterLinkNewTab,
  },
})
export default class TransactionView extends Vue {
  step = 1;
  lastConfirmation = false;
  termsConfirmation = false;

  next() {
    this.step += 1;
  }

  prev() {
    this.step -= 1;
    this.lastConfirmation = false;
    this.termsConfirmation = false;
  }

  async goToStep2() {
    const canGoNext = await (this.$refs.transactionForm as HTMLFormElement).validate();
    if (canGoNext) {
      this.next();
    }
  }

  async createTransaction() {
    if (this.selectedProduct == null) return;
    const isConfirmed = await this.$store.direct.dispatch.transaction.createTransaction(this.selectedProduct.id);
    if (isConfirmed) {
      this.step = 3;
    }
  }

  redirectToPayment() {
    routerInstance.redirectToPage(this.transactionUrl);
  }

  get order() {
    return {
      id: "555d273a1ece47578a5f8ee1ce635efb",
      transactionId: "08db3672-0067-481e-8c84-9c9369ee8acf",
    };
  }

  get transactionUrl(): string {
    return this.$store.direct.state.transaction.transactionUrl ?? "about:blank";
  }

  get selectedProduct(): Product | null {
    return this.$store.direct.state.product.selectedProduct;
  }

  async validateTransactionForm() {
    const isValid = (await (this.$refs.transactionForm as HTMLFormElement).validate()) as boolean;
    return isValid;
  }

  async mounted() {
    if (this.selectedProduct === null) {
      this.$router.push("/statistics/statistic");
    }
    await Promise.all([
      this.$store.direct.dispatch.user.getUserDetails(),
      this.$store.direct.dispatch.user.getUserCompanyDetails(),
    ]);
  }
}
</script>

<style lang="scss" scoped>
.divider {
  margin: 0 100px;
}

.form-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 40px;
  flex-grow: 1;
  align-items: flex-end;
}

.x {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  @media screen and (max-width: 1353px) {
    .y {
      flex-direction: column;
      align-content: center;
    }
    .form-container {
      margin-top: 40px;
    }
    .divider {
      border: none;
      height: 0;
      min-height: 0;
      max-height: 0;
    }
  }
}

.y {
  margin-top: 40px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 40px;
}

.product-container {
  display: flex;
  justify-content: center;
}

.buttons-container {
  display: flex;
  flex-grow: 1;
}

::v-deep .v-stepper {
  height: 100%;
  .v-stepper__items {
    height: 100%;
    .v-stepper__content {
      height: 100%;
    }
  }
}

::v-deep .v-stepper__wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

::v-deep .v-stepper__content {
  padding: 0;
}
</style>
