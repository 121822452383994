
/**
 * Loader API Version: 2
 * Is this in "webpack mode": true
 */
export default {
    "router": {
        "noPermission": "Brak uprawnień",
        "noAuthorized": "Najpierw się zaloguj"
    },
    "routes": {
        "administration": "administracja",
        "reports": "raporty",
        "statistics": "statystyka",
        "licenses": "licencje",
        "settings": "ustawienia",
        "help": "pomoc",
        "transactionStatus": "status"
    },
                                
    "alerts": {
        "error": "błąd",
        "close": "zamknij",
        "internalError": "ups... Coś poszło nie tak! Skontaktuj się z administratorem systemu"
    },
    "snackbars": {
        "operationSuccess": "operacja zakończona pomyślnie",
        "operationError": "operacja zakończona błędem",
        "operationWarning": "operacja zakończona warunkowo",
        "errorInForms": "formularz {formName} zawiera błędy, popraw aby kontynuować",
        "contactWithAdministrator": "twoje konto zostało zablokowane. Skontaktuj się z administratorem",
        "userNotFound": "użytkownik nie został znaleziony, sprawdź login i hasło",
        "checkForms": "popraw błędy w formularzu, aby kontynuować.",
        "passwordMismatch": "niepoprawne hasło",
        "passwordTooShort": "hasło za krótkie",
        "passwordRequiresDigit": "hasło wymaga cyfry",
        "passwordRequiresUpper": "hasło wymaga dużej litery",
        "passwordRequiresLower": "hasło wymaga małej litery",
        "passwordRequiresUniqueChars": "hasło wymaga znaku specjalnego",
        "numberToSmall": "wartość jest zbyt mała",
        "numberToLarge": "wartość jest zbyt duża",
        "decimalPlacesToLarge": "zbyt długa wartość po przecinku",
        "fillString": "uzupełnij pole",
        "stringToSmall": "ciąg tekstowy jest za krótki",
        "stringToLarge": "ciąg tekstowy jest za długi",
        "isNotDate": "podany ciąg nie jest datą",
        "dateToSmall": "wybrana data jest za mała",
        "dateToLarge": "wybrana data jest za duża",
        "sumIsTooSmall": "suma jest zbyt mała",
        "sumIsTooLarge": "suma jest zbyt duża",
        "fillAllForm": "Uzupełnij formularze wszystkich wspólników",
        "messageSent": "Widomość została wysłana",
        "checkYourEmailRemindPassword": "Sprawdź skrzynkę mailową",
        "remindPassword": "Brak możliwości zmiany hasła - skontaktuj się z nami mailowo",
        "selectOneOption": "Wybierz jedną opcję",
        "cannnotSysUser": "Nie możesz modyfikować systemowego użytkownika!",
        "cannnotLockYourself": "Nie możesz zablokować samego siebie!",
        "cannnotLockSysCompany": "Nie możesz modyfikować tej firmy!",
        "cannnotLockYourCompany": "Nie możesz zablokować swojej firmy!",
        "answerNotSelected": "Nie wybrano odpowiedzi",
        "minCountMore1": "Minimalna ilość to 1.",
        "daysCountMustBePositive": "Liczba dni musi być dodatnia.",
        "cannotAssigeLockLicense": "Nie możesz przekazać zablokowanej licencji.",
        "notEnoughLicenseCount": "Niewystarczająca ilość licencji do przekazania.",
        "cannotBuyLicense": "Nie masz uprawnień aby przejść do kupna licencji",
        "loadedFileIsToBig": "Wybrany plik jest zbyt duży ({currentFileSize}), załaduj plik o maksymalnej wielkości {maxFileSize}",
        "fileSizeToBig": "Przesłany plik jest za duży, wybierz inny plik",
        "wrongFileExtension": "Wybrany plik ma niepoprawny format, zmień plik aby kontynuować",
        "wrongFileFormat": "Wybrany plik ma niepoprawny format, zmień plik aby kontynuować",
        "oPermissions": "Brak uprawnień",
        "completeForm": "Uzupełnij formularz",
        "userNotExist": "Użytkownika nie istnieje",
        "remindPasswordError": "Wystąpił błąd, skontaktuj się z nami drogą mailową",
        "companyNotExist": "Firma nie istnieje",
        "dateTimeValidatorError": "Niepoprawny format daty",
        "selectedTooMuchAnswer": "Wybrano zbyt dużo odpowiedzi",
        "paymentError": "Błąd transakcji",
        "transactionNotFound": "Nie znaleziono transakcji",
        "invalidFormId": "Niepoprawne Id formularza",
        "invalidLicenseId": "Niepoprawne Id licencji",
        "invalidCompanyId": "Niepoprawne Id firmy",
        "noLicenseItemsAvailable": "Brak licencji",
        "noLicenseAssignedToForm": "Brak przypisanej licencji do formularza",
        "noFile": "Brak pliku, dodaj plik aby kontynułować",
        "wrongFormatFile": "Niepoprawny format pliku, zmień aby kontynułować"
    },
    "validation": {
        "wrongDataType": "błędne dane",
        "fieldRequired": "pole wymagane",
        "userExist": "użytkownik istnieje",
        "invalidEmail": "błędny adres email",
        "passwordLength": "długość hasła powinna wynosić {length} znaków",
        "passwordLowercase": "hasło powinno zawierać małą literę",
        "passwordUppercase": "hasło powinno zawiera dużą literę",
        "passwordSymbol": "hasło powinno zawierać znak specjalny",
        "passwordDigit": "hasło powinno zawierać cyfrę",
        "passwordSpace": "hasło nie może zawierać spacji",
        "passwordMismatch": "hasło różni się",
        "notANumber": "wymagana wartość liczbowa",
        "valueIsSmaller": "wartość jest mniejsza",
        "valueIsBigger": "wartość jest większa",
        "valueIsNotEqual": "wartości różnią się od siebie",
        "valueIsToBig": "wartość jest za duża",
        "valueIsToSmall": "wartosć jest za mała",
        "valueIsSmallerThanZero": "wartość musi być większa od 0",
        "phoneNumberWrong": "błędny numer telefonu",
        "limitReached": "osiągnięto limit znaków",
        "limitExceeded": "przekroczono limit znaków",
        "tinLengthIsIncorrect": "niepoprawna długość NIP",
        "tinIsIncorrect": "niepoprawny NIP",
        "notIntiger": "wymagana liczba całkowita"
    },
    "form": {
        "userName": "imię",
        "userSurname": "nazwisko",
        "userEmail": "email",
        "userPassword": "hasło",
        "userPasswordConfirm": "potwórz hasło",
        "userPasswordCurrent": "obecne hasło",
        "userNewPassword": "nowe hasło",
        "userCompany": "nazwa firmy",
        "userPhoneNumber": "numer telefonu",
        "userData": "dane użytkownika:",
        "rememberMe": "zapamiętaj mnie",
        "forgotPassword": "zapomniałeś hasła?",
        "noAccount": "nie masz jeszcze konta? Zarejestruj się!",
        "haveAccount": "Posiadasz już konto? Zaloguj się!",
        "cancel": "anuluj",
        "back": "wróć",
        "login": "zaloguj",
        "register": "załóż konto",
        "agree": "zgadzam się na ",
        "termsOfUse": "warunki użytkowania",
        "privacyPolicy": "polityka prywatności",
        "companyData": "dane firmy:",
        "companyName": "nazwa",
        "companyCountry": "państwo",
        "companyCity": "miasto",
        "companyPostalCode": "kod pocztowy",
        "companyStreetName": "ulica",
        "companyStreetNumber": "numer",
        "companyTIN": "NIP",
        "companyPhoneNumber": "numer telefonu",
        "remindPassword": "przypomnij hasło",
        "rootCompanyName": "wybierz firmę nadrzędną"
    },
    "nav": {
        "general": {
            "home": "strona główna"
        },
        "statistic": {
            "main": "statystyka"
        },
        "licenses": {
            "main": "licencje",
            "active": "aktywne licencje",
            "history": "historia licencji",
            "buy": "zakup"
        },
        "administration": {
            "main": "administracja",
            "account": "konto",
            "hierarchy": "hierarchia"
        },
        "reports": {
            "main": "raporty",
            "generated": "wygenerowane",
            "counter": "licznik raportów",
            "workingCopies": "kopie robocze"
        },
        "settings": {
            "main": "ustawienia"
        },
        "help": {
            "main": "pomoc"
        },
        "dev": {
            "main": "developer"
        },
        "transactionHistory": {
            "main": "historia transakcji"
        }
    },
    "actions": {
        "logout": "wyloguj",
        "login": "zaloguj",
        "register": "rejestracja",
        "save": "zapisz zmiany",
        "edit": "edytuj",
        "close": "zamknij",
        "finishEdit": "zakończ edycję",
        "cancel": "anuluj",
        "accept": "akceptuj",
        "change": "zastosuj",
        "changePassword": "zmień hasło",
        "addUser": "dodaj użytkownika",
        "addCompany": "dodaj firmę",
        "addReport": "dodaj raport",
        "addForm": "dodaj raport",
        "createReport": "utwórz raport",
        "buyLicense": "kup licencje"
    },
    "dialog": {
        "changePassword": "zmiana hasła",
        "addCompany": "dodaj firmę",
        "editCompany": "edytuj dane firmy",
        "showCompany": "dane firmy",
        "transferLicense": "przekaż licencję",
        "addUser": "dodaj użytkownika",
        "editUser": "edytuj dane użytkownika",
        "users": "użytkownicy",
        "reports": "raporty",
        "report": "raport",
        "addFreeLicenses": "darmowe licencje",
        "recordStampList": "lista modyfikacji"
    },
    "confirm": {
        "information": "informacja",
        "administration": {
            "editUserDetails": "czy na pewno chcesz zaktualizować dane użytkownika?",
            "editCompanyDetails": "czy na pewno chcesz zaktualizować dane firmy?",
            "lockCompany": "czy na pewno chcesz zablokować firmę {company}? ",
            "editUserCompanyLogoImage": "czy chcesz zmienić logo?",
            "removeUserCompanyLogoImage": "czy na pewno chcesz usunąć logo?",
            "setUserAsAdmin": "czy na pewno chcesz zmienić administratora firmy? Nowym administatorem zostanie {userName}. \nZmiana roli spowoduje wylogowanie użytkowników z systemu!",
            "updateProfileData": "w celu kontynuowania płatności z nowymi danymi dotyczącymi profilu musisz je zapisać. Zaakceptuj, aby kontynuować lub anuluj, aby dokonać edycji."
        },
        "license": {
            "assignLicenseItemToUser": "Licencja: {license}\nCzy na pewno chcesz przypisać {numberOfLicenseItems} z ({toUse}) raportów użytkownikowi: {user}",
            "assignLicenseItemToCompany": "Licencja: {license}\nCzy na pewno chcesz przypisać {numberOfLicenseItems} z ({toUse}) raportów firmie: {company}\nOperacji nie będzie można cofnąć!",
            "lockCompanyLicense": "Licencja: {license}\nCzy chcesz zablokować wybraną licencję firmie: {company}?",
            "unlockCompanyLicense": "Licencja: {license}\nCzy chcesz odblokować wybraną licencję firmie: {company}?"
        },
        "transaction": {
            "buy": "Kupuję z obowiązkiem zapłaty",
            "sendInvoiceEmail": "Czy na pewno chcesz wysłać fakturę: {invoiceName} do {userEmail}?"
        },
        "settings": {
            "update": "Czy chcesz zaktualizować dane dotyczące {key}"
        }
    },
    "filter": {
        "searchCompany": "szukaj firmy"
    },
    "criterion": {
        "from": "od",
        "to": "do"
    },
    "tooltip": {
        "transferLicense": "przekaż licencję",
        "lockLicense": "zablokuj licencję",
        "unlockLicense": "odblokuj licencję",
        "edit": "edytuj",
        "lock": "zablokuj",
        "unlock": "odblokuj",
        "continueForm": "dokończ raport",
        "refresh": "odśwież",
        "downloadReport": "pobierz raport",
        "setAsAdmin": "ustaw jako administrator firmy",
        "showDetails": "pokaż szczegóły",
        "save": "zapisz",
        "preview": "podgląd",
        "localPreview": "podgląd lokalny",
        "showRecordStamp": "pokaż dane rekordu",
        "addFreeSamples": "dodaj darmowe licencje",
        "showReports": "pokaż raporty",
        "showCompanyUsers": "zarządzaj użytkownikami firmy",
        "showCompanyDetails": "pokaż dane firmy",
        "editCompanyDetails": "edytuj dane firmy",
        "lockCompany": "zablokuj firmę",
        "showReportList": "wyświetl listę raportów",
        "downloadFile": "pobierz plik"
    },
    "header": {
        "login": "logowanie",
        "registration": "rejestracja",
        "companyName": "nazwa firmy",
        "license": "licencja",
        "createdDate": "data utworzenia",
        "paymentDate": "data zakupu",
        "expirationDate": "data wygaśnięcia",
        "initialValue": "początkowa ilość",
        "toUse": "pozostało",
        "used": "wygenerowane",
        "unused": "niewykorzystane",
        "transferredToCompanies": "przekazane firmom",
        "transferredToUsers": "przekazane użytkownikom",
        "userName": "imię",
        "userSurname": "nazwisko",
        "userEmail": "email",
        "userIsActive": "aktywny",
        "name": "nazwa",
        "createdAt": "utworzony",
        "modifiedAt": "ostatnia modyfikacja",
        "type": "typ",
        "remindPassword": "przypomnij hasło",
        "reportName": "nazwa",
        "description": "opis",
        "userLogin": "e-mail",
        "product": "nazwa produktu",
        "company": "firma",
        "amount": "kwota",
        "status": "status",
        "sentAt": "wysłane",
        "provider": "dostawca"
    },
    "transferToCompany": "przekaż firmie",
    "transferToUser": "przekaż użytkownikowi",
    "numberOfReportsToTransfer": "ilość raportów do przekazania",
    "login": "Zaloguj",
    "logout": "Wyloguj",
    "welcome": "Witaj",
    "profile": "profil",
    "report": "raporty",
    "administration": "administracja",
    "message": "witaj i18n !!",
    "extensionValidation": {
        "notNumber": "Wpisana wartość musi być liczbą",
        "minNumber": "Wymagana liczba z zakresu {min} - {max}",
        "maxNumber": "Wpisana liczba z zakresu {min} - {max}",
        "minLength": "Wpisana wartość powninna zawierać od {min} do {max} znaków",
        "maxLength": "Wpisana wartość powninna zawierać od {min} do {max} znaków",
        "minSum": "Suma wszystkich pól powinna wynosić {min}",
        "maxSum": "Suma wszystkich pól powninna wynosić {max}",
        "minValue": "Minimalna wartość wynosi: {min} {sign}",
        "maxValue": "Maksymalna wartość wynosi: {max} {sign}"
    },
    "settings": {
        "mailConfiguration": "Ustawienia maili",
        "toAddressList": "Lista odbiorców",
        "blueMediaConfiguration": "Ustawienia płatności",
        "creditPeriod": "Okres kredytu",
        "creditPercent": "Oprocentowanie kredytu",
        "supportEmail": "Mail wsparcia technicznego"
    },
    "transaction": {
        "status": {
            "created": "Utworzona",
            "pending": "Rozpoczęta",
            "failure": "Błąd",
            "success": "Opłacona"
        }
    },
    "userRole": {
        "user": "użytkownik",
        "admin": "administrator",
        "superAdmin": "superAdmin",
        "developer": "developer"
    },
    "$vuetify": {
        "badge": "Odznaka",
        "calendar": {
          "moreEvents": "{0} więcej"
        },
        "carousel": {
          "ariaLabel": {
            "delimiter": "Carousel slide {0} of {1}"
          },
          "next": "Następny obraz",
          "prev": "Poprzedni obraz"
        },
        "close": "Zamknij",
        "dataFooter": {
          "firstPage": "Pierwsza strona",
          "itemsPerPageAll": "Wszystkie",
          "itemsPerPageText": "Pozycji na stronie:",
          "lastPage": "Ostatnia strona",
          "nextPage": "Następna strona",
          "pageText": "{0}-{1} z {2}",
          "prevPage": "Poprzednia strona"
        },
        "dataIterator": {
          "loadingText": "Wczytywanie danych...",
          "noResultsText": "Nie znaleziono danych odpowiadających wyszukiwaniu"
        },
        "dataTable": {
          "ariaLabel": {
            "activateAscending": "Kliknij aby posortować rosnąco.",
            "activateDescending": "Kliknij aby posortować malejąco.",
            "activateNone": "Kliknij aby usunąć sortowanie.",
            "sortAscending": "Sortowanie rosnąco. Kliknij aby zmienić.",
            "sortDescending": "Sortowanie malejąco. Kliknij aby zmienić.",
            "sortNone": "Bez sortowania. Kliknij aby posortować rosnąco."
          },
          "itemsPerPageText": "Wierszy na stronie:",
          "sortBy": "Sortuj według"
        },
        "datePicker": {
          "itemsSelected": "{0} dat(y)",
          "nextMonthAriaLabel": "Następny miesiąc",
          "nextYearAriaLabel": "Następny rok",
          "prevMonthAriaLabel": "Poprzedni miesiąc",
          "prevYearAriaLabel": "Poprzedni rok"
        },
        "fileInput": {
          "counter": "Liczba plików: {0}",
          "counterSize": "Liczba plików: {0} (łącznie {1})"
        },
        "noDataText": "Brak danych",
        "pagination": {
          "ariaLabel": {
            "currentPage": "Bieżąca strona, strona {0}",
            "next": "Następna strona",
            "page": "Idź do strony {0}",
            "previous": "Poprzednia strona",
            "wrapper": "Nawigacja paginacyjna"
          }
        },
        "rating": {
          "ariaLabel": {
            "icon": "Rating {0} of {1}"
          }
        },
        "timePicker": {
          "am": "AM",
          "pm": "PM"
        }
      },
}