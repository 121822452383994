<template>
  <div v-if="prototype.questionItems != null">
    <span class="text-subtitle-1">{{ prototype.text }}</span>
    <div v-for="(item, index) in prototype.questionItems" :key="index">
      <extension-base :value.sync="prototype.questionItems[index].extensionObject" />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, PropSync } from "vue-property-decorator";

//Models
import BasePrototype from "@/models/api/reports/workFlow/BasePrototype";

//Components
import ExtensionBase from "@/components/reports/workFlow/components/extensions/ExtensionBase.vue";

@Component({
  components: {
    ExtensionBase,
  },
})
export default class CommonPrototype extends Vue {
  @PropSync("value", { required: true })
  prototype!: BasePrototype;
}
</script>

<style scoped></style>
