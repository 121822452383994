<template>
  <v-app-bar
    class="app-bar-desktop"
    :app="app"
    :height="height"
    :color="color"
    elevate-on-scroll
    scroll-target="div.v-main__wrap"
  >
    <div class="app-bar-desktop_wrapper">
      <div class="app-bar-desktop-logo">
        <v-icon color="black"> $logo </v-icon>
        <v-icon color="black"> $logoSignature </v-icon>
      </div>
      <div class="app-bar-desktop-navigations">
        <go-to :name="0" label="Home" />
        <go-to name="#products" label="Cennik" />
        <go-to name="#about" label="o nas" />
        <go-to name="#contact" label="Kontakt" />
        <router-link to="/faq" v-text="'FAQ'" />
      </div>
      <div class="app-bar-desktop-buttons">
        <btn type="white" @click="redirectTo()">{{ !isLogged ? "Zaloguj / Zarejestruj" : "Przejdź do panelu" }}</btn>
      </div>
    </div>
  </v-app-bar>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";
import Btn from "@/components/shared/buttons/Btn.vue";
import GoTo from "@/components/shared/buttons/GoTo.vue";
import ROLE from "../../../static/role";

@Component({
  components: {
    Btn,
    GoTo,
  },
})
export default class AppBarDesktop extends Vue {
  @Prop({ default: true })
  app!: boolean;
  @Prop({ default: 112 })
  height!: number;
  @Prop({ default: "white" })
  color!: string;

  // Computed
  get isLogged(): boolean {
    return this.$store.direct.getters.user.isLogged;
  }

  redirectTo() {
    const role = this.$store.direct.state.user.role;
    switch (role) {
      case ROLE.Guest: {
        this.$router.push("/login");
        break;
      }
      case ROLE.User: {
        this.$router.push("/reports/generated");
        break;
      }
      case ROLE.Admin: {
        this.$router.push("/statistics/statistic");
        break;
      }
      case ROLE.SuperAdmin: {
        this.$router.push("/statistics/statistic");
        break;
      }
      case ROLE.Developer: {
        this.$router.push("/statistics/statistic");
        break;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.app-bar-desktop {
  padding: 0 5%;
}

.app-bar-desktop_wrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.app-bar-desktop-logo {
  display: inline-flex;
}

.app-bar-desktop-navigations {
  max-width: 500px;
  min-width: 400px;
  width: 100%;
  display: flex;
  justify-content: space-around;
}

.app-bar-desktop-buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
</style>
