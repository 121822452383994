import { filterCapitalize } from "@/filters/capitalize";

export enum MESSAGE_TYPES {
  Warning = "Warning",
  Success = "Success",
  Error = "Error",
}

export const messageTypesToName = (messageType: MESSAGE_TYPES): string => {
  if (messageType === MESSAGE_TYPES.Warning) {
    return filterCapitalize("warning");
  }
  if (messageType === MESSAGE_TYPES.Success) {
    return filterCapitalize("success");
  }
  if (messageType === MESSAGE_TYPES.Error) {
    return filterCapitalize("error");
  }
  return "";
};
