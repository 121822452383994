<template>
  <div>
    <user-details-form ref="userForm" :value.sync="userDetails">
      <div slot="button">
        <v-btn class="btn main-light" @click="showChangePasswordDialog = true">
          {{ $t("actions.changePassword") | capitalize }}
        </v-btn>
      </div>
    </user-details-form>
    <change-password-dialog
      v-if="showChangePasswordDialog"
      :show-dialog.sync="showChangePasswordDialog"
      :title="$t('dialog.changePassword') | capitalize"
    ></change-password-dialog>
  </div>
</template>

<script lang="ts">
import { Component, Vue, PropSync } from "vue-property-decorator";

//Components
import UserDetailsForm from "@/components/administration/account/forms/UserDetailsForm.vue";
import ChangePasswordDialog from "@/components/administration/account/ChangePasswordDialog.vue";

//Models
import UserDetails from "@/models/api/user/UserDetails";

@Component({
  components: {
    UserDetailsForm,
    ChangePasswordDialog,
  },
})
export default class UserDetailsTemplate extends Vue {
  @PropSync("value", { required: true })
  userDetails!: UserDetails;

  showChangePasswordDialog = false;

  //Form validation
  validate() {
    return (this.$refs.userForm as HTMLFormElement).validate();
  }

  resetValidation() {
    (this.$refs.userForm as HTMLFormElement).resetValidation();
  }
}
</script>

<style scoped></style>
