<template>
  <div class="view-container__wrap">
    <licenses-filter :options.sync="tableOptions" />
    <div class="table-container">
      <v-data-table
        class="table data-table-items-italic"
        :items="licenses.items"
        :headers="headers"
        :header-props="{ sortByText: 'Sortuj po', sortIcon: '$arrowUp' }"
        :options.sync="tableOptions"
        :server-items-length="licenses.count"
        :item-class="getItemClass"
        single-select
        hide-default-footer
        @click:row="selectItem"
      >
        <template #[`item.paymentDate`]="{ item }">
          {{ item.paymentDate | date }}
        </template>
        <template #[`item.expirationDate`]="{ item }">
          {{ item.expirationDate | date }}
        </template>
        <template #[`item.actions`]="{ item }">
          <v-tooltip bottom>
            <template #activator="{ on }">
              <v-btn
                :disabled="item.isLocked || item.toUse == 0"
                icon
                :ripple="false"
                @click="transfer(item)"
                v-on="on"
              >
                <v-icon color="button" x-small>$repeat</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("tooltip.transferLicense") | capitalize }}</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template #activator="{ on }">
              <v-btn :disabled="!item.isLocked" icon :ripple="false" @click="unlock(item)" v-on="on">
                <v-icon color="button" x-small>$play</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("tooltip.unlockLicense") | capitalize }}</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template #activator="{ on }">
              <v-btn :disabled="item.isLocked" icon :ripple="false" @click="lock(item)" v-on="on">
                <v-icon :color="item.isLocked ? 'warning' : 'error'" x-small>$block</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("tooltip.lockLicense") | capitalize }}</span>
          </v-tooltip>
        </template>
      </v-data-table>
      <div class="table__pager">
        <custom-pagination :options.sync="tableOptions" :server-items-length="licenses.count"> </custom-pagination>
      </div>
    </div>
    <license-transfer-dialog
      v-if="show"
      :show-dialog.sync="show"
      :title="$t('dialog.transferLicense') | capitalize"
      :license="selectedLicense"
    />
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { filterCapitalize } from "@/filters/capitalize";
import i18n from "@/plugins/i18n";

//Components
import LicenseTransferDialog from "@/components/license/LicenseTransferDialog.vue";
import LicensesFilter from "@/components/license/licenses/LicensesFilter.vue";
import CustomPagination from "@/components/shared/dataTable/CustomPagination.vue";

//Models
import License from "@/models/api/licenses/license";
import { DataTableHeader, DataOptions } from "vuetify";

import Row from "@/models/vuetify/row";
import { LicenseLockUnlockInfo } from "@/models/components/license/licenseInfo";
import { ListResult } from "../../../models/api/filters/ListResult";

@Component({
  components: {
    LicenseTransferDialog,
    LicensesFilter,
    CustomPagination,
  },
})
export default class LicensesLicenseView extends Vue {
  get licenses(): ListResult<License> {
    return this.$store.direct.state.license.licenses ?? new ListResult<License>();
  }

  get tableOptions(): DataOptions {
    return this.$store.direct.state.filter.licenseFilterOptions;
  }

  set tableOptions(opt: DataOptions) {
    this.$store.direct.commit.filter.setLicenseFilterDataOptions(opt);
  }

  selectItem(item: License, row: Row<License>) {
    row.select(true);
    this.selectedLicense = item;
  }

  transfer(item: License) {
    this.selectedLicense = item;
    this.show = true;
  }

  async lock(item: License) {
    this.selectedLicense = item;
    const licenseInfo: LicenseLockUnlockInfo = {
      licenseId: item.id,
      licenseName: item.name,
      companyId: item.companyId,
      companyName: item.companyName,
    };
    await this.$store.direct.dispatch.license.lockCompanyLicense(licenseInfo);
  }

  async unlock(item: License) {
    this.selectedLicense = item;
    const licenseInfo: LicenseLockUnlockInfo = {
      licenseId: item.id,
      licenseName: item.name,
      companyId: item.companyId,
      companyName: item.companyName,
    };
    await this.$store.direct.dispatch.license.unlockCompanyLicense(licenseInfo);
  }

  show = false;
  selectedLicense: License | null = null;
  searchValue = "";

  headers: Array<DataTableHeader> = [
    {
      text: filterCapitalize(i18n.t("header.companyName")),
      value: "companyName",
    },
    {
      text: filterCapitalize(i18n.t("header.license")),
      value: "name",
    },
    {
      text: filterCapitalize(i18n.t("header.paymentDate")),
      value: "paymentDate",
      width: "160px",
    },
    {
      text: filterCapitalize(i18n.t("header.expirationDate")),
      value: "expirationDate",
      width: "160px",
    },
    {
      text: filterCapitalize(i18n.t("header.initialValue")),
      value: "initValue",
      align: "center",
    },
    {
      text: filterCapitalize(i18n.t("header.used")),
      value: "used",
      align: "center",
    },
    {
      text: filterCapitalize(i18n.t("header.transferredToCompanies")),
      value: "assigneToCompanies",
      align: "center",
    },
    {
      text: filterCapitalize(i18n.t("header.transferredToUsers")),
      value: "assigneToUsers",
      align: "center",
    },
    {
      text: filterCapitalize(i18n.t("header.toUse")),
      value: "toUse",
      align: "center",
    },
    {
      text: "",
      value: "actions",
      align: "center",
      sortable: false,
    },
  ];

  getItemClass = (item: License): string => {
    if (item.isLocked) return "locked";
    return "";
  };
}
</script>

<style lang="scss" scoped></style>
