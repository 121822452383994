<template>
  <span class="scaling-svg-container">
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="15" viewBox="0 0 12 15">
      <g transform="translate(-3 -1.5)">
        <g transform="translate(3 1.5)">
          <path
            d="M10.5,15h-9A1.5,1.5,0,0,1,0,13.5V1.5A1.5,1.5,0,0,1,1.5,0H9l3,3V13.5A1.5,1.5,0,0,1,10.5,15ZM2.25,1.5a.751.751,0,0,0-.75.75v10.5a.751.751,0,0,0,.75.75h7.5a.751.751,0,0,0,.751-.75V4.5h-3v-3ZM9,1.5V3h1.5Z"
            fill="currentColor"
          />
          <path
            d="M0,.75A.75.75,0,0,1,.75,0h4.5a.75.75,0,0,1,0,1.5H.75A.75.75,0,0,1,0,.75Z"
            transform="translate(3 6)"
            fill="currentColor"
          />
          <path
            d="M0,.75A.75.75,0,0,1,.75,0h3a.75.75,0,0,1,0,1.5h-3A.75.75,0,0,1,0,.75Z"
            transform="translate(3 9)"
            fill="currentColor"
          />
        </g>
      </g>
    </svg>
  </span>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

@Component
export default class FileIcon extends Vue {}
</script>

<style></style>
