<template>
  <div>
    <v-form v-if="prototype != null" ref="form" v-model="isCorrect" lazy-validation @submit.prevent>
      <report-dialog-stepper :key="prototype.id" :prototype="prototype" />
      <div style="heigth: 250px">
        <div>
          <div v-if="prototype.type === prototypeType.CommonPrototype">
            <common-prototype :key="prototype.id" :value="prototype" />
          </div>
          <div v-else-if="prototype.type === prototypeType.SingleChooserPrototype">
            <single-chooser-prototype :key="prototype.id" :value="prototype" />
          </div>
          <div v-else-if="prototype.type === prototypeType.MultipleChooserPrototype">
            <multiple-chooser-prototype :key="prototype.id" :value="prototype" />
          </div>
          <div v-else>
            <span>Contact with administrator</span>
          </div>
        </div>
      </div>
    </v-form>
  </div>
</template>

<script lang="ts">
import { Component, Vue, PropSync } from "vue-property-decorator";

//Components
import CommonPrototype from "@/components/reports/workFlow/components/prototypes/CommonPrototype.vue";
import SingleChooserPrototype from "@/components/reports/workFlow/components/prototypes/SingleChooserPrototype.vue";
import MultipleChooserPrototype from "@/components/reports/workFlow/components/prototypes/MulipleChooserPrototype.vue";

import ReportDialogStepper from "@/components/reports/workingCopies/ReportDialogStepper.vue";

//Models
import BasePrototype, { PrototypeTypeEnum } from "@/models/api/reports/workFlow/BasePrototype";

@Component({
  components: {
    CommonPrototype,
    SingleChooserPrototype,
    MultipleChooserPrototype,
    ReportDialogStepper,
  },
})
export default class PrototypeBase extends Vue {
  @PropSync("value", { required: true })
  prototype!: BasePrototype | null;
  @PropSync("isValid", { default: true })
  isCorrect!: boolean;

  prototypeType = PrototypeTypeEnum;

  validate() {
    return (this.$refs.form as HTMLFormElement).validate();
  }

  resetValidation() {
    return (this.$refs.form as HTMLFormElement).resetValidation();
  }
}
</script>

<style lang="scss" scoped></style>
