import { filterCapitalize } from "@/filters/capitalize";
import i18n from "@/plugins/i18n";
import ROUTE_NAMES from "@/static/routeNames";
import ROLE from "@/static/role";

export interface INavigationChild {
  id: number;
  name: string;
  to: string;
  isVisibleFor: Array<ROLE>;
}

export interface INavigation {
  id: number;
  name: string;
  icon: string;
  to: string;
  group?: string | undefined;
  active: boolean;
  isVisibleFor: Array<ROLE>;
  children: Array<INavigationChild>;
}

export const routes: Array<INavigation> = [
  {
    id: 1,
    name: filterCapitalize(i18n.t("nav.general.home")),
    icon: "$home",
    to: ROUTE_NAMES.GENERAL.WELCOME,
    active: false,
    isVisibleFor: [ROLE.User, ROLE.Admin, ROLE.SuperAdmin, ROLE.Developer],
    children: [],
  },
  {
    id: 2,
    name: filterCapitalize(i18n.t("nav.statistic.main")),
    icon: "$statistic",
    to: ROUTE_NAMES.STATISTICS.STATISTIC,
    active: false,
    isVisibleFor: [ROLE.User, ROLE.Admin, ROLE.SuperAdmin, ROLE.Developer],
    children: [],
  },
  {
    id: 3,
    name: filterCapitalize(i18n.t("nav.licenses.main")),
    icon: "$licenses",
    to: ROUTE_NAMES.LICENSES.MAIN,
    group: "licenses",
    active: false,
    isVisibleFor: [ROLE.Admin, ROLE.SuperAdmin, ROLE.Developer],
    children: [
      {
        id: 31,
        name: filterCapitalize(i18n.t("nav.licenses.active")),
        to: ROUTE_NAMES.LICENSES.LICENSE,
        isVisibleFor: [ROLE.Admin, ROLE.SuperAdmin, ROLE.Developer],
      },
      {
        id: 32,
        name: filterCapitalize(i18n.t("nav.licenses.history")),
        to: ROUTE_NAMES.LICENSES.HISTORY,
        isVisibleFor: [ROLE.Admin, ROLE.SuperAdmin, ROLE.Developer],
      },
      {
        id: 33,
        name: filterCapitalize(i18n.t("nav.licenses.buy")),
        to: ROUTE_NAMES.LICENSES.BUY,
        isVisibleFor: [ROLE.Admin, ROLE.SuperAdmin, ROLE.Developer],
      },
    ],
  },
  {
    id: 4,
    name: filterCapitalize(i18n.t("nav.administration.main")),
    icon: "$administration",
    to: ROUTE_NAMES.ADMINISTRATION.ACCOUNT,
    group: "administration",
    active: false,
    isVisibleFor: [ROLE.Admin, ROLE.SuperAdmin, ROLE.Developer],
    children: [
      {
        id: 41,
        name: filterCapitalize(i18n.t("nav.administration.account")),
        to: ROUTE_NAMES.ADMINISTRATION.ACCOUNT,
        isVisibleFor: [ROLE.Admin, ROLE.SuperAdmin, ROLE.Developer],
      },
      {
        id: 42,
        name: filterCapitalize(i18n.t("nav.administration.hierarchy")),
        to: ROUTE_NAMES.ADMINISTRATION.HIERARCHY,
        isVisibleFor: [ROLE.Admin, ROLE.SuperAdmin, ROLE.Developer],
      },
    ],
  },
  {
    id: 5,
    name: filterCapitalize(i18n.t("nav.reports.main")),
    icon: "$report",
    to: ROUTE_NAMES.REPORTS.GENERATED,
    active: false,
    isVisibleFor: [ROLE.User, ROLE.Admin, ROLE.SuperAdmin, ROLE.Developer],
    children: [],
  },
  {
    id: 6,
    name: filterCapitalize(i18n.t("nav.transactionHistory.main")),
    icon: "$file",
    to: ROUTE_NAMES.TRANSACTION_HISTORY.MAIN,
    active: false,
    isVisibleFor: [ROLE.SuperAdmin, ROLE.Developer],
    children: [],
  },
  {
    id: 7,
    name: filterCapitalize(i18n.t("nav.settings.main")),
    icon: "$settings",
    to: ROUTE_NAMES.SETTINGS.MAIN,
    active: false,
    isVisibleFor: [ROLE.SuperAdmin, ROLE.Developer],
    children: [],
  },
  {
    id: 8,
    name: filterCapitalize(i18n.t("nav.help.main")),
    icon: "$help",
    to: ROUTE_NAMES.HELP.MAIN,
    active: false,
    isVisibleFor: [ROLE.User, ROLE.Admin, ROLE.SuperAdmin, ROLE.Developer],
    children: [],
  },
  {
    id: 9,
    name: filterCapitalize(i18n.t("nav.dev.main")),
    icon: "$settings",
    to: ROUTE_NAMES.DEVELOPER.MAIN,
    active: false,
    isVisibleFor: [ROLE.Developer],
    children: [],
  },
];

export const getNavigationRoutes = (role: ROLE): Array<INavigation> => {
  return routes
    .map((x) =>
      x.children.length
        ? {
            ...x,
            children: x.children.filter((y) => y.isVisibleFor.includes(role)),
          }
        : x
    )
    .filter((x) => x.isVisibleFor.includes(role));
};
