import RequestHelper from "./RequestHelper";
import { Response } from "@/requests/RequestHelper";

//Models
import UserLoginRequest from "@/models/api/user/UserLoginRequest";
import UserLoginResponse from "@/models/api/user/UserLoginResponse";
import RegisterUserRequest from "@/models/api/user/RegisterUserRequest";
import UserDetails from "@/models/api/user/UserDetails";
import CompanyDetails from "@/models/api/company/CompanyDetails";
import PasswordEdit from "@/models/api/administration/account/PasswordEdit";
import UserRequest from "@/models/api/user/UserRequest";
import RemindPassword from "@/models/api/user/RemindPassword";
import ContactForm from "@/models/api/user/ContactForm";
import FileResultResponse from "@/models/api/reports/fileResultResponse";

//Urls
export const URL_POST_LOGIN_USER = "/User/Login";
export const URL_POST_LOGOUT_USER = "/User/Logout";

export const URL_POST_REGISTER_USER = "/User/Register";

export const URL_POST_REMIND_PASSWORD = "/User/RemindPassword";

export const URL_GET_USER_ROLE = "/User/Roles";

export const URL_GET_USER_DETAILS = "/User/Details";
export const URL_GET_USER_COMPANY = "/User/CompanyDetails";

export const URL_POST_ADD_USER = "/User/AddUser";
export const URL_POST_SET_USER_AS_ADMIN = "/Users/${UserId}/SetRoleAdmin";
export const URL_POST_EDIT_USER = "/User/EditUser";
export const URL_POST_CHANGE_PASSWORD = "/User/ChangePassword";

export const URL_POST_LOCK_USER = "/Users/${UserId}/Lock";
export const URL_POST_UNLOCK_USER = "/Users/${UserId}/Unlock";

export const URL_POST_SEND_LOGGED_USER_HELP_MAIL = "/User/SendLoggedUserHelpMail";
export const URL_POST_SEND_UNLOGGED_USER_HELP_MAIL = "/User/SendUnloggedUserHelpMail";
export const URL_GET_REPORT_DEMO = "/Report/Demo";

const _postLogin = new RequestHelper<UserLoginResponse>(URL_POST_LOGIN_USER);
export const postLogin = (requestBody: UserLoginRequest): Promise<Response<UserLoginResponse>> => {
  return _postLogin.post(null, requestBody);
};

const _postLogout = new RequestHelper<null>(URL_POST_LOGOUT_USER);
export const postLogout = (): Promise<Response<null>> => {
  return _postLogout.post();
};

const _postRegister = new RequestHelper<null>(URL_POST_REGISTER_USER, true);
export const postRegister = (requestBody: RegisterUserRequest): Promise<Response<null>> => {
  return _postRegister.post(null, requestBody);
};

const _remindPassword = new RequestHelper<null>(URL_POST_REMIND_PASSWORD, false);
export const remindPassword = (remindPassword: RemindPassword): Promise<Response<null>> => {
  return _remindPassword.post(null, remindPassword);
};

const _userRoles = new RequestHelper<Array<string>>(URL_GET_USER_ROLE);
export const getUserRoles = (): Promise<Response<Array<string>>> => {
  return _userRoles.get();
};

const _getUserDetails = new RequestHelper<UserDetails>(URL_GET_USER_DETAILS);
export const getUserDetails = async (): Promise<Response<UserDetails>> => {
  return _getUserDetails.get();
};

const _userCompanyDetails = new RequestHelper<CompanyDetails>(URL_GET_USER_COMPANY);
export const getUserCompanyDetails = async (): Promise<Response<CompanyDetails>> => {
  return _userCompanyDetails.get();
};

const _addUser = new RequestHelper<null>(URL_POST_ADD_USER);
export const addUser = async (user: UserRequest, companyId: string): Promise<Response<null>> => {
  return _addUser.post({ CompanyId: companyId }, user);
};

const _setUserAsAdmin = new RequestHelper<null>(URL_POST_SET_USER_AS_ADMIN, true);
export const setUserAsAdmin = async (userId: string): Promise<Response<null>> => {
  return _setUserAsAdmin.post({ UserId: userId });
};

const _editUser = new RequestHelper<null>(URL_POST_EDIT_USER);
export const editUser = async (model: UserDetails): Promise<Response<null>> => {
  return _editUser.post(null, model);
};

const _lockUser = new RequestHelper<null>(URL_POST_LOCK_USER);
export const lockUser = async (userId: string): Promise<Response<null>> => {
  return _lockUser.post({ UserId: userId });
};

const _unlockUser = new RequestHelper<null>(URL_POST_UNLOCK_USER, true);
export const unlockUser = async (userId: string): Promise<Response<null>> => {
  return _unlockUser.post({ UserId: userId });
};

const _changePassword = new RequestHelper<null>(URL_POST_CHANGE_PASSWORD, true);
export const postChangePassword = async (model: PasswordEdit): Promise<Response<null>> => {
  return _changePassword.post(null, model);
};

const _sendLoggedUserHelpMail = new RequestHelper<null>(URL_POST_SEND_LOGGED_USER_HELP_MAIL);
export const sendLoggedUserHelpMail = async (model: ContactForm): Promise<Response<null>> => {
  return _sendLoggedUserHelpMail.post(null, model);
};

const _sendUnloggedUserHelpMail = new RequestHelper<null>(URL_POST_SEND_UNLOGGED_USER_HELP_MAIL);
export const sendUnloggedUserHelpMail = async (model: ContactForm): Promise<Response<null>> => {
  return _sendUnloggedUserHelpMail.post(null, model);
};

const _getReportDemo = new RequestHelper<FileResultResponse>(URL_GET_REPORT_DEMO);
export const getReportDemo = async (): Promise<Response<FileResultResponse>> => {
  return await _getReportDemo.get();
};
