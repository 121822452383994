<template>
  <span class="scaling-svg-container">
    <svg xmlns="http://www.w3.org/2000/svg" width="39" height="39" viewBox="0 0 39 39" class="scaling-svg">
      <g transform="translate(-514.679 -665.679)">
        <circle cx="19.5" cy="19.5" r="19.5" transform="translate(514.679 665.679)" fill="#fff" />
        <path
          d="M20.652,2.268,7.795,15.125a.289.289,0,0,1-.41,0l-7.3-7.3a.29.29,0,0,1,0-.409L1.859,5.642a.29.29,0,0,1,.41,0L7.59,10.963,18.468.085a.29.29,0,0,1,.41,0l1.774,1.774A.289.289,0,0,1,20.652,2.268Z"
          transform="translate(523.811 677.573)"
          fill="#d4ab5b"
        />
      </g>
    </svg>
  </span>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

@Component
export default class SuccessIcon extends Vue {}
</script>

<style></style>
