import i18n from "@/plugins/i18n";
import { filterCapitalize } from "@/filters/capitalize";
import { RuleFunctionExtendWithType } from "@/rules/RuleFunction";

const numberCompareValidator: RuleFunctionExtendWithType = (
  value1: unknown | string | number,
  value2: unknown | string | number,
  type: string
): boolean | string => {
  value1 = Number(value1);
  value2 = Number(value2);

  if (typeof value1 === "number" && typeof value2 === "number") {
    if (value1 <= 0) return filterCapitalize(i18n.t("validation.valueIsSmallerThanZero").toString());
    switch (type) {
      case ">":
        return value1 > value2 || filterCapitalize(i18n.t("validation.valueIsSmaller").toString());
      case "<":
        return value1 < value2 || filterCapitalize(i18n.t("validation.valueIsBigger").toString());
      case "==":
        return value1 == value2 || filterCapitalize(i18n.t("validation.valueIsNotEqual").toString());
      case "<=":
        return value1 <= value2 || filterCapitalize(i18n.t("validation.valueIsToBig").toString());
      case ">=":
        return value1 >= value2 || filterCapitalize(i18n.t("validation.valueIsToSmall").toString());
    }
  }
  return filterCapitalize(i18n.t("validation.notANumber").toString());
};

export { numberCompareValidator };
export default numberCompareValidator;
