import { axiosInstance, prepareUrlWithParams } from "@/plugins/axios";
import { Method, CancelTokenSource } from "axios";
import { RequestError } from "./RequestError";

export default class RequestHelper<T> {
  url: string;
  snackbar: boolean;
  cancelToken: CancelTokenSource | null = null;
  previousUrl: string | null = null;

  constructor(url: string, snackbar = false) {
    this.url = url;
    this.snackbar = snackbar;
  }

  public async request(
    params: unknown = {},
    method: Method,
    data: unknown | null = null,
    headers: Record<string, string> = {}
  ): Promise<Response<T>> {
    if (this.cancelToken !== null) {
      this.cancelToken.cancel("Request cancelled, next request was started");
    }
    this.cancelToken = axiosInstance.CancelToken.source();
    this.previousUrl = this.url;
    const url = prepareUrlWithParams(params, this.url);
    const response = await axiosInstance.request({
      url: this.checkURL(url),
      method: method,
      params: params,
      data: data,
      cancelToken: this.cancelToken.token,
      headers: { ...headers, Snackbar: this.snackbar.toString() },
    });

    this.cancelToken = null;
    this.previousUrl = null;

    if (typeof response == "undefined") {
      throw new RequestError(`Request cancelled, next request was started`);
    }

    // $Empty body returns string empty -> set data to null
    if (response.data === "" || response.data === undefined) response.data = null;

    //TODO check response model!!!
    const responseData = response.data as T;

    return new Response<T>(responseData, response.status);
  }

  public async get(params: unknown = {}): Promise<Response<T>> {
    return this.request(params, "GET");
  }

  public async post(params: unknown = {}, data: unknown | null = null): Promise<Response<T>> {
    return this.request(params, "POST", data);
  }

  public async sendFile(params: unknown = {}, file: File): Promise<Response<T>> {
    const formData = new FormData();
    formData.append(file.name, file);
    const headers = {
      "Content-Type": "multipart/form-data",
    };
    return this.request(params, "POST", formData, headers);
  }

  private checkURL(url: string): string {
    if (url.charAt(0) == "/") return url;
    console.error("Wrong URL: missing '/'", url);
    return "/" + url;
  }
}

export class Response<T> {
  model!: T;
  status!: number;
  isError!: boolean;

  constructor(model: T, status: number) {
    this.model = model;
    this.status = status;
    this.isError = status != 200 ? true : false;
  }
}
