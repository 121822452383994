<template>
  <span class="scaling-svg-container">
    <svg xmlns="http://www.w3.org/2000/svg" width="9" height="9" viewBox="0 0 9 9" class="scaling-svg">
      <g id="Group_6324" data-name="Group 6324" transform="translate(-1407 -141)">
        <g
          id="Ellipse_25"
          data-name="Ellipse 25"
          transform="translate(1407 141)"
          fill="none"
          stroke="currentColor"
          stroke-width="1"
        >
          <circle cx="4.5" cy="4.5" r="4.5" stroke="none" />
          <circle cx="4.5" cy="4.5" r="4" fill="none" />
        </g>
        <line
          id="Line_2"
          data-name="Line 2"
          x1="6.133"
          y2="6.133"
          transform="translate(1408.434 142.434)"
          fill="none"
          stroke="currentColor"
          stroke-width="1"
        />
      </g>
    </svg>
  </span>
</template>

<script lang="ts">
import { Component } from "vue-property-decorator";
import IconHelper from "@/components/shared/icons/IconHelper.vue";

@Component
export default class LockIcon extends IconHelper {}
</script>

<style></style>
