<template>
  <span class="scaling-svg-container" style="margin-left: 4px; margin-bottom: 2px">
    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15">
      <g transform="translate(-515 -666)">
        <circle cx="7.5" cy="7.5" r="7.5" transform="translate(515 666)" fill="#000" />
        <path
          d="M8.1.89,3.059,5.935a.114.114,0,0,1-.161,0L.033,3.07a.114.114,0,0,1,0-.161l.7-.7a.114.114,0,0,1,.161,0L2.978,4.3,7.247.033a.114.114,0,0,1,.161,0l.7.7A.114.114,0,0,1,8.1.89Z"
          transform="translate(518.432 670.516)"
          fill="#fff"
        />
      </g>
    </svg>
  </span>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

@Component
export default class CheckBoxOnIcon extends Vue {}
</script>

<style></style>
