<template>
  <div>
    <input-text
      v-model="val"
      :label="extension.label"
      :rules="[required, extensionValidator(extension)]"
      :allow-text="false"
      :validate-on-blur="true"
      :padding="false"
      @blur="onBlur"
      @focus="onFocus"
    />
  </div>
</template>

<script lang="ts">
import { Component, Vue, PropSync } from "vue-property-decorator";

//Components
import InputText from "@/components/shared/inputs/InputText.vue";

//Models
import { DecimalExtension as DecExt } from "@/models/api/reports/workFlow/BaseExtension";

//Validation
import requiredValidator from "@/rules/requiredValidator";
import extensionValidator from "@/rules/extensionValidator";
import { NumberFormatOptions } from "vue-i18n";

@Component({
  components: {
    InputText,
  },
})
export default class DecimalExtension extends Vue {
  @PropSync("value")
  extension!: DecExt;

  val = ""; //Value to show user

  //Validation
  required = requiredValidator;
  extensionValidator = extensionValidator;

  onFocus() {
    this.val = this.extension.value.toString();
  }

  onBlur() {
    let currentValue = this.val;
    currentValue = currentValue.replace(/,/, ".").replace(/ /g, "");
    const value = parseFloat(currentValue);
    this.val = this.formatData(this.extension.numberSign, value);
    this.extension.value = Number(value.toFixed(this.extension.numberOfDecimals));
  }

  formatData(name: string, value: number): string {
    switch (name) {
      case "percent": {
        return this.formatDataToPercent(value);
      }
      case "decimal": {
        return this.formatDataToDecimal(value);
      }
      case "currency": {
        return this.formatDataToCurrency(value);
      }
      default: {
        console.warn("DecimalExtension not implemented");
        return "";
      }
    }
  }

  formatDataToPercent(value: number): string {
    return this.$n(value / 100, this.percentOptions);
  }

  formatDataToDecimal(value: number): string {
    return this.$n(value, this.decimalOptions);
  }

  formatDataToCurrency(value: number): string {
    return this.$n(value, this.currencyOptions);
  }

  percentOptions: NumberFormatOptions = {
    style: "percent",
    useGrouping: false,
  };

  decimalOptions: NumberFormatOptions = {
    style: "decimal",
  };

  currencyOptions: NumberFormatOptions = {
    style: "currency",
    currency: "PLN",
    currencyDisplay: "code",
  };

  setValuesForI18n() {
    const sign = this.extension.numberSign;
    const numberOfDecimals = this.extension.numberOfDecimals;
    switch (sign) {
      case "percent": {
        this.percentOptions.minimumFractionDigits = numberOfDecimals;
        this.percentOptions.maximumFractionDigits = numberOfDecimals;
        break;
      }
      case "decimal": {
        this.decimalOptions.minimumFractionDigits = numberOfDecimals;
        this.decimalOptions.maximumFractionDigits = numberOfDecimals;
        break;
      }
      case "currency": {
        this.currencyOptions.minimumFractionDigits = numberOfDecimals;
        this.currencyOptions.maximumFractionDigits = numberOfDecimals;
        break;
      }
    }
  }

  mounted() {
    this.val = this.extension.value.toString();
    this.setValuesForI18n();
    this.onBlur();
  }
}
</script>

<style scoped></style>
