<template>
  <custom-dialog title="Szczegóły transakcji" :show-buttons="false" @cancel="closeDialog">
    <div v-if="transactionDetails != null">
      <div class="d-flex justify-space-between mt-5">
        <div>
          <span class="text-body-1 font-weight-bold"> Dane transakcji: </span>
          <div>
            <span>Transakcja: {{ transactionDetails.id }}</span>
          </div>
          <div>
            <span>Numer zamównienia: {{ transactionDetails.orderId }}</span>
          </div>
          <div>
            <span>Produkt: {{ transactionDetails.product }}</span>
          </div>
          <div>
            <span>Kwota: {{ transactionDetails.amount }}</span>
          </div>
          <div>
            <span>Utworzono: {{ transactionDetails.createdAt | date }}</span>
          </div>
          <div>
            <span>Opłacono: {{ transactionDetails.paymentDate | date }}</span>
          </div>
          <div v-if="typeof transactionDetails.status != 'undefined'">
            <span>Status: {{ getStatusName(transactionDetails.status) }}</span>
          </div>
        </div>

        <div v-if="typeof transactionDetails.companyDetails != 'undefined'">
          <span class="text-body-1 font-weight-bold"> Dane firmy: </span>
          <div>
            <span> Name: {{ transactionDetails.companyDetails.name }} </span>
          </div>
          <div>
            <span> Nip: {{ transactionDetails.companyDetails.tin }} </span>
          </div>
          <div>
            <span> Kraj: {{ transactionDetails.companyDetails.country }} </span>
          </div>
          <div>
            <span> Miasto: {{ transactionDetails.companyDetails.city }} </span>
          </div>
          <div>
            <span> Kod-pocztowy: {{ transactionDetails.companyDetails.postalCode }} </span>
          </div>
          <div>
            <span> Ulica: {{ transactionDetails.companyDetails.streetName }} </span>
          </div>
          <div>
            <span> Numer: {{ transactionDetails.companyDetails.streetNumber }} </span>
          </div>
        </div>
      </div>

      <div class="mt-5">
        <span class="text-body-1 font-weight-bold"> Dodaj fakturę </span>
        <v-file-input
          ref="fileInput"
          :disabled="canSend()"
          :accept="accept"
          :rules="[fileRule]"
          :ripple="false"
          @change="loadFile"
        >
          <template #append-outer>
            <v-tooltip bottom>
              <template #activator="{ on }">
                <v-btn :disabled="canSend()" icon color="button" :ripple="false" @click="saveFile()" v-on="on">
                  <v-icon>$save</v-icon>
                </v-btn>
              </template>
              <span>{{ $t("tooltip.save") | capitalize }}</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template #activator="{ on }">
                <v-btn :disabled="canSend()" icon color="button" :ripple="false" @click="showLocalPreview()" v-on="on">
                  <v-icon>$loupe</v-icon>
                </v-btn>
              </template>
              <span>{{ $t("tooltip.localPreview") | capitalize }}</span>
            </v-tooltip>
          </template>
        </v-file-input>
      </div>
      <div v-if="typeof transactionDetails.invoiceDetails != 'undefined'" class="mt-5">
        <span class="text-body-1 font-weight-bold"> Faktura: </span>
        <div>
          <span>Email: {{ transactionDetails.invoiceDetails.mailTo }}</span>
        </div>
        <div>
          <span>Nazwa: {{ getInvoiceName() }}</span>
          <v-tooltip bottom>
            <template #activator="{ on }">
              <v-btn icon color="button" :ripple="false" style="height: 17px" @click="showPreview()" v-on="on">
                <v-icon small>$loupe</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("tooltip.preview") | capitalize }}</span>
          </v-tooltip>
        </div>
        <div>
          <span>Wysłano: {{ getInvoiceDate() }}</span>
        </div>
        <div class="mt-2">
          <v-btn :disabled="canSend()" class="btn main-light" @click="sendInvoice">Wyslij</v-btn>
        </div>
      </div>
    </div>
  </custom-dialog>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

// Components
import CustomDialog from "@/components/shared/layout/CustomDialog.vue";

// Models
import { TransactionStatusEnum, TransactionDetails } from "@/models/api/transactionHistory/transaction";
import i18n from "@/plugins/i18n";
import { DateTimeFormat } from "vue-i18n";
import { dateTime } from "../../filters/dateFormat";
import { SnackbarModel } from "../../models/components/popup/Snackbar";

@Component({
  components: {
    CustomDialog,
  },
})
export default class TransactionHistoryDialog extends Vue {
  get transactionDetails(): TransactionDetails {
    return this.$store.direct.state.transaction.selectedTransactionDetails ?? new TransactionDetails();
  }

  async showPreview() {
    await this.$store.direct.dispatch.transaction.getInvoice();
  }

  async sendInvoice() {
    await this.$store.direct.dispatch.transaction.sendInvoiceEmail();
  }

  async saveFile() {
    if (this.file === null) {
      const snackbarModel = new SnackbarModel(["noFile"], "error");
      this.$store.direct.dispatch.popup.addSnackbar(snackbarModel);
      return;
    }
    const validationResult = this.fileRule(this.file);
    if (typeof validationResult === "string") {
      const snackbarModel = new SnackbarModel(["wrongFormatFile"], "error");
      this.$store.direct.dispatch.popup.addSnackbar(snackbarModel);
      return;
    }
    await this.$store.direct.dispatch.transaction.addInvoiceFile(this.file);
    (this.$refs.fileInput as HTMLFormElement).reset(); // Remove file from input
  }

  async mounted() {
    await this.$store.direct.dispatch.transaction.getTransactionDetails();
  }

  closeDialog() {
    this.$store.direct.commit.transaction.setTransactionHistoryDialogState(false);
    this.$store.direct.commit.transaction.setSelectedTransactionId(null);
    this.$store.direct.commit.transaction.setSelectedTransactionDetails(null);
  }

  getStatusName(status: TransactionStatusEnum): string {
    const transactionStatus = TransactionStatusEnum[status].toLowerCase();
    return i18n.t(`transaction.status.${transactionStatus}`).toString();
  }

  getInvoiceName(): string {
    const invoice = this.transactionDetails.invoiceDetails;
    return invoice.name != null ? invoice.name : "Brak dokumentu";
  }

  getInvoiceDate(): DateTimeFormat | string {
    const invoice = this.transactionDetails.invoiceDetails;
    if (invoice.sentAt != null) {
      return dateTime(invoice.sentAt);
    }
    return "Nie wysłano";
  }

  canSend(): boolean {
    return this.transactionDetails.invoiceDetails?.sentAt != null;
  }

  loadFile(file: File | null) {
    this.file = file;
  }

  showLocalPreview() {
    if (this.file != null) {
      // eslint-disable-next-line
      this.file.arrayBuffer().then((arrayBuffer: any) => {
        const blob = new Blob([new Uint8Array(arrayBuffer)], {
          type: this.file?.type,
        });
        const urlLink = window.URL.createObjectURL(blob);
        window.open(urlLink);
      });
    } else {
      const snackbarModel = new SnackbarModel(["noFile"], "error");
      this.$store.direct.dispatch.popup.addSnackbar(snackbarModel);
      return;
    }
  }

  file: File | null = null;

  allowedTypes = ["application/pdf"];
  accept = [".pdf"];

  fileRule = (file: File | undefined | null): string | boolean => {
    if (file === null || typeof file === "undefined") {
      return true;
    }
    if (this.allowedTypes.includes(file.type)) {
      return true;
    }
    return `Nie poprawny typ pliku. Wybierz format ${this.accept.join(" ")}`;
  };
}
</script>

<style scoped></style>
