<template>
  <div>
    <v-btn @click="check">Check alert</v-btn>
  </div>
</template>

<script lang="ts">
import { Vue, Component } from "vue-property-decorator";

@Component
export default class TestView extends Vue {
  check() {
    this.$store.direct.dispatch.developer.checkAlert();
  }
}
</script>
