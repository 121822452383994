<template>
  <transaction-redirection-card />
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

// Models
import TransactionStatus from "@/models/api/transaction/transactionStatus";
import TransactionPayURedirectionParams from "@/models/api/transaction/transactionPayURedirectionParams";

// Components
import TransactionRedirectionCard from "@/components/transactionRedirection/TransactionRedirectionCard.vue";

@Component({
  components: {
    TransactionRedirectionCard,
  },
})
export default class TransactionPayURedirectionView extends Vue {
  params = new TransactionPayURedirectionParams();

  get hashIsValid(): boolean {
    return this.$store.direct.state.transaction.hashIsValid;
  }

  get transactionStatus(): TransactionStatus | null {
    return this.$store.direct.state.transaction.transactionStatus as TransactionStatus;
  }

  async refreshStatus() {
    if (this.params.orderId != null) {
      await this.$store.direct.dispatch.transaction.checkTransactionStatus(this.params.orderId);
    }
  }

  timer = 0;
  requestCounter = 0;

  created() {
    this.params.clientId = this.$route.query.clientId as string;
    this.params.orderId = this.$route.query.orderId as string;
    this.params.hash = this.$route.query.hash as string;
  }

  async loadData() {
    await this.refreshStatus();
    this.requestCounter += 1;
    if (this.requestCounter >= 5 || this.transactionStatus?.status === "SUCCESS") {
      clearInterval(this.timer);
    }
  }

  async mounted() {
    if (!this.params.validate()) {
      this.$router.push("/statistics/statistic");
      return;
    }
    await this.$store.direct.dispatch.transaction.checkPayURedirectionParameters(this.params);
    if (this.hashIsValid) {
      await this.refreshStatus(); //Only one time
      this.timer = setInterval(this.loadData, 10000);
    } else {
      clearInterval(this.timer);
      this.$router.push("/statistics/statistic");
    }
  }

  // Do not remove!
  async beforeDestroy() {
    await clearInterval(this.timer);
  }
}
</script>

<style scoped>

</style>
