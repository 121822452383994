export default class UserRequest {
  id!: string;
  name!: string;
  surname!: string;
  email!: string;
  password!: string;

  constructor() {
    this.id = "00000000-0000-0000-0000-000000000000";
    this.name = "";
    this.surname = "";
    this.email = "";
    this.password = "";
  }
}
