export interface IntExtension {
  value: number;
  minValue?: number;
  maxValue?: number;
  label: string;
  extensionName: string;
  isValid: boolean;
  validateCodes: string[];
}

export interface StringExtension {
  value: string;
  minLength?: number;
  maxLength?: number;
  label: string;
  extensionName: string;
  isValid: boolean;
  validateCodes: string[];
}

export interface DateExtension {
  value: string;
  minValue?: string;
  maxValue?: string;
  label: string;
  extensionName: string;
  isValid: boolean;
  validateCodes: string[];
}

export interface DecimalExtension {
  value: number;
  minValue?: number;
  maxValue?: number;
  label: string;
  extensionName: string;
  // isValid: boolean;
  validateCodes: string[];
  numberSign: string;
  numberOfDecimals: number;
}

export interface CurrencyExtension {
  value: number;
  minValue?: number;
  maxValue?: number;
  extensionName: string;
  isValid: boolean;
  validateCodes: string[];
}

export interface ArrayIntExtension {
  values: IntExtension[];
  extensionName: string;
  minValue?: number;
  maxValue?: number;
  isValid: boolean;
  validateCodes: string[];
}

export interface DecimalArrayExtension {
  values: DecimalExtension[];
  extensionName: string;
  minSum?: number;
  maxSum?: number;
  isValid: boolean;
  validateCodes: string[];
}

export interface StringArrayExtension {
  values: StringExtension[];
  extensionName: string;
  isValid: boolean;
  validateCodes: string[];
}

export enum ExtensionTypeEnum {
  IntExtension = 1,
  StringExtension = 2,
  DateExtension = 3,
  DecimalExtension = 4,
  CurrencyExtension = 5,
  IntArrayExtension = 6,
  DecimalArrayExtension = 7,
  StringArrayExtension = 8,
}

export type ExtensionBase =
  | IntExtension
  | StringExtension
  | DateExtension
  | DecimalExtension
  | CurrencyExtension
  | ArrayIntExtension
  | DecimalArrayExtension
  | StringArrayExtension
  | null;
