import { filterCapitalize } from "@/filters/capitalize";
import i18n from "@/plugins/i18n";
import {
  ExtensionBase,
  IntExtension,
  StringExtension,
  ExtensionTypeEnum,
  CurrencyExtension,
  ArrayIntExtension,
  DecimalExtension,
  DecimalArrayExtension,
} from "@/models/api/reports/workFlow/BaseExtension";

const extensionValidator = (extension: ExtensionBase | null): boolean | string => {
  if (extension == null) return true;
  switch (extension.extensionName) {
    case ExtensionTypeEnum[ExtensionTypeEnum.IntExtension]: {
      const object = extension as IntExtension;
      return IntExtensionValidator(object);
    }
    case ExtensionTypeEnum[ExtensionTypeEnum.StringExtension]: {
      const object = extension as StringExtension;
      return StringExtensionValidator(object);
    }
    case ExtensionTypeEnum[ExtensionTypeEnum.DateExtension]: {
      return true; //TODO
    }
    case ExtensionTypeEnum[ExtensionTypeEnum.DecimalExtension]: {
      const object = extension as DecimalExtension;
      return DecimalExtensionValidator(object);
    }
    case ExtensionTypeEnum[ExtensionTypeEnum.CurrencyExtension]: {
      const object = extension as CurrencyExtension;
      return CurrencyExtensionValidator(object);
    }
    case ExtensionTypeEnum[ExtensionTypeEnum.IntArrayExtension]: {
      const object = extension as ArrayIntExtension;
      return ArrayIntExtensionValidator(object);
    }
    case ExtensionTypeEnum[ExtensionTypeEnum.DecimalArrayExtension]: {
      const object = extension as DecimalArrayExtension;
      return ArrayDecimalExtensionValidator(object);
    }
    default: {
      console.error("not implemented ExtensionType");
    }
  }
  return true;
};

function IntExtensionValidator(object: IntExtension): string | boolean {
  const value = Number(object.value);
  if (typeof value !== "number" || isNaN(value)) {
    return filterCapitalize(i18n.t("extensionValidation.notNumber"));
  }
  if (object.minValue != undefined && object.maxValue != undefined) {
    if (object.value.toString() !== "") {
      object.value = value; //$ change type to number;
    }
    if (object.value < object.minValue) {
      return filterCapitalize(
        i18n.t("extensionValidation.minNumber", {
          min: object.minValue,
          max: object.maxValue,
        })
      );
    }
    if (object.value > object.maxValue) {
      return filterCapitalize(
        i18n.t("extensionValidation.maxNumber", {
          min: object.minValue,
          max: object.maxValue,
        })
      );
    }
  }
  return true;
}

function StringExtensionValidator(object: StringExtension): string | boolean {
  const value = object.value.toString();
  if (object.minLength != undefined && object.maxLength != undefined) {
    if (value.length < object.minLength) {
      return filterCapitalize(
        i18n.t("extensionValidation.minLength", {
          min: object.minLength,
          max: object.maxLength,
        })
      );
    }
    if (value.length > object.maxLength) {
      return filterCapitalize(
        i18n.t("extensionValidation.maxLength", {
          min: object.minLength,
          max: object.maxLength,
        })
      );
    }
  }
  return true;
}

function DecimalExtensionValidator(object: DecimalExtension): string | boolean {
  const value = Number(object.value);
  if (typeof value !== "number" || isNaN(value)) {
    return filterCapitalize(i18n.t("extensionValidation.notNumber"));
  }
  switch (object.numberSign) {
    case "percent": {
      if (object.minValue != undefined && object.maxValue != undefined) {
        if (object.value.toString() !== "") {
          object.value = value; //$ change type to number;
        }
        if (object.value < object.minValue) {
          return filterCapitalize(
            i18n.t("extensionValidation.minNumber", {
              min: object.minValue,
              max: object.maxValue,
            })
          );
        }
        if (object.value > object.maxValue) {
          return filterCapitalize(
            i18n.t("extensionValidation.maxNumber", {
              min: object.minValue,
              max: object.maxValue,
            })
          );
        }
      }
      return true;
    }
    case "currency": {
      if (object.minValue != undefined) {
        if (object.value.toString() !== "") {
          object.value = value; //$ change type to number;
        }
        if (object.value < object.minValue) {
          return filterCapitalize(
            i18n.t("extensionValidation.minValue", {
              min: object.minValue,
              sign: "PLN",
            })
          );
        }
      }
      if (object.maxValue != undefined) {
        if (object.value.toString() !== "") {
          object.value = value; //$ change type to number;
        }
        if (object.value > object.maxValue) {
          return filterCapitalize(
            i18n.t("extensionValidation.maxValue", {
              max: object.maxValue,
              sign: "PLN",
            })
          );
        }
      }
      return true;
    }
  }

  return true;
}

function CurrencyExtensionValidator(object: CurrencyExtension): string | boolean {
  const value = object.value;
  const minValue = object.minValue as number;
  const maxValue = object.maxValue as number;
  if (value < minValue) {
    return filterCapitalize(i18n.t("extensionValidation.minNumber"));
  }
  if (value > maxValue) {
    return filterCapitalize(i18n.t("extensionValidation.maxNumber"));
  }
  return true;
}

function ArrayIntExtensionValidator(object: ArrayIntExtension): string | boolean {
  const values = object.values;
  if (object.minValue != undefined && object.maxValue != undefined) {
    const minValue = object.minValue;
    const maxValue = object.maxValue;
    const sum = values.reduce((sum, item) => sum + item.value, 0);
    if (sum < minValue) {
      return filterCapitalize(
        i18n.t("extensionValidation.minSum", {
          min: minValue.toFixed(0),
        })
      );
    }
    if (sum > maxValue) {
      return filterCapitalize(
        i18n.t("extensionValidation.maxSum", {
          max: maxValue.toFixed(0),
        })
      );
    }
  }
  return true;
}

function ArrayDecimalExtensionValidator(object: DecimalArrayExtension): string | boolean {
  const values = object.values;
  if (object.minSum != undefined && object.maxSum != undefined) {
    const minValue = object.minSum;
    const maxValue = object.maxSum;
    const sum = values.reduce((sum, item) => sum + item.value, 0);
    if (sum < minValue) {
      return filterCapitalize(
        i18n.t("extensionValidation.minSum", {
          min: minValue.toFixed(0) + "%",
        })
      );
    } else if (sum > maxValue) {
      return filterCapitalize(
        i18n.t("extensionValidation.maxSum", {
          max: maxValue.toFixed(0) + "%",
        })
      );
    }
  }
  return true;
}

export { extensionValidator };
export default extensionValidator;
