import { render, staticRenderFns } from "./InputTin.vue?vue&type=template&id=2032b426&scoped=true"
import script from "./InputTin.vue?vue&type=script&lang=ts"
export * from "./InputTin.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2032b426",
  null
  
)

export default component.exports