<template>
  <span class="scaling-svg-container">
    <svg xmlns="http://www.w3.org/2000/svg" width="40.376" height="40.375" viewBox="0 0 40.376 40.375">
      <path
        d="M20.189,40.376A20.189,20.189,0,0,1,13.46,1.153a2.016,2.016,0,1,1,1.345,3.8A16.154,16.154,0,1,0,35.419,25.571a2.019,2.019,0,1,1,3.807,1.345A20.222,20.222,0,0,1,20.189,40.376ZM38.356,20.189H22.206a2.022,2.022,0,0,1-2.02-2.02V2.02A2.022,2.022,0,0,1,22.206,0,18.19,18.19,0,0,1,40.376,18.169,2.022,2.022,0,0,1,38.356,20.189ZM24.226,4.181V16.15H36.195A14.2,14.2,0,0,0,24.226,4.181Z"
        fill="#d4ab5b"
      />
    </svg>
  </span>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

@Component
export default class PieIcon extends Vue {}
</script>

<style></style>
