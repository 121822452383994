<template>
  <v-list ref="list" dense>
    <div v-for="link in links" :key="link.id" :class="link.id > 1 ? 'list-item-spacer' : ''">
      <v-list-item v-if="link.children.length === 0" :to="{ name: link.to }" exact @click="clickItem()">
        <v-list-item-icon>
          <v-icon color="black" size="22">{{ link.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-title v-html="link.name" />
      </v-list-item>
      <v-list-item v-if="link.id == 1">
        <!-- <v-list-item-icon class="list-item-icon-remover">
           <v-icon> </v-icon>
        </v-list-item-icon> -->
        <div class="list-divider"></div>
      </v-list-item>
      <v-list-group v-else-if="link.children.length !== 0" color="black" :group="link.group">
        <template #prependIcon>
          <v-icon color="black" size="22">{{ link.icon }}</v-icon>
        </template>
        <template #appendIcon>
          <v-icon> <!-- Empty --> </v-icon>
        </template>
        <template #activator>
          <v-list-item-title @click="preventClose(link, $event)" v-html="link.name" />
        </template>
        <template #default>
          <v-list-item
            v-for="child in link.children"
            :key="child.id"
            :to="{ name: child.to }"
            exact
            @click="clickItem()"
          >
            <v-list-item-icon>
              <!-- Empty -->
            </v-list-item-icon>
            <v-list-item-title style="padding-left: 20px" v-html="child.name" />
          </v-list-item>
        </template>
      </v-list-group>
    </div>
  </v-list>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { getNavigationRoutes, INavigation } from "@/routes/navigationRoutes";

@Component
export default class NavigationLink extends Vue {
  links: INavigation[] = [];

  clickItem() {
    this.closeDrawer();
    this.colapseDrawer();
    this.links.forEach((x) => (x.active = false));
  }

  closeDrawer() {
    if (this.$vuetify.breakpoint.mobile) {
      this.$emit("closeDrawer", true);
    }
  }

  colapseDrawer() {
    this.$emit("colapseDrawer", true);
  }

  preventClose(link: INavigation, event: Event) {
    if (link.active) event.stopPropagation();
  }

  mounted() {
    this.links = getNavigationRoutes(this.$store.direct.state.user.role);
  }
}
</script>

<style lang="scss" scoped>
// Default settings - font and color
.v-list-item__title {
  font-size: 0.875rem;
  color: black;
}

// Edited for preventClose function
::v-deep .v-list-group__header {
  padding-right: 0;
  .v-list-item__title {
    height: 100%;
    position: absolute;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 50px;
    right: 0;
    left: 0;
  }
}

//Remove hover and item active
.v-list-item:hover::before {
  opacity: 0;
}

// Active
.v-list-item--active:before {
  opacity: 0;
}

.v-list-item:hover::before {
  opacity: 0.04;
}

// List group active on hover
::v-deep .v-list-group__header.v-list-item--active:hover::before {
  opacity: 0.08 !important;
}

// List group active
::v-deep .v-list-group__header.v-list-item--active::before {
  opacity: 0.08 !important;
}

// Remove margin in icon
::v-deep .v-list-item__icon:first-child {
  margin-right: 10px;
}

// Add border for active link
.v-list-item--active.v-list-item--link {
  border-right: 5px solid black;
}

.list-item-icon-remover {
  width: 0;
  height: 0;
  min-width: 0;
  margin: 0;
}

.list-item-spacer {
  margin-bottom: 15px;
}

.list-divider {
  width: 100%;
  height: 2px;
  background: #cbcbcb;
  margin: 0 30px;
}
</style>
