<template>
  <span class="scaling-svg-container">
    <svg
      id="Icon_Eye"
      data-name="Icon/Eye"
      xmlns="http://www.w3.org/2000/svg"
      width="9.375"
      height="6.064"
      viewBox="0 0 9.375 6.064"
      class="scaling-svg"
    >
      <path
        id="Icon_Icon_Eye"
        data-name="Icon/Icon/Eye"
        d="M4.7,6.064A5.816,5.816,0,0,1,.062,3.247a.406.406,0,0,1,0-.431A5.734,5.734,0,0,1,4.561,0L4.7,0A5.813,5.813,0,0,1,9.313,2.816a.409.409,0,0,1,0,.431,5.74,5.74,0,0,1-4.5,2.814ZM4.671.866H4.584A3.95,3.95,0,0,0,2.279,1.7,5.824,5.824,0,0,0,1.018,3.03,4.8,4.8,0,0,0,4.71,5.2H4.79A4.707,4.707,0,0,0,8.356,3.034,4.788,4.788,0,0,0,4.671.866Zm.016,3.681A1.584,1.584,0,0,1,3.047,3.032,1.584,1.584,0,0,1,4.688,1.516,1.584,1.584,0,0,1,6.328,3.032,1.584,1.584,0,0,1,4.688,4.548Zm0-2.166a.679.679,0,0,0-.7.65.679.679,0,0,0,.7.65.679.679,0,0,0,.7-.65A.679.679,0,0,0,4.688,2.382Z"
        fill="currentColor"
      />
    </svg>
  </span>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

@Component
export default class ShowIcon extends Vue {}
</script>

<style></style>
