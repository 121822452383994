<template>
  <div>
    <h2>Ikony używane w widoku</h2>
    <div class="mt-10">
      <h4>Przyciski</h4>
      <div v-for="(value, name, index) of buttonIcons" :key="index + 'a'" class="d-inline-block text-center ma-4">
        <div>
          <v-icon x-large color="error">${{ name }}</v-icon>
        </div>
        ${{ name }}
      </div>
      <h4>Wspólne</h4>
      <div v-for="(value, name, index) of commonIcons" :key="index + 'b'" class="d-inline-block text-center ma-4">
        <div>
          <v-icon :disabled="true" x-large color="error">${{ name }}</v-icon>
        </div>
        ${{ name }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

//Icons
import { buttonIcons, commonIcons } from "@/components/shared/icons/iconfont";

@Component({
  components: {},
})
export default class IconsView extends Vue {
  buttonIcons = buttonIcons;
  commonIcons = commonIcons;
}
</script>

<style lang="scss" scoped></style>
