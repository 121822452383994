import { IFrequentlyAskedQuestions } from "@/models/components/faq/frequentlyAskedQuestions";
import store from "@/store";

export const frequentlyAskedQuestions: IFrequentlyAskedQuestions = {
  items: [
    {
      header: "Kiedy otrzymam fakturę za zakupioną licencję?",
      text: "Faktura w formie elektronicznej zostanie przesłana na adres podany przy rejestracji konta w systemie.",
    },
    {
      header: "Jakie formy prowadzenia działalności są dostępne w raporcie?",
      text: "Aktualnie raport umożliwia analizę sytuacji biznesowej dla jednoosobowej działalności gospodarczej, spółki cywilnej, spółki jawnej i spółki z ograniczoną odpowiedzialnością.",
    },
    {
      header: "W jakim czasie muszę wykorzystać dostępne w ramach licencji raporty?",
      text: "Zakupione licencje ważne są rok, po tym czasie niewykorzystanych licencji nie można już użyć.",
    },
    {
      header: "Co zawiera raport sukcesyjny?",
      text: "Raport zawiera opis aktualnej sytuacji biznesowej i rodzinnej, scenariusze krótkoterminowe (na wypadek nagłej śmieci właściciela), scenariusze długoterminowe (planowane odejście z firmy), zagrożenia i ryzyka związane z obecną sytuacją, rekomendacje usprawniające przeprowadzenie procesu przekazania majątku, możliwości zabezpieczenia środków na wypadek spłaty spadkobierców i wierzycieli, przewodnik spadkowy dla Ciebie, Twoich bliskich i partnerów biznesowych.",
    },
    {
      header: "Jakie są dostępne formy płatności?",
      text: "Wszystkie płatności przyjmujemy za pośrednictwem Blue Media S.A., dostępne formy płatności: płatność kartą, przelew internetowy, wirtualny portfel, przelew tradycyjny.",
    },
    {
      header: "Czy można zobaczyć jak wygląda przykładowy raport?",
      text: "Tak, przykładowy raport można pobrać {0}",
      params: {
        0: {
          id: Math.random(),
          text: "Tutaj",
          type: "action",
          action: () => downloadSampleReport(),
        },
      },
    },
    {
      header: "Czy mogę tworzyć raporty dla innych firm?",
      text: "Tak, w ramach jednej licencji można tworzyć raporty dla innych firm, a także umożliwić tworzenie raportów innym osobom.",
    },
    {
      header: "Czy rozwiązania  podane w raporcie są zgodne z obowiązującym prawem?",
      text: "Wszystkie rozwiązania i rekomendacje zostały przygotowane przez doświadczonych prawników, którzy na co dzień mają styczność z tematem sukcesji biznesowej.",
    },
    {
      header: "Jeśli treść raportu jest dla mnie niezrozumiała, czy mam możliwość dodatkowych konsultacji?",
      text: "Tworząc raport mieliśmy na uwadze, że jego odbiorcami będą osoby nieznające prawniczego żargonu, dlatego staraliśmy się, by jego treść była przystępna w odbiorze. Jeśli jednak istnieją elementy raportu, które pozostają dla Ciebie niejasne, udzielimy wsparcia w jego interpretacji.",
    },
  ],
};

// Actions

export const downloadSampleReport = async () => {
  await store.dispatch.user.getReportDemo();
};
