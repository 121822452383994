<template>
  <v-card>
    <v-card-text>
      <form-input ref="formInput">
        <v-row justify="space-between">
          <v-col align-self="center">
            <span class="text-subtitle-2">{{ $t("form.userData") | capitalize }}</span>
          </v-col>
          <v-col>
            <v-row no-gutters justify="end">
              <slot name="button"></slot>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <input-text
              v-model="userDetails.email"
              :label="$t('form.userEmail') | capitalize"
              :readonly="true"
              :disabled="true"
              color="accent"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col class="col" cols="12" sm="6">
            <input-text
              v-model="userDetails.name"
              v-maxchars="30"
              :label="$t('form.userName') | capitalize"
              color="accent"
              :cols="6"
              :allow-number="false"
              :allow-symbol="true"
              :rules="[required, (v) => maxChar(v, 30)]"
            />
          </v-col>
          <v-col class="col" cols="12" sm="6">
            <input-text
              v-model="userDetails.surname"
              v-maxchars="30"
              :label="$t('form.userSurname') | capitalize"
              color="accent"
              :cols="6"
              :allow-number="false"
              :allow-symbol="true"
              :rules="[(v) => maxChar(v, 30)]"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <input-phone
              v-model="userDetails.phoneNumber"
              :label="$t('form.userPhoneNumber') | capitalize"
              color="accent"
              :cols="6"
            />
          </v-col>
        </v-row>
      </form-input>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import { Component, Vue, PropSync } from "vue-property-decorator";

//Inputs
import FormInput from "@/components/shared/forms/FormInput.vue";
import InputText from "@/components/shared/inputs/InputText.vue";
import InputPhone from "@/components/shared/inputs/InputPhone.vue";

//Models
import UserDetails from "@/models/api/user/UserDetails";

//Validation
import requiredValidator from "@/rules/requiredValidator";
import phoneNumberValidator from "@/rules/phoneNumberValidator";
import maxChar from "@/rules/maxCharsValidator";
import { FormValidation } from "../../../../../customTypes/vuetify";

@Component({
  components: {
    FormInput,
    InputText,
    InputPhone,
  },
})
export default class UserDetailsForm extends Vue {
  @PropSync("value", { required: true })
  userDetails!: UserDetails;

  //Validator
  required = requiredValidator;
  phoneNumber = phoneNumberValidator;
  maxChar = maxChar;

  //Form validation
  validate() {
    return (this.$refs.formInput as FormValidation).validate();
  }

  resetValidation(): void {
    (this.$refs.formInput as FormValidation).resetValidation();
  }
}
</script>

<style lang="scss" scoped>
.v-card {
  box-shadow: unset !important;
  background-color: transparent;
}
</style>
