<template>
  <span class="scaling-svg-container">
    <svg xmlns="http://www.w3.org/2000/svg" width="15.974" height="16" viewBox="0 0 15.974 16">
      <path
        d="M9.327,16H6.656A.658.658,0,0,1,6,15.343V14.008a.658.658,0,0,1,.657-.657h2.67a.655.655,0,0,1,.657.668,4.469,4.469,0,0,0,.791-.127l.01,0a2.487,2.487,0,0,0,.7-.291l.363-.182a2.741,2.741,0,0,0,.513-.461,1.768,1.768,0,0,1-.618-1.882,6.938,6.938,0,0,0,0-3.473,1.779,1.779,0,0,1,1.181-2.18,5.018,5.018,0,0,0-9.868,0,1.771,1.771,0,0,1,.895.63,1.8,1.8,0,0,1,.329,1.4.574.574,0,0,1-.039.146,6.788,6.788,0,0,0,0,3.475A1.777,1.777,0,0,1,2.531,13.33a1.761,1.761,0,0,1-1.4-.676,5.448,5.448,0,0,1,0-6.638A1.745,1.745,0,0,1,1.71,5.54a6.332,6.332,0,0,1,12.564,0,1.746,1.746,0,0,1,.578.476,5.448,5.448,0,0,1,0,6.638,1.791,1.791,0,0,1-1.124.654,4.531,4.531,0,0,1-3.744,2.027A.655.655,0,0,1,9.327,16Zm4.13-9.345a.468.468,0,0,0-.376.187A.427.427,0,0,0,13,7.223a.047.047,0,0,1,0,.014,8.1,8.1,0,0,1,0,4.2.434.434,0,0,0,.07.388.5.5,0,0,0,.393.2.434.434,0,0,0,.347-.173,4.121,4.121,0,0,0,0-5.019A.451.451,0,0,0,13.456,6.655Zm-10.928,0a.458.458,0,0,0-.36.171,4.121,4.121,0,0,0,0,5.019.434.434,0,0,0,.347.173.5.5,0,0,0,.393-.2.434.434,0,0,0,.07-.389A7.773,7.773,0,0,1,2.96,7.314a.465.465,0,0,0,.018-.077.448.448,0,0,0-.075-.4A.466.466,0,0,0,2.528,6.655Z"
        transform="translate(-0.005 0)"
        fill="#000"
      />
    </svg>
  </span>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

@Component
export default class HelpIcon extends Vue {}
</script>

<style></style>
