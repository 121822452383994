import Vue from "vue";
import VueI18n from "vue-i18n";

const bold = (value: string | VueI18n.TranslateResult): string => {
  if (!value) return "";
  value = value.toString();
  return "<b>" + value + "</b>";
};

Vue.filter("bold", bold);

export { bold };
