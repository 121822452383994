<template>
  <v-container>
    <div class="d-flex flex-column flex-md-row align-center justify-md-space-between mt-10">
      <div class="terms-image">
        <img src="@/assets/images/FollowUp.png" />
      </div>
      <div class="terms-image">
        <img src="@/assets/images/logoBlack.svg" />
      </div>
    </div>
    <div class="terms-container">
      <div class="text-center mt-10">
        <span class="text-h6 text-xl-h5 font-weight-bold">Warunki/Regulamin serwisu system.sukcesja.biz</span>
      </div>
      <terms-of-use-paragraph v-for="(item, index) in paragraphs.items" :key="index" :item="item" />
      <div class="d-flex justify-center mt-10 mb-10">
        <v-btn class="btn main-light" @click="close"> Zamknij </v-btn>
      </div>
    </div>
  </v-container>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import TermsOfUseParagraph from "@/components/termsOfUse/TermsOfUseParagraph.vue";

//Static
import { paragraphs } from "@/static/termsOfUse/termsOfUse";

@Component({
  components: {
    TermsOfUseParagraph,
  },
})
export default class TermsOfUseView extends Vue {
  paragraphs = paragraphs;

  close() {
    window.close();
    this.$router.back();
  }
}
</script>

<style lang="scss" scoped>
.terms-image {
  width: 300px;
  margin: auto 0;
  img {
    height: 100%;
    width: 100%;
    object-fit: scale-down;
  }
}
</style>
