<template>
  <div>
    <custom-dialog
      :value="showMainDialog"
      :title="$t('dialog.reports') | capitalize"
      width="700px"
      :buttons="false"
      @cancel="closeCompanyReportsDialog"
    >
      <administration-reports-files-filter :options.sync="tableOptions" />
      <v-data-table
        ref="table"
        :items="companyReports.items"
        :headers="companyReportsHeaders"
        :headers-length="companyReportsHeaders.length"
        :options.sync="tableOptions"
        :server-items-length="companyReports.count"
        :header-props="{ sortIcon: '$arrowUp' }"
        disable-sort
        hide-default-footer
        single-select
        :no-data-text="'Brak danych'"
        :height="233"
        fixed-header
      >
        <template #[`item.createdAt`]="{ item }">
          {{ item.createdAt | date }}
        </template>
        <template #[`item.actions`]="{ item }">
          <v-btn
            v-if="$store.direct.state.user.role > ROLE.Admin"
            icon
            color="black"
            @click="showSelectedFormRecordStampDialog(item)"
          >
            <v-icon>$info</v-icon>
          </v-btn>
          <v-tooltip bottom>
            <template #activator="{ on }">
              <v-btn icon color="black" @click="showSelectedFormItemsDialog(item)" v-on="on">
                <v-icon>$copy</v-icon>
              </v-btn>
            </template>
            {{ $t("tooltip.showReportList") | capitalize }}
          </v-tooltip>
        </template>
        <template #footer>
          <custom-pagination :options.sync="tableOptions" :server-items-length="companyReports.count">
          </custom-pagination>
        </template>
      </v-data-table>
    </custom-dialog>
    <custom-dialog
      :value="showSelectedFormItems"
      :title="getSelectedFormItemsTitle() | capitalize"
      width="700px"
      :buttons="false"
      @cancel="cancelShowSelectedFormItemsDialog"
    >
      <v-data-table
        :items="selectedFormItems.length > 0 ? selectedFormItems[0].formItems : []"
        :headers="headersData2"
        :headers-length="headersData2.length"
        :options.sync="tableSelectedFormItemsOptions"
        :server-items-length="selectedFormItems[0]?.formItems.length"
        :header-props="{ sortIcon: '$arrowUp' }"
        hide-default-footer
        single-select
        disable-sort
        :no-data-text="'Brak danych'"
        :height="233"
        fixed-header
      >
        <template #[`item.createdAt`]="{ item }">
          {{ item.createdAt | date }}
        </template>
        <template #[`item.modifiedAt`]="{ item }">
          {{ item.modifiedAt | date }}
        </template>
        <template #[`item.actions`]="{ item }">
          <v-btn v-if="$store.direct.state.user.role > ROLE.Admin" icon color="black" @click="info(item)">
            <v-icon>$info</v-icon>
          </v-btn>
          <v-tooltip bottom>
            <template #activator="{ on }">
              <v-btn icon color="black" @click="print(item)" v-on="on">
                <v-icon>$download</v-icon>
              </v-btn>
            </template>
            {{ $t("tooltip.downloadFile") }}
          </v-tooltip>
        </template>
        <template #footer>
          <custom-pagination
            :options.sync="tableSelectedFormItemsOptions"
            :server-items-length="selectedFormItems[0]?.formItems.length"
          ></custom-pagination>
        </template>
      </v-data-table>
    </custom-dialog>
    <record-stamp-dialog :id="recordStampId" :show.sync="showRecordStampDialog" :width="900" />
  </div>
</template>

<script lang="ts">
import { Component, Vue, PropSync, Prop, Watch } from "vue-property-decorator";
import { DataTableHeader, DataOptions } from "vuetify";
import DefaultDataOptions from "@/models/components/dataTable/DataTableOptions";

//Components
import CustomDialog from "@/components/shared/layout/CustomDialog.vue";
import RecordStampDialog from "@/components/shared/layout/RecordStampDialog.vue";
import CustomPagination from "@/components/shared/dataTable/CustomPagination.vue";
import AdministrationReportsFilesFilter from "@/components/administration/hierarchy/reports/AdministrationReportsFilesFilter.vue";

import AdministrationAddUserDialog from "@/components/administration/AdministrationAddUserDialog.vue";
import AdministrationEditUserDialog from "@/components/administration/AdministrationEditUserDialog.vue";

import ROLE from "@/static/role";

//Translation
import { filterCapitalize } from "@/filters/capitalize";
import i18n from "@/plugins/i18n";

//Models
import FormSummaryItem from "../../models/api/reports/formSummaryItem";
import { ListResult } from "../../models/api/filters/ListResult";
import FormBasic from "../../models/api/reports/formBasic";
import FormBasicItem from "../../models/api/reports/formBasicItem";

@Component({
  components: {
    CustomDialog,
    RecordStampDialog,
    CustomPagination,
    AdministrationAddUserDialog,
    AdministrationEditUserDialog,
    AdministrationReportsFilesFilter,
  },
})
export default class AdministrationReportFilesDialog extends Vue {
  @PropSync("value", { required: true })
  show?: boolean;
  @Prop()
  companyId!: string;

  depth = 0;
  recordStampId: number | null = null;

  ROLE = ROLE;

  // Dialog 1
  get companyReports(): ListResult<FormBasic> {
    return this.$store.direct.state.administration.companyReportsPreview ?? new ListResult<FormBasic>();
  }

  get tableOptions(): DataOptions {
    return this.$store.direct.state.filter.administrationReportsFilesFilterOptions;
  }

  set tableOptions(opt: DataOptions) {
    this.$store.direct.commit.filter.setAdministrationReportsFilesFilterDataOptions(opt);
  }

  companyReportsHeaders: Array<DataTableHeader> = [
    {
      text: filterCapitalize(i18n.t("header.reportName")),
      value: "name",
      align: "start",
    },
    {
      text: filterCapitalize(i18n.t("header.createdDate")),
      value: "createdAt",
      align: "start",
    },
    {
      text: "",
      value: "actions",
      align: "end",
    },
  ];

  closeCompanyReportsDialog() {
    this.show = false;
  }

  // Dialog 2
  showSelectedFormItems = false;
  selectedFormItems: Array<FormBasic> = [];

  tableSelectedFormItemsOptions = new DefaultDataOptions(10);
  headersData2: Array<DataTableHeader> = [
    {
      text: filterCapitalize(i18n.t("header.reportName")),
      value: "name",
      align: "start",
    },
    {
      text: filterCapitalize(i18n.t("header.createdDate")),
      value: "createdAt",
      align: "start",
    },
    {
      text: filterCapitalize(i18n.t("header.modifiedAt")),
      value: "modifiedAt",
      align: "start",
    },
    {
      text: "",
      value: "actions",
      align: "end",
    },
  ];

  showSelectedFormItemsDialog(item: FormBasic) {
    this.selectedFormItems = [];
    this.selectedFormItems.push(item);
    this.depth = 1;
    this.showMainDialog = false;
    this.showSelectedFormItems = true;
  }

  cancelShowSelectedFormItemsDialog() {
    this.showSelectedFormItems = false;
    this.showMainDialog = true;
    this.depth = 0;
  }

  @Watch("showRecordStampDialog")
  cancelShowRecordStamp(state: boolean) {
    if (!state && this.depth === 1) {
      this.showSelectedFormItems = true;
    } else if (!state && this.depth === 0) {
      this.showMainDialog = true;
    }
  }

  getSelectedFormItemsTitle() {
    return (
      filterCapitalize(i18n.t("dialog.report")) +
      " - " +
      (this.selectedFormItems.length ? this.selectedFormItems[0].name : "")
    );
  }

  async print(item: FormSummaryItem) {
    await this.$store.direct.dispatch.report.generateReport(item.id);
  }

  showRecordStampDialog = false;
  showMainDialog = true;

  showSelectedFormRecordStampDialog(item: FormBasic) {
    this.showMainDialog = false;
    this.showRecordStampDialog = true;
    this.recordStampId = item.recordStampId;
  }

  info(item: FormBasicItem) {
    this.showSelectedFormItems = false;
    this.showRecordStampDialog = true;
    this.recordStampId = item.recordStampId;
  }

  async mounted() {
    await this.$store.direct.commit.administration.setSelectedCompanyId(this.companyId);
  }
}
</script>

<style lang="scss" scoped>
::v-deep .v-data-table__expanded.v-data-table__expanded__content {
  box-shadow: unset !important;
  td {
    padding: 0;
  }
}

.expand-table {
  border-spacing: 0;
  width: 100%;
  tr {
    padding: 0 !important;
    height: 37px;
    td {
      border-bottom: thin solid rgba(0, 0, 0, 0.12);
      padding: 0 16px !important;
    }
  }
}
</style>
