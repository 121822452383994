<template>
  <custom-dialog
    v-if="_showDialog"
    :title="edit ? $t('dialog.editCompany') : $t('dialog.addCompany') | capitalize"
    @cancel="cancel"
    @save="save"
  >
    <v-form ref="companyForm">
      <v-row>
        <v-col>
          <form-flex :cols="12">
            <div slot="1">
              <v-text-field
                v-model="companyDetails.name"
                :label="$t('form.companyName') | capitalize"
                hide-details="auto"
                color="accent"
                :rules="[required]"
              ></v-text-field>
            </div>
          </form-flex>
          <form-flex :cols="6">
            <div slot="1">
              <v-text-field
                v-model="companyDetails.country"
                :label="$t('form.companyCountry') | capitalize"
                hide-details="auto"
                color="accent"
              ></v-text-field>
            </div>
            <div slot="2">
              <v-text-field
                v-model="companyDetails.city"
                :label="$t('form.companyCity') | capitalize"
                hide-details="auto"
                color="accent"
              ></v-text-field>
            </div>
          </form-flex>
          <form-flex :cols="6">
            <div slot="1">
              <v-text-field
                v-model="companyDetails.postalCode"
                :label="$t('form.companyPostalCode') | capitalize"
                hide-details="auto"
                color="accent"
              ></v-text-field>
            </div>
          </form-flex>
          <form-flex :cols="8">
            <div slot="1">
              <v-text-field
                v-model="companyDetails.streetName"
                :label="$t('form.companyStreetName') | capitalize"
                hide-details="auto"
                color="accent"
              ></v-text-field>
            </div>
            <div slot="2">
              <v-text-field
                v-model="companyDetails.streetNumber"
                :label="$t('form.companyStreetNumber') | capitalize"
                hide-details="auto"
                color="accent"
              ></v-text-field>
            </div>
          </form-flex>
          <form-flex :cols="6">
            <div slot="1">
              <input-tin
                v-model="companyDetails.tin"
                :label="$t('form.companyTIN') | capitalize"
                color="accent"
                :cols="6"
                :rules="[required]"
              />
            </div>
            <div slot="2">
              <input-phone
                v-model="companyDetails.phoneNumber"
                :label="$t('form.companyPhoneNumber') | capitalize"
                color="accent"
                :cols="6"
              />
            </div>
          </form-flex>
        </v-col>
      </v-row>
    </v-form>
  </custom-dialog>
</template>

<script lang="ts">
import { Vue, Component, PropSync, Prop } from "vue-property-decorator";
import CustomDialog from "@/components/shared/layout/CustomDialog.vue";
import requiredValidator from "@/rules/requiredValidator";
import FormFlex from "@/components/shared/inputs/FormFlex.vue";
import UserCompanyDetailsResponse from "@/models/api/user/UserCompanyDetailsResponse";
import InputTin from "@/components/shared/inputs/InputTin.vue";
import InputPhone from "@/components/shared/inputs/InputPhone.vue";

@Component({
  components: { CustomDialog, FormFlex, InputPhone, InputTin },
})
export default class AddCompanyDialog extends Vue {
  required = requiredValidator;

  @PropSync("showDialog", { required: true })
  _showDialog?: boolean;
  @Prop()
  edit!: boolean;
  @Prop()
  parentCompanyId!: string;

  companyDetails: UserCompanyDetailsResponse = new UserCompanyDetailsResponse();

  save() {
    this._showDialog = false;
  }

  cancel() {
    this._showDialog = false;
  }
}
</script>
