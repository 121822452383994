import i18n from "@/plugins/i18n";
import { filterCapitalize } from "@/filters/capitalize";

const maxCharsValidator = (value: unknown | string | number | null, maxLength: number): boolean | string => {
  if (typeof value === "string") {
    return value.toString().length > maxLength ? filterCapitalize(i18n.t("validation.limitExceeded")) : true;
  }
  if (typeof value === "number") {
    return value.toString().length > maxLength ? filterCapitalize(i18n.t("validation.limitExceeded")) : true;
  }
  return true;
};

export { maxCharsValidator };
export default maxCharsValidator;
