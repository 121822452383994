<template>
  <div>
    <input-text
      v-model="extension.value"
      :label="extension.label"
      :rules="[required, extensionValidator(extension)]"
      validate-on-blur
      :padding="false"
    />
  </div>
</template>

<script lang="ts">
import { Component, Vue, PropSync } from "vue-property-decorator";

//Components
import InputText from "@/components/shared/inputs/InputText.vue";

//Models
import { StringExtension as StringExt } from "@/models/api/reports/workFlow/BaseExtension";

//Validation
import requiredValidator from "@/rules/requiredValidator";
import extensionValidator from "@/rules/extensionValidator";

@Component({
  components: {
    InputText,
  },
})
export default class StringExtension extends Vue {
  @PropSync("value")
  extension!: StringExt;

  //Validation
  required = requiredValidator;
  extensionValidator = extensionValidator;
}
</script>

<style scoped></style>
