export default class TransactionBlueMediaRedirectionParams {
  serviceId?: string = "";
  orderId?: string = "";
  hash?: string = "";

  validate(): boolean {
    return (
      typeof this.serviceId != "undefined" && typeof this.orderId != "undefined" && typeof this.hash != "undefined"
    );
  }
}
