import { defineModule } from "direct-vuex";
import { moduleActionContext, moduleGetterContext } from "@/store/index";

//Models
import CompanyInfoResponse from "@/models/api/company/CompanyInfoResponse";
import CompanyDetails from "@/models/api/company/CompanyDetails";

import UserDetails from "@/models/api/user/UserDetails";
import UserInfoResponse from "@/models/api/user/UserInfoResponse";

//Requests
import { downloadCompanyChildren, downloadCompanyUsers, downloadCompanyDetails } from "@/requests/company";

import { editUser } from "@/requests/user";

export interface CompanyStateInterface {
  companyChildren: Array<CompanyInfoResponse>;
  companyUsers: Array<UserInfoResponse>;
  companyDetails: CompanyDetails;
  selectedCompanyId: string;
}

export const CompanyState: CompanyStateInterface = {
  companyChildren: [],
  companyUsers: [],
  companyDetails: new CompanyDetails(),
  selectedCompanyId: "",
};

const companyModule = defineModule({
  state: CompanyState as CompanyStateInterface,
  namespaced: true as const,
  getters: {},
  mutations: {
    setCompanyChildren(state, param: Array<CompanyInfoResponse>) {
      state.companyChildren = param;
    },
    setCompanyUsers(state, param: Array<UserInfoResponse>) {
      state.companyUsers = param;
    },
    setCompanyDetails(state, company: CompanyDetails) {
      state.companyDetails = company;
    },
    setSelectedCompanyId(state, companyId: string) {
      state.selectedCompanyId = companyId;
    },
  },
  actions: {
    async getCompanyChildren(context, companyId: string) {
      const { commit } = _moduleActionContext(context);
      const result = await downloadCompanyChildren(companyId);
      commit.setCompanyChildren(result.model);
    },
    async getCompanyUsers(context, companyId: string) {
      const { commit } = _moduleActionContext(context);
      const result = await downloadCompanyUsers(companyId);
      commit.setCompanyUsers(result.model);
    },
    async getCompanyDetails(context, companyId: string) {
      const { commit } = _moduleActionContext(context);
      const result = await downloadCompanyDetails(companyId);
      commit.setCompanyDetails(result.model);
    },
    async editUser(context, user: UserDetails) {
      const { state, dispatch } = _moduleActionContext(context);
      const companyId = state.selectedCompanyId;
      const result = await editUser(user);
      if (result.status == 200) {
        dispatch.getCompanyUsers(companyId);
        return true;
      } else {
        return false;
      }
    },
  },
});

export default companyModule;

/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable */
const _moduleGetterContext = (args: [any, any, any, any]) =>
  moduleGetterContext(args, companyModule);
const _moduleActionContext = (context: any) =>
  moduleActionContext(context, companyModule);
