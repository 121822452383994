<template>
  <span class="scaling-svg-container">
    <svg xmlns="http://www.w3.org/2000/svg" width="39" height="39" viewBox="0 0 39 39" class="scaling-svg">
      <g transform="translate(-624 -862)">
        <g transform="translate(109.321 196.321)">
          <circle cx="19.5" cy="19.5" r="19.5" transform="translate(514.679 665.679)" fill="#fff" />
        </g>
        <g transform="translate(-775.187 728.813)">
          <g transform="translate(1407.187 141.187)" fill="none" stroke="#bd0555" stroke-width="2">
            <circle cx="11.5" cy="11.5" r="11.5" stroke="none" />
            <circle cx="11.5" cy="11.5" r="10.5" fill="none" />
          </g>
          <line
            x1="15.927"
            y2="15.927"
            transform="translate(1410.723 144.723)"
            fill="none"
            stroke="#bd0555"
            stroke-width="2"
          />
        </g>
      </g>
    </svg>
  </span>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

@Component
export default class ErrorIcon extends Vue {}
</script>

<style></style>
