<template>
  <custom-dialog :value="showDelay" :title="title" :buttons="buttons" title-position="center" @cancel="close">
    <div class="mt-2 mb-2 text-black text-body-2">
      Poniżej znajduje się lista formularzy do uzupełniania. Wypełnij wszyskie aby móc wygenerować raport.
    </div>
    <form-summary-data />
  </custom-dialog>
</template>

<script lang="ts">
import { Component, Vue, PropSync } from "vue-property-decorator";

//Components
import CustomDialog, { DialogButtonInterface } from "@/components/shared/layout/CustomDialog.vue";
import FormSummaryData from "@/components/reports/FormSummaryData.vue";

@Component({
  components: {
    CustomDialog,
    FormSummaryData,
  },
})
export default class FormSummaryDialog extends Vue {
  @PropSync("show")
  _show!: boolean;

  showDelay = false;
  title = "Formularze";
  buttons: Array<DialogButtonInterface> = [
    {
      text: "Zamknij",
      color: "",
      class: "btn main-light--reverse",
      align: "",
      disabled: false,
      action: () => {
        this.close();
      },
    },
  ];

  close() {
    this._show = false;
  }

  async mounted() {
    await this.$store.direct.dispatch.report.getFormSummary();
    setTimeout(() => {
      this.showDelay = true;
    }, 100);
  }
}
</script>

<style scoped></style>
