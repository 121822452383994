import { defineModule } from "direct-vuex";
import { moduleActionContext, moduleGetterContext } from "@/store/index";
import vuetify from "@/plugins/vuetify";
import { GoToOptions, VuetifyGoToTarget } from "vuetify/types/services/goto";

export interface VuetifyStateInterface {
  defaultOptions: GoToOptions;
}

export const VuetifyState: VuetifyStateInterface = {
  defaultOptions: {
    container: "div.v-main__wrap", // Main container with overflow
    duration: 1000,
    offset: 100,
  },
};

const vuetifyModule = defineModule({
  state: VuetifyState as VuetifyStateInterface,
  namespaced: true as const,
  getters: {},
  mutations: {},
  actions: {
    async goTo(context, target: VuetifyGoToTarget) {
      const { state } = _moduleActionContext(context);
      vuetify.framework.goTo(target, state.defaultOptions);
    },
    async goToStart(context, duration: number | undefined = 0) {
      const { state } = _moduleActionContext(context);
      vuetify.framework.goTo(0, {
        ...state.defaultOptions,
        duration: duration,
      });
    },
  },
});

export default vuetifyModule;

/* eslint-disable */
const _moduleGetterContext = (args: [any, any, any, any]) =>
  moduleGetterContext(args, vuetifyModule);
const _moduleActionContext = (context: any) =>
  moduleActionContext(context, vuetifyModule);
