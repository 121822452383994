<template>
  <custom-dialog
    :title="$t('dialog.addCompany') | capitalize"
    width="500px"
    :buttons="edit"
    @cancel="cancel"
    @save="save"
  >
    <autocomplete-companies
      :company-id="$store.direct.getters.user.userCompanyId"
      :value.sync="selectedCompany"
      :show-parent="true"
      :label="$t('form.rootCompanyName')"
    />
    <company-add-form ref="companyForm" :company.sync="companyModel" :valid.sync="valid" :edit.sync="selectedCompany" />
  </custom-dialog>
</template>

<script lang="ts">
import { Component, Vue, PropSync, Prop } from "vue-property-decorator";

//Components
import CustomDialog from "@/components/shared/layout/CustomDialog.vue";

import CompanyAddForm from "@/components/administration/forms/CompanyAddForm.vue";

import AutocompleteCompanies from "@/components/shared/inputs/AutocompleteCompanies.vue";

//Models
import CompanyModel from "@/models/api/company/CompanyModel";
import CompanyBasic from "@/models/api/company/CompanyBasic";

@Component({
  components: {
    CustomDialog,
    CompanyAddForm,
    AutocompleteCompanies,
  },
})
export default class AdministrationAddCompanyDialog extends Vue {
  @PropSync("value", { required: true })
  show?: boolean;
  @Prop()
  edit!: boolean;
  @PropSync("parentId")
  selectedCompanyId!: string;

  userCompanyId = "";

  selectedCompany: CompanyBasic | null = null;

  companyModel: CompanyModel = new CompanyModel();
  valid = true;

  async save() {
    (this.$refs.companyForm as HTMLFormElement).validate();
    if (!this.valid) return;
    if (this.selectedCompany != null) {
      this.companyModel.parentCompanyId = this.selectedCompany.id;
      this.selectedCompanyId = this.selectedCompany.id;
      const response = await this.$store.direct.dispatch.administration.addNewCompany(this.companyModel);
      this.show = !response;
    }
  }

  cancel() {
    this.show = false;
  }
}
</script>

<style lang="scss" scoped></style>
