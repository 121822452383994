<template>
  <div>
    <v-container>
      <form-input ref="form" :disabled="!edit" :flex="false">
        <v-row>
          <v-col cols="12">
            <input-text
              v-model="_company.name"
              :label="$t('form.companyName') | capitalize"
              hide-details="auto"
              color="accent"
              :rules="[required]"
            ></input-text>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <input-text
              v-model="_company.country"
              :label="$t('form.companyCountry') | capitalize"
              color="accent"
              :allow-number="false"
              :allow-symbol="true"
            ></input-text>
          </v-col>
          <v-col cols="6">
            <input-text
              v-model="_company.city"
              :label="$t('form.companyCity') | capitalize"
              color="accent"
              :allow-number="false"
              :allow-symbol="true"
            ></input-text>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <input-postal-code
              v-model="_company.postalCode"
              :label="$t('form.companyPostalCode') | capitalize"
              color="accent"
              :rules="[]"
            ></input-postal-code>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="9">
            <input-text
              v-model="_company.streetName"
              :label="$t('form.companyStreetName') | capitalize"
              color="accent"
            ></input-text>
          </v-col>
          <v-col cols="3">
            <input-text
              v-model="_company.streetNumber"
              :label="$t('form.companyStreetNumber') | capitalize"
              color="accent"
            ></input-text>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <input-tin
              v-model="_company.tin"
              :label="$t('form.companyTIN') | capitalize"
              color="accent"
              :cols="6"
              :rules="[required]"
            />
          </v-col>
          <v-col cols="6">
            <input-phone
              v-model="_company.phoneNumber"
              :label="$t('form.companyPhoneNumber') | capitalize"
              color="accent"
              :cols="6"
            />
          </v-col>
        </v-row>
      </form-input>
    </v-container>
  </div>
</template>

<script lang="ts">
import { Component, Vue, PropSync } from "vue-property-decorator";

//Components
import InputPostalCode from "@/components/shared/inputs/InputPostalCode.vue";
import InputText from "@/components/shared/inputs/InputText.vue";
import FormInput from "@/components/shared/forms/FormInput.vue";
import InputTin from "@/components/shared/inputs/InputTin.vue";
import InputPhone from "@/components/shared/inputs/InputPhone.vue";

//Models
import CompanyModel from "@/models/api/company/CompanyModel";

//Validation
import requiredValidator from "@/rules/requiredValidator";
import phoneNumberValidator from "@/rules/phoneNumberValidator";

@Component({
  components: {
    InputText,
    InputPostalCode,
    FormInput,
    InputPhone,
    InputTin,
  },
})
export default class CompanyAddForm extends Vue {
  @PropSync("company", { required: true })
  _company!: CompanyModel;
  @PropSync("valid")
  _valid!: boolean;
  @PropSync("edit")
  _edit!: object | null;

  //Validators
  required = requiredValidator;
  phoneNumber = phoneNumberValidator;

  //Form validation
  validate() {
    const valid = (this.$refs.form as HTMLFormElement).validate();
    this._valid = valid;
  }
}
</script>

<style scoped></style>
