import { render, staticRenderFns } from "./InputPhone.vue?vue&type=template&id=f3c63538&scoped=true"
import script from "./InputPhone.vue?vue&type=script&lang=ts"
export * from "./InputPhone.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f3c63538",
  null
  
)

export default component.exports