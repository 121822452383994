import { CriterionBase } from "@/models/api/filters/CriterionBase";

export interface Filter {
  name: string;
  sortBy: string;
  sortDirection: SortDirectionEnum;
  criterions: Array<CriterionBase>;
}

export enum SortDirectionEnum {
  Asc = 1,
  Desc = 2,
}
