import { CustomRouteConfig } from "@/models/plugins/CustomRouterTypes";
import ROLE from "@/static/role";
import ROUTE_NAMES from "@/static/routeNames";
import { filterCapitalize } from "@/filters/capitalize";
import i18n from "@/plugins/i18n";

//Views
//Statistic
import StatisticView from "@/views/nav/statistic/StatisticView.vue";
import StatisticsStatisticView from "@/views/nav/statistic/StatisticsStatisticView.vue";

//Licenses
import LicensesView from "@/views/nav/licenses/LicensesView.vue";
import LicensesLicenseView from "@/views/nav/licenses/LicensesLicenseView.vue";
import LicensesHistoryView from "@/views/nav/licenses/LicensesHistoryView.vue";
import LicensesBuyView from "@/views/nav/licenses/LicensesBuyView.vue";

//Administration
import AdministrationView from "@/views/nav/administration/AdministrationView.vue";
import AdministrationAccountView from "@/views/nav/administration/AdministrationAccountView.vue";
import AdministrationHierarchyView from "@/views/nav/administration/AdministrationHierarchyView.vue";
import AdministrationUsersView from "@/views/nav/administration/AdministrationUsersView.vue";

//Reports
import ReportsView from "@/views/nav/reports/ReportsView.vue";
import ReportsGeneratedView from "@/views/nav/reports/ReportsGeneratedView.vue";

//Settings
import SettingsView from "@/views/nav/settings/SettingsView.vue";

//Help
import HelpView from "@/views/nav/help/HelpView.vue";

//Transaction
import TransactionBlueMediaRedirectionView from "@/views/nav/transaction/TransactionBlueMediaRedirectionView.vue";
import TransactionPayURedirectionView from "@/views/nav/transaction/TransactionPayURedirectionView.vue";

//TransactionHistory
import TransactionHistoryView from "@/views/nav/transactionHistory/TransactionHistoryView.vue";

const NavigationRoutes: Array<CustomRouteConfig> = [
  {
    name: ROUTE_NAMES.STATISTICS.MAIN,
    path: "/statistics",
    component: StatisticView,
    redirect: { name: ROUTE_NAMES.STATISTICS.STATISTIC },
    meta: {
      userRole: ROLE.User,
      title: filterCapitalize(i18n.t("routes.statistics")),
    },
    children: [
      {
        name: ROUTE_NAMES.STATISTICS.STATISTIC,
        path: "statistic",
        component: StatisticsStatisticView,
        meta: {
          userRole: ROLE.User,
        },
      },
    ],
  },
  {
    name: ROUTE_NAMES.LICENSES.MAIN,
    path: "/licenses",
    component: LicensesView,
    redirect: { name: ROUTE_NAMES.LICENSES.LICENSE },
    meta: {
      userRole: ROLE.Admin,
      title: filterCapitalize(i18n.t("routes.licenses")),
    },
    children: [
      {
        name: ROUTE_NAMES.LICENSES.LICENSE,
        path: "license",
        component: LicensesLicenseView,
        meta: {
          userRole: ROLE.Admin,
        },
      },
      {
        name: ROUTE_NAMES.LICENSES.HISTORY,
        path: "history",
        component: LicensesHistoryView,
        meta: {
          userRole: ROLE.Admin,
        },
      },
      {
        name: ROUTE_NAMES.LICENSES.BUY,
        path: "buy",
        component: LicensesBuyView,
        meta: {
          userRole: ROLE.Admin,
        },
      },
    ],
  },
  {
    name: ROUTE_NAMES.ADMINISTRATION.MAIN,
    path: "/administration",
    redirect: { name: ROUTE_NAMES.ADMINISTRATION.ACCOUNT },
    component: AdministrationView,
    meta: {
      userRole: ROLE.Admin,
      title: filterCapitalize(i18n.t("routes.administration")),
    },
    children: [
      {
        name: ROUTE_NAMES.ADMINISTRATION.ACCOUNT,
        path: "account",
        component: AdministrationAccountView,
        meta: {
          userRole: ROLE.Admin,
        },
      },
      {
        name: ROUTE_NAMES.ADMINISTRATION.HIERARCHY,
        path: "hierarchy",
        component: AdministrationHierarchyView,
        meta: {
          userRole: ROLE.Admin,
        },
      },
      {
        name: ROUTE_NAMES.ADMINISTRATION.USERS,
        path: "users",
        component: AdministrationUsersView,
        meta: {
          userRole: ROLE.Admin,
        },
      },
    ],
  },
  {
    name: ROUTE_NAMES.REPORTS.MAIN,
    path: "/reports",
    component: ReportsView,
    redirect: { name: ROUTE_NAMES.REPORTS.GENERATED },
    meta: {
      userRole: ROLE.User,
      title: filterCapitalize(i18n.t("routes.reports")),
    },
    children: [
      {
        name: ROUTE_NAMES.REPORTS.GENERATED,
        path: "generated",
        component: ReportsGeneratedView,
        meta: {
          userRole: ROLE.User,
        },
      },
    ],
  },
  {
    name: ROUTE_NAMES.SETTINGS.MAIN,
    path: "/settings",
    component: SettingsView,
    meta: {
      userRole: ROLE.Admin,
      title: filterCapitalize(i18n.t("routes.settings")),
    },
  },
  {
    name: ROUTE_NAMES.HELP.MAIN,
    path: "/help",
    component: HelpView,
    meta: {
      userRole: ROLE.User,
      title: filterCapitalize(i18n.t("routes.help")),
    },
  },
  {
    name: ROUTE_NAMES.TRANSACTION.BLUEMEDIA,
    path: "/payment/bluemedia/status",
    component: TransactionBlueMediaRedirectionView,
    meta: {
      userRole: ROLE.User,
      title: filterCapitalize(i18n.t("routes.transactionStatus")),
    },
  },
  {
    name: ROUTE_NAMES.TRANSACTION.PAYU,
    path: "/payment/payu/status",
    component: TransactionPayURedirectionView,
    meta: {
      userRole: ROLE.User,
      title: filterCapitalize(i18n.t("routes.transactionStatus")),
    },
  },
  {
    name: ROUTE_NAMES.TRANSACTION_HISTORY.MAIN,
    path: "/transaction-history",
    component: TransactionHistoryView,
    meta: {
      isAnonymousAccess: false,
      userRole: ROLE.Admin,
    },
  },
];

export { NavigationRoutes };
export default NavigationRoutes;
