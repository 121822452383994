export default class Migrations {
  items: Array<MigrationItem> = [];
}

export class MigrationItem {
  date!: string;
  name!: string;
  type!: MigrationTypeEnum;
}

export enum MigrationTypeEnum {
  Applied = 1,
  Pending = 2,
}
