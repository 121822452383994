<template>
  <span class="btn-wrapper">
    <span class="upper-text">{{ upperText }}</span>
    <v-btn ref="btn" :class="`btn ${type} ${icon ? 'icon' : ''}`" :ripple="false" @click="click">
      <v-icon v-if="icon && left">{{ icon }}</v-icon>
      <v-icon v-if="icon && !left" size="20" class="icon">{{ icon }}</v-icon>
      <slot></slot>
      <v-icon v-if="icon && !left" size="20" class="icon">{{ icon }}</v-icon>
    </v-btn>
  </span>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class Btn extends Vue {
  @Prop()
  type!: string;
  @Prop()
  icon!: string;
  @Prop()
  left!: boolean;
  @Prop()
  upperText!: string;

  click() {
    this.$emit("click");
  }
}
</script>

<style lang="scss" scoped>
.upper-text {
  display: flex;
  justify-content: center;
  font-size: 0.75rem;
}

.btn-wrapper {
  display: flex;
  flex-direction: column;
}

.btn {
  width: 170px;
  height: 40px !important;
  min-width: 130px !important;
  padding: 0 !important;
  // height: 35px !important;
  border-radius: 10px;
  box-shadow: unset;
  margin: 2px 0 0 0;
  font-size: 0.75rem;
  padding-top: 1px !important;
  &.black {
    color: white;
    border: 1px solid black !important;
    &:hover {
      color: black;
      background-color: white !important;
    }
  }
  &.white {
    color: black;
    border: 1px solid black !important;
    &:hover {
      color: white;
      background-color: black !important;
    }
  }
  &.icon {
    justify-content: space-evenly;
  }
}

@media screen and (min-width: 440px) and (max-width: 760px) {
  .btn {
    width: 155px !important;
    height: 40px !important;
  }
}

@media screen and (max-width: 440px) {
  .btn {
    width: 140px !important;
    height: 35px !important;
  }
}
</style>
