<template>
  <div class="logo-content">
    <div class="logo" />
    <div class="logo-signature" />
  </div>
</template>

<script lang="ts">
import { Vue, Component } from "vue-property-decorator";
import "@/assets/layout/logo.svg";

@Component
export default class LogoImage extends Vue {}
</script>

<style scoped>
.logo-content {
  position: relative;
  height: 127px;
  justify-content: center;
  align-content: center;
  margin-bottom: 15px;
}

.logo {
  position: relative;
  width: 54px;
  height: 87px;
  left: 50%;
  transform: translate(-50%, 0%);
  background: transparent url("@/assets/layout/logo.svg") 0% 0% no-repeat;
}

.logo-signature {
  position: relative;
  width: 147px;
  height: 37px;
  top: 7px;
  left: 50%;
  transform: translate(-40%, 0%);
  background: transparent url("@/assets/layout/logo_signature.svg") 0% 0% no-repeat;
}
</style>
