<template>
  <v-row justify="center" justify-sm="end" style="gap: 10px" class="buttons">
    <v-btn :width="150" class="btn main-light--reverse" @click="cancel">{{ $t("actions.cancel") | capitalize }}</v-btn>
    <v-btn :width="150" :disabled="!_disabled" class="btn main-light" @click="save">{{
      $t("actions.save") | capitalize
    }}</v-btn>
  </v-row>
</template>

<script lang="ts">
import { Vue, Component, PropSync } from "vue-property-decorator";

@Component
export default class ButtonsDefault extends Vue {
  @PropSync("disabled", { default: true })
  _disabled!: boolean;

  save() {
    this.$emit("save");
  }

  cancel() {
    this.$emit("cancel");
  }
}
</script>

<style lang="scss" scoped>
.buttons {
  margin: 20px 0 10px 0;
  @media screen and (min-width: 500px) {
    margin-right: 20px;
  }
}
</style>
