<template>
  <v-container fluid class="view-container pb-0 mb-0" style="overflow-y: clip">
    <div class="welcome-wiew-container" style="position: relative">
      <div style="z-index: 5; position: relative">
        <plan-succession />
        <business-succession />
        <report-path />
        <report-contains />
        <products id="products" />
        <about id="about" />
        <information />
        <contact id="contact" />
      </div>
      <div class="wattermarks">
        <img class="wattermark" style="top: 437px; right: -160px" src="@/assets/layout/background/el_1.svg" />
        <img class="wattermark" style="top: 1320px; left: 40px" src="@/assets/layout/background/el_1.svg" />
        <img class="wattermark" style="top: 2400px; left: -240px" src="@/assets/layout/background/el_1.svg" />
        <img class="wattermark" style="top: 3504px; right: -180px" src="@/assets/layout/background/el_1.svg" />
        <img class="wattermark" style="top: 3904px; left: -440px" src="@/assets/layout/background/el_1.svg" />
        <img class="wattermark" style="top: 5504px; right: -180px" src="@/assets/layout/background/el_1.svg" />
        <img class="wattermark" style="top: 6504px; left: -240px" src="@/assets/layout/background/el_1.svg" />
        <img class="wattermark" style="top: 7504px; right: -180px" src="@/assets/layout/background/el_1.svg" />
        <img class="wattermark" style="top: 8504px; left: -240px" src="@/assets/layout/background/el_1.svg" />
        <img class="wattermark" style="top: 9504px; right: -180px" src="@/assets/layout/background/el_1.svg" />
        <img class="wattermark" style="top: 10504px; right: -240px" src="@/assets/layout/background/el_1.svg" />
      </div>
    </div>
    <my-footer />
  </v-container>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

//Components
import PlanSuccession from "@/components/anonymous/startPage/planSuccession/PlanSuccession.vue";
import BusinessSuccession from "@/components/anonymous/startPage/businessSuccession/BusinessSuccession.vue";
import ReportPath from "@/components/anonymous/startPage/reportPath/ReportPath.vue";
import ReportContains from "@/components/anonymous/startPage/reportContains/ReportContains.vue";
import Products from "@/components/anonymous/startPage/products/Products.vue";
import About from "@/components/anonymous/startPage/about/About.vue";
import Information from "@/components/anonymous/startPage/information/Information.vue";
import Contact from "@/components/anonymous/startPage/contact/Contact.vue";
import MyFooter from "@/components/anonymous/startPage/footer/Footer.vue";

@Component({
  components: {
    PlanSuccession,
    BusinessSuccession,
    ReportPath,
    ReportContains,
    Products,
    About,
    Information,
    Contact,
    MyFooter,
  },
})
export default class WelcomeView extends Vue {
  go() {
    this.$vuetify.goTo("#about", { container: "div.v-main__wrap" });
  }
}
</script>

<style lang="scss" scoped>
.v-card {
  background-color: transparent !important;
}

.v-card__text {
  text-align: left !important;
  color: black !important;
}

.wattermarks {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.wattermark {
  position: absolute;
}

// .wattermarks {
//   @media screen and (max-width: 1919px) {
//     img {
//       height: 400px;
//     }
//   }

//   @media screen and (max-width: 768px) {
//     img {
//       height: 300px;
//     }
//   }

//   @media screen and (max-width: 420px) {
//     img {
//       height: 200px;
//     }
//   }
// }

.welcome-wiew-container {
  @media screen and (min-width: 1920px) {
    width: 1440px;
    margin: 0 auto;
  }
}
</style>
