<template>
  <div class="stepper-wrapper">
    <div class="stepper" :style="3 === texts.length ? 'width: 250px' : 'width: 125px'">
      <div class="stepper-bar" />
      <div
        v-for="item in texts"
        :key="item + Math.random().toString()"
        class="stepper-item"
        :class="item.index === 1 ? 'current' : ''"
      >
        <div class="stepper-item-circle" />
        <div class="stepper-item-title">
          <span class="stepper-item-title-text">
            {{ item.text }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, PropSync, Watch } from "vue-property-decorator";
import BasePrototype from "@/models/api/reports/workFlow/BasePrototype";

export interface QuestionText {
  index: number;
  text: string | null;
}

@Component
export default class ReportDialogStepper extends Vue {
  @PropSync("prototype")
  value!: BasePrototype;

  texts: Array<QuestionText> = [];

  @Watch("value.nextQuestionText")
  updateLabels() {
    this.texts = [];
    this.texts.push({ index: 0, text: this.value.previousQuestionText });
    this.texts.push({ index: 1, text: this.value.shortText });
    this.texts.push({
      index: 2,
      text: this.value.nextQuestionText == null ? "Koniec" : this.value.nextQuestionText,
    });
    this.texts = this.texts.filter((v) => v.text != "" && v.text != null);
  }

  created() {
    this.updateLabels();
  }
}
</script>

<style lang="scss" scoped>
.stepper-wrapper {
  display: flex;
  justify-content: center;
}

.stepper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 250px;
  position: relative;
  z-index: 0;
  margin-bottom: 55px;

  &-bar {
    position: absolute;
    background-color: $border;
    height: 1px;
    z-index: -1;
    left: 0;
    right: 0;
    margin: 0 auto;
  }
}

.stepper-item {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  color: black;

  &-circle {
    height: 15px;
    width: 15px;
    display: grid;
    place-items: center;
    background-color: #fff;
    border-radius: 100%;
    border: 1px solid $border;
    position: relative;
  }

  &-title {
    position: absolute;
    font-size: 0.725rem;
    font-weight: 200;
    top: 20px;
    width: 90px;
    word-break: normal;
    display: table;
    text-align: center;

    &-text {
      display: table-cell;
      vertical-align: middle;
      line-height: normal;
    }
  }
}

.stepper-item.current {
  .stepper-item-circle {
    background-color: black;
  }
}
</style>
