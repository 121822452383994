<template>
  <custom-dialog :title="title" @cancel="cancel" @save="save">
    <user-password-form ref="userPassword" :value.sync="passwordModel" />
  </custom-dialog>
</template>

<script lang="ts">
import { Vue, Component, PropSync, Prop } from "vue-property-decorator";

//Components
import CustomDialog from "@/components/shared/layout/CustomDialog.vue";
import UserPasswordForm from "@/components/administration/account/forms/UserPasswordForm.vue";

//Models
import PasswordEdit from "@/models/api/administration/account/PasswordEdit";

@Component({
  components: {
    CustomDialog,
    UserPasswordForm,
  },
})
export default class ChangePasswordDialog extends Vue {
  @PropSync("showDialog", { required: true })
  _showDialog?: boolean;
  @Prop({ required: true })
  title!: string;

  passwordModel: PasswordEdit = {
    oldPassword: "",
    newPassword: "",
  };

  validateForm(): boolean {
    return (this.$refs.userPassword as HTMLFormElement).validate();
  }

  async save() {
    if (this.validateForm()) {
      this._showDialog = !(await this.$store.direct.dispatch.user.changePassword(this.passwordModel));
    }
  }

  cancel() {
    this._showDialog = false;
  }
}
</script>
