import Vue from "vue";
import VueI18n, { LocaleMessages, NumberFormats, DateTimeFormats } from "vue-i18n";

Vue.use(VueI18n);

function loadLocaleMessages(): LocaleMessages {
  const locales = require.context("../locales", true, /[A-Za-z0-9-_,\s]+\.jsonc$/i);
  const messages: LocaleMessages = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key).default;
    }
  });
  return messages;
}

const numberFormats: NumberFormats = {
  en: {
    currency: {
      style: "currency",
      currency: "CAD",
      currencyDisplay: "name",
    },
    decimal: {
      style: "decimal",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
  },
  pl: {
    currency: {
      style: "currency",
      currency: "PLN",
      currencyDisplay: "symbol",
      minimumFractionDigits: 2,
    },
    decimal: {
      style: "decimal",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: "percent",
      useGrouping: false,
      // minimumFractionDigits: 2,
      // maximumFractionDigits: 2,
    },
    integer: {
      style: "decimal",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    },
    phone: {
      style: "decimal",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
      minimumIntegerDigits: 9,
    },
  },
};

const dateTimeFormats: DateTimeFormats = {
  en: {
    short: {
      year: "numeric",
      month: "short",
      day: "numeric",
    },
    long: {
      year: "numeric",
      month: "short",
      day: "numeric",
      weekday: "short",
      hour: "numeric",
      minute: "numeric",
    },
  },
  pl: {
    short: {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    },
    long: {
      year: "numeric",
      month: "short",
      day: "numeric",
      weekday: "short",
      hour: "numeric",
      minute: "numeric",
    },
    custom: {
      day: "numeric",
      month: "short",
      year: "numeric",
    },
  },
};

export default new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || "en",
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "en",
  messages: loadLocaleMessages(),
  numberFormats: numberFormats,
  dateTimeFormats: dateTimeFormats,
});
