<template>
  <ol v-if="typeof item.items != 'undefined'" class="mb-5">
    <li v-for="item in item.items" :key="item.id" class="text-body-2">
      {{ item.text }}
      <privacy-policy-paragraph-list :item="item" />
    </li>
  </ol>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";

//Interface
import { IPrivacyPolicyParagraphData } from "../../models/components/privacyPolicy/privacyPolicy";

@Component({
  components: {
    PrivacyPolicyParagraphList: () => import("@/components/privacyPolicy/PrivacyPolicyParagraphList.vue"),
  },
})
export default class PrivacyPolicyParagraphList extends Vue {
  @Prop({ required: true })
  item!: IPrivacyPolicyParagraphData;
}
</script>

<style lang="scss" scoped>
li + li {
  margin-top: 5px;
}

ol li {
  margin-top: 5px;
}

ol {
  list-style: none;
}

li {
  ol li {
    list-style: disc;
  }
}
</style>
