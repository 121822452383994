<template>
  <div>
    <apex-chart
      v-if="noData"
      ref="chart"
      :height="350"
      :options="chartOptionsNoData"
      :series="seriesNoData"
    ></apex-chart>
    <apex-chart v-else ref="chart" :height="350" :options="chartOptions" :series="series"></apex-chart>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Watch } from "vue-property-decorator";
import { ApexOptions } from "apexcharts";

import LicenseChartData from "../../models/api/statistic/licenseChartData";

import ROLE from "@/static/role";

@Component({
  components: {},
})
export default class StatisticsLicensesChart extends Vue {
  get licensesChartData(): LicenseChartData {
    return this.$store.direct.state.statistic.licenseChartData;
  }

  ROLE = ROLE;

  noData = true;
  seriesNoData: Array<number> = [1];
  chartOptionsNoData: ApexOptions = {
    chart: {
      height: 350,
      type: "donut",
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      position: "bottom",
    },
    labels: ["Brak danych"],
    title: {
      text: "Ilość licencji",
      align: "center",
      style: {
        fontFamily: "Rubik",
        fontWeight: 600,
        fontSize: "16px",
        color: "black",
      },
    },
  };

  series: Array<number> = [];
  labels: Array<string> = [];

  chartOptions: ApexOptions = {
    chart: {
      height: 350,
      type: "donut",
    },
    legend: {
      position: "bottom",
    },
    labels: [...this.labels],
    title: {
      text: "Ilość licencji",
      align: "center",
      style: {
        fontFamily: "Rubik",
        fontWeight: 600,
        fontSize: "16px",
        color: "black",
      },
    },
  };

  @Watch("licensesChartData", { deep: true })
  updateChart() {
    this.resetSeriesAndLabels();

    if (this.licensesChartData.data.filter((x) => x.value == 0).length != this.licensesChartData.data.length) {
      this.noData = false;
    } else {
      this.noData = true;
    }

    this.licensesChartData.data.forEach((x) => {
      this.addValueToSeries(x.value);
      this.addValueToLabel(x.label);
    });
    this.chartOptions = {
      ...this.chartOptions,
      labels: this.labels,
      colors: this.noData ? ["#f0f0f0"] : undefined,
    };
  }

  addValueToSeries(value: number) {
    this.series.push(value);
  }

  addValueToLabel(label: string) {
    this.labels.push(label);
  }

  resetSeriesAndLabels() {
    this.series = [];
    this.labels = [];
  }

  async mounted() {
    this.updateChart();
  }
}
</script>

<style>
#chart {
  --max-width: 750px;
  margin: 35px auto;
}
</style>

<style lang="scss" scoped></style>
