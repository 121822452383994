import RequestHelper from "./RequestHelper";
import { Response } from "@/requests/RequestHelper";

//Models
import { Filter } from "@/models/api/filters/Filter";
import CompanyBasic from "@/models/api/company/CompanyBasic";

//Urls
export const URL_GET_FILTER = "/Filter/GetFilter";

// Urls for criterions data
export const URL_GET_COMPANY_TREE = "/Filter/GetCompanyTree";

const _getFilter = new RequestHelper<Filter>(URL_GET_FILTER, false);
export const getFilter = async (filterName: string): Promise<Response<Filter>> => {
  return _getFilter.get({ FilterName: filterName });
};

const _companyAutocompleteTree = new RequestHelper<Array<CompanyBasic>>(URL_GET_COMPANY_TREE);
export const companyAutocompleteTree = async (search: string): Promise<Response<Array<CompanyBasic>>> => {
  return _companyAutocompleteTree.get({ Value: search });
};
