<template>
  <span class="scaling-svg-container">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13.692"
      height="14.376"
      viewBox="0 0 13.692 14.376"
      class="scaling-svg"
    >
      <path
        d="M6.845,14.376A6.846,6.846,0,0,1,3.707,1.445a.685.685,0,0,1,.628,1.217,5.476,5.476,0,1,0,5.021,0,.685.685,0,0,1,.316-1.293.675.675,0,0,1,.313.076,6.846,6.846,0,0,1-3.139,12.93Zm0-6.16a.685.685,0,0,1-.684-.685V.685a.684.684,0,1,1,1.369,0V7.53A.686.686,0,0,1,6.845,8.215Z"
        transform="translate(0 0)"
        fill="#d11164"
      />
    </svg>
  </span>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

@Component
export default class PowerIcon extends Vue {}
</script>

<style></style>
