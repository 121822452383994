<template>
  <span class="scaling-svg-container">
    <svg xmlns="http://www.w3.org/2000/svg" width="6.651" height="7.001" viewBox="0 0 6.651 7.001" class="scaling-svg">
      <path
        id="Icon_Icon_Repeat"
        data-name="Icon/Icon/Repeat"
        d="M5.251,7A.35.35,0,0,1,5,6.4l.453-.453h-4.2A1.247,1.247,0,0,1,0,4.715V3.85a.35.35,0,0,1,.7,0v.865a.547.547,0,0,0,.557.535h4.2L5,4.8A.35.35,0,0,1,5.5,4.3l1.05,1.05a.35.35,0,0,1,0,.495L5.5,6.9A.348.348,0,0,1,5.251,7ZM6.3,3.5a.351.351,0,0,1-.35-.35V2.286a.547.547,0,0,0-.556-.536H1.2l.452.453a.35.35,0,1,1-.495.495L.1,1.648a.35.35,0,0,1,0-.495L1.153.1a.35.35,0,0,1,.495.5L1.2,1.05h4.2A1.248,1.248,0,0,1,6.651,2.286V3.15A.351.351,0,0,1,6.3,3.5Z"
      />
    </svg>
  </span>
</template>

<script lang="ts">
import { Component } from "vue-property-decorator";
import IconHelper from "@/components/shared/icons/IconHelper.vue";

@Component
export default class RepeatIcon extends IconHelper {}
</script>

<style></style>
