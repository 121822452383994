<template>
  <v-container fluid class="view-container">
    <contact id="contact" />
  </v-container>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

//Components
import BackgroundImage from "@/components/shared/layout/BackgroundImage.vue";
import Contact from "@/components/anonymous/startPage/contact/Contact.vue";

@Component({
  components: {
    BackgroundImage,
    Contact,
  },
})
export default class HelpView extends Vue {}
</script>

<style lang="scss" scoped></style>
