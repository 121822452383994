<template>
  <span class="scaling-svg-container">
    <svg xmlns="http://www.w3.org/2000/svg" width="11.136" height="11.136" viewBox="0 0 11.136 11.136">
      <path
        d="M11.136,11.136H8.675V7.221C8.675,6.2,8.29,5.5,7.443,5.5a1.262,1.262,0,0,0-1.175.927,1.882,1.882,0,0,0-.053.618v4.094H3.777s.031-6.935,0-7.565H6.215V4.758A2.205,2.205,0,0,1,8.381,3.5c1.543,0,2.755,1.088,2.755,3.431v4.206ZM1.311,2.625H1.3A1.255,1.255,0,0,1,0,1.313,1.266,1.266,0,0,1,1.326,0,1.259,1.259,0,0,1,2.635,1.311,1.263,1.263,0,0,1,1.311,2.625Zm-1.03.946H2.451v7.565H.281Z"
        fill="#000"
      />
    </svg>
  </span>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

@Component
export default class MediaLinkedInIcon extends Vue {}
</script>

<style></style>
