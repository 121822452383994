import Vue from "vue";

Vue.directive("maxchars", {
  bind(el, binding) {
    const maxChars = binding.value;
    let prevKey = 0;
    el.addEventListener("keydown", (e) => (prevKey = keyDown(e, maxChars, prevKey))); //Add listener on keydown event
  },
});

const keyDown = (e: KeyboardEvent, maxChars: number, prevKey: number): number => {
  if (e.target != null) {
    const target = e.target as HTMLFormElement;
    if (target.value.length >= maxChars) {
      if (containsAllowedKeyCodes(prevKey)) {
        return 0;
      } else if (e.keyCode > 46) {
        e.preventDefault(); //Prevent event
      }
    }
    prevKey = e.keyCode;
    return prevKey;
  }
  return 0;
};

const containsAllowedKeyCodes = (number: number): boolean => {
  const allowedKeyCodes = [8, 9, 12, 13, 16, 17, 18, 19, 20, 27, 32, 33, 34, 35, 36, 37, 38, 39, 40, 44, 45, 46];
  return allowedKeyCodes.filter((x) => x == number).length > 0;
};
