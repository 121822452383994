import { RuleFunction } from "@/rules/RuleFunction";
import { filterCapitalize } from "@/filters/capitalize";
import i18n from "@/plugins/i18n";

const requiredValidator: RuleFunction = (value: unknown | number | string | null | undefined): boolean | string => {
  if (typeof value === "object" && value !== null && Object.keys(value).length > 0) {
    return true;
  }
  if (typeof value === "string" && value.trim().length > 0) {
    return true;
  }
  if (typeof value === "number") {
    return true;
  }
  if (typeof value === "boolean") {
    return true;
  }
  return filterCapitalize(i18n.t("validation.fieldRequired").toString());
};

export { requiredValidator };
export default requiredValidator;
