<template>
  <div>
    <apex-chart ref="chart" :height="350" :options="chartOptions" :series="createdReportsChartData.series"></apex-chart>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Watch } from "vue-property-decorator";
import { ApexOptions } from "apexcharts";
import { ApexPairedValueChartSeries } from "@/typings/apexChartTypes";

import CreatedReportChartData from "../../models/api/statistic/createdRedportChartData";

@Component({
  components: {},
})
export default class StatisticsCreatedReportsChart extends Vue {
  get createdReportsChartData(): CreatedReportChartData {
    return this.$store.direct.state.statistic.createdReportsChartData;
  }

  chartOptions: ApexOptions = {
    chart: {
      height: 350,
      type: "area",
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 1,
    },
    title: {
      text: "Ilość utworzonych raportów",
      align: "left",
      style: {
        fontFamily: "Rubik",
        fontWeight: 600,
        fontSize: "16px",
        color: "black",
      },
    },
    tooltip: {
      x: {
        format: "yyyy-MM-dd",
      },
      followCursor: true,
    },
    xaxis: {
      type: "numeric",
      categories: [""],
    },
    yaxis: {
      labels: {
        formatter: (v): string => {
          return v.toFixed(0).toString();
        },
      },
    },
    colors: ["#3f3a4a"],
    fill: {
      colors: ["#3f3a4a"],
      gradient: {
        gradientToColors: undefined,
        opacityFrom: 1,
        inverseColors: false,
        opacityTo: 1,
        type: "horizontal",
        shadeIntensity: 0,
      },
    },
  };

  @Watch("createdReportsChartData", { deep: true })
  init() {
    const max = (this.createdReportsChartData.series as ApexPairedValueChartSeries).reduce((prev, current) => {
      const currentMax = Math.max.apply(null, current.data as Array<number>);
      return currentMax > prev ? currentMax : prev;
    }, 0);

    this.chartOptions = {
      ...this.chartOptions,
      xaxis: {
        type: this.createdReportsChartData.xaxis.type,
        categories: this.createdReportsChartData.xaxis.categories,
      },
      yaxis: {
        min: 0,
        max: max + 1,
        tickAmount: max + 1,
      },
    };
  }

  mounted() {
    this.init();
  }
}
</script>

<style>
#chart {
  --max-width: 750px;
  margin: 35px auto;
}
</style>

<style lang="scss" scoped></style>
