import RequestHelper from "./RequestHelper";
import { Response } from "@/requests/RequestHelper";

//Models
import Migrations from "@/models/api/developer/Migrations";

//Urls
export const URL_POST_MIGRATE = "/Developer/Migrate";
export const URL_GET_MIGRATIONS = "/Developer/Migrations";
export const URL_GET_DATE_TIME_NOW = "/Developer/DateTimeNow";
export const URL_GET_UPDATE_TRANSACTION_CONFIGURATION = "/Developer/UpdateTransactionConfigurtation";
export const URL_GET_TRANSACTION_CONFIGURATION_IS_LOADED = "/Developer/CheckTransactionConfigurtationIsLoaded";

export const URL_POST_BODY_RESPONSE = "Dev/Bm/Test";

export const URL_POST_CHECK_ALERT = "/Developer/AlertTest";
export const URL_GET_TEXT_ROUTE = "/Developer/${developerId}/Route/${routeId}";

// export const URL_SEND_DATE = "/Developer/SendDate";
//Requests

const _migrate = new RequestHelper<null>(URL_POST_MIGRATE, true);
export const migrate = async (): Promise<Response<null>> => {
  return _migrate.post();
};

const _migrations = new RequestHelper<Migrations>(URL_GET_MIGRATIONS);
export const migrations = async (): Promise<Response<Migrations>> => {
  return _migrations.get();
};

const _dateTimeNow = new RequestHelper<string>(URL_GET_DATE_TIME_NOW, true);
export const dateTimeNow = async (): Promise<Response<string>> => {
  return _dateTimeNow.get();
};

const _updateTransactionConfiguration = new RequestHelper<null>(URL_GET_UPDATE_TRANSACTION_CONFIGURATION, true);
export const updateTransactionConfiguration = async (): Promise<Response<null>> => {
  return _updateTransactionConfiguration.get();
};

const _checkTransactionConfigurationIsLoaded = new RequestHelper<boolean>(URL_GET_TRANSACTION_CONFIGURATION_IS_LOADED);
export const checkTransactionConfigurationIsLoaded = async (): Promise<Response<boolean>> => {
  return _checkTransactionConfigurationIsLoaded.get();
};

const _testBodyResponse = new RequestHelper<string>(URL_POST_BODY_RESPONSE);
export const testBodyResponse = async (text: string): Promise<Response<string>> => {
  return _testBodyResponse.post(null, text);
};

const _checkAlert = new RequestHelper<null>(URL_POST_CHECK_ALERT, true);
export const checkAlert = async (): Promise<Response<null>> => {
  return _checkAlert.post();
};

const _textRoute = new RequestHelper<null>(URL_GET_TEXT_ROUTE);
export const textRoute = async (): Promise<Response<null>> => {
  return _textRoute.get({ developerId: 1, routeId: 2, id: 3 });
};

const _getTransactionUrl = new RequestHelper<null>("Transaction/GetUrl/8e7c6b4cd7494ee8a0b0e06429950cc7/f415asfgj465");
export const getTransactionUrl = async (): Promise<Response<null>> => {
  return _getTransactionUrl.get();
};

//TODO
// const _sendDate = new RequestHelper<null>(URL_SEND_DATE);
// export const sendDate = async (dateTime: DateTime): Promise<Response<null>> => {
//   return _sendDate.post({ DateTime: dateTime })
// }
