<template>
  <div v-if="canShow()" class="device-info">
    <span>{{ $vuetify.breakpoint.name }}</span> - <span>{{ $vuetify.breakpoint.width }}</span> x
    <span>{{ $vuetify.breakpoint.height }}</span
    >px
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

@Component
export default class DeviceInfo extends Vue {
  canShow(): boolean {
    return process.env.NODE_ENV === "production" ? false : true;
  }
}
</script>

<style scoped>
.device-info {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 99999;
  display: flex;
  color: red;
}
</style>
