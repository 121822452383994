<template>
  <div>
    <v-text-field label="IntCriterion"></v-text-field>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

@Component
export default class IntCriterion extends Vue {}
</script>

<style scoped></style>
