import RequestHelper from "./RequestHelper";
import { Response } from "@/requests/RequestHelper";

//Models
import { CompanyStatisticChartData } from "@/models/api/statistic/chartData";

//Urls
export const URL_GET_CHART_DATA = "/Statistic/GetChartData";

const _getCharData = new RequestHelper<CompanyStatisticChartData>(URL_GET_CHART_DATA);
export const getCharData = async (): Promise<Response<CompanyStatisticChartData>> => {
  return _getCharData.get();
};
