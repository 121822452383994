<template>
  <div class="margin" :class="`informations-${$vuetify.breakpoint.smAndDown ? 'column' : 'row'}`">
    <div v-for="item in items" :key="item.id" class="information">
      <div class="information-icon">
        <v-icon large> {{ item.icon }} </v-icon>
      </div>
      <div class="information-title text-subtitle-1 font-weight-bold pt-3">
        {{ item.title }}
      </div>
      <div class="information-description text-body-2 text-md-body-1 pt-5">
        {{ item.description }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

export interface InformationItem {
  id: number;
  icon: string;
  title: string;
  description: string;
}

@Component
export default class Information extends Vue {
  items: Array<InformationItem> = [
    {
      id: 1,
      icon: "$pie",
      title: "Indywidualne raporty",
      description: "Generuj indywidualne raportów dla Ciebie, Twoich wspólników oraz klientów.",
    },
    {
      id: 2,
      icon: "$shield",
      title: "Zagrożenia i rozwiązania",
      description: "Raport zawiera zarówno przegląd zagrożeń jaki i gotowych rozwiązań oraz rekomendacji.",
    },
    {
      id: 3,
      icon: "$company",
      title: "JDG i Spółki",
      description:
        "Generuj raporty dla jednoosobowych działalności gospodarczych oraz spółek z ograniczoną odpowiedzialnością, jawnych i cywilnych.",
    },
  ];
}
</script>

<style lang="scss" scoped>
.informations-row {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  .information {
    width: calc(100% / 3);
    min-width: 250px;
    max-width: 350px;
    min-height: 150px;
    display: flex;
    flex-direction: column;
    padding: 2% 2%;
  }
}

.informations-column {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  .information {
    min-width: 250px;
    max-width: 550px;
    min-height: 150px;
    display: flex;
    flex-direction: column;
    padding: 2% 2%;
    margin-top: 25px;
  }
}

.information-icon {
  width: 40px;
  height: 40px;
  span {
    width: 40px;
    height: 40px;
    span {
      font-size: 40px !important; // important to force change icon size
      height: 40px !important;
      width: 40px !important;
    }
  }
}

.information-title {
}

.information-description {
  text-align: left;
}

.margin {
  margin-top: 100px;
  @media screen and (max-width: 901px) {
    margin-top: 100px;
  }
  @media screen and (max-width: 900px) {
    margin-top: 60px;
  }
  @media screen and (max-width: 440px) {
    margin-top: 30px;
  }
}
</style>
