import { defineModule } from "direct-vuex";
import { moduleActionContext, moduleGetterContext } from "@/store/index";

//Requests
import {
  getForms,
  getFormActiveQuestion,
  checkFormQuestionAnswer,
  addForm,
  undoQuestion,
  generateReport,
  formAssignLicense,
  getFormSummary,
} from "@/requests/report";

//Models
import Form from "@/models/api/reports/form";
import Prototype from "@/models/api/reports/Prototype";
import { ListResult } from "@/models/api/filters/ListResult";
import AddForm from "@/models/api/reports/addForm";
import FormSummary from "@/models/api/reports/formSummary";
import { LicenseBasicInfo } from "@/models/api/licenses/license";

export interface ReportStateInterface {
  formActiveQuestion: Prototype | null;
  formError: boolean;

  forms: ListResult<Form> | null;
  formSummary: FormSummary | null;
  selectedFormId: number | null;
  selectedParentFormId: number | null;
  showAddFormDialog: boolean;
  showFormDialog: boolean;
  showSummaryFormDialog: boolean;
}

export const LicenseState: ReportStateInterface = {
  formActiveQuestion: null,
  formError: false,

  forms: null,
  formSummary: null,
  selectedFormId: null,
  selectedParentFormId: null,
  showAddFormDialog: false,
  showFormDialog: false,
  showSummaryFormDialog: false,
};

const reportModule = defineModule({
  state: LicenseState as ReportStateInterface,
  namespaced: true as const,
  getters: {},
  mutations: {
    setForms(state, param: ListResult<Form>) {
      state.forms = param;
    },
    setFormActiveQuestion(state, param: Prototype | null) {
      state.formActiveQuestion = param;
    },
    setFormError(state, param: boolean) {
      state.formError = param;
    },
    setSelectedFormId(state, param: number) {
      state.selectedFormId = param;
    },
    setSelectedParentFormId(state, param: number) {
      state.selectedParentFormId = param;
    },
    setStateAddFormDialog(state, param: boolean) {
      state.showAddFormDialog = param;
    },
    setStateFormDialog(state, param: boolean) {
      state.showFormDialog = param;
    },
    setStateSummaryFormDialog(state, param: boolean) {
      state.showSummaryFormDialog = param;
    },
    setFormSummary(state, param: FormSummary) {
      state.formSummary = param;
    },
  },
  actions: {
    async addForm(context, model: AddForm) {
      const { commit, dispatch } = _moduleActionContext(context);
      const response = await addForm(model);
      if (!response.isError) {
        commit.setSelectedFormId(response.model);
        commit.setSelectedParentFormId(response.model);
        commit.setStateAddFormDialog(false);
        commit.setStateFormDialog(true);
      }
      await dispatch.getForms();
    },
    async getForms(context) {
      const { commit, rootState } = _moduleActionContext(context);
      const filter = rootState.filter.reportCopiesFilterRequest;
      const response = await getForms(filter);
      commit.setForms(response.model);
    },
    async undoQuestion(context) {
      const { state, commit } = _moduleActionContext(context);
      const selectedFormId = state.selectedFormId as number;
      const response = await undoQuestion(selectedFormId);
      commit.setFormActiveQuestion(response.model);
      commit.setFormError(response.isError);
    },
    async getFormActiveQuestion(context) {
      const { state, commit } = _moduleActionContext(context);
      const selectedFormId = state.selectedFormId;
      if (selectedFormId != null) {
        const response = await getFormActiveQuestion(selectedFormId);
        commit.setFormActiveQuestion(response.model);
        commit.setFormError(response.isError);
        if (response.model == null) {
          commit.setStateSummaryFormDialog(true);
        } else {
          commit.setStateSummaryFormDialog(false);
          commit.setStateFormDialog(true);
        }
      }
    },
    async checkFormQuestionAnswer(context, answer: Prototype) {
      const { state, commit, dispatch } = _moduleActionContext(context);
      const response = await checkFormQuestionAnswer(answer);
      commit.setFormError(response.isError);
      if (!state.formError) {
        if (response.model != null) commit.setFormActiveQuestion(response.model);
        else {
          commit.setStateFormDialog(false);
          commit.setStateSummaryFormDialog(true);
          await dispatch.getForms();
        }
      }
    },
    async generateReport(context, formId: number) {
      const response = await generateReport(formId);
      const byteArray = new Uint8Array(response.model.data);
      const urlLink = window.URL.createObjectURL(new Blob([byteArray], { type: response.model.mimeType }));

      const a = document.createElement("a");
      document.body.appendChild(a);
      a.href = urlLink;
      a.download = response.model.name;
      a.click();
    },
    async assignLicenceToForm(context, selectedLicense: LicenseBasicInfo) {
      const { state, rootDispatch } = _moduleActionContext(context);
      const formId = state.selectedParentFormId as number;
      await formAssignLicense(formId, selectedLicense.id, selectedLicense.assigneToUser);
      await rootDispatch.user.userLicenseItemsCount();
    },
    async getFormSummary(context) {
      const { state, commit } = _moduleActionContext(context);
      const selectedParentFormId = state.selectedParentFormId as number;
      const response = await getFormSummary(selectedParentFormId);
      commit.setFormSummary(response.model);
    },
  },
});

export default reportModule;

/* eslint-disable */
const _moduleGetterContext = (args: [any, any, any, any]) =>
  moduleGetterContext(args, reportModule);
const _moduleActionContext = (context: any) =>
  moduleActionContext(context, reportModule);
