export interface ConfirmModelInterface {
  title: string;
  content: string;
  width?: undefined | string;
  show: boolean;
  time: number;
}

export class ConfirmModel implements ConfirmModelInterface {
  title!: string;
  content!: string;
  width?: undefined | string;
  show: boolean;
  time!: number;

  constructor(title = "", content: string, time: undefined | number = 0, width: undefined | string = "450px") {
    this.title = title;
    this.content = content;
    this.width = width;
    this.show = true;
    this.time = time;
  }
}
