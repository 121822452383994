<template>
  <v-app-bar
    class="app-bar-mobile"
    :app="app"
    :height="height"
    :color="color"
    elevate-on-scroll
    scroll-target="div.v-main__wrap"
  >
    <div class="app-bar-mobile_wrapper">
      <v-app-bar-nav-icon v-if="$vuetify.breakpoint.mobile" class="app-bar-nav-icon">
        <v-btn v-if="!userIsLoged" icon class="btn-icon" @click="expand = !expand">
          <v-icon size="24" color="white"> $menuBar </v-icon>
        </v-btn>
        <v-btn v-if="userIsLoged" icon class="btn-icon" @click="_drawer = !_drawer">
          <v-icon size="24" color="white"> $menuBar </v-icon>
        </v-btn>
      </v-app-bar-nav-icon>
      <div class="app-bar-mobile-logo">
        <img src="@/assets/images/logo/logo.svg" />
      </div>
    </div>
    <v-expand-transition>
      <div v-show="expand" class="list">
        <v-list color="black">
          <v-list-item v-for="item in items" :key="item.id" @click="action(item)">
            <v-list-item-title style="color: white" v-text="item.name"></v-list-item-title>
          </v-list-item>
        </v-list>
        <div class="butons">
          <v-btn :width="120" :height="35" class="btn main-dark--reverse" @click="login()">Zaloguj się</v-btn>
          <v-btn :width="120" :height="35" class="btn main-dark" @click="register()">Zarejestruj się</v-btn>
        </div>
      </div>
    </v-expand-transition>
  </v-app-bar>
</template>

<script lang="ts">
import { Component, Vue, Prop, PropSync } from "vue-property-decorator";

export interface IAppBarRoutes {
  id: number;
  name: string;
  path: string;
  goTo?: string | number;
}

@Component
export default class AppBarMobile extends Vue {
  @Prop({ default: true })
  app!: boolean;
  @Prop({ default: 60 })
  height!: number;
  @Prop({ default: "black" })
  color!: string;
  @Prop({ default: true })
  closeOnClick!: boolean;
  @PropSync("drawer", { required: true })
  _drawer!: boolean;

  expand = false;

  items: Array<IAppBarRoutes> = [
    {
      id: 1,
      name: "Home",
      path: "/",
      goTo: 0,
    },
    {
      id: 2,
      name: "Cennik",
      path: "/",
      goTo: "#products",
    },
    {
      id: 3,
      name: "O nas",
      path: "/",
      goTo: "#about",
    },
    {
      id: 4,
      name: "Kontakt",
      path: "/",
      goTo: "#contact",
    },
    {
      id: 5,
      name: "FAQ",
      path: "/faq",
    },
  ];

  get userIsLoged(): boolean {
    return this.$store.direct.getters.user.isLogged;
  }

  async action(item: IAppBarRoutes) {
    if (this.$route.path === item.path) {
      await this.goTo(item);
      this.closeMenu();
    } else {
      await this.goTo(item);
      await this.$router.push(item.path);
      this.closeMenu();
    }
  }

  async goTo(item: IAppBarRoutes) {
    setTimeout(() => {
      if (typeof item.goTo !== "undefined") {
        this.$store.direct.dispatch.vuetify.goTo(item.goTo);
      } else {
        this.$store.direct.dispatch.vuetify.goToStart();
      }
    }, 200);
  }

  closeMenu() {
    this.expand = false;
  }

  login() {
    this.closeMenu();
    this.$store.direct.dispatch.vuetify.goToStart(0);
    this.$router.push("/login");
  }

  register() {
    this.closeMenu();
    this.$store.direct.dispatch.vuetify.goToStart(0);
    this.$router.push("/register");
  }
}
</script>

<style lang="scss" scoped>
.app-bar-mobile_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: calc(100%);
}

.app-bar-nav-icon {
  position: absolute;
  left: 10px;
}

.app-bar-mobile-logo {
  height: calc(90%);
  img {
    height: calc(100%);
  }
}

.list {
  position: absolute;
  top: 56px;
  left: 0;
  width: 100%;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  background: black;
  padding-bottom: 10px;
}

.v-list-item {
  min-height: 40px;
  padding: 0 10%;
  font-weight: 100;
  .v-list-item__title {
    font-size: 0.875rem;
  }
}

.v-list-item__title {
  border-bottom: 1px solid white;
  padding: 0 0 5px 0;
}

.butons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  height: 60px;
  max-width: 270px;
  margin-left: auto;
  margin-right: auto;
}
</style>
