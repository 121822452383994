<template>
  <window-divider class="margin">
    <div slot="left">
      <div class="about-text">
        <div>
          <div class="text-h3 text-md-h2 font-weight-bold">O nas</div>
        </div>
        <div class="text-body-2 text-md-body-1 pt-5">
          <p lang="pl">
            Od kilkunastu lat jestem przedsiębiorcą, członkiem zarządu firm i&nbsp;stowarzyszeń. Od kilku lat zajmuję
            się kwestią sukcesji klientów biznesowych.
          </p>
          <p lang="pl">
            Przez ten czas poznałem szereg problemów związanych z&nbsp;sukcesją biznesu, a&nbsp; za cel obrałem sobie
            usprawnienie procesu przekazania firmy przyszłym pokoleniom w&nbsp;oparciu&nbsp;o&nbsp;wiedzę&nbsp;
            i&nbsp;doświadczenie.
          </p>
        </div>
        <div>
          <div class="text-subtitle-1 font-weight-bold">Tomasz Szybiak</div>
          <div class="text-subtitle-2 grey--text font-weight-regular">CEO FollowUp</div>
        </div>
      </div>
    </div>
    <div slot="right">
      <div class="about-image">
        <img src="@/assets/images/CEO_FollowUp.png" />
      </div>
    </div>
  </window-divider>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import WindowDivider from "@/components/shared/layout/WindowDivider.vue";

@Component({
  components: {
    WindowDivider,
  },
})
export default class About extends Vue {}
</script>

<style lang="scss" scoped>
.about {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.about-text {
  // margin: 0 5%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.about-description {
  font-size: 0.925rem;
  text-align: justify;
  line-height: 1.5;
}

.about-signature {
  padding: 20px 0 0 0;
  &-name {
    font-weight: 600;
  }
  &-position {
    font-weight: 400;
    color: gray;
  }
}

.about-image {
  // padding: 20px;
  img {
    width: 100%;
    min-width: 250px;
    max-width: 355px;
    max-height: 422px;
  }
}

.margin {
  margin-top: 100px;
  @media screen and (max-width: 901px) {
    margin-top: 80px;
  }
  @media screen and (max-width: 900px) {
    margin-top: 60px;
  }
  @media screen and (max-width: 440px) {
    margin-top: 30px;
  }
}
</style>
