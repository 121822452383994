<template>
  <span class="scaling-svg-container">
    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 4.178 7.379">
      <path
        id="Right"
        d="M2.971,0,0,2.979,2.971,5.964"
        transform="translate(0.5 0.707)"
        fill="none"
        stroke="#333"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-miterlimit="10"
        stroke-width="1"
      />
    </svg>
  </span>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

@Component
export default class ArrowLeftIcon extends Vue {}
</script>

<style></style>
