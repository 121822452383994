<template>
  <v-card>
    <v-card-text>
      <form-input ref="formInput">
        <v-row justify="space-between">
          <v-col align-self="center">
            <span class="text-subtitle-2">{{ $t("form.companyData") | capitalize }}</span>
          </v-col>
          <v-col no-gutters justify="end">
            <slot name="button"></slot>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <input-text
              v-model="companyDetails.name"
              :max-length="200"
              :label="$t('form.companyName') | capitalize"
              color="accent"
              :show-counter="true"
              :rules="[required, (v) => maxChar(v, 200)]"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col class="col" cols="12" sm="6">
            <input-text
              v-model="companyDetails.country"
              :max-length="100"
              :label="$t('form.companyCountry') | capitalize"
              color="accent"
              :cols="6"
              :allow-number="false"
              :allow-symbol="true"
              :rules="[(v) => maxChar(v, 100)]"
            />
          </v-col>
          <v-col class="col" cols="12" sm="6">
            <input-text
              v-model="companyDetails.city"
              :max-length="100"
              :label="$t('form.companyCity') | capitalize"
              color="accent"
              :cols="6"
              :allow-number="false"
              :allow-symbol="true"
              :rules="[(v) => maxChar(v, 100)]"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col class="col" cols="12" sm="6">
            <input-postal-code
              v-model="companyDetails.postalCode"
              :label="$t('form.companyPostalCode') | capitalize"
              color="accent"
              :cols="6"
              :max-length="12"
              :rules="[]"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col class="col" cols="12" sm="6">
            <input-text
              v-model="companyDetails.streetName"
              :max-length="50"
              :label="$t('form.companyStreetName') | capitalize"
              color="accent"
              :cols="6"
              :rules="[(v) => maxChar(v, 50)]"
            />
          </v-col>
          <v-col class="col" cols="12" sm="6">
            <input-text
              v-model="companyDetails.streetNumber"
              :max-length="10"
              :label="$t('form.companyStreetNumber') | capitalize"
              color="accent"
              :cols="6"
              :rules="[(v) => maxChar(v, 10)]"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col class="col" cols="12" sm="6">
            <input-tin
              v-model="companyDetails.tin"
              :label="$t('form.companyTIN') | capitalize"
              color="accent"
              :cols="6"
              :rules="[required]"
            />
          </v-col>
          <v-col class="col" cols="12" sm="6">
            <input-phone
              v-model="companyDetails.phoneNumber"
              :label="$t('form.companyPhoneNumber') | capitalize"
              color="accent"
              :cols="6"
            />
          </v-col>
        </v-row>
      </form-input>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import { Component, Vue, PropSync } from "vue-property-decorator";

//Inputs
import FormInput from "@/components/shared/forms/FormInput.vue";
import InputText from "@/components/shared/inputs/InputText.vue";
import InputPostalCode from "@/components/shared/inputs/InputPostalCode.vue";

//Models
import UserCompanyDetailsResponse from "@/models/api/user/UserCompanyDetailsResponse";

//Validation
import requiredValidator from "@/rules/requiredValidator";
import phoneNumberValidator from "@/rules/phoneNumberValidator";
import maxChar from "@/rules/maxCharsValidator";
import tinValidator from "@/rules/tinValidator";
import { FormValidation } from "../../../../../customTypes/vuetify";
import InputTin from "@/components/shared/inputs/InputTin.vue";
import InputPhone from "@/components/shared/inputs/InputPhone.vue";

@Component({
  components: {
    FormInput,
    InputText,
    InputPostalCode,
    InputTin,
    InputPhone,
  },
})
export default class CompanyDetailsForm extends Vue {
  @PropSync("value", { required: true })
  companyDetails!: UserCompanyDetailsResponse;

  required = requiredValidator;
  phoneNumber = phoneNumberValidator;
  maxChar = maxChar;
  tinValidator = tinValidator;

  //Form validation
  validate() {
    return (this.$refs.formInput as FormValidation).validate();
  }

  resetValidation(): void {
    (this.$refs.formInput as FormValidation).resetValidation();
  }
}
</script>

<style lang="scss" scoped>
.v-card {
  box-shadow: unset !important;
  background-color: transparent;
}
</style>
