<template>
  <div v-if="prototype.questionItems.length > 0">
    <span class="text-subtitle-1">{{ prototype.text }}</span>
    <v-radio-group ref="radioGroup" v-model="selectedIndex" multiple validate-on-blur>
      <div v-for="(item, index) of prototype.questionItems" :key="index">
        <v-radio
          :key="index"
          :label="item.label"
          :value="index"
          :ripple="false"
          off-icon="$checkboxOffBlack"
          on-icon="$checkboxOnBlack"
          color="black"
          @mouseup="toggle(index)"
        />
        <div v-if="item.isChecked && item.extensionObject != null">
          <div style="">
            <extension-base :value.sync="item.extensionObject" />
          </div>
        </div>
        <v-spacer />
      </div>
    </v-radio-group>
  </div>
</template>

<script lang="ts">
import { Component, Vue, PropSync, Watch } from "vue-property-decorator";

//Components
import ExtensionBase from "@/components/reports/workFlow/components/extensions/ExtensionBase.vue";

//Models
import BasePrototype from "@/models/api/reports/workFlow/BasePrototype";

@Component({
  components: {
    ExtensionBase,
  },
})
export default class MultipleChooserPrototype extends Vue {
  @PropSync("value")
  prototype!: BasePrototype;

  selectedIndex: Array<number> = [];

  //Unselect item if is selected
  toggle(index: number) {
    const selectedItem = this.selectedIndex.indexOf(index);
    if (selectedItem != -1) {
      setTimeout(() => {
        this.selectedIndex.splice(selectedItem, 1);
      }, 100);
    }
  }

  @Watch("selectedIndex")
  updateSelectedItems() {
    this.prototype.questionItems.forEach((v) => (v.isChecked = false)); //Set all to false
    for (const index of this.selectedIndex) {
      this.prototype.questionItems.filter((v) => v.ordinal === index + 1)[0].isChecked = true;
    }
  }

  @Watch("prototype")
  checkInitialValue() {
    const items = this.prototype.questionItems.filter((v) => v.isChecked === true);
    if (items.length > 0) {
      for (const item of items) {
        this.selectedIndex.push(item.ordinal - 1);
      }
    }
  }

  created() {
    this.checkInitialValue(); //$ To check prototype selected index when created
  }
}
</script>

<style scoped></style>
