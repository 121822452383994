<template>
  <div v-if="prototype.questionItems.length > 0">
    <span class="text-subtitle-1">{{ prototype.text }}</span>
    <v-radio-group ref="radioGroup" v-model="selectedIndex" :rules="[required]" validate-on-blur>
      <div v-for="(item, index) of prototype.questionItems" :key="index">
        <v-radio
          :label="item.label"
          :value="index"
          :ripple="false"
          off-icon="$checkboxOffBlack"
          on-icon="$checkboxOnBlack"
          color="black"
          @click="changeSelected(index)"
        />
        <div v-if="item.isChecked && item.extensionObject != null">
          <div style="">
            <extension-base :value.sync="item.extensionObject" />
          </div>
        </div>
        <v-spacer />
      </div>
    </v-radio-group>
  </div>
</template>

<script lang="ts">
import { Component, Vue, PropSync, Watch } from "vue-property-decorator";

//Components
import ExtensionBase from "@/components/reports/workFlow/components/extensions/ExtensionBase.vue";

//Models
import BasePrototype from "@/models/api/reports/workFlow/BasePrototype";

//Validation
import requiredValidator from "@/rules/requiredValidator";

@Component({
  components: {
    ExtensionBase,
  },
})
export default class SingleChooserPrototype extends Vue {
  @PropSync("value")
  prototype!: BasePrototype;

  selectedIndex: number | null = null;

  changeSelected(index: number) {
    const items = this.prototype.questionItems;
    items.forEach((v) => (v.isChecked = false)); //$ change isChecked in all items to false
    items[index].isChecked = true; //$ change isChecked to true for item selected by index
    const nextQuestionShortText = items[index].nextQuestionText;
    this.prototype.nextQuestionText = nextQuestionShortText;
    this.resetValidation();
  }

  //$ Watcher to check prototype when change
  @Watch("prototype")
  checkInitialValue() {
    const items = this.prototype.questionItems.filter((v) => v.isChecked == true);
    if (items.length > 0) {
      this.selectedIndex = this.prototype.questionItems.indexOf(items[0]);
    } else {
      this.selectedIndex = null;
    }
  }

  resetValidation() {
    const radio = this.$refs.radioGroup as HTMLFormElement;
    radio.resetValidation();
  }

  created() {
    this.checkInitialValue(); //$ To check prototype selected index when created
  }

  //Validation
  required = requiredValidator;
}
</script>

<style lang="scss" scoped>
::v-deep .v-radio.v-item--active {
  .v-label {
    font-weight: 500;
  }
}
</style>
