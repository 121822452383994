<template>
  <v-card class="d-flex flex-column" :style="`height: ${windowHeight - 50}px`">
    <v-card-text>
      <div>
        <user-details-template ref="userDetails" :value.sync="userDetails"></user-details-template>
      </div>
      <div>
        <company-details-template ref="companyDetails" :value.sync="companyDetails"></company-details-template>
      </div>
      <div>
        <administration-image-picker :file.sync="companyLogo" :valid.sync="imageIsValid" />
      </div>
    </v-card-text>
    <v-spacer />
    <v-card-actions>
      <buttons-default :disabled.sync="allowUpdate" @save="saveChanges" @cancel="init" />
    </v-card-actions>
  </v-card>
</template>

<script lang="ts">
import { Component, Vue, Watch } from "vue-property-decorator";

//Components
import ButtonsDefault from "@/components/shared/buttons/ButtonsDefault.vue";

import UserDetailsTemplate from "@/components/administration/account/UserDetailsTemplate.vue";
import CompanyDetailsTemplate from "@/components/administration/account/CompanyDetailsTemplate.vue";
import AdministrationImagePicker from "@/components/administration/account/AdministrationImagePicker.vue";

//Models
import UserDetails from "@/models/api/user/UserDetails";
import CompanyDetails from "@/models/api/company/CompanyDetails";
import { SnackbarModel } from "../../../models/components/popup/Snackbar";

@Component({
  components: {
    ButtonsDefault,
    UserDetailsTemplate,
    CompanyDetailsTemplate,
    AdministrationImagePicker,
  },
})
export default class AdministrationAccountView extends Vue {
  get UserDetails(): UserDetails {
    const userDetails = this.$store.direct.state.user.userDetails;
    if (userDetails != null) return userDetails;
    return new UserDetails();
  }

  get CompanyDetails(): CompanyDetails {
    const companyDetails = this.$store.direct.state.user.userCompanyDetails;
    if (companyDetails != null) return companyDetails;
    return new CompanyDetails();
  }

  get imageFile(): File | null {
    const fileResult = this.$store.direct.state.user.userCompanyLogoImage;
    if (fileResult != null) {
      if (fileResult.data.length) {
        const byteArray = new Uint8Array(fileResult.data);
        const file = new Blob([byteArray], { type: fileResult.mimeType });
        return new File([file], fileResult.name, {
          type: fileResult.mimeType,
        });
      }
      return null;
    }
    return null;
  }

  userDetails = new UserDetails();
  userDetailsChanged = false;

  companyDetails = new CompanyDetails();
  companyDetailsChanged = false;

  companyLogo: File | null = null;
  companyLogoChanged = false;

  imageIsValid: boolean | string = true;
  allowUpdate = false;

  checkUserDetails(): boolean {
    if (JSON.stringify(this.UserDetails) === JSON.stringify(this.userDetails)) return false;
    return true;
  }

  checkCompanyDetails() {
    if (JSON.stringify(this.CompanyDetails) === JSON.stringify(this.companyDetails)) return false;
    return true;
  }

  checkLogoImage() {
    const current = JSON.stringify((this.companyLogo?.name as string) + (this.companyLogo?.size as number));
    const loaded = JSON.stringify((this.imageFile?.name as string) + (this.imageFile?.size as number));
    if (current == loaded) return false;
    return true;
  }

  @Watch("UserDetails")
  @Watch("CompanyDetails")
  @Watch("imageFile")
  @Watch("userDetails", { deep: true })
  @Watch("companyDetails", { deep: true })
  @Watch("companyLogo", { deep: true })
  checkChanges() {
    this.userDetailsChanged = this.checkUserDetails();
    this.companyDetailsChanged = this.checkCompanyDetails();
    this.companyLogoChanged = this.checkLogoImage();
    if (this.userDetailsChanged || this.companyDetailsChanged || this.companyLogoChanged) {
      this.allowUpdate = true;
    } else this.allowUpdate = false;
  }

  validateForms(): boolean {
    const user = (this.$refs.userDetails as HTMLFormElement).validate();
    const company = (this.$refs.companyDetails as HTMLFormElement).validate();
    return user && company;
  }

  validateUserForm(): boolean {
    return (this.$refs.userDetails as HTMLFormElement).validate();
  }

  validateCompanyForm(): boolean {
    return (this.$refs.companyDetails as HTMLFormElement).validate();
  }

  resetFormsValidation() {
    (this.$refs.userDetails as HTMLFormElement).resetValidation();
    (this.$refs.companyDetails as HTMLFormElement).resetValidation();
  }

  //Check which data changed end execute right action
  async saveChanges() {
    if (this.userDetailsChanged) {
      if (this.validateUserForm()) {
        await this.$store.direct.dispatch.user.editUser(this.userDetails);
      } else {
        this.$store.direct.dispatch.popup.addSnackbarFormError("dane użytkownika");
      }
    }
    if (this.companyDetailsChanged) {
      if (this.validateCompanyForm()) {
        await this.$store.direct.dispatch.user.editCompany(this.companyDetails);
      } else {
        this.$store.direct.dispatch.popup.addSnackbarFormError("dane firmy");
      }
    }
    if (this.companyLogoChanged) {
      if (this.imageIsValid === true) {
        if (this.companyLogo != null) {
          this.$store.direct.dispatch.user.uploadCompanyLogo(this.companyLogo);
        } else {
          this.$store.direct.dispatch.user.removeCompanyLogo();
        }
      } else {
        this.$store.direct.dispatch.popup.addSnackbar(this.imageSnackbarError);
      }
    }
  }

  imageSnackbarError = new SnackbarModel(["wrongFileExtension"], "error");

  windowHeight = 0;

  onResize() {
    this.windowHeight = window.innerHeight;
  }

  created() {
    this.onResize();
  }

  mounted() {
    this.init();
    this.companyLogo = this.imageFile;
    window.addEventListener("resize", this.onResize);
  }

  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  }

  init() {
    this.resetFormsValidation();
    this.userDetails = new UserDetails(this.$store.direct.state.user.userDetails);
    this.companyDetails = new CompanyDetails(this.$store.direct.state.user.userCompanyDetails);
    this.companyLogo = this.imageFile;
  }
}
</script>

<style lang="scss" scoped>
.v-card {
  box-shadow: unset !important;
  background-color: transparent;
}
</style>
