<template>
  <div id="resizable-container" v-resize="onResize" class="resizable-container">
    <slot name="buttons" />
    <slot name="filters" />
    <div ref="tableContainer">
      <slot name="table" :table-height="tableHeight"></slot>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

@Component
export default class ResizableContainer extends Vue {
  tableHeight = 0;
  footerHeight = 59;
  containerHeight = 0;

  onResize() {
    this.checkContainerHeight();
    this.tableHeight =
      this.containerHeight -
      (this.$refs.tableContainer as HTMLFormElement).getBoundingClientRect().y -
      this.footerHeight;
  }

  checkContainerHeight() {
    const container = document.getElementById("resizable-container") as Element;
    this.containerHeight = container.clientHeight;
  }

  mounted() {
    this.$nextTick(() => {
      this.checkContainerHeight();
      this.onResize();
    });
  }
}
</script>

<style lang="scss" scoped>
.resizable-container {
  height: 100%;
  padding: 0 40px;
}
</style>
