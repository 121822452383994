<template>
  <div class="no-container">
    <background-image />
    <div class="mt-10">
      <logo-image />
    </div>
    <div class="no-container_items">
      <div class="no-container_text">
        <span class="text-h2 font-weight-bold">404</span>
        <span class="text-h4 font-weight-bold">Strona nie istnieje</span>
      </div>
      <div class="no-container_button">
        <v-btn class="btn main-light--reverse" :to="{ name: route.WELCOME }">{{ $t("form.back") | capitalize }}</v-btn>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

//Components
import BackgroundImage from "@/components/shared/layout/BackgroundImage.vue";
import LogoImage from "@/components/shared/layout/LogoImage.vue";

import ROUTE_NAMES from "@/static/routeNames";

@Component({
  components: { BackgroundImage, LogoImage },
})
export default class NotFoundView extends Vue {
  route = ROUTE_NAMES.GENERAL;
}
</script>

<style lang="scss" scoped>
// .no-container {
//   display: flex;
//   flex-direction: column;
//   width: 100%;
//   padding-bottom: 10px;
// }

// .no-container_items {
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   position: absolute;
//   top: 50%;
//   left: 50%;
//   transform: translate(-50%, -50%);
// }

// .no-container_logo {
//   display: flex;
//   justify-content: center;
//   margin: 30px;
// }

// .no-container_text {
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   min-height: 200px;
//   margin-top: 20px;
//   span {
//     margin-top: 10px;
//   }
// }

// .no-container_button {
//   display: flex;
//   justify-content: center;
// }
</style>
