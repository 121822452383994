<template>
  <div class="d-flex align-center flex-column margin" style="z-index: 5; position: relative">
    <div class="justify-center">
      <div class="text-h3 text-md-h2 font-weight-bold">
        Jak otrzymać
        <text-mark :width="20" :height="20" :tx="8" :ty="10" item-class="text-h3 text-md-h2 font-weight-bold"
          >raport?</text-mark
        >
      </div>
      <!-- <div class="text-subtitle-2 text-md-subtitle-1 font-weight-regular grey--text" style="text-align: center">
        Zaufało nam już ponad 800 klientów
      </div> -->
    </div>
    <div v-if="!$vuetify.breakpoint.mobile" class="report-path-svg-desktop mt-10 mb-10">
      <img src="@/assets/images/report_path_desktop.svg" />
    </div>
    <div v-else class="report-path-svg-mobile mt-10 mb-10">
      <img src="@/assets/images/report_path_mobile.svg" />
    </div>
    <div class="mb-10">
      <btn type="black" @click="$store.direct.dispatch.user.getReportDemo()">Raport demo</btn>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import Btn from "@/components/shared/buttons/Btn.vue";
import TextMark from "@/components/shared/layout/TextMark.vue";

@Component({
  components: {
    Btn,
    TextMark,
  },
})
export default class ReportPath extends Vue {}
</script>

<style lang="scss" scoped>
.report-path-svg-desktop {
  width: 100%;
  height: 100%;
  max-width: 900px;
  img {
    width: 100%;
    height: 100%;
  }
}

.report-path-svg-mobile {
  width: 100%;
  height: 100%;
  max-width: 500px;
  img {
    width: 100%;
    height: 100%;
  }
}

.margin {
  margin-top: 100px;
  @media screen and (max-width: 901px) {
    margin-top: 100px;
  }
  @media screen and (max-width: 900px) {
    margin-top: 60px;
  }
  @media screen and (max-width: 440px) {
    margin-top: 30px;
  }
}
</style>
