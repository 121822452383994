<template>
  <div>
    <v-data-table :items="_items[0].children" :headers="headers" disable-sort>
      <!-- <template #header[`name`]="{ header }">
            <tr>
                <th>{{header.text}} + dasfdas</th>
            </tr>
        </template> -->
      <template #[`header.expand`]>
        <th></th>
      </template>

      <template #[`header.id`]="{ header }">
        <th>
          {{ header.text }}
        </th>
      </template>

      <template #item="{ item }">
        <tr>
          <td @click="toggleNode(item)"><v-icon>mdi-account</v-icon></td>
          <td>{{ item.name }}</td>
          <td>{{ item.id }}</td>
          <!-- <td @click="toggleNode(props.item)"></td>
          <td><slot :bind="props" name="row"></slot></td> -->
        </tr>
      </template>
    </v-data-table>
  </div>
</template>

<script lang="ts">
import { Vue, Component, PropSync, Prop } from "vue-property-decorator";

//Models
import Node from "@/models/components/treeDataTable/Node";
import { DataTableHeader } from "vuetify";

@Component
export default class TreeDataTable extends Vue {
  @PropSync("items")
  _items!: Array<Node>;
  @Prop()
  headers!: Array<DataTableHeader>;

  mainHeaders: Array<DataTableHeader> = [
    {
      text: "expand",
      value: "expand",
      width: "20px",
    },
  ];

  //   toggleNode(node: any) {
  //     console.log(node);
  //     this.$set(node, "expanded", !node.expanded);
  //   }

  created() {
    this.headers = this.mainHeaders.concat(this.headers);
  }
}
</script>
