<template>
  <div>
    <v-row justify="space-between" align="center" style="margin-bottom: -24px">
      <v-col :cols="cols">
        <slot name="1"></slot>
      </v-col>
      <v-col v-if="cols < 12" :cols="12 - cols">
        <slot name="2"></slot>
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class FormFlex extends Vue {
  @Prop({ default: 6 })
  cols!: number;
}
</script>
