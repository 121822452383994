import { CustomRouteConfig } from "@/models/plugins/CustomRouterTypes";
import ROLE from "@/static/role";
import RouteNames from "@/static/routeNames";

//Views
import IconView from "@/views/developer/IconsView.vue";
import TestView from "@/views/developer/TestView.vue";
import DeveloperView from "@/views/developer/DeveloperView.vue";

const DeveloperRoutes: Array<CustomRouteConfig> = [
  {
    name: RouteNames.DEVELOPER.ICONS,
    path: "/icons",
    component: IconView,
    meta: {
      userRole: ROLE.SuperAdmin,
    },
  },
  {
    name: RouteNames.DEVELOPER.TEST,
    path: "/test",
    component: TestView,
    meta: {
      userRole: ROLE.SuperAdmin,
    },
  },
  {
    name: RouteNames.DEVELOPER.MAIN,
    path: "/developer",
    component: DeveloperView,
    meta: {
      userRole: ROLE.SuperAdmin,
    },
  },
];

export { DeveloperRoutes };
export default DeveloperRoutes;
