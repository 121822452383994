import RequestHelper from "@/requests/RequestHelper";
import { Response } from "@/requests/RequestHelper";

//Models
import TransactionBlueMediaRedirectionParams from "@/models/api/transaction/transactionBlueMediaRedirectionParams";
import TransactionStatus from "@/models/api/transaction/transactionStatus";
import { ListResult } from "@/models/api/filters/ListResult";
import TransactionHistory, { TransactionDetails } from "@/models/api/transactionHistory/transaction";
import FilterRequest from "@/models/api/filters/FilterRequest";
import FileResultResponse from "@/models/api/reports/fileResultResponse";
import TransactionPayURedirectionParams from "@/models/api/transaction/transactionPayURedirectionParams";

//Urls
export const URL_GET_TRANSACTION_HISTORY = "/Transaction/TransactionHistory";
export const URL_GET_TRANSACTION_DETAILS = "/Transaction/${TransactionId}/Details";
export const URL_POST_ADD_INVOICE_FILE = "/Transaction/${TransactionId}/AddInvoiceFile";
export const URL_GET_INVOICE_FILE = "/Transaction/${TransactionId}/GetInvoiceFile";
export const URL_POST_SEND_INVOICE_EMAIL = "/Transaction/${TransactionId}/SendInvoiceEmail";
export const URL_POST_TRANSACTION_BUY = "/Transaction/Buy/${productId}";
export const URL_POST_TRANSACTION_PAY = "/Transaction/Pay/${transactionId}";
export const URL_GET_TRANSACTION_STATUS = "/Transaction/Status/${orderId}";
export const URL_POST_TRANSACTION_CHECK_REDIRECTION_PAYU = "/Transaction/CheckRedirectionParameters/PayU";
export const URL_POST_TRANSACTION_CHECK_REDIRECTION_BLUE_MEDIA = "/Transaction/CheckRedirectionParameters/BlueMedia";
export const URL_POST_TRANSACTION_FREE_SAMPLES =
  "/Transactions/FreeSamples/Company/${CompanyId}/Count/${Count}/Days/${Days}";

//Request
const _createTransaction = new RequestHelper<string>(URL_POST_TRANSACTION_BUY, false);
export const createTransaction = async (productId: string): Promise<Response<string>> => {
  return await _createTransaction.post({
    productId: productId,
  });
};

const _payForTransaction = new RequestHelper<string>(URL_POST_TRANSACTION_PAY, false);
export const payForTransaction = async (transactionId: string): Promise<Response<string>> => {
  return await _payForTransaction.post({
    transactionId: transactionId,
  });
};

const _checkBlueMediaRedirectionParameters = new RequestHelper<boolean>(
  URL_POST_TRANSACTION_CHECK_REDIRECTION_BLUE_MEDIA,
  false
);
export const checkBlueMediaRedirectionParameters = async (
  params: TransactionBlueMediaRedirectionParams
): Promise<Response<boolean>> => {
  return await _checkBlueMediaRedirectionParameters.post({
    serviceId: params.serviceId,
    orderId: params.orderId,
    hash: params.hash,
  });
};

const _checkPayURedirectionParameters = new RequestHelper<boolean>(URL_POST_TRANSACTION_CHECK_REDIRECTION_PAYU, false);
export const checkPayURedirectionParameters = async (
  params: TransactionPayURedirectionParams
): Promise<Response<boolean>> => {
  return await _checkPayURedirectionParameters.post({
    clientId: params.clientId,
    orderId: params.orderId,
    hash: params.hash,
  });
};

const _checkTransactionStatus = new RequestHelper<TransactionStatus>(URL_GET_TRANSACTION_STATUS, false);
export const checkTransactionStatus = async (orderId: string): Promise<Response<TransactionStatus>> => {
  return await _checkTransactionStatus.get({
    orderId: orderId,
  });
};

const _freeSamples = new RequestHelper<null>(URL_POST_TRANSACTION_FREE_SAMPLES, true);
export const addFreeSamples = async (companyId: string, count: number, days: number): Promise<Response<null>> => {
  return await _freeSamples.post({
    CompanyId: companyId,
    Count: count,
    Days: days,
  });
};

const _transactionHistory = new RequestHelper<ListResult<TransactionHistory>>(URL_GET_TRANSACTION_HISTORY);
export const transactionHistory = async (filter: FilterRequest): Promise<Response<ListResult<TransactionHistory>>> => {
  return await _transactionHistory.get({ Filter: filter });
};

const _transactionDetails = new RequestHelper<TransactionDetails>(URL_GET_TRANSACTION_DETAILS);
export const transactionDetails = async (transactionId: string): Promise<Response<TransactionDetails>> => {
  return await _transactionDetails.get({ TransactionId: transactionId });
};

const _addInvoiceFile = new RequestHelper<null>(URL_POST_ADD_INVOICE_FILE, true);
export const addInvoiceFile = async (transactionId: string, file: File): Promise<Response<null>> => {
  return await _addInvoiceFile.sendFile(
    {
      TransactionId: transactionId,
    },
    file
  );
};

const _getInvoice = new RequestHelper<FileResultResponse>(URL_GET_INVOICE_FILE);
export const getInvoiceFile = async (transactionId: string): Promise<Response<FileResultResponse>> => {
  return await _getInvoice.get({ TransactionId: transactionId });
};

const _sendInvoice = new RequestHelper<null>(URL_POST_SEND_INVOICE_EMAIL);
export const sendInvoiceEmail = async (transactionId: string): Promise<Response<null>> => {
  return await _sendInvoice.post({ TransactionId: transactionId });
};
