<template>
  <div>
    <v-app>
      <device-info />
      <!-- Visible only on development -->
      <v-progress-linear :active="isLoading" :indeterminate="isLoading" color="success" absolute height="6" />
      <app-bar :drawer.sync="navigation.show" />
      <navigation-drawer
        v-if="
          isLogged &&
          (($route.path !== '/' &&
            $route.path !== '/login' &&
            $route.path !== '/terms-of-use' &&
            $route.path !== '/privacy-policy' &&
            $route.path !== '/faq') ||
            $vuetify.breakpoint.mobile)
        "
        :expand.sync="navigation.show"
        :mini.sync="navigation.mini"
        :colapse-on-scroll="true"
      />
      <v-main>
        <v-container fluid class="pb-0">
          <router-view />
        </v-container>
        <alert v-if="showAlerts" />
        <confirm-popup v-if="showConfirms" />
        <snackbar v-if="showSnackbars" />
      </v-main>
    </v-app>
  </div>
</template>

<script lang="ts">
import { Vue, Component } from "vue-property-decorator";
import store from "@/store";

import Alert from "@/components/popup/Alert.vue";
import ConfirmPopup from "@/components/popup/ConfimrPopup.vue";
import Snackbar from "@/components/popup/Snackbar.vue";

import DeviceInfo from "@/components/shared/developer/DeviceInfo.vue";

import AppBar from "@/components/app/appBar/AppBar.vue";
import NavigationDrawer from "@/components/app/navigationDrawer/NavigationDrawer.vue";

//Functions
import INavigationDrawer from "./models/components/navigation/Navigation";

@Component({
  components: {
    Alert,
    Snackbar,
    ConfirmPopup,
    AppBar,
    NavigationDrawer,
    DeviceInfo,
  },
})
export default class App extends Vue {
  // $NavigationDraver state
  // navMini = false;
  // drawer = false;

  get navigation(): INavigationDrawer {
    return this.$store.direct.state.navigation;
  }

  set navigation(object: INavigationDrawer) {
    this.$store.direct.commit.SET_NAVIGATION(object);
  }

  // @Watch("navMini")
  // setNavState() {
  //   localStorage.setItem("navigation", this.navigation.mini.toString()); //set value to localStorage
  // }

  // getNavState(): boolean {
  //   const navMini = localStorage.getItem("navigation");
  //   if (navMini == null) {
  //     localStorage.setItem("navigation", "false");
  //     return false;
  //   } else {
  //     return stringToBoolean(navMini);
  //   }
  // }

  // $User info
  get isLogged(): boolean {
    return store.getters.user.isLogged;
  }

  get user(): string | null {
    return store.state.user.userLogin;
  }

  // $Popups
  get showAlerts(): boolean {
    return store.state.popup.showAlerts;
  }

  get showSnackbars(): boolean {
    return store.state.popup.showSnackbars;
  }

  get showConfirms(): boolean {
    return store.state.popup.showConfirms;
  }

  // $Request
  get isLoading(): boolean {
    return store.getters.isLoading;
  }

  async created(): Promise<void> {
    if (store.getters.user.isLogged) {
      console.log("restored");
      await store.dispatch.user.restoreToken(); // $restore token for axios
    }
    // this.navigation.mini = this.getNavState();
  }
}
</script>

<style lang="scss">
.select-menu {
  border-radius: 10px;
}

.router {
  position: relative;
  height: 100vh; //set max screeen size
  overflow: hidden; //hide scrollbar
}
</style>
