<template>
  <custom-dialog :title="$t('dialog.editUser') | capitalize" width="500px" @cancel="cancel" @save="save">
    <user-edit-form ref="userForm" :user.sync="user" :valid.sync="valid" />
  </custom-dialog>
</template>

<script lang="ts">
import { Component, Vue, PropSync, Prop } from "vue-property-decorator";

//Components
import CustomDialog from "@/components/shared/layout/CustomDialog.vue";
import UserEditForm from "@/components/administration/forms/UserEditForm.vue";

//Models
import UserDetails from "@/models/api/user/UserDetails";

@Component({
  components: {
    CustomDialog,
    UserEditForm,
  },
})
export default class AdministrationEditUserDialog extends Vue {
  @PropSync("value", { required: true })
  show?: boolean;
  @Prop()
  user!: UserDetails;

  valid = true;

  async save() {
    (this.$refs.userForm as HTMLFormElement).validate();
    if (!this.valid) return;
    const response = await this.$store.direct.dispatch.administration.editUser(this.user);
    this.show = !response;
  }

  cancel() {
    this.show = false;
  }
}
</script>

<style lang="scss" scoped></style>
