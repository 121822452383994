<template>
  <v-container fluid class="view-container">
    <v-col xl="6" lg="8">
      <v-card>
        <v-card-title>Ustawienia</v-card-title>
        <v-card-text>
          <v-list-item v-for="(item, i) in settings" :key="i">
            <v-list-item-content style="display: contents">
              <div class="d-flex align-center" style="gap: 20px">
                <v-text-field v-model="item.value" :label="getLabel(item.keyStr)"></v-text-field>
                <v-btn class="btn main-light" @click="setSetting(item)">Aktualizuj</v-btn>
              </div>
            </v-list-item-content>
          </v-list-item>
        </v-card-text>
      </v-card>
    </v-col>
  </v-container>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

//Components
import BackgroundImage from "@/components/shared/layout/BackgroundImage.vue";

// Models
import Setting from "@/models/api/setting/Setting";
import { ConfirmModel } from "@/models/components/popup/Confirm";

//Translations
import { filterCapitalize } from "@/filters/capitalize";
import i18n from "@/plugins/i18n";
import { bold } from "@/filters/bold";

@Component({
  components: { BackgroundImage },
})
export default class SettingsView extends Vue {
  get settings(): Setting[] {
    return this.$store.direct.state.setting.settings;
  }

  getLabel(label: string): string {
    var key = label.charAt(0).toLowerCase() + label.slice(1);
    var value = filterCapitalize(i18n.t(`settings.${key}`));
    return value;
  }

  async setSetting(tmp: Setting) {
    const confirmModel = new ConfirmModel(
      filterCapitalize(i18n.t("confirm.information")),
      filterCapitalize(i18n.t("confirm.settings.update", { key: bold(tmp.keyStr) }))
    );
    const isConfirmed = await this.$store.direct.dispatch.popup.addConfirm(confirmModel);
    if (isConfirmed) {
      await this.$store.direct.dispatch.setting.setSetting(tmp);
    }
  }

  async created() {
    await this.$store.direct.dispatch.setting.settings();
  }
}
</script>

<style lang="scss" scoped></style>
