<template>
  <input-text v-model="_criterion.value" :label="_criterion.label" :solo="true" :padding="false" :width="width" />
</template>

<script lang="ts">
import { Component, Vue, PropSync, Prop } from "vue-property-decorator";

//Components
import InputText from "@/components/shared/inputs/InputText.vue";

//Models
import { CriterionBase } from "@/models/api/filters/CriterionBase";

export interface StringCriterionSettings extends CriterionBase {
  value: string;
}

@Component({
  components: {
    InputText,
  },
})
export default class StringCriterion extends Vue {
  @PropSync("criterion")
  _criterion!: StringCriterionSettings;
  @Prop({ default: 150 })
  width!: number;
}
</script>

<style lang="scss" scoped></style>
