import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import "font-awesome/css/font-awesome.min.css";

//Custom icons
import { icons } from "@/components/shared/icons/iconfont";
import i18n from "./i18n";

Vue.use(Vuetify);

const vuetify = new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: "#3f51b5",
        secondary: "#b0bec5",
        accent: "#76AFC1",
        error: "#BD0555",
        warning: "#5b5665",
        success: "#d4ab5b",
        button: "#000000",
        disabled: "#E1E1E1",
      },
    },
  },
  lang: {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    t: (key, ...params) => i18n.t(key, params),
  },
  icons: {
    values: icons,
  },
  breakpoint: {
    mobileBreakpoint: 768, //TODO -> set value
  },
});

export default vuetify;
