<template>
  <div class="footer">
    <div class="footer-background-container">
      <div class="footer-background">
        <img src="@/assets/images/mark2.svg" />
      </div>
      <div class="footer-logo">
        <img src="@/assets/images/eu.jpg" />
      </div>
      <div class="footer-copyright">
        <span>Copyright by SolvSoft 2023</span>
      </div>
      <div class="footer-content">
        <div class="footer-media">
          <v-btn icon @click="$router.redirectToFacebook()">
            <v-icon size="24">$facebook</v-icon>
          </v-btn>
          <v-btn icon @click="$router.redirectToLinkedIn()">
            <v-icon size="24">$linkedIn</v-icon>
          </v-btn>
        </div>
        <div style="align-self: end">
          <div class="footer-links">
            <go-to :name="0" label="Home" />
            <go-to name="#products" label="Cennik" />
            <go-to name="#about" label="o nas" />
            <go-to name="#contact" label="Kontakt" />
          </div>
          <div class="footer-links">
            <router-link-new-tab to="/terms-of-use" type="button"
              >{{ $t("form.termsOfUse") | capitalize }}
            </router-link-new-tab>
            <router-link-new-tab to="/privacy-policy" type="button"
              >{{ $t("form.privacyPolicy") | capitalize }}
            </router-link-new-tab>
          </div>
        </div>
        <div class="footer-details">
          <img src="@/assets/images/logoBlack.svg" />
          <h3>Siedziba</h3>
          <p>
            <span>Kielnarowa 108b</span>
            <span>36-020 Tyczyn</span>
            <span>Tel. 17 779 64 13</span>
            <span>biuro@sukcesja.pl</span>
          </p>
          <p>
            <span>KRS 0000466256</span>
            <span>NIP 7010374912</span>
            <span>REGON 146615458</span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import GoTo from "@/components/shared/buttons/GoTo.vue";
import RouterLinkNewTab from "@/components/shared/buttons/RouterLinkNewTab.vue";

@Component({
  components: {
    GoTo,
    RouterLinkNewTab,
  },
})
export default class FooterDesktop extends Vue {}
</script>

<style lang="scss" scoped>
.footer {
  display: flex;
  height: 500px;
  overflow-y: hidden;
  overflow-x: hidden;
}

.footer-copyright {
  position: absolute;
  bottom: 0;
  height: 23px;
  width: 100%;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    font-size: 0.85rem;
    font-weight: 100;
    color: #fff;
  }
}

.footer-media {
  width: 150px;
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
  margin-bottom: 70px;
  // margin-right: 50px;
}

.footer-logo {
  width: 100%;
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 30px;
  padding: 0 150px;
  img {
    height: 100%;
    width: 100%;
    object-fit: scale-down;
  }
}

.footer-content {
  position: absolute;
  bottom: 120px;
  right: 50px;
  left: 50px;
  display: flex;
  justify-content: space-around;
}

.footer-links {
  width: 500px;
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
  margin-bottom: 70px;
}

.footer-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}

.footer-background-container {
  overflow-y: hidden;
  position: absolute;
  width: 100vw;
  left: 0px;
  height: 500px;
  overflow-x: clip;
}

.footer-background {
  width: 2700px;
  img {
    width: 100%;
    height: 100%;
  }
}

p {
  text-align: end;
  span {
    display: block;
    line-height: 1.6rem;
  }
}

@media screen and (max-width: 3840px) {
  .footer-background {
    width: 3200px;
  }
}

@media screen and (max-width: 1920px) {
  .footer-background {
    width: 2700px;
  }
}

@media screen and (max-width: 765px) {
  .footer-background {
    width: 2000px;
  }
  .footer-background-container {
    height: 400px;
  }
  .footer {
    height: 400px;
  }
  // .footer-links {
  //   flex-direction: column;
  //   margin-bottom: 0;
  // }
}
</style>
