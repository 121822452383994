<template>
  <span class="scaling-svg-container">
    <svg xmlns="http://www.w3.org/2000/svg" width="16.002" height="16" viewBox="0 0 16.002 16">
      <path
        d="M8,16A8.083,8.083,0,0,1,.577,10.982,7.9,7.9,0,0,1,.649,4.872,7.837,7.837,0,0,1,4.8.655.777.777,0,0,1,5.087.6a.691.691,0,0,1,.655.42.774.774,0,0,1,0,.589.656.656,0,0,1-.359.356A6.921,6.921,0,0,0,1.959,5.454a6.611,6.611,0,0,0-.027,5.027,6.407,6.407,0,0,0,3.52,3.554A6.647,6.647,0,0,0,8,14.544a6.491,6.491,0,0,0,6.035-4,.691.691,0,0,1,.655-.419.775.775,0,0,1,.29.056.656.656,0,0,1,.373.391.783.783,0,0,1-.009.555,7.685,7.685,0,0,1-2.964,3.582A8.044,8.044,0,0,1,8,16Zm7.275-7.273H8A.689.689,0,0,1,7.27,8V.727A.689.689,0,0,1,8,0a7.978,7.978,0,0,1,8,8A.688.688,0,0,1,15.272,8.727Zm-6.548-7.2V7.273h5.747A6.522,6.522,0,0,0,8.725,1.527Z"
        transform="translate(0.002)"
        fill="#000"
      />
    </svg>
  </span>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

@Component
export default class ReportIcon extends Vue {}
</script>

<style></style>
